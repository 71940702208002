import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitMediaQuiz } from '../../redux/actions/quiz';
import Button from '../Button/Button';

import styles from './styles.module.css';

const QuizQuestions = ({
  questionJson,
  onQuizStateChange,
  onQuizStatusChange,
  retried,
  onChangeRetried,
  episodeNumber
}) => {
  const [answerIndex, setAnswerIndex] = useState(0);
  const [selected, setSelected] = useState(0);
  const [selectedItem, setSelectedItem] = useState([]);
  const dispatch = useDispatch();
  const quizId = useSelector((state) => state.quiz.quizDetail.id);
  const selectAnswer = (questionId, answerId, index) => {
    const selectedItemtemp = selectedItem;
    selectedItemtemp[index] = {
      questionId,
      answerId
    };

    setSelectedItem(selectedItemtemp);
    setSelected(answerId);
  };

  const handleNextPage = () => {
    if (answerIndex + 1 < questionJson.length) {
      setAnswerIndex(answerIndex + 1);
      setSelected(0);
    }
  };

  const handlePrevPage = () => {
    if (answerIndex >= 0) {
      setAnswerIndex(answerIndex - 1);
    }
  };

  const handleSubmitAnswer = async (quizContentId, answers) => {
    const result = await dispatch(submitMediaQuiz(quizContentId, answers));
    if (result === true) {
      onQuizStateChange('quizResult');
      onQuizStatusChange('success');
    } else if (result !== true && !retried) {
      onQuizStateChange('quizResult');
      onQuizStatusChange('failed');
      onChangeRetried(true);
    } else {
      onQuizStateChange('quizResult');
      onQuizStatusChange('failedAgain');
    }
  };

  return (
    <div className={styles['questionwrapper']}>
      <div className={styles['content']}>
        <div className={styles['header']}>
          Quiz Episode {episodeNumber}{' '}
          <div className={styles['counter']}>
            {answerIndex + 1}/{questionJson.length}
          </div>
        </div>
      </div>
      <div>{questionJson[answerIndex].question}</div>
      <div className={styles['action']}>
        <div className={styles['questions']}>
          {questionJson[answerIndex]?.choice?.map((item) => {
            return (
              <Button
                key={item.id}
                id={item.id}
                shape='rounded'
                type={selected === item.id ? 'primary' : 'text'}
                textWrap={false}
                onClick={() => {
                  selectAnswer(
                    questionJson[answerIndex].id,
                    item.id,
                    answerIndex
                  );
                }}
              >
                {`${String.fromCharCode(64 + (item?.id || 0))}. ${item?.text}`}
              </Button>
            );
          })}
        </div>
        <div className={styles['actionButton']}>
          {answerIndex !== 0 && (
            <Button
              id='yes'
              color='dark'
              textColor='#F49832'
              width='343px'
              shape='rounded'
              onClick={handlePrevPage}
            >
              Kembali
            </Button>
          )}
          {answerIndex + 1 !== questionJson.length && (
            <Button
              id='no'
              color='primary'
              textColor='primary'
              width='343px'
              shape='rounded'
              onClick={handleNextPage}
              disabled={!selected}
            >
              Berikutnya
            </Button>
          )}
          {answerIndex + 1 === questionJson.length && (
            <Button
              id='no'
              color='primary'
              textColor='primary'
              width='343px'
              shape='rounded'
              disabled={!selected}
              onClick={() => {
                handleSubmitAnswer(quizId, selectedItem);
              }}
            >
              Kirim
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizQuestions;
