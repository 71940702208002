import React from 'react';
import { formatSecsDuration } from '../../utils';
import styles from './Item.module.css';

import icMiniPlayCircle from '../../assets/ic-mini-play.svg';

const PositionOverlay = ({ item }) => {
  return (
    <div className={styles['progress-wrapper']}>
      <div className={styles['progress-detail']}>
        <div>
          <img
            src={icMiniPlayCircle}
            alt='mini-play-icon'
          />
        </div>
        <span>{formatSecsDuration(item.duration, { format: 'mm ss' })}</span>
      </div>
      <div className={styles['progress-bar']}>
        <div
          style={{
            width: `${item.position.percentage}%`,
            height: '100%',
            backgroundColor: '#F20303'
          }}
        />
      </div>
    </div>
  );
};

export default PositionOverlay;
