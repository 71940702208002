import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PageWrapper, Button, Loading } from '../../components';
import { redeemVoucher } from '../../redux/actions/subscription';

import voucherCardImg from '../../assets/voucher_card.svg';

import styles from './Voucher.module.css';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { PAYMENT_GATEWAY_URL } from '../../config/URL';
import { generateErrorDetail, parseQuery } from '../../utils';
import { packageid } from '../../constants';

const VoucherRedeem = () => {
  const location = useLocation();
  const { code: voucherCode } = parseQuery(location?.search);
  const dispatch = useDispatch();
  const [code, setCode] = useState(voucherCode || '');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    analytic(analyticTypes.trackPage, 'VoucherRedeem', { user });
  }, [user]);

  const handleChangeValue = (e) => {
    setCode(e.target.value);
  };

  const handleRedeem = async (e) => {
    e.preventDefault();
    setSuccess('');
    setError('');
    setLoading(true);
    try {
      const data = await dispatch(redeemVoucher(code));
      setLoading(false);
      if (data && data.data) {
        setSuccess('Successfuly redeemed');

        const voucher = data.data[0];

        const paramsEvent = {
          voucherCode,
          voucherNominal: voucher.package.price,
          voucherName: voucher.package.name,
          startSubscription: voucher.subscription.startAt,
          endSubscription: voucher.subscription.endAt,
          payableType: voucher.payments[0].payableType,
          voucherPrice: voucher.package.price,
          redeemDate: voucher.subscription.startAt
        };
        analytic(analyticTypes.event, analyticEvents.VOUCHER.COMPLETE_REDEEM, {
          params: paramsEvent,
          user
        });
      }
    } catch (redeemError) {
      setLoading(false);
      const errorData = generateErrorDetail(redeemError);

      if (errorData) {
        switch (errorData.code) {
          case 'gft4000603':
            return (window.location.href = `${PAYMENT_GATEWAY_URL}/?member=${user.account.id}&plan=${packageid}&promo_code=${code}`);
          case 'gft4000602':
            return setError('Voucher has been redeemed');
          case 'gft4000605':
            return setError('Campaign has not started');
          default:
            setError(`${errorData.message} (#${errorData.code})`);
        }
      }
    }
  };

  return (
    <PageWrapper>
      <p className={styles['title']}>Redeem Voucher</p>
      <form
        className={styles['form-wrapper']}
        onSubmit={handleRedeem}
      >
        <input
          placeholder='Voucher Code'
          value={code}
          onChange={handleChangeValue}
        />
        <Button
          type='submit'
          disabled={!code.length}
        >
          Redeem Voucher
        </Button>
      </form>
      {loading && <Loading />}
      {success && <p className={styles['redeemSuccess']}>{success}</p>}
      {error && <p className={styles['redeemError']}>{error}</p>}
      <img
        src={voucherCardImg}
        alt='voucher card'
        className={styles['voucherImg']}
      />
    </PageWrapper>
  );
};

export default VoucherRedeem;
