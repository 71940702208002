const initialState = {
  // campain banner list state
  data: [],
  loading: false,
  isNotEmpty: false,
  err: false,

  // campaign detail state
  detailData: null,
  detailLoading: false,
  detailError: null,

  // campaign UGC leaderboard state
  leaderboardState: null,
  loadingLeaderboard: false,
  leaderboardError: null,

  // campaign ugc
  campaigns: {
    data: [],
    ugcDetail: null,
    loading: false,
    isNotEmpty: false,
    count: 0,
    err: null
  }
};

export default function campaignReducer(state = initialState, action) {
  const { type, payload, err, isNotEmpty, count } = action;

  switch (type) {
    case 'GET_CAMPAIGN_CAROUSEL':
      return {
        ...state,
        loading: true
      };

    case 'GET_CAMPAIGN_CAROUSEL_SUCCESS':
      return {
        ...state,
        data: payload,
        loading: false,
        isNotEmpty
      };

    case 'GET_CAMPAIGN_CAROUSEL_SUCCESS_FROM_STATE':
      return {
        ...state,
        data: payload,
        loading: false,
        isNotEmpty
      };

    case 'GET_CAMPAIGN_CAROUSEL_FAILED':
      return {
        ...state,
        loading: false,
        err: true
      };

    case 'GET_CAMPAIGN_DETAIL':
      return {
        ...state,
        detailLoading: true,
        detailError: null
      };

    case 'GET_CAMPAIGN_DETAIL_SUCCESS':
      return {
        ...state,
        detailData: payload?.props,
        detailLoading: false,
        detailError: null
      };

    case 'GET_CAMPAIGN_DETAIL_SUCCESS_FROM_STATE':
      return {
        ...state,
        detailData: payload?.props,
        detailLoading: false,
        detailError: null
      };

    case 'GET_CAMPAIGN_DETAIL_FAILED':
      return {
        ...state,
        detailLoading: false,
        detailError: err
      };

    case 'GET_CAMPAIGN_LIST':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: true,
          err: null
        }
      };

    case 'GET_CAMPAIGN_LIST_SUCCESS':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          data: payload,
          loading: false,
          isNotEmpty,
          count
        }
      };

    case 'GET_CAMPAIGN_LIST_SUCCESS_FROM_STATE':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          data: payload,
          loading: false
        }
      };

    case 'GET_CAMPAIGN_LIST_FAILED':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: false,
          err
        }
      };

    case 'GET_CAMPAIGN_UGC_DETAIL':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: true,
          err: null
        }
      };

    case 'GET_CAMPAIGN_UGC_DETAIL_SUCCESS':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          ugcDetail: payload,
          loading: false
        }
      };

    case 'GET_CAMPAIGN_UGC_DETAIL_SUCCESS_FROM_STATE':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: false
        }
      };

    case 'GET_CAMPAIGN_UGC_DETAIL_FAILED':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          loading: false,
          err
        }
      };

    case 'GET_CAMPAIGN_UGC_LEADERBOARD': // loading leaderboard
      return {
        ...state,
        loadingLeaderboard: true
      };

    case 'GET_CAMPAIGN_UGC_LEADERBOARD_SUCCESS': // success leaderboard
      return {
        ...state,
        leaderboardState: payload,
        loadingLeaderboard: false,
        leaderboardError: null
      };

    case 'GET_CAMPAIGN_UGC_LEADERBOARD_SUCCESS_FROM_STATE': // success leaderboard
      return {
        ...state,
        loadingLeaderboard: false,
        leaderboardError: null
      };

    case 'GET_CAMPAIGN_UGC_LEADERBOARD_FAILED': // error leaderboard
      return {
        ...state,
        loadingLeaderboard: false,
        leaderboardError: payload
      };

    case 'RESET_CAMPAIGN_UGC':
      return {
        ...initialState
      };

    default:
      return {
        ...state
      };
  }
}
