import React from 'react';
import TextTruncated from '../TextTruncated/TextTruncated';

import styles from './EpisodeItem.module.css';

const EpisodeItem = ({ item, onClickItem }) => {
  const {
    titleLocalized,
    // ordinal: episodeNumber,
    duration,
    descriptionLongLocalized: description
  } = item;
  const thumbnail = item?.thumbnail_portrait || '';

  return (
    <div
      className={styles['episode-item-wrapper']}
      onClick={onClickItem}
    >
      <img
        className={styles['episode-thumbnail']}
        src={thumbnail}
        alt='episode thumbnail'
      />
      <div className={styles['episode-title']}>{titleLocalized}</div>
      <p className={styles['episode-duration']}>{duration}</p>
      <div className={styles['episode-description']}>
        <TextTruncated
          maxLength={120}
          content={description}
          readMore={false}
        />
      </div>
    </div>
  );
};

export default EpisodeItem;
