import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import QuizOffer from './QuizOffer';
import QuizQuestions from './QuizQuestions';
import close from '../../assets/Close.svg';
import styles from './styles.module.css';
import QuizResult from './QuizResult';
import { getSingleMediaInfo } from '../../redux/actions/media';

const QuizPopup = ({ onClose, questionJson, quizContentId }) => {
  const [quizState, setQuizState] = useState('quizOffer'); // params: quizOffer,quizQuestions,quizResult
  const [quizStatus, setQuizStatus] = useState('success'); // params: 'success',failed,failedAgain,notEligible
  const [retried, setRetried] = useState(false);
  const videoId = useSelector((state) => state?.quiz?.quizDetail?.videoId);
  const [episodeNumber, setEpisodeNumber] = useState(0);

  const handleQuizStateChange = (state) => {
    setQuizState(state);
  };

  const handleQuizStatusChange = (state) => {
    setQuizStatus(state);
  };

  const handleGetEpisodeNumber = useCallback(async () => {
    if (videoId !== undefined) {
      const episodeData = await getSingleMediaInfo(videoId);
      setEpisodeNumber(episodeData.ordinal);
      // console.log('quiz episode data get!');
    }
  }, [videoId]);

  useEffect(() => {
    handleGetEpisodeNumber();
  }, [handleGetEpisodeNumber]);

  return (
    <div className={styles['backdrop']}>
      <div
        className={styles['closeButton']}
        onClick={onClose}
      >
        <img
          src={close}
          alt='close'
        />
      </div>
      {quizState === 'quizOffer' && (
        <QuizOffer
          onQuizStateChange={handleQuizStateChange}
          onQuizClose={onClose}
          onQuizStatusChange={handleQuizStatusChange}
        />
      )}
      {quizState === 'quizQuestions' && (
        <QuizQuestions
          questionJson={questionJson}
          onQuizStateChange={handleQuizStateChange}
          onQuizStatusChange={handleQuizStatusChange}
          quizContentId={quizContentId}
          retried={retried}
          onChangeRetried={setRetried}
          episodeNumber={episodeNumber}
        />
      )}
      {quizState === 'quizResult' && (
        <QuizResult
          status={quizStatus}
          onQuizClose={onClose}
          onQuizStateChange={handleQuizStateChange}
          onQuizStatusChange={handleQuizStatusChange}
        />
      )}
    </div>
  );
};

export default QuizPopup;
