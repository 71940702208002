import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSTransition from 'react-transition-group/CSSTransition';
import { useOutsideHandle } from '../../hooks';
import { toggleDialogNotif } from '../../redux/actions/inAppNotification';
import Portal from '../Modal/Portal';

import styles from './PopModalDialog.module.css';

const PopModalNotification = ({
  children,
  content,
  placement = 'bottom', // top, left, right, bottom, top-left, top-right, bottom-left, bottom-right, left-top, left-bottom, right-top, right-bottom
  spacing = 10,
  modalStyle,
  zIndex,
  customXBase, // custom base container node, default is div.button-pop-modal width
  customYBase, // custom base container node, default is div.button-pop-modal height
  action = 'click'
}) => {
  const popModalRef = useRef();
  const containerRef = useRef();
  const arrowRef = useRef();
  const dispatch = useDispatch();
  const { openDialog } = useSelector((state) => state.inAppNotification);

  const handleClickOutsideDialog = useCallback(
    (target) => {
      const checkParent = (el) => {
        if (el?.className?.includes(styles['button-pop-modal'])) return true;
        return el.parentNode && checkParent(el.parentNode);
      };
      // ignore user-profile-icon to use its onClick func
      if (target === containerRef.current.children[0] || checkParent(target)) {
        return;
      }
      if (openDialog) {
        dispatch(toggleDialogNotif());
      }
    },
    [openDialog, dispatch]
  );

  useOutsideHandle(popModalRef, handleClickOutsideDialog);

  useLayoutEffect(() => {
    const containerEl = containerRef.current;
    const popModalEl = popModalRef.current;
    const arrowEl = arrowRef.current;

    if (containerEl && popModalEl && arrowEl) {
      const boundingContainer = containerEl.getBoundingClientRect();
      const boundinCustomXBase =
        customXBase && customXBase.getBoundingClientRect();
      const boundinCustomYBase =
        customYBase && customYBase.getBoundingClientRect();
      const baseXDOMRect = boundinCustomXBase || boundingContainer;
      const baseYDOMRect = boundinCustomYBase || boundingContainer;

      // x axis base container
      const containerWidth = baseXDOMRect.width;
      const containerLeft = baseXDOMRect.left;
      const containerRight = baseXDOMRect.right;

      // y axis base container
      const containerHeight = baseYDOMRect.height;
      const containerTop = baseYDOMRect.top;
      // const containerBottom = baseYDOMRect.bottom

      const contentDOMRect = popModalEl.getBoundingClientRect();
      const contentWidth = contentDOMRect.width;
      // const contentHeight = popModalEl.offsetHeight

      // const arrowWidth = arrowEl.offsetWidth
      const arrowHeight = arrowEl.offsetHeight - 10;

      const halfContainerWidth = containerWidth / 2;
      // const halfContainerHeight = containerHeight / 2
      const halftContentWidth = contentWidth / 2;
      // const halfContentHeight = contentHeight / 2

      if (placement) {
        if (placement.includes('bottom')) {
          popModalEl.style.top = `${
            containerHeight + containerTop + arrowHeight
          }px`;

          arrowEl.style.top = '-24px';

          popModalEl.style.left = `${
            containerLeft + halfContainerWidth - halftContentWidth
          }px`;

          arrowEl.style.top = '-24px';
          arrowEl.style.left = '50%';
          arrowEl.style.right = 'initial';
          arrowEl.style.transform = 'translateX(-50%)';
        }
        if (placement.includes('right')) {
          popModalEl.style.left = 'initial';
          popModalEl.style.right = `${
            window.innerWidth - containerRight - 10
          }px`;

          arrowEl.style.top = '-24px';
          arrowEl.style.left = 'initial';
          arrowEl.style.right = '25px';
          arrowEl.style.transform = 'none';
        }
      }
    }
  }, [openDialog, placement, spacing, customXBase, customYBase]);

  const handleButtonClick = () => {
    dispatch(toggleDialogNotif());
  };

  const handleOnHover = () => {
    dispatch(toggleDialogNotif());
  };

  return (
    <>
      <div
        ref={containerRef}
        className={styles['button-pop-modal']}
        data-action={action}
        {...{
          // ...action === 'click' && { onClick: handleButtonClick },
          ...(action === 'hover' && { onMouseEnter: handleOnHover })
        }}
        onClick={handleButtonClick}
      >
        {children}
      </div>
      <Portal>
        <CSSTransition
          in={openDialog}
          timeout={300}
          classNames='fade'
          unmountOnExit
        >
          <div
            ref={popModalRef}
            className={styles['pop-modal-dialog']}
            style={{ ...modalStyle, zIndex }}
          >
            <div className={styles['pop-modal-content']}>
              <div
                ref={arrowRef}
                className={styles['pop-modal-arrow']}
              />
              {content}
            </div>
          </div>
        </CSSTransition>
      </Portal>
    </>
  );
};

export default PopModalNotification;
