// /* global videojs */
import React from 'react';
import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';
import {
  registerIVSTech,
  isPlayerSupported,
  registerIVSQualityPlugin
} from 'amazon-ivs-player';
import videojs from 'video.js';
import { connect } from 'react-redux';
import { withRouter } from '../../hooks';
import Loading from '../Loading/Loading';

import './CustomControlButton/CustomControlButton';
import PlayerSubscription from './PlayerSubscription';
import styles from './VideoPlayer.module.css';
import VideoInterval from './VideoInterval';
import analytic, { analyticTypes } from '../../service/analytic';

import 'videojs-contrib-ads';
import 'videojs-ima';
import 'video.js/dist/video-js.css';
// import { ADS_TAG_URL } from '../../config/URL';
import { getAppSettings } from '../../redux/actions/settings';
import FullScreenMode from './FullScreenMode';
import { settingKeys } from '../../hooks/useSettings';

const { adTagUrlKey } = settingKeys;

const createAbsolutePath = (assetPath) =>
  new URL(assetPath, document.URL).toString();

class LiveShowVODPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // showDissmissIcon: false,
      runInterval: false,
      mediaStart: false,
      loading: false,
      error: null,
      // showBullet: false,
      // isHideComment: false,
      videoVertical: true,
      isShowStreamerInfo: true,
      isFullscreenNow: false
    };
  }

  async componentDidMount() {
    const { dispatchGetAppSettings } = this.props;
    dispatchGetAppSettings();

    const { stream, user } = this.props;
    if (!stream || (stream && stream.error)) return;

    const videoOptions = {
      controls: true,
      autoplay: true,
      responsive: true,
      controlBar: {
        pictureInPictureToggle: false
      },
      aspectRatio: '16:9',
      html5: {
        nativeCaptions: false
      },
      // make the text track settings dialog not initialize
      textTrackSettings: false
    };
    // const imaOptions = {
    //   adTagUrl: this.props.appSettingAdsTagUrl !== undefined ? this.props.appSettingAdsTagUrl : ADS_TAG_URL,
    // };

    // register Amazon IVS as playback technology for videojs
    if (isPlayerSupported) {
      // eslint-disable-next-line no-console
      console.log('IVS supported');
      registerIVSTech(videojs, {
        wasmWorker: createAbsolutePath(wasmWorkerPath),
        wasmBinary: createAbsolutePath(wasmBinaryPath)
      });
      registerIVSQualityPlugin(videojs);
      // integrate aws IVS plugin
      videoOptions.techOrder = ['AmazonIVS', 'html5'];
    }

    // instantiate Video.js
    this.player = videojs(
      this.videoNode,
      videoOptions,
      function onPlayerReady() {
        // eslint-disable-next-line no-console
        console.log('onPlayerReady', this);
        const _player = this.player();
        // auto play
        _player.autoplay('any');
      }
    );

    // handle ads no show on user have subscriptions and VOD video
    // if (!this.props.userSubscriptions?.length >= 1) {
    //   this.player.ima(imaOptions);
    // }

    isPlayerSupported && this.player.enableIVSQualityPlugin();
    this.player.src(stream);

    const vjsControll = document.getElementsByClassName(
      'vjs-progress-control'
    )[0];
    const vjsCurrentTime =
      document.getElementsByClassName('vjs-current-time')[0];

    if (vjsControll && vjsCurrentTime) {
      vjsControll.style.display = 'block';
      vjsCurrentTime.style.display = 'block';
    }

    analytic(analyticTypes.trackPage, 'LiveShowVODPlayer', { user });

    this.addEventListener();
  }

  componentDidUpdate() {
    const { authPopupStatus } = this.props;
    if (authPopupStatus) {
      if (this.player.isFullscreen()) {
        this.player.exitFullscreen();
      }
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  addEventListener = () => {
    this.player.on('play', () => {
      // console.log('video started', e);
      this.setState({ mediaStart: true });
      this.setState({ runInterval: true });
      if (this.pausing) {
        this.pausing = false;
      }

      // this.setState(() => ({
      //   showDissmissIcon: true
      // }));
    });

    this.player.on('pause', () => {
      if (!this.player.seeking()) {
        this.pausing = true;
        this.setState({ runInterval: false });
      }
    });

    this.player.on('durationchange', () => {});

    this.player.on('useractive', () => {
      this.setState(() => ({
        // showDissmissIcon: true,
        isShowStreamerInfo: true
      }));
    });

    this.player.on('ended', () => {
      this.setState({ mediaStart: false });
    });

    this.player.on('userinactive', () => {
      this.setState(() => ({
        // showDissmissIcon: false,
        isShowStreamerInfo: false
      }));
    });

    this.player.on('error', (e) => {
      // eslint-disable-next-line no-console
      console.log('error', e);
      return this.setState(() => ({
        error: {
          code: 'player-error',
          message: 'General Player Error!'
        }
      }));
    });

    document.addEventListener('fullscreenchange', this.handleFullScreen);
  };

  updateHistoryCallback = () => {
    const { handleUpdateHistory } = this.props;
    const { mediaStart } = this.state;
    if (this.player && mediaStart) {
      handleUpdateHistory(this.player.currentTime(), this.player.duration());
    }
  };

  handleFullScreen = () => {
    if (this.player.isFullscreen()) {
      const videoWidth = document?.querySelector('.vjs-tech')?.videoWidth;
      const videoHeight = document?.querySelector('.vjs-tech')?.videoHeight;

      const isVertical = videoHeight > videoWidth;

      this.setState({
        isFullscreenNow: true,
        videoVertical: isVertical
      });
    } else {
      this.setState({
        isFullscreenNow: false
      });
    }
  };

  exitFullScreen = () => {
    if (this.player.isFullscreen()) {
      this.player.exitFullscreen();
    }
  };

  renderError = (error) => {
    const { onDismissPlayer } = this.props;
    const content = <p>{`${error.message} (# ${error.code})`}</p>;

    return (
      <div className={styles['errorWrapper']}>
        <div
          className='vjs-dismiss-btn-wrapper'
          onClick={onDismissPlayer}
        >
          <i className='vjs-dismiss-player-icon' />
        </div>
        {content}
      </div>
    );
  };

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  // isRelative flag for the interactive live stream
  render() {
    const {
      subtitles,
      onDismissPlayer,
      stream,
      viewers,
      isRelative,
      bulletComment,
      basicGift,
      premiumGift,
      lastJoined,
      isVideoStop,
      videoInfo,
      ...otherProps
    } = this.props;
    const {
      loading,
      error,
      runInterval,
      isFullscreenNow,
      isShowStreamerInfo,
      videoVertical
    } = this.state;

    if (stream && stream.error) {
      return (
        <PlayerSubscription
          isInteractive={isRelative} // Give flag is relative when the player is for interactive
          onDismissPlayer={onDismissPlayer}
          otherProps={otherProps}
          error={stream.error}
        />
      );
    }

    if (error) {
      return this.renderError(error);
    }

    if (isVideoStop && this?.player?.pause) {
      this.player.pause();
    }

    return loading ? (
      <Loading height='100vh' />
    ) : (
      <div
        className='live-player live-game-player'
        style={{ aspectRatio: '16/9' }}
      >
        {runInterval && (
          <VideoInterval updateHistoryCallback={this.updateHistoryCallback} />
        )}
        <div data-vjs-player>
          <video
            ref={(node) => (this.videoNode = node)}
            className='video-js video-js-relative vjs-big-play-centered liveshow'
          >
            {subtitles?.map((item, index) => (
              <track
                key={index}
                src={item.url}
                kind='subtitles'
                srcLang={item.language}
                label={item.label}
              />
            ))}
          </video>
          {isFullscreenNow && (
            <FullScreenMode
              isShowStreamerInfo={isShowStreamerInfo}
              isVerticalVideo={videoVertical}
              isHideComment
              views={videoInfo?.views || 0}
              onExitFullScreen={this.exitFullScreen}
              streamInfo={videoInfo}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userSubscriptions: state.auth.user?.account?.subscriptions,
  appSettingAdsTagUrl: state.appSettings.settings?.[adTagUrlKey],
  authPopupStatus: state.auth.authPopupStatus
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetAppSettings: () => dispatch(getAppSettings([adTagUrlKey]))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LiveShowVODPlayer)
);
