// /* global videojs */
import React from 'react';
import videojs from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';
import 'video.js/dist/video-js.css';
import { connect } from 'react-redux';

import { withRouter } from '../../hooks';
import Loading from '../Loading/Loading';
import VideoInterval from './VideoInterval';
import './CustomControlButton/CustomControlButton';
import PlayerSubscription from './PlayerSubscription';
import styles from './VideoPlayer.module.css';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import './LivePlayer.css';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';
import { errorCode } from '../../constants';

class LivePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDissmissIcon: false,
      runInterval: false,
      mediaStart: false,
      loading: false,
      error: null
    };
  }

  componentDidMount() {
    const { stream, contentType, channelID, user, channelDetail } = this.props;
    if (
      !stream ||
      (stream && stream.error) ||
      stream?.code === errorCode.NO_SUBSCRIPTION
    )
      return;

    const videoOptions = {
      controls: true,
      autoplay: true,
      fluid: true,
      liveui: true,
      responsive: true,
      controlBar: {
        pictureInPictureToggle: false
      },
      aspectRatio: '16:9',
      sources: [
        {
          src: stream,
          type: 'application/x-mpegURL'
        }
      ],
      html5: {
        nativeCaptions: false,
        nativeAudioTracks: false,
        nativeTextTracks: false,
        vhs: {
          enableLowInitialPlaylist: true,
          limitRenditionByPlayerDimensions: false
        }
      },
      vhs: {
        bandwidth: 30000000,
        overrideNative: true
      },
      // make the text track settings dialog not initialize
      textTrackSettings: false
    };

    // instantiate Video.js
    this.player = videojs(
      this.videoNode,
      videoOptions,
      function onPlayerReady() {
        const _player = this.player();
        // auto play
        _player.autoplay('any');
      }
    );

    this.player.src(stream);
    const paramsEvent = {
      contentType,
      channelID
    };

    if (channelDetail.titleLocalized)
      paramsEvent.channelName = channelDetail.titleLocalized;
    if (channelDetail.type) paramsEvent.channelType = channelDetail.type;
    if (channelDetail.contentRights)
      paramsEvent.channelContentRights = channelDetail.contentRights;

    analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_LAUNCH, {
      params: paramsEvent,
      user
    });
    analytic(analyticTypes.trackPage, 'LiveTVPlayer', { user });

    this.addEventListener();
  }

  // destroy player on unmount
  componentWillUnmount() {
    const { contentType, channelID, user, channelDetail } = this.props;

    if (this.player) {
      const paramsEvent = {
        contentType,
        channelID
      };
      if (channelDetail.titleLocalized)
        paramsEvent.channelName = channelDetail.titleLocalized;
      if (channelDetail.type) paramsEvent.channelType = channelDetail.type;
      if (channelDetail.contentRights)
        paramsEvent.channelContentRights = channelDetail.contentRights;

      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_EXIT, {
        params: paramsEvent,
        user
      });
      // this.player.dispose();
    }
  }

  updateHistoryCallback = () => {
    const { handleUpdateHistory } = this.props;
    const { mediaStart } = this.state;
    if (this.player && mediaStart) {
      handleUpdateHistory(this.player.currentTime(), this.player.duration());
    }
  };

  addEventListener = () => {
    const { channelID, contentType, handleUpdateHistory, user, channelDetail } =
      this.props;

    const paramsEvent = {
      contentType,
      channelID
    };
    if (channelDetail.titleLocalized)
      paramsEvent.channelName = channelDetail.titleLocalized;
    if (channelDetail.type) paramsEvent.channelType = channelDetail.type;
    if (channelDetail.contentRights)
      paramsEvent.channelContentRights = channelDetail.contentRights;

    this.player.on('play', () => {
      // console.log('video started', e);
      this.setState({ mediaStart: true });
      this.setState({ runInterval: true });
      if (!this.pausing) handleUpdateHistory(0);
      if (this.pausing) {
        this.pausing = false;

        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_RESUME,
          {
            params: paramsEvent,
            user
          }
        );
      }

      this.setState(() => ({
        showDissmissIcon: true
      }));
    });

    this.player.on('pause', () => {
      if (!this.player.seeking()) {
        this.pausing = true;
        this.setState({ runInterval: false });

        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_PAUSE,
          {
            params: paramsEvent,
            user
          }
        );
      }
    });

    this.player.on('useractive', () => {
      this.setState(() => ({
        showDissmissIcon: true
      }));
    });

    this.player.on('ended', () => {
      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_END, {
        params: paramsEvent,
        user
      });
      this.setState({ mediaStart: false });
    });

    this.player.on('userinactive', () => {
      this.setState(() => ({
        showDissmissIcon: false
      }));
    });

    this.player.on('error', () => {
      return this.setState(() => ({
        error: {
          code: 'player-error',
          message: 'General Player Error!'
        }
      }));
    });
  };

  renderError = (error) => {
    const { onDismissPlayer } = this.props;
    const content = <p>{`${error.message} (# ${error.code})`}</p>;

    return (
      <div className={styles['errorWrapper']}>
        <div
          className='vjs-dismiss-btn-wrapper'
          onClick={onDismissPlayer}
        >
          <i className='vjs-dismiss-player-icon' />
        </div>
        {content}
      </div>
    );
  };

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  // isRelative flag for the interactive live stream
  render() {
    const {
      subtitles,
      onDismissPlayer,
      stream,
      handleUpdateHistory,
      viewers,
      isRelative,
      ...otherProps
    } = this.props;
    const { loading, error, runInterval, showDissmissIcon } = this.state;

    if (
      (stream && stream.error) ||
      stream?.code === errorCode.NO_SUBSCRIPTION
    ) {
      return (
        <GeneralNotifModal
          open
          className='wrapper-playerSubs'
          contentClass='content-playerSubs'
        >
          <PlayerSubscription
            onDismissPlayer={onDismissPlayer}
            otherProps={otherProps}
            error={stream}
          />
        </GeneralNotifModal>
      );
    }

    if (error) {
      return this.renderError(error);
    }

    return loading ? (
      <Loading height='100vh' />
    ) : (
      <div className='live-player'>
        {runInterval && (
          <VideoInterval updateHistoryCallback={this.updateHistoryCallback} />
        )}
        {onDismissPlayer && !!showDissmissIcon && (
          <div
            className={`vjs-dismiss-btn-wrapper ${isRelative && 'relative'}`}
            onClick={onDismissPlayer}
          >
            <i className='vjs-dismiss-player-icon' />
          </div>
        )}
        {!!showDissmissIcon && isRelative && viewers && (
          <div
            className='vjs-viewer-btn-wrapper'
            onClick={onDismissPlayer}
          >
            <i className='vjs-viewer-player-icon' />
            <span>{viewers} </span>
          </div>
        )}
        <div data-vjs-player>
          <video
            ref={(node) => (this.videoNode = node)}
            className={`video-js ${
              isRelative && 'video-js-relative'
            } vjs-big-play-centered`}
          >
            {subtitles?.map((item, index) => (
              <track
                key={index}
                src={item.url}
                kind='subtitles'
                srcLang={item.language}
                label={item.label}
              />
            ))}
          </video>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userSubscriptions: state.auth.user?.account?.subscriptions
});

export default withRouter(connect(mapStateToProps, null)(LivePlayer));
