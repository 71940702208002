import React, { Suspense } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BottomBar, Loading } from '../components';
import {
  Home,
  History,
  MediaPageWrapper,
  Playlist,
  LiveTv,
  Detail,
  // Subscription,
  Voucher,
  Search,
  Payment,
  ForgotPassword,
  ResetPassword,
  NotFound,
  AboutUs,
  Terms,
  PrivacyPolicy,
  Faqs,
  ContactUs,
  EmailVerification,
  ProfileRoutes,
  Stream,
  // LiveShowStream,
  // LiveShowPlaylist,
  // Live,
  Interactive,
  StreamerDashboard,
  PromoPage,
  PromoDetailPage,
  // PartnerPlayer,
  // LiveShowVodStream,
  // ClipsPlaylist,
  // ClipsStream,
  UserLicenseAgreement,
  Shop,
  CampaignDetail,
  QuizDetail,
  TermsCashout,
  // TermsUgc,
  // PreviousLiveShowPage,
  Notifications,
  NotificationDetail,
  // CampaignUgcDetail,
  FormPage
} from '../pages';
// import SideMenu from '../components/SideMenu/SideMenu';
import { useUserDetail } from '../hooks';

const router = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/form',
    element: <FormPage />
  },
  {
    path: '/mobile/contact-us',
    element: <ContactUs />
  },
  {
    path: '/playlists/:playlistId',
    element: <Playlist />
  },
  {
    path: '/history',
    element: <History />,
    loggedIn: true
  },
  {
    path: '/notifications',
    element: <Notifications />,
    loggedIn: true
  },
  {
    path: '/notification/:notificationId',
    element: <NotificationDetail />,
    loggedIn: true
  },
  {
    path: '/subscription',
    element: <Navigate to='/profile/subscription/buy-new' />,
    loggedIn: true
  },
  {
    path: '/shop',
    element: <Shop />,
    loggedIn: true
  },
  {
    path: '/voucher',
    element: <Voucher />,
    loggedIn: true
  },
  {
    path: '/redeem',
    element: <Voucher />,
    loggedIn: true
  },
  {
    path: '/search',
    element: <Search />
  },
  {
    path: '/live-tv',
    element: <LiveTv />
  },
  {
    path: '/live-tv/:channelID?',
    element: <LiveTv />,
    hideBars: true
  },
  // {
  //   path: '/live',
  //   element: <Live />
  // },
  {
    path: '/interactive',
    element: <Interactive />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/email-verification',
    element: <EmailVerification />
  },
  {
    path: '/reset-password',
    element: <ResetPassword />
  },
  {
    path: '/about-us',
    element: <AboutUs />
  },
  {
    path: '/terms',
    element: <Terms />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/faqs',
    element: <Faqs />
  },
  {
    path: '/end-user-license-agreement',
    element: <UserLicenseAgreement />
  },
  {
    path: '/profile/*',
    element: <ProfileRoutes />,
    hideBars: false
  },
  {
    path: '/stream/:channelID?',
    element: <Stream />
  },
  // {
  //   path: '/liveshow/:liveshowId',
  //   element: <LiveShowStream />,
  //   hideBars: true
  // },
  // {
  //   path: '/live-shows/',
  //   element: <LiveShowPlaylist />
  // },
  // {
  //   path: '/previous-live-shows/',
  //   element: <PreviousLiveShowPage />
  // },
  // {
  //   path: '/liveshow-vod/:liveshowId',
  //   element: <LiveShowVodStream />,
  //   hideBars: true
  // },
  {
    path: '/partner-player/:mediaId',
    Component: ({ location }) => {
      return (
        <Navigate to={`/mobile${location.pathname}${location?.search || ''}`} />
      );
    }
  },
  {
    path: '/streamer-dashboard',
    element: <StreamerDashboard />,
    loggedIn: true,
    hideBars: true
  },
  // {
  //   path: '/clips',
  //   element: <ClipsPlaylist />
  // },
  // {
  //   path: '/clips/:mediaId',
  //   element: <ClipsStream />,
  //   hideBars: true
  // },
  {
    path: '/campaign/:campaignId',
    element: <CampaignDetail />
  },
  // {
  //   path: '/campaign-ugc/:campaignId',
  //   element: <CampaignUgcDetail />
  // },
  {
    path: '/quiz/:quizId',
    element: <QuizDetail />
  },
  {
    path: '/terms-cashout',
    element: <TermsCashout />
  },
  // {
  //   path: '/terms-ugc',
  //   element: <TermsUgc />
  // },
  {
    path: '/not-found',
    element: <NotFound />
  },
  {
    path: '/payment',
    element: <Payment />
  },
  {
    path: '/promo',
    element: <PromoPage />
  },
  {
    path: '/promo/:id',
    element: <PromoDetailPage />
  },
  {
    path: '/payment/:planId',
    element: <Payment />
  },
  {
    path: '/payment/:planId/:method',
    element: <Payment />
  },
  {
    path: '/payment/:planId/:method/:promoCode',
    element: <Payment />
  },
  {
    path: '/stream/:channelID?',
    element: <Stream />,
    hideBars: true
  },
  {
    path: '/:programType/:mediaId',
    element: <Detail />,
    hideBars: true
  },
  {
    path: '/:pageName',
    element: <MediaPageWrapper />,
    hideBars: false
  }
];

const BaseComponentWrapper = ({ element, loggedIn, hideBars }) => {
  const authState = useSelector((state) => state.auth);
  const { checkPartnerUser } = useUserDetail();
  const isPartnerUser = checkPartnerUser();

  if (loggedIn && !authState.token) return <Navigate to='/' />;
  if (isPartnerUser)
    return <Navigate to={`/mobile/${isPartnerUser.substring(1)}`} />;

  return (
    <>
      {!hideBars && (
        <>
          {/* <SideMenu /> */}
          <BottomBar />
        </>
      )}
      {element}
    </>
  );
};

export default function IndexRoute() {
  return (
    <Suspense fallback={<Loading height='100vh' />}>
      <Routes>
        {router.map(({ path, element, noLogin, loggedIn, hideBars }) => (
          <Route
            key={path}
            path={path}
            element={
              <BaseComponentWrapper
                element={element}
                noLogin={noLogin}
                loggedIn={loggedIn}
                hideBars={hideBars}
              />
            }
          />
        ))}
        <Route
          path='*'
          element={<Navigate to='/not-found' />}
        />
      </Routes>
    </Suspense>
  );
}

export { default as MobileRoutes } from './mobile';
