import React from 'react';
import TextTruncated from '../TextTruncated/TextTruncated';
import { getDetailPoster } from '../../utils';

import styles from './EpisodeItem.module.css';

const EpisodeItem = ({ item, onClickItem }) => {
  const { titleLocalized, descriptionLongLocalized: description } = item;
  const thumbnail = getDetailPoster(item);
  return (
    <div
      className={styles['episode-item-wrapper']}
      onClick={onClickItem}
    >
      <div className={styles['episode-thumbnail']}>
        <img
          src={thumbnail}
          alt='episode thumbnail'
        />
        <div className={styles['thumbnail-shadow']} />
      </div>
      <div className={styles['episode-title']}>{titleLocalized}</div>
      <div className={styles['episode-description']}>
        <TextTruncated
          maxLength={120}
          content={description}
          readMore={false}
        />
      </div>
    </div>
  );
};

export default EpisodeItem;
