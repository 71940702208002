import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';
import { getSubscriptionTransactions } from '../../redux/actions/subscription';
import styles from './transaction.module.css';
import Loading from '../Loading/Loading';
import { currencyFormatter, formatDateV2 } from '../../utils';

const SubscriptionHistory = () => {
  const dispatch = useDispatch();
  const { subscriptionTransactions, loading, err } = useSelector(
    (state) => state.pack
  );
  const { page } = subscriptionTransactions;

  useEffect(() => {
    dispatch(getSubscriptionTransactions({ page }));
  }, [dispatch, page]);

  const loadCompleted =
    !loading &&
    subscriptionTransactions?.data &&
    subscriptionTransactions?.meta &&
    subscriptionTransactions?.meta?.total <=
      subscriptionTransactions?.data?.length;

  const fetchNextPage = () => {
    dispatch({ type: 'FETCH_NEXT_PAGE_SUBSCRIPTION_TRANSACTION' });
  };

  const calculateExpirationDate = (date) => {
    // const dateNow = new Date().getTime();
    // const seconds = Math.floor((date - dateNow) / 1000); // in seconds
    // const numberOfDays = Math.floor(seconds / 86400); //get days
    // if (numberOfDays > 0 && numberOfDays < 30) {
    //   return `Expire in ${numberOfDays} days`;
    // }
    // if (numberOfDays > 0 && numberOfDays > 30) {
    //   return `Expire in: ${Math.floor(numberOfDays / 30)} months`;
    // }
    return formatDateV2(date, 'dd mmm yyyy, hh:mm');
  };

  // const onClickRenew = (subscription) => {
  //   navigateh('/profile/subscription');
  // };

  return (
    <div className={styles['listWrapper']}>
      {!subscriptionTransactions.data.length && (
        <div className={styles['packagesWrapper']}>
          <div className={styles['noData']}>
            <div>No History Subscription</div>
            <div>Your history subscription will show up here.</div>
          </div>
        </div>
      )}
      {subscriptionTransactions &&
        subscriptionTransactions?.data?.map((sub, key) => {
          return (
            <div
              className={styles['itemWrapper']}
              key={key}
            >
              <div className={styles['detail']}>
                <h3 className={styles['title']}>{sub.package.name}</h3>
                {/* <span className={styles['subtitle']}>{sub.package.name}</span> */}
                <span className={`${styles['subtitle']} ${styles['mt']}`}>
                  {currencyFormatter.format(sub.package.price)} for{' '}
                  {sub.package.duration} {sub.package.durationType}
                  {sub.package.duration !== 1 ? 's' : ''}
                </span>
                <span className={styles['amount']}>
                  {sub?.transaction?.createdAt &&
                    calculateExpirationDate(sub?.transaction?.createdAt)}
                </span>
              </div>
              {/* <button
              onClick={() => onClickRenew(sub)}
              className={styles['renewButton']}
            >
              <span>Renew</span>
            </button> */}
            </div>
          );
        })}
      {!err && !loadCompleted && (
        <InView
          onChange={(inView) => {
            inView && subscriptionTransactions?.data?.length && fetchNextPage();
          }}
        >
          <Loading />
        </InView>
      )}
    </div>
  );
};

export default SubscriptionHistory;
