import { lazy } from 'react';

const ContentPage = lazy(
  () => import(/* webpackChunkName: "content-page" */ './ContentPage')
);

const LiveTvPage = lazy(
  () => import(/* webpackChunkName: "live-tv" */ './LiveTvPage')
);

const RedeemVoucher = lazy(
  () => import(/* webpackChunkName: "redeem-couvher" */ './RedeemVoucher')
);

const PageNotFound = lazy(
  () => import(/* webpackChunkName: "page-not-found" */ './PageNotFound')
);

export { ContentPage, RedeemVoucher, LiveTvPage, PageNotFound };
