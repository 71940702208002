import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCaptionSettings,
  resetCaptionSettings
} from '../../../redux/actions/settings';
import styles from './subtitle.module.css';
import arrowIcn from '../../../assets/forword-arrow.svg';
import arrowBackIcn from '../../../assets/ic-arrow-back.svg';
import { formatOptions, Menu } from './constants';

const CutomSubTitlePopup = () => {
  const captionSettings = useSelector(
    (state) => state.appSettings.captionSettings
  );
  const [currentFormatOption, setFormatOption] = useState(
    formatOptions.MAIN_MENU
  );
  const dispatch = useDispatch();

  const handleSubMenuItemClicked = (subMenuItem) => {
    const key = getSelectedKey(subMenuItem);
    if (key) {
      const payload = {
        [key]: subMenuItem
      };
      dispatch(setCaptionSettings(payload));
    }
  };

  const renderMainMenu = () => {
    return Menu.map((item, index) => {
      if (item.name === 'Reset') {
        return (
          <div className={styles['menuItem']}>
            <button
              type='button'
              className={styles['resetButton']}
              onClick={() => dispatch(resetCaptionSettings())}
            >
              {item.name}
            </button>
          </div>
        );
      }
      return (
        <div
          key={index}
          className={styles['menuItem']}
        >
          <span>{item.name}</span>
          <span
            className={styles['rightArrow']}
            onClick={() => setFormatOption(item.name)}
          >
            {' '}
            {item?.key ? captionSettings[item.key] : ''} &nbsp;
            <img
              src={arrowIcn}
              alt='forword-arrow'
              style={{
                width: '5.6px',
                height: '9.6px'
              }}
            />
          </span>
        </div>
      );
    });
  };

  const getSelectedKey = useCallback(() => {
    let key = null;
    const option = Menu.find((item) => item.name === currentFormatOption);
    key = option?.key ? option.key : null;
    return key;
  }, [currentFormatOption]);

  const renderSubMenu = () => {
    const header = currentFormatOption;
    const option = Menu.find((item) => item.name === currentFormatOption);
    const items = option ? option.data : [];
    return (
      <div className={styles['subMenu']}>
        <div
          className={styles['subMenuHeader']}
          onClick={() => setFormatOption(formatOptions.MAIN_MENU)}
        >
          <img
            src={arrowBackIcn}
            alt='backword-arrow'
            style={{
              width: '5.6px',
              height: '9.6px'
            }}
          />{' '}
          &nbsp;{header}
        </div>
        <div className={styles['divider']} />
        <div className={styles['subMenuItemWrapper']}>
          {items.map((item, index) => (
            <div
              key={index}
              className={`${styles['subMenuItem']} ${
                captionSettings[getSelectedKey()] === item
                  ? styles['subItemSelected']
                  : ''
              }`}
              onClick={() => handleSubMenuItemClicked(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={styles['ModalWrapper']}
      style={{
        minWidth:
          currentFormatOption !== formatOptions.MAIN_MENU ? '140px' : '300px'
      }}
    >
      <div className={styles['MenuWrapper']}>
        {currentFormatOption === formatOptions.MAIN_MENU
          ? renderMainMenu()
          : renderSubMenu()}
      </div>
    </div>
  );
};

export default React.memo(CutomSubTitlePopup);
