import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { useLocation } from 'react-router-dom';
import platform from 'platform';
import { Button, Loading } from '..';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import {
  resetAuthState,
  selectConsent,
  signinWithFB,
  signinWithGoogle
} from '../../redux/actions/auth';
import PhoneAuthentication from './PhoneAuthentication';
import EmailAuthentication from './EmailAuthentication';

import styles from './LoginDialog.module.css';
import icArrowBack from '../../assets/ic-arrow-back.svg';
import icClose from '../../assets/ic-close.svg';
import icPhone from '../../assets/ic-phone.svg';
import icEmail from '../../assets/ic-email.svg';
import icFb from '../../assets/ic-fb.svg';
import icGg from '../../assets/ic-gg.svg';
import TNCDialog from './TNCDialog';
import TNCConfirmDialog from './TNCConfirmDialog';
import { parseQuery } from '../../utils';

const checkWebBrowser = () => {
  const os = platform.os.family?.toLocaleLowerCase();
  const browser = platform.ua?.toLocaleLowerCase();

  if (os === 'ios') {
    return !browser?.includes('safari');
  }
  return browser?.includes('wv');
};

const isWebView = checkWebBrowser();

const contents = {
  login: {
    title: {
      default: 'Login',
      email: (
        <div>
          <div>Login with</div> Email/User ID
        </div>
      ),
      phone: 'Login with Mobile',
      fb: 'Login with Facebook',
      gg: 'Login with Google'
    },
    buttons: [
      {
        id: 'phone',
        text: 'Login with Mobile',
        icon: icPhone,
        color: '#F49832'
      },
      {
        id: 'email',
        text: 'Login with Email/User ID',
        icon: icEmail,
        color: '#353535'
      },
      {
        id: 'fb',
        text: 'Login with Facebook',
        icon: icFb,
        color: '#0A61B0'
      },
      ...(isWebView
        ? []
        : [
            {
              id: 'gg',
              text: 'Login with Google',
              icon: icGg,
              color: '#E83D28'
            }
          ])
    ],
    footer: {
      message: "Don't have an account?",
      button: 'Register Now'
    }
  },
  register: {
    title: {
      default: 'Register',
      email: 'Register with Email',
      phone: 'Register with Mobile',
      fb: 'Register with Facebook',
      gg: 'Register with Google'
    },
    buttons: [
      {
        id: 'phone',
        text: 'Register with Mobile',
        icon: icPhone,
        color: '#F49832'
      },
      {
        id: 'email',
        text: 'Register with Email',
        icon: icEmail,
        color: '#353535'
      },
      {
        id: 'fb',
        text: 'Register with Facebook',
        icon: icFb,
        color: '#0A61B0'
      },
      ...(isWebView
        ? []
        : [
            {
              id: 'gg',
              text: 'Register with Google',
              icon: icGg,
              color: '#E83D28'
            }
          ])
    ],
    footer: {
      message: 'Have an account?',
      button: 'Login Now'
    }
  }
};

const RenderAuth = ({ authWith, ...otherProps }) => {
  switch (authWith) {
    case 'email':
      return <EmailAuthentication {...otherProps} />;
    case 'phone':
      return <PhoneAuthentication {...otherProps} />;
    default:
      return null;
  }
};

const LoginDialog = ({ onClose }) => {
  const location = useLocation();
  const { partner, msisdn, isNewUser, email } = parseQuery(location.search);
  const [dialogState, setDialogState] = useState('login');
  const [showTNC, setShowTNC] = useState(''); // '' , 'ugc' 'privacy'
  const [showConfirmTNC, setShowConfirmTNC] = useState(''); // '', 'facebook' , 'google'
  const [authWith, setAuthWith] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { loading, sessionStatus, authDialogConfigs } = auth;
  const { showClose, state: configState, type, showFooter } = authDialogConfigs;

  useEffect(() => {
    if (partner && msisdn) {
      setDialogState(configState);
      setAuthWith(type);
    }
    if (isNewUser && isNewUser === 'true') {
      if (msisdn) {
        setDialogState('register');
        handleClickOption('phone');
      } else if (email) {
        setAuthWith('email');
      }
    } else if (isNewUser === 'false') {
      setDialogState('login');
      if (msisdn) {
        handleClickOption('phone');
      } else if (email) {
        localStorage.setItem('autoFillUserId', email);
        handleClickOption('email');
      }
    }
  }, [email, partner, msisdn, configState, type, isNewUser]);

  const handleGoBack = () => {
    localStorage.removeItem('autoFillUserId');
    setShowResetPassword(false);
    setAuthWith();
  };

  const handleRedirectLogin = (authType) => {
    setShowResetPassword(false);
    setAuthWith(authType);
  };

  const dialogStateToggle = () => {
    setDialogState((state) => {
      return state === 'login' ? 'register' : 'login';
    });
    dispatch(selectConsent(null));
    handleGoBack();
  };

  const handleCloseModal = () => {
    onClose();
    handleGoBack();
  };

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await dispatch(signinWithGoogle(tokenResponse));
      return onClose();
    },
    flow: 'auth-code',
    ux_mode: 'popup'
  });

  const handleLoginFacebook = async () => {
    analytic(analyticTypes.event, analyticEvents.AUTH.START_LOGIN_BY_FACEBOOK);
    setShowConfirmTNC('');
    if (dialogState === 'login') {
      dispatch(selectConsent(null));
    }
    await dispatch(signinWithFB());
    return onClose();
  };

  const handleClickOption = async (id) => {
    switch (id) {
      case 'phone':
      case 'email':
        return setAuthWith(id);

      case 'fb':
        return setShowConfirmTNC('Facebook');

      case 'gg':
        analytic(
          analyticTypes.event,
          analyticEvents.AUTH.START_LOGIN_BY_GOOGLE
        );
        return setShowConfirmTNC('Google');

      default:
    }
  };

  const handleClickTNC = (action) => {
    setShowTNC(action);
  };

  useEffect(() => {
    analytic(
      analyticTypes.trackPage,
      dialogState === 'login' ? 'LoginOptions' : 'RegisterOption'
    );
  }, [dispatch, dialogState]);

  useEffect(() => {
    if (dialogState || authWith || showResetPassword)
      dispatch(resetAuthState());
    return () => {
      dispatch(resetAuthState());
    };
  }, [dispatch, dialogState, authWith, showResetPassword]);

  return (
    <>
      {showTNC && (
        <TNCDialog
          type={showTNC}
          onClose={() => {
            handleClickTNC('');
          }}
        />
      )}

      {!showTNC &&
        (showConfirmTNC ? (
          <TNCConfirmDialog
            onClickTNC={handleClickTNC}
            onClickGoogle={() => {
              if (dialogState === 'login') {
                dispatch(selectConsent(null));
              }
              handleLoginGoogle();
            }}
            onClickFacebook={handleLoginFacebook}
            type={showConfirmTNC}
            dialogstate={
              authWith
                ? contents[dialogState].title[authWith]
                : contents[dialogState].title.default
            }
            onClose={() => {
              setShowConfirmTNC('');
            }}
          />
        ) : (
          <div className={styles['container']}>
            <div className={styles['header']}>
              {authWith && showClose && (
                <img
                  src={icArrowBack}
                  alt='back'
                  onClick={handleGoBack}
                />
              )}
              <h1>
                {authWith
                  ? contents[dialogState].title[authWith]
                  : contents[dialogState].title.default}
              </h1>

              {showClose && (
                <img
                  src={icClose}
                  alt='close'
                  onClick={handleCloseModal}
                />
              )}
            </div>

            {loading && authWith !== 'phone' && (
              <div className={styles['loading-wrapper']}>
                <Loading />
                {sessionStatus === 'validating' ? (
                  <p>Validating session</p>
                ) : (
                  <p>Authenticating</p>
                )}
              </div>
            )}

            {!loading &&
              (authWith ? (
                <RenderAuth
                  authWith={authWith}
                  type={dialogState}
                  msisdn={msisdn}
                  email={email}
                  isNewUser={isNewUser}
                  showResetPassword={showResetPassword}
                  setShowResetPassword={setShowResetPassword}
                  onCloseModal={handleCloseModal}
                  onGoBack={handleGoBack}
                  onRedirectLogin={handleRedirectLogin}
                  onClickTNC={handleClickTNC}
                />
              ) : (
                <div className={styles['options']}>
                  {contents[dialogState].buttons.map((button) => {
                    return (
                      <Button
                        key={button.id}
                        icon={button.icon}
                        iconStyle={{
                          width: 24,
                          marginLeft: 10,
                          marginRight: 40
                        }}
                        color={button.color}
                        size='large'
                        style={{
                          padding: '1rem 2rem',
                          margin: '1rem 0',
                          width: '100%',
                          fontSize: 16,
                          fontWeight: 600,
                          justifyContent: 'flex-start'
                        }}
                        onClick={() =>
                          handleClickOption(button.id, dialogState)
                        }
                      >
                        {button.text}
                      </Button>
                    );
                  })}
                  {!!auth.err && <div className='error'>{auth.err}</div>}
                </div>
              ))}

            {showFooter && (
              <div className={`${styles['footer']} ${styles['inline-list']}`}>
                <div>
                  <span>{contents[dialogState].footer.message}</span>
                  <span
                    className={styles['state-toggle']}
                    onClick={dialogStateToggle}
                  >
                    {contents[dialogState].footer.button}
                  </span>
                </div>
                {dialogState === 'register' && authWith && (
                  <div
                    className={styles['remind']}
                    onClick={handleGoBack}
                  >
                    <span>Other Register Options</span>
                  </div>
                )}
                {dialogState === 'login' && authWith && (
                  <div
                    className={styles['remind']}
                    onClick={handleGoBack}
                  >
                    <span>Other Login Options</span>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
    </>
  );
};

export default LoginDialog;
