const initialState = {
  promoList: {
    data: [],
    meta: [],
    page: 1
  },
  loading: false,
  err: null
};

export default function promoReducer(state = initialState, action) {
  const { type, payload, page, err } = action;

  switch (type) {
    case 'GET_PROMO_LIST':
      return {
        ...state,
        promoList: state.promoList,
        loading: true,
        err: null
      };
    case 'GET_PROMO_LIST_SUCCESS':
      const promoList = state.promoList;
      return {
        ...state,
        promoList: {
          data:
            payload?.meta?.page === 1
              ? payload.data
              : [...promoList.data, ...payload.data],
          meta: payload.meta,
          page
        },
        loading: false,
        err: false
      };
    case 'FETCH_NEXT_PAGE_PROMO_LIST':
      return {
        ...state,
        promoList: {
          ...state.promoList,
          page: state.promoList.page + 1
        },
        loading: false,
        err: false
      };
    case 'GET_PROMO_LIST_FAILURE':
      return {
        ...state,
        loading: false,
        err
      };
    case 'GET_PROMO_DETAIL':
      return {
        ...state,
        loading: true,
        err: null
      };
    case 'GET_PROMO_DETAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        promoDetail: payload.data
      };
    case 'GET_PROMO_DETAIL_FAILURE':
      return {
        ...state,
        loading: false,
        err
      };

    case 'GET_PROMO_PARTNERS':
      return {
        ...state,
        loading: true,
        err: null
      };
    case 'GET_PROMO_PARTNERS_SUCCESS':
      return {
        ...state,
        loading: false,
        partnerList: payload.data
      };
    case 'GET_PROMO_PARTNERS_FAILURE':
      return {
        ...state,
        loading: false,
        err
      };

    case 'GET_PACKAGE_LIST_PROCESS':
      return {
        ...state,
        loading: true,
        error: false
      };

    case 'GET_PACKAGE_LIST_SUCCESS':
      return {
        ...state,
        packageList: payload?.data || [],
        loading: false,
        error: false
      };

    case 'GET_PACKAGE_LIST_FAILURE':
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}
