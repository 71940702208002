import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '..';
import styles from './LoginDialog.module.css';

const DefaultPassword = ({ onClose, password }) => {
  const navigate = useNavigate();

  const goToPassword = () => {
    navigate(`/profile?defaultPassword=${password}`);
  };

  return (
    <div className={`${styles['container']} ${styles['containerDefault']}`}>
      <h2>Your Default Password</h2>
      <div
        className={styles['close-btn']}
        onClick={() => {
          onClose();
        }}
      >
        X
      </div>
      <h3>{decodeURIComponent(password)}</h3>
      <div className={styles['buttonWrapperDefault']}>
        <Button
          onClick={() => {
            goToPassword();
            onClose();
          }}
          shape='rounded'
          size='large'
          width='100%'
        >
          Change Password Now
        </Button>
      </div>
    </div>
  );
};

export default DefaultPassword;
