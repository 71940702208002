import { formatObject, generateUserData } from '../utils';

export const initFbPixelUserData = async (user) => {
  const userData = generateUserData(user, {
    separateSensitiveData: true
  });

  if (window?.fbq) {
    await window.fbq(
      'init',
      process.env.REACT_APP_FACEBOOK_PIXEL_ID,
      userData?.sensitiveData
    );
  }
};

const filterUserProperties = (userData) => {
  const sensitiveDataKeys = [
    'account_email',
    'account_id',
    'account_name',
    'account_user_id',
    'device_uuid',
    'profile_account_id',
    'profile_id',
    'profile_name',
    'user_id'
  ];
  const data = {};

  for (const key in userData) {
    if (!sensitiveDataKeys?.includes(key)) data[key] = userData[key];
  }

  return data;
};

const fbPixel = (event, options = { params: {}, user: null }) => {
  const { params = {}, user = null } = options;
  const userData = generateUserData(user, { separateSensitiveData: true });
  const time = new Date().getTime();
  const property = formatObject(params);
  const filteredData = filterUserProperties(property);

  const payload = {
    ...userData?.regularData,
    ...filteredData,
    time
  };

  window.fbq('trackCustom', event, payload);
};

export default fbPixel;
