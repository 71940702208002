import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './Header.module.css';
import icSeries from '../../assets/ic-series.svg';
import icMovie from '../../assets/ic-movie.svg';
import icAnimation from '../../assets/ic-animation.svg';
import icHome from '../../assets/ic-home.svg';

const BottomBar = () => {
  const location = useLocation();
  const menu = [
    {
      name: 'Home',
      path: '/',
      icon: icHome
    },
    {
      name: 'Movies',
      path: '/movies',
      icon: icMovie
    },
    {
      name: 'Series',
      path: '/series',
      icon: icSeries
    },
    {
      name: 'Animation',
      path: '/animation',
      icon: icAnimation
    }
  ];
  return (
    <div className={`${styles['bottomBar']} show-mobile`}>
      {menu?.map((item, index) => {
        const selected = location?.pathname === item.path;
        return (
          <div
            className={styles['menuIcon']}
            key={index}
          >
            <img
              src={item.icon}
              alt={item.name}
              className={selected ? styles['IconImage'] : ''}
            />
            <div className={selected ? styles['selected'] : ''}>
              <Link to={item.path}>{item.name}</Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BottomBar;
