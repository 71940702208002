import React, { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryLiveShows } from '../../redux/actions/liveShow';
import styles from './EditStreamInfoModal.module.css';
import Button from '../Button/Button';

import icClose from '../../assets/ic-close.svg';
import icThumbnail from '../../assets/ic-thumbnail.svg';
import {
  createGameStream,
  editGameStreamInfo
} from '../../redux/actions/liveGame';
import Loading from '../Loading/Loading';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';

const EditStreamInfoModal = ({
  streamInfo,
  loadingLiveGame,
  onCloseModal,
  open
}) => {
  const dispatch = useDispatch();
  const { loadingEdit } = useSelector((state) => state.liveGame);
  const { dataCategory, loadingCategory, metaCategory } = useSelector(
    (state) => state.liveShow
  );

  const [pageCategory, setPageCategory] = useState(1);
  const [name, setName] = useState((streamInfo && streamInfo.name) || '');
  const [description, setDescription] = useState(
    (streamInfo && streamInfo.description) || ''
  );
  const [category, setCategory] = useState(
    (streamInfo && streamInfo.showCategory) || ''
  );
  // const [gameTitle, setGameTitle] = useState((streamInfo && streamInfo.gameTitle) || '')
  const [thumbnail, setThumbnail] = useState(
    (streamInfo && streamInfo.thumbnail) || ''
  );
  const [error, setError] = useState();

  useEffect(() => {
    dispatch(getCategoryLiveShows({ page: pageCategory }));
  }, [dispatch, pageCategory]);

  const fetchNextDataCategory = () => {
    if (!loadingCategory) {
      setPageCategory(pageCategory + 1);
    }
  };

  const handleSelectCategory = (categoryId, categoryName) => {
    if (category && category.id === categoryId) {
      return setCategory();
    }

    return setCategory({
      id: categoryId,
      name: categoryName
    });
  };

  const handleFileUpload = (e) => {
    setError();

    const file = e.target.files[0];

    if (!file) return;

    const checkFormat = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!checkFormat) {
      return setError({ thumbnail: 'Thumbnail format must be jpeg or png' });
    }

    setError(error && (error.thumbnail = null));
    return setThumbnail(file);
  };

  const handleSubmitStreamInfo = async () => {
    setError();

    if (!name)
      setError((state) => ({ ...state, title: '*Field is Required.' }));
    if (!category) setError((state) => ({ ...state, category: '*Required.' }));
    if (!thumbnail)
      setError((state) => ({ ...state, thumbnail: '*Required.' }));
    if (!name || !category || !thumbnail) return;

    const data = {
      name,
      description,
      category,
      // gameTitle,
      thumbnail
    };

    if (streamInfo) {
      await dispatch(editGameStreamInfo(streamInfo.id, data));
    } else {
      await dispatch(createGameStream(data));
    }

    onCloseModal();
  };

  return (
    <GeneralNotifModal
      open={open}
      contentClass={styles['modalWrapper']}
    >
      {loadingLiveGame || loadingEdit ? (
        <div className={styles['loading']}>
          <Loading />
          <p>{streamInfo ? 'Updating live game info' : 'Creating live game'}</p>
        </div>
      ) : (
        <>
          <div className={styles['modalHeader']}>
            <h3>Stream Info</h3>
            <img
              src={icClose}
              alt='close'
              onClick={onCloseModal}
            />
          </div>
          <div className={styles['modalContent']}>
            <section>
              <div className={styles['sectionHeader']}>
                <span>Title</span>
                <span className={styles['errorMessage']}>
                  {error && error.title}
                </span>
              </div>
              <textarea
                className={`${styles['editInput']} ${
                  styles['descriptionInput']
                } ${error && !name.length ? styles['inputError'] : ''}`}
                rows={2}
                maxLength={100}
                placeholder='Enter a title'
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
              />
              <p className={styles['descriptionCount']}>
                {name ? name.length : 0}/100
              </p>
            </section>
            <section>
              <div className={styles['sectionHeader']}>
                <p>Description (optional)</p>
              </div>
              <textarea
                className={`${styles['editInput']} ${styles['descriptionInput']}`}
                placeholder='Enter a description'
                maxLength={120}
                rows={2}
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
              />
              <p className={styles['descriptionCount']}>
                {description ? description.length : 0}/120
              </p>
            </section>
            <section>
              <div className={styles['sectionHeader']}>
                <span>Category</span>
                <span className={styles['errorMessage']}>
                  {error && error.category}
                </span>
              </div>
              <div>
                {dataCategory &&
                  dataCategory
                    .filter((item) => item.id)
                    .map((item, i) => {
                      const selected = item.id === (category && category.id);

                      return (
                        <div
                          key={i}
                          className={`${styles['categoryItem']} ${
                            selected ? styles['selectedCategory'] : ''
                          }`}
                          onClick={() =>
                            handleSelectCategory(item.id, item.name)
                          }
                        >
                          {item.iconUrl ? (
                            <img
                              src={item.iconUrl}
                              height='13'
                              width='13'
                              alt='filter'
                            />
                          ) : null}
                          <span>{item.name}</span>
                        </div>
                      );
                    })}
                {metaCategory &&
                  !(
                    !loadingCategory &&
                    metaCategory &&
                    pageCategory >= metaCategory.totalPages
                  ) && (
                    <InView
                      onChange={(inView) => {
                        inView &&
                          dataCategory.length &&
                          fetchNextDataCategory();
                      }}
                    >
                      <Loading />
                    </InView>
                  )}
              </div>
              {/* {category && (
                  <input
                    className={styles['editInput']}
                    placeholder='Game title (optional)'
                    value={gameTitle ? gameTitle : ''}
                    onChange={e => setGameTitle(e.target.value)}
                  />
                )} */}
            </section>
            <section>
              <div className={styles['sectionHeader']}>
                <span>Thumbnail</span>
                <span className={styles['errorMessage']}>
                  {error && error.thumbnail}
                </span>
              </div>
              <div className={styles['thumbnailSection']}>
                <label
                  className={styles['thumbnailLabel']}
                  htmlFor='select-thumbnail'
                >
                  {thumbnail ? (
                    <img
                      className={styles['selectThumbnail']}
                      src={
                        thumbnail instanceof File
                          ? URL.createObjectURL(thumbnail)
                          : thumbnail
                      }
                      alt='thumbnail'
                    />
                  ) : (
                    <div className={styles['selectThumbnail']}>
                      <img
                        src={icThumbnail}
                        alt='thumbnail'
                      />
                      Upload file
                    </div>
                  )}
                </label>
                <span>
                  <p>Recommendation size:</p>
                  <p>Portrait (3:4)</p>
                </span>
                <input
                  id='select-thumbnail'
                  className={styles['thumbnail']}
                  name='thumbnail'
                  type='file'
                  accept='image/*'
                  onChange={handleFileUpload}
                />
              </div>
            </section>
          </div>
          <div className={styles['modalAction']}>
            <Button
              width='120px'
              color='#282828'
              onClick={onCloseModal}
            >
              Cancel
            </Button>
            <Button
              width='120px'
              onClick={handleSubmitStreamInfo}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </GeneralNotifModal>
  );
};

export default EditStreamInfoModal;
