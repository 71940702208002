import React from 'react';
import { getInitial } from '../../utils';
import styles from './userinfo.module.css';
import userIcon from '../../assets/ic-user.svg';

const UserAvatar = ({ profile, onClick, profileLive, style }) => {
  return (
    <div
      className={styles['liveBorder']}
      style={style}
      data-live-active={profileLive}
    >
      {profile?.profile?.avatar ? (
        <img
          className={styles['avatar']}
          src={profile?.profile?.avatar || userIcon}
          alt='user'
          data-live={profileLive}
          onClick={onClick}
        />
      ) : (
        <div
          className={styles['avatar']}
          data-initial-name
          data-live={profileLive}
          onClick={onClick}
          alt='user'
        >
          {getInitial(profile?.profile?.name || '')}
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
