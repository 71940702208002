import React from 'react';
import { getThumbnail } from '../../utils';
import { SLIDER_ITEM_THUMBNAIL } from '../../config/Thumbnail';
import styles from './Item.module.css';

import playIcn from '../../assets/ioh-assets/icons/play-icon.svg';
import icCrown from '../../assets/ic-crown.svg';
import PositionOverlay from './PositionOverlay';
// import { useMatch } from 'react-router-dom';
import LiveShowOverlay from './LiveShowOverlay';

const MediaItem = ({ item, exclusiveContents, showPlayIcon }) => {
  const thumbnail = item && getThumbnail(item, SLIDER_ITEM_THUMBNAIL);

  return (
    <>
      {(item?.type === 'liveshow-vod' || item?.type === 'liveshow') && (
        <LiveShowOverlay item={item} />
      )}
      <img
        src={thumbnail || item?.thumbnail}
        alt='thumbnail'
        className={styles['thumbnail']}
      />
      {exclusiveContents && (
        <img
          className={styles['exclusiveBadge']}
          src={icCrown}
          alt='premium'
        />
      )}
      {showPlayIcon && (
        <img
          className={styles['play-icon']}
          src={playIcn}
          alt='play'
        />
      )}
      {item.position && <PositionOverlay item={item} />}
    </>
  );
};

export default MediaItem;
