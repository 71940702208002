import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import userPic from '../../assets/user.svg';
import styles from './Comments.module.css';

const CommentItem = ({ avatar, name, comment }) => {
  return (
    <div className={styles['commentItem']}>
      {avatar ? (
        <img
          className={styles['commentAvatar']}
          src={avatar}
          alt={name}
        />
      ) : (
        <img
          className={styles['commentAvatar']}
          src={userPic}
          alt={name}
        />
      )}
      <div>
        <span className={styles['commentName']}>{name}</span>
        <span className={styles['commentContent']}>{comment}</span>
      </div>
    </div>
  );
};

const CommentList = () => {
  const listWrapper = useRef();
  const list = useRef();
  const [overflow, setOverflow] = useState(false);
  const comments = useSelector((state) => state.interactive.comments);

  useEffect(() => {
    setOverflow(list.current.offsetHeight > listWrapper.current.offsetHeight);
  }, [listWrapper, list, comments]);

  return (
    <div
      ref={listWrapper}
      className={`${styles['commentListWrapper']} ${
        overflow && styles['overflow']
      }`}
    >
      <ul
        ref={list}
        className={styles['commentList']}
      >
        {comments?.comments?.map((com, index) => {
          return (
            <li key={index}>
              <CommentItem
                avatar={com?.profile?.avatar}
                name={com?.profile?.name}
                comment={com?.data}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CommentList;
