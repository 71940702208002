import { useEffect, useCallback, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPages } from '../../redux/actions/page';
import { getAuthInfo, logout } from '../../redux/actions/auth';
import { getInAppNotificationSummary } from '../../redux/actions/inAppNotification';
import useSettings from '../../hooks/useSettings';

const AppWrapper = (props) => {
  const dispatch = useDispatch();
  const logoutWhenClearStorage = useCallback(
    (e) => {
      if (e.key === 'gfToken' && e.oldValue && !e.newValue) {
        dispatch(logout());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener('storage', logoutWhenClearStorage);

    return () => window.removeEventListener('storage', logoutWhenClearStorage);
  }, [logoutWhenClearStorage]);

  useEffect(() => {
    dispatch(getAuthInfo());
    dispatch(getPages());
  }, [dispatch]);

  useLayoutEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(getInAppNotificationSummary());
    }
  }, [dispatch]);

  // Init app configs
  useSettings();

  return props.children;
};

export default AppWrapper;
