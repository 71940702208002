import { useSelector } from 'react-redux';

export const partnerSuffix = ['@surge'];

const useUserDetail = () => {
  const { user } = useSelector((state) => state.auth);
  const userSubscription = user?.account?.subscription;
  const userSubscriptions = user?.account?.subscriptions;

  const checkPartnerUser = () => {
    return partnerSuffix.find((item) =>
      user?.account?.username?.includes(item)
    );
  };

  const getUserMainSubscription = () => {
    return userSubscription;
  };
  const getUserSubscriptions = () => {
    return userSubscriptions;
  };
  const findUserSubsByName = (key) => {
    return userSubscriptions?.find((item) =>
      item.packageName.toLowerCase().includes(key)
    );
  };

  return {
    getUserMainSubscription,
    getUserSubscriptions,
    findUserSubsByName,
    checkPartnerUser
  };
};

export default useUserDetail;
