import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './NotificationDialog.module.css';
import { capitalizeFirstLetter, cleanText, formatDateV2 } from '../../utils';
import {
  closeDialogNotif,
  getInAppNotification,
  getInAppNotificationById,
  getInAppNotificationSummary
} from '../../redux/actions/inAppNotification';

const NotifcationListItem = ({ notificationDetails, type, read }) => {
  // type: dialog,page
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickNotification = () => {
    dispatch(closeDialogNotif());
    if (!read) {
      dispatch(getInAppNotificationSummary());
      dispatch(getInAppNotification(1, 20));
    }
    if (notificationDetails.refId && notificationDetails.type !== 'promo') {
      const ref = notificationDetails.refId.split(':');
      dispatch(getInAppNotificationById(notificationDetails.id));
      return navigate(`/${ref[0]}/${ref[1]}`);
    }
    return navigate(`/notification/${notificationDetails.id}`);
  };
  const typeFormatting = (typeName) => {
    let processed = '';
    if (typeName.includes('_')) {
      const words = typeName.split('_');

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      processed = words.join(' ');
    } else {
      processed = capitalizeFirstLetter(typeName);
    }
    return processed;
  };
  return (
    <div
      className={`
      ${styles['notifBackground']} ${type === 'page' && styles['pageMargin']} ${
        !read && styles['read']
      }`}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        onClickNotification();
      }}
    >
      <div className={styles['row']}>
        <div className={styles['category']}>
          {notificationDetails?.type
            ? typeFormatting(notificationDetails?.type)
            : ''}
        </div>
        <div className={styles['timestamp']}>
          {notificationDetails?.startDate &&
            formatDateV2(notificationDetails?.startDate, 'hh:mm')}
        </div>
      </div>
      <div className={styles['header']}>{notificationDetails?.title}</div>

      <div className={styles['content']}>
        {cleanText(notificationDetails?.desc)}
      </div>
    </div>
  );
};

export default NotifcationListItem;
