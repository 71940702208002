const initialState = {
  data: [],
  err: null,
  loading: false
};

export default function pageReducer(state = initialState, action) {
  const { type, payload, err } = action;
  switch (type) {
    case 'GET_PAGES':
      return {
        ...state,
        loading: true,
        err: null
      };
    case 'GET_PAGES_SUCCESS':
      return {
        ...state,
        loading: false,
        data: payload,
        err: null
      };
    case 'GET_PAGES_FAILED':
      return {
        ...state,
        loading: false,
        err
      };
    default:
      return state;
  }
}
