const initialData = {
  profileInfo: null,
  followers: {
    data: [],
    meta: null
  },
  following: {
    data: [],
    meta: null
  },
  dataVod: null,
  metaVod: null,
  loadingVod: false,
  loading: false,
  loadingFollow: false,
  loadingProfile: false,
  err: null,
  dataClips: null,
  loadingCLips: false,
  metaClips: null,
  loadingBlockedProfiles: false,
  blockedProfileList: [],
  blockUser: false,
  unBlockUser: false
};

export default function publicProfileReducer(state = initialData, action) {
  const { type, err, payload, page } = action;
  switch (type) {
    case 'GET_PUBLIC_PROFILE':
      return {
        ...state,
        loadingProfile: true
      };
    case 'SET_PUBLIC_PROFILE':
      // const profile = {
      //   ...payload.profile,
      //   ...payload.relation,
      //   block: payload.block || null
      // }
      return {
        ...state,
        profileInfo: payload,
        loadingProfile: false,
        err: null
      };
    case 'GET_RECORDED_LIVESHOW':
      return {
        ...state,
        loadingLiveShow: true,
        err: null
      };
    case 'GET_RECORDED_LIVESHOW_SUCCESS':
      const data = payload.data.map((item) => {
        if ((item.type === 'show' || item.type === 'stream') && item.show) {
          item.type = 'liveshow-vod';

          const thumbnail = item.attachments.thumbnail_portrait
            ? item.attachments.thumbnail_portrait.fileUrl
            : item.show.profile.avatar;

          return {
            ...item.show,
            type: item.type,
            id: item.id,
            name: item.titleLocalized,
            thumbnail
          };
        }

        return item;
      });
      return {
        ...state,
        dataVod: page === 1 ? data : [...state.dataVod, ...data],
        metaVod: payload.meta,
        loadingLiveShow: false,
        err: null
      };
    case 'GET_RECORDED_LIVESHOW_FAILED':
      return {
        ...state,
        loadingLiveShow: false,
        err
      };
    case 'GET_CLIPS':
      return {
        ...state,
        loadingClips: true,
        err: null
      };
    case 'GET_CLIPS_SUCCESS':
      const clipsData = payload.data;
      return {
        ...state,
        dataClips: page === 1 ? clipsData : [...state.dataClips, ...clipsData],
        metaClips: payload.meta,
        loadingClips: false,
        err: null
      };
    case 'GET_CLIPS_FAILED':
      return {
        ...state,
        loadingClips: false,
        err
      };
    case 'GET_FOLLOWER_LIST':
      return {
        ...state,
        loading: true,
        err: null
      };
    case 'SET_FOLLOWERS':
      const followers = {
        data:
          page === 1
            ? payload.data
            : [...state.followers.data, ...payload.data],
        meta: payload.meta
      };
      return {
        ...state,
        followers,
        loading: false,
        err: null
      };
    case 'SET_FOLLOWING':
      const following = {
        data:
          page === 1
            ? payload.data
            : [...state.following.data, ...payload.data],
        meta: payload.meta
      };
      return {
        ...state,
        following,
        loading: false,
        err: null
      };
    case 'RESET_FOLLOW_LIST':
      return {
        ...state,
        followers: {
          data: [],
          meta: null
        },
        following: {
          data: [],
          meta: null
        },
        loading: false,
        err: null
      };
    case 'SET_FOLLOW_STATUS':
      return {
        ...state,
        loadingFollow: true,
        err: null
      };
    case 'FOLLOW_USER_SUCCESS':
    case 'UNFOLLOW_USER_SUCCESS':
      return {
        ...state,
        loadingFollow: false,
        err: null
      };
    case 'GET_PUBLIC_PROFILE_FAILED':
      return {
        ...state,
        loadingProfile: false,
        err
      };
    case 'GET_FOLLOWERS_FAILED':
    case 'GET_FOLLOWINGS_FAILED':
      return {
        ...state,
        loading: false,
        err
      };
    case 'FOLLOW_USER_FAILED':
    case 'UNFOLLOW_USER_FAILED':
      return {
        ...state,
        loadingFollow: false,
        err
      };

    case 'GET_BLOCKED_PROFILE_LIST':
      return {
        ...state,
        loadingBlockedProfiles: true
      };
    case 'GET_BLOCKED_PROFILE_LIST_SUCCESS':
      return {
        ...state,
        loadingBlockedProfiles: false,
        blockedProfileList: payload,
        blockUser: false,
        unBlockUser: false
      };
    case 'GET_BLOCKED_PROFILE_LIST_SUCCESS_FROM_STATE':
      return {
        ...state,
        loadingBlockedProfiles: false,
        blockedProfileList: payload,
        blockUser: false,
        unBlockUser: false
      };
    case 'GET_BLOCKED_PROFILE_LIST_FAIL':
      return {
        ...state,
        loadingBlockedProfiles: false
      };
    case 'SET_BLOCK_USER':
      return {
        ...state
      };
    case 'SET_BLOCK_USER_SUCCESS':
      return {
        ...state,
        blockUser: true
      };
    case 'SET_BLOCK_USER_FAILED':
      return {
        ...state,
        blockUser: false
      };
    case 'SET_UNBLOCK_USER':
      return {
        ...state
      };
    case 'SET_UNBLOCK_USER_SUCCESS':
      return {
        ...state,
        unBlockUser: true
      };
    case 'SET_UNBLOCK_USER_FAILED':
      return {
        ...state,
        unBlockUser: false
      };
    case 'CLEAR_PUBLIC_PROFILE_STATE':
      return {
        ...initialData
      };
    default:
      return { ...state };
  }
}
