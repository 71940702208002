import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Loading } from '..';
import { errorCode } from '../../constants';
import styles from './OtpModal.module.css';

const OtpModal = ({
  open,
  content,
  codeLength,
  onDismiss,
  onResendOtp,
  onVerifyOtp,
  phoneNumber
}) => {
  const otpRef = useRef();
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);
  const [startCountdown, setStartCountdown] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(59);
  const interval = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleDismiss = () => {
    setErr('');
    onDismiss();
    resetCountdown();
  };

  useEffect(() => {
    return () => {
      resetCountdown();
    };
  }, [dispatch]);

  useEffect(() => {
    if (startCountdown) {
      interval.current = setInterval(() => {
        setResendCountdown((state) => state - 1);
      }, 1000);
    }
  }, [startCountdown]);

  useEffect(() => {
    setResendCountdown(60);
    resetOtpValue();
  }, [phoneNumber]);

  const resetOtpValue = () => {
    const otpForm = otpRef.current.elements;

    for (let i = 0; i < otpForm?.otp.length; i++) {
      otpForm.otp[i].value = '';
    }
  };

  useEffect(() => {
    if (resendCountdown === 0) {
      resetCountdown();
    }
  }, [resendCountdown]);

  const resetCountdown = () => {
    clearInterval(interval.current);
    setResendCountdown(59);
    setStartCountdown(false);
  };

  useEffect(() => {
    return () => {
      setStartCountdown(true);
    };
  }, [open]);

  const getOtpData = () => {
    const otpForm = otpRef.current.elements;
    const val = [];

    for (let i = 0; i < otpForm?.otp.length; i++) {
      val.push(otpForm?.otp[i].value);
    }

    return val?.filter((item) => item)?.join('');
  };

  const handleKeydownOTP = (e) => {
    const target = e.target;
    const enabledKeys = ['Tab', 'Backspace', 'Enter'];

    // enable keydown for enabledKeys item, keys on enabledKey list still have default actions
    if (enabledKeys.includes(e.key)) {
      /* 
        addition for Backspace key, when the current focus field is empty and Backspace key is called
        it will automatically focus to previous field and delete its value
      */
      if (
        e.key === 'Backspace' &&
        !target.value &&
        target.previousElementSibling
      ) {
        target.previousElementSibling.focus();
      }
    } else {
      // only accept input numbers
      // eslint-disable-next-line no-lonely-if
      if (/[0-9]/.test(e.key)) {
        /* 
          when current active/focuse field is empty and keydown value is number 
          it will fill current field with entered value
        */
        if (!target.value) {
          target.value = e.key;

          // when all 6 field is filled it will automatically call the function to send and verify the OTP
          // const otpData = getOtpData();
          // const checkOtp = otpData?.length === 6;
          // if (checkOtp) handleRegisterPhone();
        }
        /*
          if current active/focus field is filled with entered number it will automatically focus to next field
          e.preventDefault inside this if statement is for prevent previous key entered and not filling next field
        */
        if (target.nextElementSibling) {
          target.nextElementSibling.focus();
          e.preventDefault();
        }
      } else {
        // prevent keydown other than number keys and list keys on enabledKeys
        return e.preventDefault();
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      setLoading(true);
      await onResendOtp();
      setStartCountdown(true);
    } catch (resendErr) {
      const mes = resendErr.response
        ? resendErr.response.data.error.message
        : 'Internal Server Error!';

      setErr(mes);
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterPhone = async () => {
    const otpData = getOtpData();
    const checkOtp = otpData?.length === codeLength;

    if (checkOtp) {
      setLoading(true);
      try {
        await onVerifyOtp({ mobile: phoneNumber, otp: otpData });
        onDismiss();
      } catch (verifyError) {
        const errCode = verifyError?.response?.data?.error?.code;
        const mes = verifyError.response
          ? verifyError.response.data.error.message
          : 'Internal Server Error!';
        if (errCode === errorCode.INVALID_OTP_CODE) {
          return setErr('Incorrect OTP Code');
        }
        setErr(mes);
      } finally {
        setLoading(false);
      }
    } else {
      setErr('Invalid OTP code');
    }
  };

  return (
    <div className={`${styles['phoneModal']} ${open ? styles['show'] : ''}`}>
      <div className={styles['passWrapper']}>
        <div className={`${styles['form-wrapper']}`}>
          <h3>Verification</h3>
          <span className={styles['verification']}>{content}</span>
          <form
            id='otp-form'
            className={styles['form']}
            ref={otpRef}
            onSubmit={handleRegisterPhone}
          >
            {[...Array(codeLength)].map((_, i) => {
              return (
                <input
                  key={i}
                  type='text'
                  name='otp'
                  maxLength='1'
                  onKeyDown={handleKeydownOTP}
                />
              );
            })}
          </form>
          {(!!err || !!auth.err) && (
            <div className='error'>{err || auth.err}</div>
          )}
          {loading ? (
            <Loading height='100px' />
          ) : (
            <>
              <span className={styles['resend-otp']}>
                <span>Did’t get the code? </span>
                {startCountdown ? (
                  <span className={styles['countdown']}>
                    Resend in {resendCountdown}s
                  </span>
                ) : (
                  <span
                    className={styles['resend-button']}
                    onClick={handleResendOtp}
                  >
                    Resend code
                  </span>
                )}
              </span>
              <Button
                onClick={handleRegisterPhone}
                shape='rounded'
                width='80%'
                className={styles['actionButton']}
                data-submit
              >
                Verify
              </Button>
              <Button
                onClick={handleDismiss}
                type='text'
                width='80%'
                className={styles['actionButton']}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
