import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AnimatedModal, ShakaVideoPlayer } from '..';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { detectAgent } from '../../utils';

import styles from './MediaPlayer.module.css';
import { getMediaHistory, updateHistory } from '../../redux/actions/history';
import {
  // clearMediaDetail,
  getMediaAccess,
  getMediaInfo,
  getPublicAccess,
  getSingleMediaInfo
} from '../../redux/actions/media';
import { authPopup } from '../../redux/actions/auth';
import { errorCode } from '../../constants';

const MediaPlayer = ({ mediaType, mediaId, autoPlay, onClose, onMediaEnd }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openPlayer, setOpenPlayer] = useState(false);
  const [detailData, setDetailData] = useState();
  const fetchDetailError = useSelector((state) => state.media.err);
  const userToken = useSelector((state) => state.auth.token);
  const [historyData, setHistoryData] = useState();
  const [shouldGetLatestHisoty, setShouldGetLatestHistory] = useState(false);
  const [access, setAccess] = useState();
  const user = useSelector((state) => state.auth && state.auth.user);
  const account = user && user.account;
  const telco = user && user.telco;
  const [sessionId, setSessionId] = useState();
  const isMobile = detectAgent('mobile');
  const [nextEpisode, setNextEpisode] = useState();

  const showPlayer = useCallback(() => {
    if (!detailData) return;
    // if (!userToken && detailData.premium) {
    //   return dispatch(authPopup.show())
    // }
    setSessionId(uuidv4());

    // const isMobile = detectAgent('mobile')
    // if (isMobile) {
    //   const os = detectOS()
    //   if (os === 'ios') {
    //     return (window.location.href =
    //       `https:${appURL.APP_STORE}`)
    //   }
    //   return (window.location.href =
    //     `https:${appURL.GOOGLE_PLAY}`)
    // }

    setOpenPlayer(true);
  }, [detailData]);

  const dismissPlayer = () => {
    setOpenPlayer(false);
    onClose && onClose();
  };

  const getHistoryData = useCallback(async () => {
    try {
      const data = await dispatch(getMediaHistory(mediaId));
      if (data && data.data && data.data.length) {
        const selectedData = data.data[0];
        setHistoryData(selectedData);
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }, [dispatch, mediaId]);

  const getAccessData = useCallback(async () => {
    if (detailData) {
      try {
        let data;
        if (userToken) {
          data = await dispatch(getMediaAccess(mediaId));
        } else {
          data = await dispatch(getPublicAccess(mediaId));
        }
        if (data && data.data) {
          setAccess(data.data);
        }
      } catch (error) {
        if (
          !userToken &&
          error.response.data.error.code === errorCode.VIDEO_CONTENT_PREMIUM
        ) {
          navigate(`/${mediaType === 'movie' ? 'movies' : 'series'}`, {
            replace: true
          });
          return dispatch(authPopup.show());
        }
        setAccess({ error });
      }
    }
  }, [detailData, userToken, dispatch, mediaId, mediaType, navigate]);

  useEffect(() => {
    const getDetailData = async () => {
      const data = await dispatch(
        getMediaInfo(mediaId, mediaType, false, { dispatchPayload: false })
      );
      setDetailData(data);

      if (user) {
        const type =
          (data?.type?.charAt(0).toUpperCase() || '') +
          (data?.type?.slice(1) || '');

        window.clevertap.event.push(`IOH_Genflix_Watch_${type}`, {
          uid: user?.account?.id,
          msisdn: user?.msisdn || '',
          category: JSON.stringify(data?.tags?.genres),
          partner_name: 'genflix',
          video_title: data?.titleLocalized,
          video_url: window.location.href
        });
      }
    };

    getDetailData();

    return () => {
      // dispatch(clearMediaDetail());
      setHistoryData();
      setOpenPlayer(false);
      setShouldGetLatestHistory(false);
      setAccess();
      setSessionId();
      setNextEpisode();
    };
  }, [dispatch, mediaId, mediaType, user]);

  useEffect(() => {
    getAccessData();
  }, [getAccessData]);

  useEffect(() => {
    if (autoPlay) {
      showPlayer();
      // setTimeout(() => {
      // }, 500);
    }
  }, [showPlayer, autoPlay]);

  useEffect(() => {
    if (userToken && mediaId) {
      getHistoryData();
    }
  }, [userToken, getHistoryData, mediaId]);

  useEffect(() => {
    /* get new history data when shouldGetLatestHisoty is updated */
    /* we don't use player from open to close condition because VideoPlayer is unmount after
    player state change from open to close
    -> mean getHistoryData will be fired first instead of updateHistory */
    if (shouldGetLatestHisoty) {
      setShouldGetLatestHistory(false);
      getHistoryData();
    }
  }, [shouldGetLatestHisoty, getHistoryData]);

  useEffect(() => {
    if (detailData) {
      const paramsEvent = {
        movieID: mediaId,
        movieName: detailData?.titleLocalized,
        movieGenres: detailData?.tags?.genres,
        contentType: detailData?.type
      };

      analytic(
        analyticTypes.event,
        analyticEvents.CATALOGUE.VIEW_MOVIE_DETAIL,
        {
          params: paramsEvent,
          user
        }
      );
      analytic(analyticTypes.trackPage, 'MovieDetails', { user });
    }
  }, [mediaId, user, detailData]);

  // get next episode when current episode changed
  useEffect(() => {
    const getNextEpisode = async () => {
      try {
        const currentEpisodeDetail = await getSingleMediaInfo(detailData.id);
        const nextEpisodeID = currentEpisodeDetail.nextId;
        if (!nextEpisodeID || nextEpisodeID === detailData.id) {
          return setNextEpisode(null);
        }
        return setNextEpisode(nextEpisodeID);
      } catch (err) {
        return null;
      }
    };

    if (detailData && detailData.id) {
      getNextEpisode();
    }
  }, [detailData]);

  const handlePlayNextEpisode = useCallback(async () => {
    setSessionId(uuidv4());

    const timeToReinit = 2000;
    try {
      if (nextEpisode) {
        // important!: since videojs has been initialized, it will not updating the options. we should set the play to false then to unmount the player
        setOpenPlayer(false);
        // const nextEpisodeDetail = await getSingleMediaInfo(nextEpisode)
        // setPlayedEpisode(nextEpisodeDetail)
        navigate(`${window.location.pathname}?playEpisode=${nextEpisode}`);

        // important: because we cannot re-initializing the player, so we set the timeout to avoid race condition after we unmount the player
        setTimeout(() => setOpenPlayer(true), timeToReinit);
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  }, [nextEpisode, navigate]);

  const handleUpdateHistory = useCallback(
    (currentTime, duration) => {
      try {
        const historyParam = {
          mediaType: 'video',
          mediaSubType: mediaType,
          mediaId,
          position: currentTime,
          length: duration
        };

        if (mediaType === 'episode' && detailData) {
          historyParam.seriesId = detailData.seriesId;
          if (detailData.nextId) {
            historyParam.nextId = detailData.nextId;
          }
        }
        dispatch(updateHistory(historyParam, sessionId));
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    [mediaId, mediaType, detailData, dispatch, sessionId]
  );

  const handleEndHistory = async (APIduration, currentTime, duration) => {
    handleUpdateHistory(duration, duration);
    onMediaEnd(true);
    onClose();
  };

  if (fetchDetailError && fetchDetailError.code) {
    return (
      <div className={styles['errorWrapper']}>
        <p>{`This media is not valid (#${fetchDetailError.code}).`}</p>
      </div>
    );
  }

  return (
    <AnimatedModal
      duration={300}
      open={openPlayer}
    >
      <div className={styles['player-container']}>
        {access && (
          <ShakaVideoPlayer
            videoInfo={detailData}
            access={access}
            onDismissPlayer={dismissPlayer}
            handlePlayNext={handlePlayNextEpisode}
            handleUpdateHistory={handleUpdateHistory}
            setShouldGetHistory={setShouldGetLatestHistory}
            handleEndHistory={handleEndHistory}
            historyData={historyData}
            telco={telco}
            account={account}
            width={isMobile ? '100%' : '100vw'}
            height={isMobile ? '100%' : '100vh'}
          />
        )}
      </div>
    </AnimatedModal>
  );
};

export default MediaPlayer;
