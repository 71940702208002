const initialState = {
  publicProfile: null,
  selectedLiveshow: null,
  streamStop: null,
  streamBlock: null,
  basicGift: [],
  premiumGift: [],
  lastJoined: null,
  data: null,
  error: false,
  dataCategory: [],
  loadingCategory: false,
  errorCategory: false,
  meta: null,
  liveShowViewers: null,
  loadingViewers: false,
  loading: false,
  vodData: [],
  vodLoading: false,
  vodMeta: null,
  vodError: false,
  webSocket: null,
  wsError: false,
  err: null
};

export default function liveShowReducer(state = initialState, action) {
  const { type, payload, page, err } = action;

  switch (type) {
    case 'SET_BASIC_GIFT':
      return {
        ...state,
        basicGift: [...state.basicGift, payload.data]
      };

    case 'HIDE_BASIC_GIFT':
      const mappedGifts = state.basicGift.map((gift) => {
        if (gift.id === payload.id) {
          return { ...gift, show: false };
        }
        return gift;
      });
      return {
        ...state,
        basicGift: mappedGifts
      };

    case 'SET_LAST_JOIN':
      return {
        ...state,
        lastJoined: payload.data
      };

    case 'SET_PREMIUM_GIFT':
      return {
        ...state,
        premiumGift: [...state.premiumGift, payload.data]
      };

    case 'NEXT_PREMIUM_GIFT':
      const restGifts = [
        ...state.premiumGift.splice(1, state.premiumGift.length - 1)
      ];
      return {
        ...state,
        premiumGift: restGifts
      };

    case 'HIDE_PREMIUM_GIFT':
      const premiumGifts = state.premiumGift.map((gift) => {
        if (gift.id === payload.id) {
          return { ...gift, show: false };
        }
        return gift;
      });
      return {
        ...state,
        premiumGift: premiumGifts
      };

    case 'SET_STREAM_STOP':
      return {
        ...state,
        streamStop: payload.data
      };
    case 'SET_STREAM_BLOCK':
      return {
        ...state,
        streamBlock: payload.data
      };

    case 'RESET_GIFT':
      return {
        ...state,
        premiumGift: null
      };

    case 'GET_PUBLIC_PROFILE':
      return {
        ...state,
        loading: true,
        err: null
      };

    case 'GET_LIVE_SHOWS':
      return {
        ...state,
        data: page === 1 ? [] : state.data,
        loading: true,
        err: null
      };

    case 'SET_SELECTED_LIVE_SHOW':
      return {
        ...state,
        loading: false,
        err: null,
        selectedLiveshow: payload
      };

    case 'GET_LIVE_SHOWS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: page === 1 ? payload.data : [...state.data, ...payload.data],
        meta: payload.meta,
        page
      };

    case 'GET_PUBLIC_PROFILE_SUCCESS':
      return {
        ...state,
        publicProfile: payload.data
      };

    case 'GET_CATGEORY_LIVE_SHOWS':
      return {
        ...state,
        loadingCategory: true,
        dataCategory: [],
        err: null
      };

    case 'GET_CATEGORY_LIVE_SHOWS_SUCCESS':
      return {
        ...state,
        loadingCategory: false,
        dataCategory:
          page === 1 ? payload.data : [...state.dataCategory, ...payload.data],
        metaCategory: payload.meta,
        pageCategory: page
      };

    case 'GET_CATEGORY_LIVE_SHOWS_SUCCESS_FROM_STATE':
      return {
        ...state,
        loadingCategory: false,
        dataCategory:
          page === 1 ? payload.data : [...state.dataCategory, ...payload.data],
        metaCategory: payload.meta,
        pageCategory: page
      };

    case 'GET_CATEGORY_LIVE_SHOWS_FAILURE':
      return {
        ...state,
        errorCategory: true,
        loadingCategory: false,
        err
      };

    case 'RESET_LIVESHOW_ERROR':
      return {
        ...state,
        loading: false,
        err: null
      };

    case 'TRANSFER_GIFT_FAILURE':
    case 'GET_LIVE_SHOWS_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
        err
      };

    case 'GET_LIVE_SHOW_VOD':
      return {
        ...state,
        vodLoading: true,
        err: null
      };
    case 'GET_LIVE_SHOW_VOD_SUCCESS':
      const data = payload.data.map((item) => {
        if ((item.type === 'show' || item.type === 'stream') && item.show) {
          item.type = 'liveshow-vod';

          const thumbnail = item.attachments.thumbnail_portrait
            ? item.attachments.thumbnail_portrait.fileUrl
            : item.show.profile.avatar;

          return {
            ...item.show,
            type: item.type,
            id: item.id,
            name: item.titleLocalized,
            thumbnail
          };
        }

        return item;
      });
      return {
        ...state,
        vodLoading: false,
        vodData: page === 1 ? data : [...state.vodData, ...data],
        vodMeta: payload.meta,
        err: null
      };

    case 'GET_LIVE_SHOW_VOD_SUCCESS_FROM_STATE':
      return {
        ...state,
        vodData: payload.vodData,
        vodMeta: payload.vodMeta,
        vodLoading: false,
        err: null
      };

    case 'GET_LIVE_SHOW_VOD_FAILED':
      return {
        ...state,
        vodLoading: false,
        vodError: true,
        err
      };
    case 'GET_LIVE_SHOW_VIEWERS':
      return {
        ...state,
        loadingViewers: true,
        err: null
      };
    case 'GET_LIVE_SHOW_VIEWERS_SUCCESS':
      return {
        ...state,
        liveShowViewers: payload,
        loadingViewers: false,
        err: null
      };
    case 'GET_LIVE_SHOW_VIEWERS_FAILED':
      return {
        ...state,
        loadingViewers: false,
        err
      };
    case 'SET_WEB_SOCKET_CONNECTION':
      return {
        ...state,
        webSocket: payload
      };
    case 'CLOSE_WEB_SOCKET_CONNECTION':
      return {
        ...state,
        webSocket: null
      };
    case 'SET_WEB_SOCKET_ERROR':
      return {
        ...state,
        wsError: payload
      };
    default:
      return state;
  }
}
