import { messaging } from '../../service/firebase';

const timeout = 3000;
export const showToastNotif = (type, message) => (dispatch) => {
  dispatch({
    type: 'SHOW_TOAST_NOTIF',
    payload: {
      show: true,
      type,
      message
    }
  });

  setTimeout(() => {
    dispatch({
      type: 'SHOW_TOAST_NOTIF',
      payload: {
        show: false,
        type: '',
        message: ''
      }
    });
  }, timeout);
};

export const requestFCMToken = () => async (dispatch) => {
  try {
    const token = await messaging.getToken();

    dispatch({
      type: 'SET_FCM_TOKEN',
      payload: token
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Unable to get permission to notify.', err);
  }
};

export const showNotification = (data) => (dispatch) => {
  dispatch({
    type: 'SHOW_NOTIFICATION',
    payload: data
  });

  // setTimeout(() => {
  //   dispatch({
  //     type: 'HIDE_NOTIFICATION',
  //     payload: data,
  //   });
  // }, 5000);
};

export const hideNotification = (data) => (dispatch) => {
  dispatch({
    type: 'HIDE_NOTIFICATION',
    payload: data
  });
};

export const subscribeTopic = () => async (dispatch) => {
  const fcmToken = localStorage.getItem('fireMessagingToken');
  const topicURL = `https://iid.googleapis.com/iid/v1/${fcmToken}/rel/topics/${process.env.REACT_APP_FIRE_MESSAGING_TOPIC}`;

  dispatch({
    type: 'NOTIFICATION_SUBSCRIBE_TOPIC'
  });

  fetch(topicURL, {
    method: 'POST',
    headers: {
      Authorization: `key=${process.env.REACT_APP_FIRE_MESSAGING_SERVERKEY}`
    }
  }).then((response) => {
    if (response.status < 200 || response.status >= 400) {
      dispatch({
        type: 'NOTIFICATION_SUBSCRIBE_TOPIC_FAILED'
      });
      const err = { message: `Error subscribing to topic: ${response.status}` };
      throw err;
    }
    dispatch({
      type: 'NOTIFICATION_SUBSCRIBE_TOPIC_SUCCESS'
    });
  });
};
