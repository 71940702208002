import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { generateErrorDetail } from '../../utils';
import { showToastNotif } from './notification';

export const getInAppNotification =
  (page, perPage) => async (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: 'GET_IN_APP_NOTIFICATION'
    });
    try {
      const res = await axios.get(
        `${BASE_URL}/notification/v1.0/listings?page=${page}&perPage=${perPage}&type=non-highlight`,
        {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID,
            'cloudfront-jwt-profileId': state?.auth?.user?.profile?.id
          }
        }
      );

      analytic(
        analyticTypes.event,
        analyticEvents.NOTIFICATION.IN_APP_NOTIFICATION_RECEIVE
      );
      dispatch({
        type: 'GET_IN_APP_NOTIFICATION_SUCCESS',
        payload: {
          ...res.data
        },
        page
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_IN_APP_NOTIFICATION_FAILED',
        payload: errorData.message
      });
      throw err;
    }
  };

export const getInAppNotificationHighlight =
  () => async (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: 'GET_IN_APP_NOTIFICATION'
    });

    try {
      const res = await axios.get(
        `${BASE_URL}/notification/v1.0/listings?type=highlight`,
        {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID,
            'cloudfront-jwt-profileId': state?.auth?.user?.profile?.id
          }
        }
      );

      dispatch({
        type: 'GET_IN_APP_NOTIFICATION_HIGHLIGHT_SUCCESS',
        payload: {
          ...res.data
        }
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_IN_APP_NOTIFICATION_HIGHLIGHT_FAILED',
        payload: errorData.message
      });
      throw err;
    }
  };

export const getInAppNotificationSummary = () => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: 'GET_IN_APP_UNREAD_NOTIFICATION'
  });

  try {
    const res = await axios.get(
      `${BASE_URL}/notification/v1.0/listings/summary`,
      {
        headers: {
          Authorization: state.auth.token,
          'Cloudfront-JWT-AppId': JWT_APP_ID,
          'cloudfront-jwt-profileId': state.auth.user.profile.id
        }
      }
    );
    dispatch({
      type: 'GET_IN_APP_UNREAD_NOTIFICATION_SUCCESS',
      payload: {
        ...res.data.data
      }
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_IN_APP_UNREAD_NOTIFICATION_FAILED',
      payload: errorData.message
    });
  }
};

export const getInAppNotificationById = (id) => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: 'GET_IN_APP__NOTIFICATION_DETAIL'
  });

  try {
    const res = await axios.get(`${BASE_URL}/notification/v1.0/listing/${id}`, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID,
        'cloudfront-jwt-profileId': state.auth.user.profile.id
      }
    });
    dispatch({
      type: 'GET_IN_APP_DETAIL_NOTIFICATION_SUCCESS',
      payload: {
        ...res.data.data
      }
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_IN_APP_DETAIL_NOTIFICATION_FAILED',
      payload: errorData.message
    });
  }
};

export const getDetailInAppNotification =
  (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: 'GET_DETAIL_IN_APP_NOTIFICATION'
    });

    try {
      const res = await axios.get(
        `${BASE_URL}/notification/v1.0/listing/${id}`,
        {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID,
            'cloudfront-jwt-profileId': state.auth.user.profile.id
          }
        }
      );

      analytic(
        analyticTypes.event,
        analyticEvents.NOTIFICATION.IN_APP_NOTIFICATION_SET_READ
      );
      dispatch({
        type: 'GET_DETAIL_IN_APP_NOTIFICATION_SUCCESS',
        payload: {
          ...res.data
        }
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_DETAIL_IN_APP_NOTIFICATION_FAILED',
        payload: errorData.message
      });
    }
  };

export const toggleDialogNotif = () => (dispatch) => {
  dispatch({
    type: 'TOGGLE_IN_APP_NOTIFICATION_DIALOG'
  });
};

export const closeDialogNotif = () => (dispatch) => {
  dispatch({
    type: 'CLOSE_IN_APP_NOTIFICATION_DIALOG'
  });
};
