import React from 'react';
import { social } from '../../constants';

export const termUGCList = {
  id: {
    languageTitle: 'Indonesian',
    nextLanguage: 'English',
    header: ['Syarat & Ketentuan', 'Genflix User-Generated Content'],
    contentList: [
      {
        contents: [
          'Persyaratan dan ketentuan Genflix User-Generated Content ini (“Syarat dan Ketentuan Genflix User-Generated Content”) berlaku dan mengikat PT Festival Citra Lestari (“Genflix”), selaku pengelola dari Aplikasi Genflix (“Aplikasi”), dan pihak pengguna Aplikasi yang telah terdaftar dan terverifikasi oleh Genflix (yang selanjutnya disebut “Pengguna”), sehubungan dengan penggunaan Aplikasi oleh Pengguna terkait dengan fungsi Genflix User-Generated Content, baik melalui gawai (gadget) apapun dimana Aplikasi tersedia dan dapat digunakan.',
          'Pengguna diwajibkan untuk membaca, memahami serta menyetujui seluruh isi dari Syarat dan Ketentuan Genflix User-Generated Content ini sebelum dapat memanfaatkan fungsi Genflix User-Generated Content dalam Aplikasi.',
          'Yang dimaksud dengan “Genflix User-Generated Content” di dalam Syarat dan Ketentuan Genflix User-Generated Content ini adalah layanan yang disedikan oleh Kami kepada Pengguna untuk dapat mengunggah dan mendistribusikan gambar dan karya audio-visual yang telah direkam sebelumnya, melalui layanan kami, seperti integrasi gambar atau video, dan untuk berpartisipasi dalam aktivitas di mana Pengguna dapat membuat, memposting, mengirimkan, melakukan, atau menyimpan konten, gambar, suara, teks, atau data atau materi lain pada Aplikasi. Bahwa Syarat dan Ketentuan Genflix User-Generated Content ini merupakan satu kesatuan yang tidak terpisahkan dengan Syarat dan Ketentuan umum Genflix.'
        ]
      },
      {
        title: '1. UMUM',
        contents: [
          'Layanan Genflix User-Generated Content merupakan tempat perantara informasi untuk menghubungkan antara Pengguna Aplikasi untuk membangun komunikasi, komunitas, berbagi pengalaman dan momen tiap Pengguna melalui fitur Aplikasi yang memungkinkan untuk mendistribusikan streaming langsung, menggunakan layanan obrolan, dimana Pengguna dapat membuat, mengupload, menyimpan konten, gambar, suara, teks dan fitur lain yang disediakan dalam Aplikasi (“Layanan UGC”).',
          'Genflix senantiasa untuk mengembangkan Layanan UGC untuk menghindari penyalahgunaan Aplikasi maupun perilaku berbahaya dari Pengguna. Jika kami mengetahui Layanan UGC digunakan dengan cara yang bertentangan dengan hukum, kami akan mengambil tindakan yang sesuai - misalnya, menawarkan bantuan, menghapus konten, menghapus atau membatasi akses ke fitur tertentu, menonaktifkan akun, atau menghubungi penegak hukum.'
        ]
      },
      {
        title: '2. LISENSI',
        contents: [
          'Pengguna dengan ini bertanggung jawab secara penuh bahwa dengan menggunakan Aplikasi Genflix, pengguna menyatakan dan menjamin bahwa pengguna memiliki hak secara penuh untuk mengizinkan Genflix untuk menampilkan, melakukan, menggunakan, dan menyiarkan semua konten yang dibuat pengguna dalam Layanan UGC pada Aplikasi Genflix.',
          'Hak lisensi yang diberikan disini akan berlaku diseluruh teritori di seluruh dunia yang tidak dapat dibatalkan tanpa syarat, non-eksklusif, bebas royalti, sepenuhnya dapat dialihkan, dapat disublisensikan, dapat digunakan, dapat dimodifikasi, diadaptasi, direproduksi, dibuat karya turunannya, dan/atau ditransmisikan, dan untuk memberi wewenang kepada Pengguna Layanan UGC lainnya dan pihak ketiga lainnya untuk melihat, mengakses, menggunakan, memodifikasi, menerjemahkan, mengadaptasi, mereproduksi, membuat karya turunan, dan/atau mengirimkan Konten Pengguna Anda sehubungan dengan Layanan UGC pada Aplikasi Genflix. Pengguna memahami dan menyetujui bahwa setiap waktu akan selalu mematuhi Syarat dan Ketentuan yang ditetapkan Genflix dari waktu kewaktu.'
        ]
      },
      {
        title: '3. TANGGUNG JAWAB PENGGUNA',
        contents: [
          'Pengguna bertanggung jawab penuh atas setiap konten yang diunggah dalam Layanan UGC. Pengguna akan selalu mematuhi ketentuan Hukum yang berlaku dan setiap konten yang di unggah melalui Layanan UGC pada Aplikasi Genflix dilarang berisi hal bermuatan politik, berisi konten pertunjukan perilaku seksual atau konten pornografi,terkait perjudian, mengandung konten terorisme, obat-obatan, berisi materi yang menyesatkan, kegiatan cabul, mengancam, melecehkan, memfitnah, menipu, curang, menyerang privasi orang lain, menyinggung, memfitnah siapa pun atau illegal, berisi informasi pribadi pihak ketiga mana pun, melanggar hak hukum orang lain termasuk namun tidak terbatas pada hak cipta, merek dagang, rahasia dagang atau hak kekayaan intelektual atau privasi orang lain. Pengguna bertanggung jawab secara penuh mengganti rugi Genflix atas setiap kerugian yang timbul dari setiap pelanggaran dari Syarat dan Ketentuan disini.'
        ]
      },
      {
        title: '4. RISIKO',
        contents: [
          'Genflix akan menggunakan cara-cara teknis dan keamanan yang baik, tepat dan wajar untuk menjaga Aplikasi aman dan terbebas dari virus dan kesalahan. Namun demikian, bagaimanapun efektifnya teknologi ini, tidak ada sistem keamanan yang aman sepenuhnya dan tidak dapat ditembus.',
          'Oleh karena itu Genflix tidak dapat menjamin secara utuh keamanan database Genflix dan Genflix juga tidak dapat menjamin bahwa layanan tidak akan mengalami gangguan.',
          {
            list: {
              order: 'ul',
              title:
                'Ketika Pengguna menggunakan aplikasi serupa ataupun aplikasi manapun, termasuk melalui Aplikasi, akan selalu ada risiko kegagalan dan/atau kehilangan akibat:',
              items: [
                'Kegagalan atau kerusakan komputer, handphone, atau gawai lain yang anda gunakan (termasuk baterainya);',
                'Koneksi internet yang kurang baik, yang menyebabkan Pengguna tidak dapat terkoneksi dengan Aplikasi;',
                'Peretasan yang dilakukan oleh pihak-pihak lain, baik peretasan secara langsung terhadap Aplikasi, peretasan melalui perangkat lunak (software), maupun link berbahaya yang memungkinkan pihak pelaku peretasan mendapatkan atau menggunakan akses ke informasi dan/atau aset Pengguna;',
                'Perangkat anda tidak kompatibel (incompatible) dengan, atau tidak sesuai dengan spesifikasi sistem Aplikasi, termasuk akibat dari kesalahan dalam penyetelan (settings) atau sistem operasi gawai anda yang tidak sesuai dengan yang disyaratkan;',
                'Kegagalan sistem pada Aplikasi, atau kegagalan pada perangkat keras dan perangkat lunak dari gawai yang Pengguna gunakan; dan',
                'Ketidakpatuhan Pengguna terhadap Syarat dan Ketentuan Genflix User-Generated Content ini yang dapat berakibat pada penangguhan atau bahkan pemblokiran pada Akun Pengguna.'
              ]
            }
          },
          'Dengan membuka akun pada Aplikasi dan terdaftar, Pengguna dianggap telah setuju dan mengakui bahwa Pengguna telah membaca dan memahami risiko yang akan terjadi.'
        ]
      },
      {
        title: '5. PENGUMPULAN DAN PERLINDUNGAN DATA PENGGUNA',
        contents: [
          'Dalam menjalankan kegiatan usahanya dan untuk meningkatkan layanan Aplikasi, Genflix akan melakukan permintaan dan pengumpulan data, serta melakukan transfer data Pengguna kepada otoritas pemerintah apabila terdapat kewajiban hukum untuk melakukan pengungkapan data dan pihak yang bekerjasama dengan Genflix (“Pihak Vendor”) guna keperluan Pengguna dalam menjalankan aktivitas pada Aplikasi. Mohon diperhatikan bahwa untuk melindungi data Pengguna dan mematuhi aturan hukum yang berlaku, Genflix hanya akan memberikan data kepada Pihak Vendor apabila Genflix telah mendapatkan persetujuan dari Pengguna.',
          'Sebelum Pengguna dapat menggunakan Aplikasi Genflix, Pengguna akan terlebih dahulu dimintai persetujuannya atas Syarat dan Ketentuan Genflix User-Generated Content ini (termasuk atas pengumpulan data-data pribadi sebagaimana ternyata di atas). Khusus untuk pengumpulan data-data pribadi melalui akses data lokal dan data sistem yang ada pada gawai (gadget) dari Pengguna, Genflix akan meminta persetujuan terlebih dahulu di awal sebelum mendapatkan akses tersebut.',
          'Kelengkapan data Pengguna akan sangat berguna bagi Aplikasi untuk memberikan kesan dan pengalaman yang baik kepada Pengguna dalam menggunakan Aplikasi. Genflix juga berpendapat bahwa perlindungan atas informasi pribadi adalah hal yang sangat penting sebagaimana juga dipersyaratkan oleh peraturan perundang-undangan yang berlaku. Genflix dengan kemampuan terbaiknya akan menjaga, melindungi, dan menggunakan data Pengguna dengan prosedur kontrol yang baik dan ketat.'
        ]
      },
      {
        title: '6. PENGHAPUSAN DATA',
        contents: [
          'Genflix selaku penyelenggara Sistem Elektronik wajib menyimpan Data Pribadi Pengguna paling singkat selama 5 (lima) tahun sejak tanggal terakhir pemilik data pribadi menjadi Pengguna pada Aplikasi. Penghapusan data akan dilakukan oleh Genflix sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.',
          'Sejauh diizinkan oleh hukum yang berlaku, Pengguna berhak meminta kami untuk menghapus data pribadi Pengguna yang disimpan pada sistem Genflix. Atas permintaan Pengguna, kami akan berusaha untuk menghapus data pribadi Anda dari sistem kami, kecuali jika kami diizinkan atau diwajibkan oleh hukum yang berlaku untuk melakukan sebaliknya. Genflix juga akan berhenti mengumpulkan, menggunakan, atau mengungkapkan data pribadi Pengguna, kecuali jika diizinkan atau diwajibkan oleh hukum yang berlaku. Mohon diperhatikan bahwa dengan meminta kami untuk menghapus data pribadi Anda, kami mungkin tidak dapat terus menyediakan layanan kami kepada Anda.',
          'Sehubungan dengan Penghapusan Data ini, Pengguna dapat menghubungi kami melalui kontak yang tersedia pada Aplikasi.'
        ]
      },
      {
        title: '7. KETENTUAN KEAMANAN PENGGUNA',
        contents: [
          'Dalam mendorong kualitas keamanan data Pengguna, Genflix akan melakukan kebijakan agar mewajibkan Pengguna untuk membuat password akun Pengguna yang tidak dapat dengan mudah di retas. Genflix akan memberikan saran agar Pengguna menggunakan konfigurasi tersebut pada saat Pengguna melakukan pendaftaran password.',
          'Lebih lanjut, untuk menjaga keamanan data dan penggunaan Aplikasi, Aplikasi diatur sedemikian rupa agar dapat menjaga keamanan data Pengguna. Selain menggunakan password, dan/atau OTP, Aplikasi juga diatur sedemikian rupa untuk dipaksa logged out dari Akun Pengguna apabila Aplikasi dalam keadaan diam (idle) selama 30 hari berturut-turut.'
        ]
      },
      {
        title: '8. SISTEM PENDUKUNG',
        contents:
          'Genflix akan memberlakukan ketentuan spesifik tertentu pada Pengguna untuk menggunakan versi Aplikasi yang paling terkini. Pada saat Pengguna mengakses bagian Genflix User-Generated Content, sistem Aplikasi akan secara otomatis mendeteksi apakah sistem yang digunakan oleh Pengguna dapat mendukung aktivitas Genflix User-Generated Content. Apabila pada saat tersebut Aplikasi menganggap gawai Pengguna tidak kompatibel, maka Aplikasi akan menyarankan Pengguna untuk melakukan update terlebih dahulu. '
      },
      {
        title: '9. PEMELIHARAAN (MAINTENANCE)',
        contents: [
          'Untuk menjaga dan meningkatkan kualitas layanan, Genflix akan selalu melakukan pembaruan (update) dan pemeliharaan Aplikasi (“Pemeliharaan”). Pemeliharaan dapat dilakukan Genflix secara periodik ataupun secara sewaktu-waktu. Pemeliharaan secara periodik akan dilakukan setiap 1 (satu) bulan sekali, dan Genflix berkewajiban untuk memberitahukan jadwal Pemeliharaan kepada Pengguna paling lambat 2 hari (48 jam) sebelum proses Pemeliharaan periodik dilakukan.',
          'Dalam hal terdapat suatu kejadian yang tidak dapat dihindarkan, yang memaksa Genflix harus melakukan Pemeliharaan, maka Genflix wajib memberitahukan kepada Pengguna secepatnya disertai dengan alasan dilakukannya Pemeliharaan secara mendadak. Dalam pemberitahuannya, Genflix diwajibkan untuk mengingatkan bahwa pada saat Pemeliharaan, tidak ada aktivitas Pengguna yang akan diproses oleh sistem.',
          'Dalam hal Genflix telah melaksanakan prosedur pemberitahuan yang sesuai terkait Pemeliharaan kepada Pengguna, maka Genflix tidak dapat dimintai pertanggungjawaban atas kegagalan apapun dalam Aplikasi yang dilaksanakan pada waktu Pemeliharaan.'
        ]
      },
      {
        title: '10. FORCE MAJEURE',
        contents: [
          {
            list: {
              oreder: 'ul',
              title:
                'Yang dimaksud dengan Force Majeure adalah segala kejadian-kejadian yang terjadi di luar kekuasaan dan kemampuan Genflix sehingga mempengaruhi pelaksanaan jasa yang diberikan Genflix (termasuk melalui Aplikasi) kepada Pengguna sehubungan dengan Genflix User-Generated Content, antara lain namun tidak terbatas pada:',
              items: [
                'Gempa bumi, angin topan, banjir, tanah longsor, gunung meletus dan bencana alam lainnya;',
                'Perang, demonstrasi, huru-hara, terorisme, sabotase, embargo, dan pemogokan massal; dan',
                'Kebijakan ekonomi dan pemerintah yang mempengaruhi secara langsung.'
              ]
            }
          },
          'Sepanjang Genflix telah melaksanakan segala kewajibannya sesuai dengan peraturan perundang-undangan yang berlaku sehubungan dengan terjadinya Force Majeure tersebut, maka Genflix tidak akan memberikan ganti rugi dan/atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak lain manapun atas segala risiko, tanggungjawab dan tuntutan apapun yang mungkin timbul sehubungan dengan keterlambatan maupun tidak dapat dilaksanakannya kewajiban akibat Force Majeure.'
        ]
      },
      {
        title: '11. PENYELESAIAN SENGKETA',
        contents:
          'Dalam hal terdapat sengketa antara Genflix dengan Pengguna, maka penyelesaian sengketa akan tunduk pada yurisdiksi pengadilan di Indonesia sepanjang para pihak sepakat untuk tidak memilih yurisdiksi manapun. Seluruh perjanjian antara Pengguna dan Genflix akan ditafsirkan sesuai dengan peraturan perundang-undangan di Indonesia.'
      },
      {
        title: '12. PENGGUNA MENINGGAL DUNIA',
        contents:
          'Dalam hal Pengguna meninggal dunia, maka Genflix berhak meminta salinan yang sah atas akta kematian, surat keterangan hak waris, akta wasiat, dan dokumen lain yang menurut pertimbangan Genflix diperlukan untuk mengetahui pihak yang berhak menerima warisan Pengguna.'
      },
      {
        title: '13. LAIN-LAIN',
        contents:
          'Syarat dan Ketentuan Genflix User-Generated Content ini dapat dimodifikasi dan diubah dari waktu ke waktu. Genflix akan memberitahu Pengguna melalui Aplikasi dan/atau email atas modifikasi, dan/atau perubahan atas Syarat dan Ketentuan Genflix User-Generated Content. Penggunaan Aplikasi secara terus menerus oleh Pengguna setelah diterimanya pemberitahuan tersebut merupakan persetujuan dan penerimaan Pengguna atas modifikasi, dan/atau perubahan atas Syarat dan Ketentuan Aplikasi Genflix.'
      }
    ]
  },
  en: {
    languageTitle: 'English',
    nextLanguage: 'Indonesian',
    header: ['Terms & Conditions', 'Genflix User-Generated Content'],
    contentList: [
      {
        contents: [
          'This Terms & Condition of Genflix User-Generated (“Genflix User-Generated Content Terms and Conditions“) is a valid agreement between PT. Festival Citra Lestari (“Genflix”),as the operator  of Genflix Aplication (“App”) and the user of App that is registered and verified by by Genflix (hereinafter referred to as “Users”) in connection with the use of the App by the User relating to Genflix User-Generated Content functions, via any gadget where the App is available and may be used.',
          'Users must read, understand as well as approve the entire content of these Genflix User-Generated Content Terms and Conditions prior to the use of Genflix User-Generated Content functions on the App.',
          '“Genflix User-Generated Content”  in these Genflix User-Generated Content Terms and Conditions is a service provided by Us to Users to be able to upload and distribute pre-recorded images and audio-visual works, through our services, such as image or video integration, and to participate in activities where Users can create, post, transmit, perform or store content, images, sound, text, or other data or materials on the Application.',
          'Whereas these Genflix User-Generated Content Terms and Conditions are an integral part of the general Genflix Terms and Conditions.'
        ]
      },
      {
        title: '1. GENERAL',
        contents: [
          'Genflix User-Generated Content service is a place for information intermediaries to connect between Users of App to build communication, community, share experiences and moments of each User through App features that allow to distribute the live streaming, use chat features, where Users can create, upload, store content, images, sound, text and other features provided in the Application (“UGC Service”).',
          'Genflix continues to develop UGC Services to avoid misuse of the Application or malicious behavior from Users. If we become aware of the UGC Service being used in a way that is against the law, we will take appropriate action - for example, offering assistance, removing content, removing or restricting access to certain features, deactivating an account, or contacting law enforcer.'
        ]
      },
      {
        title: '2. LICENSE',
        contents: [
          'The user hereby is fully responsible that by using the Genflix App, Users represent and warrant that they have full rights to allow Genflix to display, perform, use, and broadcast all user-generated content in the UGC Service on the Genflix App.',
          'The license rights granted herein shall apply in all territories around the world which are irrevocable, non-exclusive, royalty-free, completely transferable, sublicensable, usable, modifiable, adaptable, reproduced, created derivative works, and/or transmitted, and to authorize other UGC Service Users and other third parties to view, access, use, modify, translate, adapt, reproduce, create derivative works, and/or transmit your User’s Content in connection with the UGC Service on the Genflix Application. Users understand and agree that at all times they will comply with the Terms and Conditions set by Genflix from time to time.'
        ]
      },
      {
        title: '3. USER RESPONSIBILITY',
        contents:
          'Users are fully responsible for any content uploaded in the UGC Service. Users will always comply with applicable legal provisions and any content uploaded through the UGC Service on the Genflix Application is prohibited from containing politically charged things, containing content showing sexual behavior or pornographic content, related to gambling, containing terrorism content, drugs, containing material that misleading, obscene activity, threatening, harassing, libelous, deceptive, fraudulent, invading the privacy of others, offensive, slanderous or illegal, contains personal information of any third party, violates the legal rights of others including but not limited to copyrights, trademarks trade secrets or other intellectual property rights or privacy of others. The user is fully responsible for indemnifying Genflix for any losses arising from any violation of the Terms and Conditions herein.'
      },
      {
        title: '4. RISK',
        contents: [
          'Genflix will use technical and security methods that are good, proper, and appropriate to maintain the security of the App and free from viruses and errors. However, notwithstanding the effectiveness of this technology, no security system is completely safe and unhackable.',
          'Therefore, Genflix is unable to warrant the complete security of Genflix database and Genflix is also unable to warrant that the services will be uninterrupted.',
          {
            list: {
              order: 'ul',
              title:
                'When Users using a similar applications, including via the App, there will always be a risk of failure and/or loss due to:',
              items: [
                'Failures and damages on the computer, handphone or other gadgets that you use (including the battery);',
                'Unstable internet connection, that result in the User unable to connect with the App or the User may be connected while such unstable connection result in delays that carry a risk in the placement and activity of your investment;',
                'Hacking performed by other parties, whether direct hacking on the App, hacking o the software, as well as malicious links that enable the hackers to gain or use access to information and/or asset of the User;',
                'Incompactible device with, or does not meet with the App system specification, including due to errors in settings or operating system of your gadget does not with the requirements;',
                'System failures on the App, or failures on the hardwares and softwares on the gadget that the User use; and',
                'User incompliance of these Genflix User-Generated Content Terms and Conditions that may result in suspension or blocking of the User Account.'
              ]
            }
          },
          'By opening an account on and registered, the User is deemed to approve and acknowledge that the User has read and understood the potential risks.'
        ]
      },
      {
        title: '5. COLLECTION AND PROTECTION OF USER DATA',
        contents: [
          'In conducting its business activities and in improving services on the App, Genflix shall also request for and collect data, as well as transfer Users data if there is a legal obligation to disclose data to the government agencies as well as to the party in cooperation with Genflix (“Vendor”) to enable the User to undertake activities on App. Please beware that to protect User data and to comply with the applicable laws, Genflix will only provide data to Vendors upon approval from Users.',
          'Before Users may use the App and services provided by Genflix, the User shall firstly requested to approve this Genflix User-Generated Content Terms and Conditions (including on the collection of personal data as mentioned above). Specifically on personal data collection via local data access and system data that are in the User gadget, Genflix shall request prior approval before having such access.',
          'Completeness of Users data will be useful for the App to present great impressions and experiences to the User in using the App. Genflix is also of the view that protection of personal information is a very important requirement as required under the applicable laws and regulations. Genflix, with its best efforts, will maintain, protect and use User data with tight and appropriate control procedures.'
        ]
      },
      {
        title: '6. DATA DELETION',
        contents: [
          'Genflix as the Electronic System provider shall store the User Personal Data for a minimum period of 5 (five) years as of the last date when the owner of personal data is an App User. Data deletion shall be undertaken by Genflix in accordance with the applicable laws and regulations.',
          'To the extent permitted by the applicable laws, the User is entitled to request us to delete User personal data that are stored in Genflix’s systems. Upon the User request, we will attempt to delete Your personal data from our system, unless permitted or required otherwise by the applicable laws. Genflix shall also cease to collect, use, or dislose User personal data, unless permitted or required by the applicable laws. Please note that by requesting us to delete Your personal data, we may not be able to continue providing our services to You.',
          'In relation to this Data Deletion, the User may contact us via contacts as set out under customer care center on the App.'
        ]
      },
      {
        title: '7. USER TERMS OF SECURITY',
        contents: [
          'In supporting User data safety quality, Genflix shall implement a policy that the User must register a password so that the User account is not easily hackable. Genflix shall suggest Users to use such configuration while registering its password.',
          'Furthermore, to maintain data security and use of App, the App is arranged as such to allow maintenance of User data. In addition to password, and/or OTP, the App is also arranged as such to have forced log out from the User Account in the event that the App is idle for 30 consecutive days.'
        ]
      },
      {
        title: '8. SUPPORTING SYSTEMS',
        contents:
          'Genflix shall apply certain specific terms on the User to use the latest version of the App. When the user access the Genflix User-Generated Content section, the App shall automatically detect whether the system used by the User may support the Genflix User-Generated Content activities. If during such condition, the App deems that the User gadget is not compactible, the App shall suggest the User to firstly update.'
      },
      {
        title: '9. MAINTENANCE',
        contents: [
          'To maintain and improve the quality of Services, Genflix will always update and maintain the App (“Maintenance”). Maintenance may be performed by Genflix periodically or on an ad hoc basis. Periodical Maintenance will be performed 1 (one) time per month, and Genflix is obligated to notify the Maintenance schedule to the User at least 2 days (48 hours) in advance prior to the commencement of the periodical Maintenance.',
          'In the event of unavoidable circumstances that require Genflix to perform Maintenances, Genflix shall notify the User immediately with the reasons of such sudden Maintenances. In its notice, Genflix is obligated to remind that during the Maintenances, no User activities that will be processed by the system.',
          'In the event that Genflix has complied with the appropriate notification procedures concerning the Maintenances to the User, Genflix may not be claimed for any failed transactions that are made during the Maintenances.'
        ]
      },
      {
        title: '10. FORCE MAJEURE',
        contents: [
          {
            list: {
              oreder: 'ul',
              title:
                'Force Majeure means any events outside of the control and capability of Genflix that affects the implementation of services that are provided by Genflix (including via the App) to the User in relation the Genflix User-Generated Content, among others including but not limited to:',
              items: [
                'Earthquakes, hurricanes, floods, landslides, volcanic eruptions and other natural disasters;',
                'War, demonstrations, riots, terrorism, sabotages, embargoes, and mass strikes; and',
                'Economic and government policies that has direct effects.'
              ]
            }
          },
          'Provided that Genflix has performed all of its obligations in accordance with the applicable laws and regulations in relation to the occurence of such Force Majeure, Genflix will not provide any indemnities and/or accountabilities in any form to the User or other party of any risks, liabilities and claims that may arise in connection with any delays as well as non-fulfilment of obligations due to a Force Majeure.'
        ]
      },
      {
        title: '11. DISPUTE RESOLUTION',
        contents:
          'In the event of disputes between Genflix with Users, the dispute settlement will be subject to the courts jurisdiction in Indonesia, to the extent that the parties agree not to select other jurisdictions. All agreements between the User and Genflix will be construed in accordance with the laws and regulations in Indonesia.'
      },
      {
        title: '12. PASSING AWAY OF THE USER',
        contents:
          'In the event that the User passes away, Genflix is entitled to request a valid copy of the death certificate, inheritance statements, wills, and other documents that Genflix, in its opinion, deems necessary to know the entitled party to receive the User’s inheritances.'
      },
      {
        title: '13. MISCELLANEOUS',
        contents:
          'These Genflix User-Generated Content Terms and Conditions may be modified and amended from time to time. Genflix will notify Users via the Application and/or email for modifications to, and/or changes to the Genflix User-Generated Content Terms and Conditions. The User’s continuous use of the Application after receiving the notification constitutes the User’s approval and acceptance of modifications to, and/or changes to the Genflix Application Terms and Conditions.'
      }
    ]
  }
};

export const privacyPolicyList = {
  id: {
    languageTitle: 'Indonesian',
    nextLanguage: 'English',
    header: 'Kebijakan Privasi Genflix',
    contentList: [
      {
        contents: [
          'Kebijakan privasi ini menjelaskan bagaimana PT Festival Citra Lestari (“Genflix”) sebagai pengelola dari aplikasi Genflix (“Aplikasi”) dalam melakukan pengumpulan, penyimpanan, penggunaan, proses pengolahan, transfer, pengungkapan, dan perlindungan atas data dan informasi pengguna (”Kebijakan Privasi Genflix”). Kebijakan Privasi Genflix berlaku atas setiap penggunaan layanan atau jasa yang tersedia pada Aplikasi, termasuk dalam bentuk aplikasi seluler dan perangkat lunak dan lainnya, yang digunakan oleh pengguna Aplikasi yang telah terdaftar dan terverifikasi oleh Genflix (yang selanjutnya disebut “Pengguna”). Pengguna diwajibkan untuk membaca, memahami, dan menyetujui Kebijakan Privasi Genflix karena data Pengguna akan menjadi dasar bagi Genflix untuk memberikan layanan yang lebih baik bagi Pengguna.',
          'Apabila Pengguna tidak mengizinkan pengolahan data atau informasi pengguna seperti yang dijelaskan dalam Kebijakan Privasi Genflix ini, maka Genflix tidak menyarankan pengguna untuk menggunakan layanan Aplikasi.'
        ]
      },
      {
        title: '1. PENGUMPULAN DATA',
        contents: [
          {
            list: {
              order: 'ol',
              type: '1',
              title:
                'Data Pengguna dikumpulkan oleh Genflix melalui beberapa cara yaitu melalui:',
              items: [
                'Pengisian formulir atau aplikasi oleh calon Pengguna. Data yang dikumpulkan pada tahap ini adalah mengenai data pribadi calon pengguna, yang antara lain terdiri dari, nama lengkap, nomor telepon, alamat surat elektronik, and gambar profil.',
                {
                  list: {
                    order: 'ol',
                    type: 'a',
                    title: 'Aktivitas Pengguna antara lain:',
                    items: [
                      'Konten apa yang ditonton Pengguna? kapan? berapa lama?',
                      'Apakah konten ditransmisikan ke perangkat lain?',
                      'Layar apa yang dibuka Pengguna? ketika?',
                      'Kata kunci apa yang dicari Pengguna? kapan?',
                      'Metode apa yang digunakan Pengguna untuk login dan mendaftar? kapan?',
                      'Daftar putar mana yang dibuka Pengguna? kapan?',
                      'Konten apa yang dibagikan Pengguna? kapan?',
                      'Live show apa yang ditonton oleh Pengguna? kapan?',
                      'Hadiah apa yang diberikan Pengguna dalam live show? kapan?',
                      'Komentar apa yang diberikan Pengguna dalam live show? kapan?',
                      'Kapan Pengguna mengajukan permintaan cash out gencash?',
                      'Siapa yang diikuti dan dihentikan oleh Pengguna di web/aplikasi?',
                      'Langganan mana yang dipilih dan dibeli Pengguna? kapan?',
                      'Voucher apa yang digunakan Pengguna? kapan?',
                      'Menandai atau menyimpan aktivitas, dan aktivitas lain yang dilakukan oleh Pengguna di Aplikasi'
                    ]
                  }
                },
                'Data lokasi yang dikirimkan oleh sistem saat membaca penggunaan Aplikasi di lokasi atau area tertentu;',
                'Data teknis yang diperoleh saat Pengguna menggunakan Aplikasi. Data teknis tersebut meliputi alamat Internet Protocol (IP), informasi situs web, durasi penggunaan Aplikasi atau kunjungan situs web, gadget saat menggunakan internet, iklan, dan hal-hal lain yang relevan;',
                'Data interaksi dengan layanan pelanggan kami;',
                'Mekanisme pengumpulan data lainnya yang dilakukan oleh Genflix (termasuk melalui Aplikasi);',
                'Sumber lain yang bekerja sama dengan Genflix, di mana Pengguna telah mengizinkan transfer data Pengguna.'
              ]
            }
          },
          'Sebelum Pengguna dapat menggunakan Aplikasi dan jasa yang diberikan oleh Genflix, Pengguna akan terlebih dahulu dimintai persetujuannya atas Kebijakan Privasi ini (termasuk atas pengumpulan data-data pribadi sebagaimana ternyata di atas). Khusus untuk pengumpulan data-data pribadi melalui akses data lokal dan data sistem yang ada pada gawai (gadget) dari Pengguna, Genflix akan meminta persetujuan terlebih dahulu di awal sebelum mendapatkan akses tersebut, termasuk akses terhadap foto, suara, kamera, dan fitur gawai lainnya yang digunakan oleh Pengguna.'
        ]
      },
      {
        title: '2. PENGGUNAAN DATA',
        contents: [
          {
            list: {
              order: 'ol',
              title:
                'Genflix berhak untuk menggunakan data Pengguna apabila Pengguna menyetujui Kebijakan Privasi Genflix Data yang telah dikumpulkan oleh Genflix akan digunakan untuk hal-hal sebagai berikut:',
              items: [
                'Mengidentifikasi Pengguna termasuk dalam hal pengurusan kepentingan Pengguna yang terkait dengan verifikasi, pengaktifan atau penonaktifan akun Pengguna, dan hal lain sehubungan dengan akun Pengguna;',
                'Untuk mendaftarkan dan memberikan akses kepada data Pengguna pada pihak yang memiliki kerjasama dengan Genflix dalam menyediakan produk-produk yang ditawarkan pada aplikasi (Pihak Vendor), untuk mendaftarkan Pengguna pada sistem yang disediakan oleh Pihak Vendor.',
                'Untuk mempermudah komunikasi antara Genflix dengan Pengguna melalui dan atas penggunaan Aplikasi;',
                'Untuk memberikan update atas informasi terbaru, baik mengenai pembaruan sistem Aplikasi, fitur-fitur Aplikasi, perubahan kebijakan apapun baik yang dilakukan oleh Genflix maupun pemerintah yang mempengaruhi Aplikasi, dan informasi lain yang dapat ditampilkan atau disampaikan kepada Pengguna;',
                'Untuk memproses dan merespon pertanyaan dan feedback dari Pengguna;',
                'Untuk memperbarui, mempertahankan, mengembangkan, dan mempersonalisasi Aplikasi agar sesuai dengan preferensi Pengguna;',
                'Untuk memonitor, memantau dan dan mencari aktivitas, genre dan/atau preferensi kategori, dalam hal penggunaan atas layanan-layanan yang disediakan pada Aplikasi;',
                'Untuk menawarkan voucher, reward, bonus, survey, promosi, dan periklanan kepada Pengguna.'
              ]
            }
          }
        ]
      },
      {
        title: '3. TRANSFER DATA/DATA SHARING',
        contents: [
          {
            list: {
              order: 'ul',
              title:
                'Pembagian atau transfer data yang dilakukan oleh Genflix hanya untuk tujuan penggunaan Aplikasi dan untuk mendukung layanan-layanan dan fitur-fitur yang disediakan oleh Genflix pada Aplikasi. Genflix hanya akan melakukan pembagian atau transfer data apabila Pengguna telah memberikan persetujuan kepada Genflix untuk melakukan itu. Genflix hanya akan memberikan data dan informasi Pengguna kepada pihak-pihak berikut:',
              items: [
                'Pihak yang bekerja sama dengan Genflix untuk memberikan layanan dalam Aplikasi (“Pihak Vendor”), dan',
                'Lembaga pemerintah dan pejabat-pejabat penegak hukum, yang merupakan bentuk pelaksanaan Genflix dalam melaksanakan kewajiban perundang-undangan untuk melakukan pelaporan berkala.'
              ]
            }
          },
          'Genflix tidak akan menjual atau menyewakan data dan informasi Pengguna kepada pihak lain. Genflix juga memiliki kebijakan bahwa Genflix hanya akan memberikan data kepada pihak-pihak yang disebutkan di atas sebatas data dan informasi yang dimintakan olehnya. Genflix akan menggunakan upaya yang wajar untuk menolak menyampaikan data dan informasi Pengguna yang dianggap tidak relevan dengan permintaan dari pihak-pihak yang disebutkan di atas.'
        ]
      },
      {
        title: '4. PENYIMPANAN DATA',
        contents:
          'Data dan informasi Pengguna pada Aplikasi akan disimpan selama masih dipergunakan untuk tujuan pengumpulan data, dan sepanjang diatur oleh perundang-undangan yang berlaku. Genflix akan berhenti menyimpan data dan informasi Pengguna segera apabila kami menganggap data yang kami kumpulkan sudah tidak sesuai dengan tujuan pengumpulan data, dan penyimpanan atas data tersebut tidak lagi diperlukan untuk tujuan bisnis dan tidak diizinkan atau diwajibkan oleh hukum yang berlaku.'
      },
      {
        title: '5. AKSES DAN PERUBAHAN DATA',
        contents: [
          'Dengan tunduk pada ketentuan perundangan yang berlaku, Pengguna dapat meminta kepada Genflix, dan Genflix juga dapat memberikan akses kepada Pengguna untuk melakukan perubahan data dan informasi selain dari informasi nomor telepon & alamat surat elektronik, pribadi dengan cara menghubungi layanan pelanggan (customer service) kami terlebih dahulu. Sebelum pemberian akses kepada Pengguna, Genflix berhak untuk mencari tahu alasan dari perubahan data dan informasi pribadi Pengguna tersebut.',
          'Genflix berhak menolak permintaan Pengguna untuk mengakses, atau memperbaiki, beberapa atau semua informasi pribadi Pengguna yang kami miliki atau kendalikan jika diizinkan atau diwajibkan berdasarkan hukum yang berlaku. Ini mungkin termasuk keadaan di mana informasi pribadi dapat berisi referensi ke individu lain atau di mana permintaan untuk akses atau permintaan untuk memperbaiki adalah untuk alasan yang kami anggap sepele, menjengkelkan, kurang tepat, dan tidak masuk akal.',
          'Pengguna dapat menghubungi kami melalui kontak yang tersedia pada poin 10 Kebijakan Privasi ini.'
        ]
      },
      {
        title: '6. PENGHAPUSAN DATA',
        contents: [
          'Sejauh diizinkan oleh hukum yang berlaku, Pengguna berhak meminta kami untuk menghapus data pribadi Pengguna yang disimpan pada sistem Genflix. Atas permintaan Pengguna, kami akan berusaha untuk menghapus data pribadi Pengguna dari sistem kami, kecuali jika kami diizinkan atau diwajibkan oleh hukum yang berlaku untuk melakukan sebaliknya. Genflix juga akan berhenti mengumpulkan, menggunakan, atau mengungkapkan data pribadi Pengguna, kecuali jika diizinkan atau diwajibkan oleh hukum yang berlaku. Mohon diperhatikan bahwa dengan meminta kami untuk menghapus data pribadi Pengguna, kami mungkin tidak dapat terus menyediakan layanan kami kepada Pengguna.',
          'Sehubungan dengan Penghapusan Data ini, Pengguna dapat menghubungi kami melalui kontak yang tersedia pada poin 10 Kebijakan Privasi ini.'
        ]
      },
      {
        title: '7. KEAMANAN DATA',
        contents: [
          'Kerahasiaan data dan informasi Pengguna adalah yang paling penting bagi Genflix. Genflix akan menggunakan semua upaya yang wajar untuk melindungi dan mengamankan data dan informasi Pengguna dari akses, pengumpulan, penggunaan, atau pengungkapan oleh orang yang tidak berwenang dan terhadap pemrosesan yang melanggar hukum, kehilangan yang tidak disengaja, penghancuran dan kerusakan atau risiko serupa. Namun, untuk diketahui bahwa transmisi informasi melalui Internet tidak sepenuhnya aman dan sangat rentan akan risiko peretasan. Meskipun kami akan melakukan yang terbaik untuk melindungi data dan informasi Pengguna, Pengguna mengakui bahwa Genflix tidak dapat menjamin integritas dan keakuratan data dan informasi apa pun yang Pengguna kirimkan melalui Internet, atau menjamin bahwa data dan informasi Pengguna tersebut tidak akan dicegat, diakses, diungkapkan, diubah, atau dihancurkan oleh pihak ketiga yang tidak berwenang, karena faktor-faktor di luar kendali Genflix. Genflix bertanggung jawab untuk menjaga kerahasiaan detail akun Pengguna dan Pengguna tidak boleh membagikan detail akun Pengguna, termasuk kata sandi dan One Time Password (OTP), kepada siapa pun dan Pengguna harus selalu menjaga dan bertanggung jawab penuh atas keamanan perangkat yang Pengguna gunakan.',
          'Dalam menyelenggarakan Aplikasi, Genflix juga telah memenuhi ketentuan hukum sehubungan dengan ketentuan penempatan data pada Data Centre dan Data Recovery Center yang mutakhir sesuai dengan persyaratan perundang-undangan, serta telah membekali sistem perlindungan Aplikasi yang telah memenuhi syarat perundang-undangan.'
        ]
      },
      {
        title: '8. PERUBAHAN KETENTUAN PRIVASI',
        contents:
          'Genflix, atas kebijaksanaan kami sendiri, akan dari waktu ke waktu terus melakukan evaluasi untuk memastikan bahwa Kebijakan Privasi Genflix ini konsisten dengan perkembangan zaman dan perkembangan atas ketentuan hukum yang berlaku. Atas setiap perubahan ketentuan Kebijakan Privasi Genflix, Genflix akan memberikan pemberitahuan kepada Pengguna melalui pemberitaan umum yang dapat dilihat dan diakses oleh Pengguna melalui Aplikasi.  Pengguna setuju bahwa merupakan tanggung jawab pribadi Pengguna untuk membaca dan meninjau atas setiap perubahan Kebijakan Privasi Genflix. Apabila terdapat hal-hal yang tidak dapat dimengerti terkait dengan perubahan ketentuan privasi ini, maka Pengguna dapat menghubungi customer service Genflix melalui Aplikasi atau melalui kontak yang tersedia pada Aplikasi dan Kebijakan Privasi ini.'
      },
      {
        title: '9. PENGAKUAN DAN PERSETUJUAN',
        contents: [
          'Dengan menggunakan platform kami ini, Pengguna mengakui bahwa Pengguna telah membaca dan memahami Kebijakan Privasi ini dan Pengguna menerima seluruh ketentuan Kebijakan Privasi Genflix. Secara khusus, Pengguna setuju dan mengizinkan atas tindakan yang akan dilakukan oleh Genflix sesuai dengan Kebijakan Privasi Genflix. Penggunaan terus-menerus atas platform ini setelah pengumuman perubahan kebijakan ini akan dianggap sebagi penerimaan Pengguna terhadap perubahan tersebut.',
          'Pengguna dapat menarik persetujuan Pengguna untuk setiap atau semua pengumpulan, penggunaan, atau pengungkapan atau pembagian data dan informasi Pengguna kapan saja dengan memberi kami pemberitahuan yang wajar secara tertulis menggunakan detail kontak yang disebutkan di bawah ini. Pengguna juga dapat menarik persetujuan Pengguna kepada kami untuk mengirimkan komunikasi dan informasi tertentu kepada Pengguna melalui fasilitas “opt-out” atau “unsubscribe” yang terdapat dalam pesan kami kepada Anda. Bergantung pada keadaan dan sifat persetujuan yang Pengguna tarik, Pengguna harus memahami dan mengakui bahwa setelah penarikan persetujuan tersebut, Pengguna mungkin tidak lagi dapat menggunakan Aplikasi beserta layanan yang menempel padanya. Penarikan persetujuan oleh Pengguna dapat mengakibatkan penghentian akun Pengguna atau hubungan kontraktual Pengguna dengan kami dan seluruh Pihak Afiliasi Genflix, dengan semua hak dan kewajiban yang masih harus dibayar tetap sepenuhnya dilindungi undang-undang. Setelah menerima pemberitahuan Pengguna untuk menarik persetujuan untuk pengumpulan, penggunaan, atau pengungkapan atau pembagian data dan informasi Pengguna, kami akan memberi tahu Pengguna tentang kemungkinan konsekuensi dari penarikan tersebut sehingga Pengguna dapat memutuskan apakah Pengguna memang ingin menarik persetujuan.'
        ]
      },
      {
        title: '10. HUBUNGI KAMI',
        contents: [
          'Apabila Pengguna memiliki pertanyaan sehubungan dengan Ketentuan Privasi Pengguna atau apabila Pengguna ingin mendapatkan akses atas data dan informasi pribadinya atau sehubungan dengan penarikan (dalam fasilitas “opt-out” atau “unsubscribe” tidak tersedia), silahkan hubungi kontak berikut:',
          {
            contentUrl: {
              label: 'Email',
              url: (
                <a
                  target='_top'
                  href={`mailto:${social.GENFLIX_EMAIL}`}
                >
                  {social.GENFLIX_EMAIL}
                </a>
              )
            }
          }
        ]
      }
    ]
  },
  en: {
    languageTitle: 'English',
    nextLanguage: 'Indonesian',
    header: 'Genflix Privacy Policy',
    contentList: [
      {
        contents: [
          'This privacy policy sets out how PT Festival Citra Lestari (“Genflix”), as the manager of Genflix web and application (“App”), collects, stores, uses, processes, transfers, discloses and protects User data and information (”Genflix Privacy Policy”). Genflix Privacy Policy shall apply for all use of services that are available in the App, including in the form of mobile applications, software and other forms, that are used by an App User that is registered and verified by Genflix (hereinafter referred to as the “User”). The User must read, comprehend, and approve Genflix Privacy Policy as the User data will be used as a basis for Genflix to provide higher quality services for the User.',
          'In the event that the User does not permit processing of its data or information as set out under this Genflix Privacy Policy, Genflix does not recommend the User to use App services.'
        ]
      },
      {
        title: '1. DATA COLLECTION',
        contents: [
          {
            list: {
              order: 'ol',
              type: '1',
              title:
                'User Data are collected by Genflix in several ways, namely:',
              items: [
                'Through forms or applications by a potential User. Data collected at this stage are personal identities of the potential User that consist of, among others, full name, valid phone number, email address, and profile picture',
                {
                  list: {
                    order: 'ol',
                    type: 'a',
                    title: 'User activities such as:',
                    items: [
                      'What content does the User watch? when? how long?',
                      'Does the content get casted to other device?',
                      'What screen does the User open? when?',
                      'What keyword does the User search? when?',
                      'What method does the User use to login and register? when?',
                      'Which playlist does the User open? when?',
                      'Which content does the User share? when?',
                      'What live show does the User stream? when?',
                      'What gift does the User give in a live show? when?',
                      'What comment does the User give in a live show? when?',
                      'When does the User submit gencash cash out request?',
                      'Who does the User follow and unfollow in the web/app?',
                      'Which subscription does the User select and purchase? when?',
                      'What voucher does the User use? when?',
                      'Bookmark or save activities, and other activities conducted by the User on the App'
                    ]
                  }
                },
                'Location data delivered by the system when reading App usages at a certain location or area;',
                'Technical data obtained when User is using the App. Such technical data include Internet Protocol (IP) address, website information, duration of App usages or website visits, gadget when using the internet, advertisements, and other relevant matters;',
                'Interaction data with our customer service;',
                'Other data collection mechanism performed by Genflix (including via the App);',
                'Other sources in cooperation with Genflix, where the User has permitted the transfer of User data.'
              ]
            }
          },
          'Before a User may use the App and services provided by Genflix, the User shall firstly be requested to approve this Privacy Policy (including on the collection of personal data as mentioned above). Specifically on personal data collection via local data access and system data that are in the User gadget, Genflix shall request prior approval before having access to the same, including access to photos, voices, cameras, and other features of gadgets used by the User.'
        ]
      },
      {
        title: '2. USE OF DATA',
        contents: [
          {
            list: {
              order: 'ol',
              title:
                'Genflix is entitled to use Users data once the User agrees to Genflix Privacy Policy Data collected by Genflix shall be used for the following matters:',
              items: [
                'To identify the User, activation or non activation of User account, and other matters in connection with the User account.',
                'To register and grant access to the User data, to parties in cooperation with Genflix in providing products offered on the app (Vendor), to register the User on the system provided by the Vendor.',
                'To ease communication between Genflix and the User via and for the usage of the App;',
                'To provide updates on the latest information, whether the App systems, App features, change in any policies whether required by Genflix or the government that are affecting the App, and other information that may be shown or delivered to the User;',
                'To process and respond questions and feedbacks from the User;',
                'To update, maintain, develop, and personalize the App to conform with User preferences;',
                'To monitor, supervise and search activities, genre and/or category preference and in using the services provided by the App;',
                'To offer vouchers, rewards, bonuses, surveys, promotions, and advertisements to the User.'
              ]
            }
          }
        ]
      },
      {
        title: '3. DATA SHARING',
        contents: [
          {
            list: {
              order: 'ul',
              title:
                'Distribution or transfer of data performed by Genflix shall only be for the purpose of using the App and to support the services and features offered by Genflix on the App. Genflix shall only distribute or transfer data in the event that the User has granted an approval to Genflix to perform the same. Genflix shall only provide User data and information to the following parties:',
              items: [
                'A Party in cooperation with Genflix to provide services on the App (“Vendor”), and',
                'Government agencies and officials, law enforcers, being the performance by Genflix of its statutory obligations to make periodical reports and to submit a report on certain requirement.'
              ]
            }
          },
          'Genflix shall not sell or lease User data and information to third parties. Genflix also has a policy that Genflix shall only provide data to the foregoing parties to the extent that such data and information are requested by it. Genflix shall use reasonable endeavors to reject any request for User data and information that are deemed to be irrelevant with the requests from the foregoing parties.'
        ]
      },
      {
        title: '4. DATA STORAGE',
        contents:
          'User data and information on the App shall be stored as long as the same are being used for the purpose of data collection, and as long as the same is governed by the applicable laws and regulations. Genflix shall cease to store any User data and information immediately in the event we are of the view that the data we collect no longer in accordance with the objective of data collection, and the storage of such data is no longer required for business purposes and is not permitted or required by the applicable laws.'
      },
      {
        title: '5. DATA AMENDMENT AND ACCESS',
        contents: [
          'Subject to the provisions of the applicable laws, the User may request to Genflix, and Genflix may also grant access to the User, to amend personal data and information other than information of their phone number & email address, by firstly contacting the customer service. Prior to granting access to the User, Genflix is entitled to seek the underlying reasons for such change of User personal data and information.',
          'Genflix is entitled to reject any User request to access, or correct, any or all User personal information that we hold or control as may be permitted or required by the applicable laws. This may include circumstances where such personal information may contain references to other individuals or where the request for such access or correction are deemed by us to be trivial, outrageous, inappropriate, and unreasonable.',
          'The User may contact us via contacts as set out under point 10 of this Privacy Policy.'
        ]
      },
      {
        title: '6. DATA DELETION',
        contents: [
          'To the extent permitted by the applicable laws, the User is entitled to request us to delete User personal data that are stored in Genflix systems. Upon the User request, we will attempt to delete the User’s personal data from our system, unless permitted or required otherwise by the applicable laws. Genflix shall also cease to collect, use, or disclose User personal data, unless permitted or required by the applicable laws. Please note that by requesting us to delete the User’s personal data, we may not be able to continue providing our services to the User.',
          'In relation to this Data Deletion, the User may contact us via contacts as set out under point 10 of this Privacy Policy.'
        ]
      },
      {
        title: '7. DATA SECURITY',
        contents: [
          'Confidentiality of User data and information is the utmost importance of Genflix. Genflix shall use all reasonable endeavours to protect and secure User data and information from any access, collection, usage, or disclosure by an authorized person and against any process in violation of the laws, unintentional losses, destruction and damages or any similar risks. However, it should be known that transmission of information through the Internet is not completely secured and prone of hacking risks. While we will do our best in protecting User data and information, the User acknowledges that Genflix may not be able to guarantee the integrity and accuracy of any data and information that the User sent through the Internet, or guarantee that such User data and information will not be intercepted, accessed, disclosed, modified, or destroyed by an authorized third party, due to reasons outside of Genflix control. Genflix shall be responsible to maintain the confidentiality of the User account and the User shall not share the details of the User account, including the password and the One Time Password (OTP), to any person and the User shall also secure and fully responsible over the security of the device being used by the User.',
          'In implementing the App, Genflix has also complied with the applicable laws in relation to the storage of data in an advanced Data Centre and Data Recovery Centre in accordance with the provisions of the laws and regulations, as well as has equipped safety systems on the App that are in accordance with the laws and regulations.'
        ]
      },
      {
        title: '8. AMENDMENTS TO THE PRIVACY POLICY',
        contents:
          'Genflix, in our own discretion, from time to time will perform evaluations to ensure that this Genflix Privacy Policy is consistent with the current development and development of the applicable laws. Genflix shall notify to the User of any amendment to Genflix Privacy Policy, via public news that may be seen and accessed by the User on the App.  The User agrees that it is the personal responsibility of the User to read and review any amendment to Genflix Privacy Policy. In the event of any questions in relation to the amendment of this privacy policy, the User may contact Genflix customer service via the App or to the contact available on the App and this Privacy Policy.'
      },
      {
        title: '9. APPROVAL AND ACKNOWLEDGEMENT',
        contents: [
          'By using this platform, the User acknowledges that the User has read and understood this Privacy Policy and the User has agreed to all terms of Genflix Privacy Policy. Specifically, the User agrees and permits any actions undertaken by Genflix in accordance with Genflix Privacy Policy. Continuous use of this platform following a notice on amendment to this policy shall be deemed your acceptance of such amendment.',
          'The User may withdraw its approval for any or all User data and information collection, use, or disclosure or distribution at any time by providing reasonable written notification to us via contact details as set out below. The User may also withdraw its approval to us to send certain communication and information to the User via “opt-out” or “unsubscribe” facilities that are available in our message to You. Subject to the circumstances and nature of the approval that the User withdraws, the User shall understand that acknowledge that upon such withdrawal of approval, the User may no longer be able to use the App as well as the services attached thereto. Withdrawal of approval by the User may result in discontinuation of User account or User contractual relationship with us and all Genflix Affiliates, with prejudice to any outstanding rights and obligations. Upon receiving User notice to withdraw the approval to collect, use or disclose or distribute User data and information, we will notify the User of any consequences of such withdrawal so that the User may decide such intention to withdraw.'
        ]
      },
      {
        title: '10. CONTACT US',
        contents: [
          'Should the User have any questions in relation to the User Privacy Policy or should the User intend to gain any access of its personal data and information, or in connection with the withdrawal(in the event that opt-out” or “unsubscribe” facilities is not available), please contact the following:',
          {
            contentUrl: {
              label: 'Email',
              url: (
                <a
                  target='_top'
                  href={`mailto:${social.GENFLIX_EMAIL}`}
                >
                  {social.GENFLIX_EMAIL}
                </a>
              )
            }
          }
        ]
      }
    ]
  }
};

export const TNCList = {
  id: {
    languageTitle: 'Indonesian',
    nextLanguage: 'English',
    header: ['Syarat & Ketentuan Genflix'],
    contentList: [
      {
        contents: [
          'Persyaratan dan ketentuan Genflix ini <strong>(“Syarat dan Ketentuan Genflix”)</strong> berlaku dan mengikat PT Festival Citra Lestari <strong>(“Genflix”)</strong>, selaku pengelola dari Aplikasi Genflix <strong>(“Aplikasi”)</strong>, dan pihak pengguna Aplikasi yang telah terdaftar dan terverifikasi oleh Genflix (yang selanjutnya disebut <strong>“Pengguna”</strong>), sehubungan dengan penggunaan Aplikasi oleh Pengguna terkait dengan fungsi Genflix, baik melalui gawai (gadget) apapun dimana Aplikasi tersedia dan dapat digunakan.',
          'Pengguna diwajibkan untuk membaca, memahami serta menyetujui seluruh isi dari Syarat dan Ketentuan Genflix ini sebelum dapat memanfaatkan fungsi Genflix dalam Aplikasi.',
          'Bahwa Syarat dan Ketentuan Genflix ini merupakan satu kesatuan yang tidak terpisahkan dengan Syarat dan Ketentuan umum Genflix.'
        ]
      },
      {
        title: '1. UMUM'
      },
      {
        contents: [
          'Yang dimaksud dengan <strong>“Genflix”</strong> di dalam Syarat dan Ketentuan Genflix ini adalah layanan yang disedikan kepada Pengguna secara eksklusif untuk penggunaan pribadi dan nonkomersial. Selama masa keanggotaan Anda, Genflix memberi Anda lisensi yang tidak eksklusif, tidak dapat dipindahtangankan, dan dapat dibatalkan untuk mengakses dan melakukan streaming konten melalui platform Genflix untuk tontonan pribadi.',
          'Harap perhatikan bahwa ketersediaan konten pada layanan Genflix dapat berubah secara berkala. Kualitas konten streaming dapat bervariasi di berbagai perangkat dan bergantung pada berbagai faktor, termasuk lokasi geografis Anda, bandwidth yang tersedia, dan kecepatan koneksi internet. Untuk streaming yang optimal, kami sarankan kecepatan koneksi minimal 500 Kbps untuk layar yang lebih kecil dan 5 Mbps untuk layar yang lebih besar (baca ketentuan penggunaan internet untuk keterangan lebih lanjut). Genflix tidak membuat pernyataan atau jaminan apa pun terkait kualitas pengalaman menonton di perangkat Anda.',
          '<strong>"ShortBite"</strong> adalah layanan Genflix yang memungkinkan Pengguna untuk menonton klip video singkat, yang biasanya berdurasi beberapa detik hingga beberapa menit. Video-video ini sangat cocok untuk ditonton dengan cepat untuk tujuan hiburan, pendidikan, interaksi sosial, atau pemasaran.',
          'Genflix berhak, atas kebijakannya sendiri, untuk menghapus atau menonaktifkan akses ke layanan, konten, atau fitur ShortBite kapan saja, tanpa pemberitahuan atau kewajiban sebelumnya. Hal ini dapat mencakup, tetapi tidak terbatas pada, penangguhan akun pengguna, penghapusan konten, atau pembatasan akses ke fitur-fitur tertentu, untuk alasan apa pun yang dianggap tepat oleh Genflix."',
          'Genflix senantiasa untuk mengembangkan Layanan untuk menghindari penyalahgunaan Aplikasi maupun perilaku berbahaya dari Pengguna. Jika kami mengetahui Layanan Genflix digunakan dengan cara yang bertentangan dengan hukum, kami akan mengambil tindakan yang sesuai - misalnya, menawarkan bantuan, menghapus konten, menghapus atau membatasi akses ke fitur tertentu, menonaktifkan akun, atau menghubungi penegak hukum.'
        ]
      },
      {
        title: '2. LISENSI DAN PEMBAYARAN'
      },
      {
        title: '2.1. Lisensi',
        contents: [
          'Tunduk pada kepatuhan Anda sepenuhnya dan berkelanjutan terhadap Ketentuan yang ditetapkan di sini, dan sejauh kami secara sah berwenang untuk memberikan hak tersebut, Genflix dengan ini memberikan kepada Anda lisensi terbatas, non-eksklusif, tidak dapat disublisensikan, dan tidak dapat dipindahtangankan untuk mengakses, melihat, dan menggunakan Karya dan Platform. Lisensi ini diberikan semata-mata untuk tujuan terlibat dengan konten yang tersedia di Aplikasi, serta untuk aktivitas lain yang secara tegas diizinkan dalam lingkup Ketentuan ini. Lisensi ini diberikan semata-mata untuk penggunaan pribadi dan non-komersial Anda kecuali dinyatakan lain secara tertulis.',
          'Untuk menghindari keraguan, secara tegas dijelaskan bahwa tidak ada dalam Ketentuan ini yang ditafsirkan sebagai pengalihan kepada Anda hak kekayaan intelektual apa pun yang dimiliki oleh Genflix, afiliasinya, atau pihak ketiga mana pun. Semua hak, kepemilikan, dan kepentingan dalam dan terhadap kekayaan intelektual apa pun, termasuk namun tidak terbatas pada merek dagang, hak cipta, paten, dan hak milik lainnya, akan tetap menjadi milik eksklusif dari masing-masing pemilik, dan tidak ada hak kepemilikan yang diberikan kepada Anda oleh lisensi ini.'
        ]
      },
      {
        title: '2.2. Ketentuan Pembayaran',
        contents: [
          'Akses ke Aplikasi dan fitur-fitur tertentu dalam Layanan mungkin memerlukan pembayaran sejumlah biaya. Biaya-biaya ini diperlukan untuk membuka atau memperoleh akses penuh ke konten premium, fitur-fitur canggih, atau layanan-layanan lain yang ditawarkan dalam Aplikasi. Sebelum biaya apa pun diterapkan, Anda akan diberikan kesempatan yang menyeluruh dan transparan untuk meninjau biaya-biaya tertentu yang terkait dengan penggunaan Aplikasi atau fitur-fitur Layanan tertentu yang Anda maksudkan. Proses peninjauan ini akan memungkinkan Anda untuk memahami sepenuhnya biaya-biaya yang timbul dan untuk membuat keputusan yang tepat tentang apakah akan melanjutkan pembayaran. Proses ini dirancang untuk memastikan kejelasan dan transparansi, yang memungkinkan Anda untuk membuat keputusan-keputusan yang sepenuhnya tepat mengenai akses dan penggunaan Aplikasi oleh Anda, sekaligus memastikan bahwa Anda mengetahui kewajiban-kewajiban finansial apa pun yang terkait dengan fitur-fitur atau layanan-layanan tertentu.',
          'Genflix memiliki hak eksklusif untuk menentukan dan menetapkan harga untuk Layanan, termasuk namun tidak terbatas pada biaya berlangganan, biaya satu kali, atau biaya-biaya lain apa pun yang terkait dengan penggunaan Aplikasi dan fitur-fiturnya. Genflix akan menagih metode pembayaran yang Anda tentukan pada saat pembelian dan biaya-biaya tersebut dapat dikenakan pajak.',
          'Khusus untuk layanan ShortBite yang ditawarkan oleh Genflix, Pengguna memiliki opsi untuk mengisi ulang akun mereka dengan Coins virtual yang digunakan secara eksklusif dalam layanan ShortBite. Coins ini dirancang untuk memfasilitasi akses ke konten premium, fitur, atau layanan lain yang tersedia dalam ekosistem ShortBite. Penting untuk dicatat bahwa Coins dimaksudkan hanya untuk digunakan dalam layanan ShortBite dan tidak dapat ditebus, dikonversi, atau ditukar dengan bentuk mata uang lainnya. Setelah diperoleh, Coins tidak dapat dipindahtangankan dan hanya dapat digunakan untuk konten atau fitur dalam layanan ShortBite, sebagaimana diuraikan dalam syarat dan ketentuan.'
        ]
      },
      {
        title: '3. TANGGUNG JAWAB PENGGUNA',
        contents: [
          'Anda setuju untuk tidak menggunakan layanan ini untuk tampilan atau siaran publik.',
          'Anda secara tegas dilarang mengubah, menyalin, menduplikasi, menerbitkan, mendistribusikan, atau mengeksploitasi secara komersial sebagian atau seluruh konten, termasuk penghapusan merek dagang Genflix atau transmisi ulang konten melalui aplikasi perangkat keras atau perangkat lunak, kecuali tindakan tersebut secara tegas diizinkan secara tertulis oleh Genflix.',
          'Pengguna bertanggung jawab secara penuh mengganti rugi Genflix atas setiap kerugian yang timbul dari setiap pelanggaran dari Syarat dan Ketentuan disini.'
        ]
      },
      {
        title: '4. RISIKO',
        contents: [
          'Genflix akan menggunakan cara-cara teknis dan keamanan yang baik, tepat dan wajar untuk menjaga Aplikasi aman dan terbebas dari virus dan kesalahan. Namun demikian, bagaimanapun efektifnya teknologi ini, tidak ada sistem keamanan yang aman sepenuhnya dan tidak dapat ditembus.',
          'Oleh karena itu Genflix tidak dapat menjamin secara utuh keamanan database Genflix dan Genflix juga tidak dapat menjamin bahwa layanan tidak akan mengalami gangguan.',
          'Ketika Pengguna menggunakan aplikasi serupa ataupun aplikasi manapun, termasuk melalui Aplikasi, akan selalu ada risiko kegagalan dan/atau kehilangan akibat:',
          'a. kegagalan atau kerusakan komputer, handphone, atau gawai lain yang anda gunakan (termasuk baterainya);',
          'b. koneksi internet yang kurang baik, yang menyebabkan Pengguna tidak dapat terkoneksi dengan Aplikasi;',
          'c. peretasan yang dilakukan oleh pihak-pihak lain, baik peretasan secara langsung terhadap Aplikasi, peretasan melalui perangkat lunak (software), maupun link berbahaya yang memungkinkan pihak pelaku peretasan mendapatkan atau menggunakan akses ke informasi dan/atau aset Pengguna;',
          'd. perangkat anda tidak kompatibel (incompatible) dengan, atau tidak sesuai dengan spesifikasi sistem Aplikasi, termasuk akibat dari kesalahan dalam penyetelan (settings) atau sistem operasi gawai anda yang tidak sesuai dengan yang disyaratkan;',
          'e. kegagalan sistem pada Aplikasi, atau kegagalan pada perangkat keras dan perangkat lunak dari gawai yang Pengguna gunakan; dan ',
          'f. ketidakpatuhan Pengguna terhadap Syarat dan Ketentuan Genflix ini yang dapat berakibat pada penangguhan atau bahkan pemblokiran pada Akun Pengguna.',
          'Dengan membuka akun pada Aplikasi dan terdaftar, Pengguna dianggap telah setuju dan mengakui bahwa Pengguna telah membaca dan memahami risiko yang akan terjadi.'
        ]
      },
      {
        title: '5. PENGUMPULAN DAN PERLINDUNGAN DATA PENGGUNA',
        contents: [
          'Dalam menjalankan kegiatan usahanya dan untuk meningkatkan layanan Aplikasi, Genflix akan melakukan permintaan dan pengumpulan data, serta melakukan transfer data Pengguna kepada otoritas pemerintah apabila terdapat kewajiban hukum untuk melakukan pengungkapan data dan pihak yang bekerjasama dengan Genflix <strong>(“Pihak Vendor”)</strong> guna keperluan Pengguna dalam menjalankan aktivitas pada Aplikasi. Mohon diperhatikan bahwa untuk melindungi data Pengguna dan mematuhi aturan hukum yang berlaku, Genflix hanya akan memberikan data kepada Pihak Vendor apabila Genflix telah mendapatkan persetujuan dari Pengguna.',
          'Sebelum Pengguna dapat menggunakan Aplikasi Genflix, Pengguna akan terlebih dahulu dimintai persetujuannya atas Syarat dan Ketentuan Genflix ini (termasuk atas pengumpulan data-data pribadi sebagaimana ternyata di atas). Khusus untuk pengumpulan data-data pribadi melalui akses data lokal dan data sistem yang ada pada gawai (gadget) dari Pengguna, Genflix akan meminta persetujuan terlebih dahulu di awal sebelum mendapatkan akses tersebut.',
          'Kelengkapan data Pengguna akan sangat berguna bagi Aplikasi untuk memberikan kesan dan pengalaman yang baik kepada Pengguna dalam menggunakan Aplikasi. Genflix juga berpendapat bahwa perlindungan atas informasi pribadi adalah hal yang sangat penting sebagaimana juga dipersyaratkan oleh peraturan perundang-undangan yang berlaku. Genflix dengan kemampuan terbaiknya akan menjaga, melindungi, dan menggunakan data Pengguna dengan prosedur kontrol yang baik dan ketat.'
        ]
      },
      {
        title: '6. PENGHAPUSAN DATA',
        contents: [
          'Genflix selaku penyelenggara Sistem Elektronik wajib menyimpan Data Pribadi Pengguna paling singkat selama 5 (lima) tahun sejak tanggal terakhir pemilik data pribadi menjadi Pengguna pada Aplikasi. Penghapusan data akan dilakukan oleh Genflix sesuai dengan ketentuan peraturan perundang-undangan yang berlaku.',
          'Sejauh diizinkan oleh hukum yang berlaku, Pengguna berhak meminta kami untuk menghapus data pribadi Pengguna yang disimpan pada sistem Genflix. Atas permintaan Pengguna, kami akan berusaha untuk menghapus data pribadi Anda dari sistem kami, kecuali jika kami diizinkan atau diwajibkan oleh hukum yang berlaku untuk melakukan sebaliknya. Genflix juga akan berhenti mengumpulkan, menggunakan, atau mengungkapkan data pribadi Pengguna, kecuali jika diizinkan atau diwajibkan oleh hukum yang berlaku. Mohon diperhatikan bahwa dengan meminta kami untuk menghapus data pribadi Anda, kami mungkin tidak dapat terus menyediakan layanan kami kepada Anda.',
          'Sehubungan dengan Penghapusan Data ini, Pengguna dapat menghubungi kami melalui kontak yang tersedia pada Aplikasi.'
        ]
      },
      {
        title: '7. KETENTUAN KEAMANAN PENGGUNA',
        contents: [
          'Dalam mendorong kualitas keamanan data Pengguna, Genflix akan melakukan kebijakan agar mewajibkan Pengguna untuk membuat password akun Pengguna yang tidak dapat dengan mudah di retas. Genflix akan memberikan saran agar Pengguna menggunakan konfigurasi tersebut pada saat Pengguna melakukan pendaftaran password.',
          'Lebih lanjut, untuk menjaga keamanan data dan penggunaan Aplikasi, Aplikasi diatur sedemikian rupa agar dapat menjaga keamanan data Pengguna. Selain menggunakan password, dan/atau OTP, Aplikasi juga diatur sedemikian rupa untuk dipaksa logged out dari Akun Pengguna apabila Aplikasi dalam keadaan diam (idle) selama 30 hari berturut-turut.'
        ]
      },
      {
        title: '8. SISTEM PENDUKUNG',
        contents: [
          'Genflix akan memberlakukan ketentuan spesifik tertentu pada Pengguna untuk menggunakan versi Aplikasi yang paling terkini. Pada saat Pengguna mengakses bagian Genflix, sistem Aplikasi akan secara otomatis mendeteksi apakah sistem yang digunakan oleh Pengguna dapat mendukung aktivitas Genflix. Apabila pada saat tersebut Aplikasi menganggap gawai Pengguna tidak kompatibel, maka Aplikasi akan menyarankan Pengguna untuk melakukan update terlebih dahulu.'
        ]
      },
      {
        title: '9. PEMELIHARAAN (MAINTENANCE)',
        contents: [
          'Untuk menjaga dan meningkatkan kualitas layanan, Genflix akan selalu melakukan pembaruan (update) dan pemeliharaan Aplikasi <strong>(“Pemeliharaan”)</strong>. Pemeliharaan dapat dilakukan Genflix secara periodik ataupun secara sewaktu-waktu. Pemeliharaan secara periodik akan dilakukan setiap 1 (satu) bulan sekali, dan Genflix berkewajiban untuk memberitahukan jadwal Pemeliharaan kepada Pengguna paling lambat 2 hari (48 jam) sebelum proses Pemeliharaan periodik dilakukan.',
          'Dalam hal terdapat suatu kejadian yang tidak dapat dihindarkan, yang memaksa Genflix harus melakukan Pemeliharaan, maka Genflix wajib memberitahukan kepada Pengguna secepatnya disertai dengan alasan dilakukannya Pemeliharaan secara mendadak. Dalam pemberitahuannya, Genflix diwajibkan untuk mengingatkan bahwa pada saat Pemeliharaan, tidak ada aktivitas Pengguna yang akan diproses oleh sistem.',
          'Dalam hal Genflix telah melaksanakan prosedur pemberitahuan yang sesuai terkait Pemeliharaan kepada Pengguna, maka Genflix tidak dapat dimintai pertanggungjawaban atas kegagalan apapun dalam Aplikasi yang dilaksanakan pada waktu Pemeliharaan.'
        ]
      },
      {
        title: '10. FORCE MAJEURE',
        contents: [
          'Yang dimaksud dengan Force Majeure adalah segala kejadian-kejadian yang terjadi di luar kekuasaan dan kemampuan Genflix sehingga mempengaruhi pelaksanaan jasa yang diberikan Genflix (termasuk melalui Aplikasi) kepada Pengguna sehubungan dengan Genflix, antara lain namun tidak terbatas pada:',
          'a. Gempa bumi, angin topan, banjir, tanah longsor, gunung meletus dan bencana alam lainnya;',
          'b. Perang, demonstrasi, huru-hara, terorisme, sabotase, embargo, dan pemogokan massal; dan',
          'c. Kebijakan ekonomi dan pemerintah yang mempengaruhi secara langsung.',
          'Sepanjang Genflix telah melaksanakan segala kewajibannya sesuai dengan peraturan perundang-undangan yang berlaku sehubungan dengan terjadinya Force Majeure tersebut, maka Genflix tidak akan memberikan ganti rugi dan/atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak lain manapun atas segala risiko, tanggungjawab dan tuntutan apapun yang mungkin timbul sehubungan dengan keterlambatan maupun tidak dapat dilaksanakannya kewajiban akibat Force Majeure.'
        ]
      },
      {
        title: '11. PENYELESAIAN SENGKETA',
        contents: [
          'Dalam hal terdapat sengketa antara Genflix dengan Pengguna, maka penyelesaian sengketa akan tunduk pada yurisdiksi pengadilan di Indonesia sepanjang para pihak sepakat untuk tidak memilih yurisdiksi manapun. Seluruh perjanjian antara Pengguna dan Genflix akan ditafsirkan sesuai dengan peraturan perundang-undangan di Indonesia.'
        ]
      },
      {
        title: '12. PENGGUNA MENINGGAL DUNIA',
        contents: [
          'Dalam hal Pengguna meninggal dunia, maka Genflix berhak meminta salinan yang sah atas akta kematian, surat keterangan hak waris, akta wasiat, dan dokumen lain yang menurut pertimbangan Genflix diperlukan untuk mengetahui pihak yang berhak menerima warisan Pengguna.'
        ]
      },
      {
        title: '13. LAIN-LAIN',
        contents: [
          'Syarat dan Ketentuan Genflix ini dapat dimodifikasi dan diubah dari waktu ke waktu. Genflix akan memberitahu Pengguna melalui Aplikasi dan/atau email atas modifikasi, dan/atau perubahan atas Syarat dan Ketentuan Genflix. Penggunaan Aplikasi secara terus menerus oleh Pengguna setelah diterimanya pemberitahuan tersebut merupakan persetujuan dan penerimaan Pengguna atas modifikasi, dan/atau perubahan atas Syarat dan Ketentuan Aplikasi Genflix.'
        ]
      }
    ]
  },
  en: {
    languageTitle: 'English',
    nextLanguage: 'Indonesian',
    header: ['Terms & Conditions Genflix'],
    contentList: [
      {
        contents: [
          'This Terms & Condition of Genflix <strong>(“Genflix Terms and Conditions“)</strong> is a valid agreement between PT. Festival Citra Lestari <strong>(“Genflix”)</strong>,as the operator of Genflix Aplication <strong>(“App”)</strong> and the user of App that is registered and verified by by Genflix (hereinafter referred to as <strong>“Users”</strong>) in connection with the use of the App by the User relating to Genflix functions, via any gadget where the App is available and may be used.',
          'Users must read, understand as well as approve the entire content of these Genflix Terms and Conditions prior to the use of Genflix functions on the App.',
          'Whereas these Genflix Terms and Conditions are an integral part of the general Genflix Terms and Conditions.'
        ]
      },
      {
        title: '1. GENERAL'
      },
      {
        contents: [
          '<strong>“Genflix”</strong> in these Genflix Terms and Conditions is a service provided to Users exclusively for personal and non-commercial use. During the term of your membership, Genflix grants you a non-exclusive, non-transferable, and revocable license to access and stream content via the Genflix platform for personal viewing. ',
          'Please note that the availability of content on the Genflix service may change periodically. The quality of streaming content may vary across devices and is subject to multiple factors, including your geographical location, available bandwidth, and internet connection speed. For optimal streaming, we recommend a minimum connection speed of 500 Kbps for smaller screens and 5 Mbps for larger screens (refer to the internet usage provisions for further details). Genflix does not make any representations or guarantees regarding the quality of the viewing experience on your device.',
          '<strong>“ShortBite”</strong> is the Genflix services that allows the User to watch a brief video clip, typically lasting anywhere from a few seconds to a few minutes. These videos are are particularly well-suited for fast consumption and are often used for entertainment, education, social interaction, or marketing purposes.',
          'Genflix reserves the right, at its sole discretion, to remove or disable access to its ShortBite services, content, or features at any time, without prior notice or liability. This may include, but is not limited to, the suspension of user accounts, the removal of content, or the restriction of access to certain features, for any reason deemed appropriate by Genflix."',
          'Genflix continues to develop the Services to avoid misuse of the Application or malicious behavior from Users. If we become aware of the Genflix Service being used in a way that is against the law, we will take appropriate action - for example, offering assistance, removing content, removing or restricting access to certain features, deactivating an account, or contacting law enforcer.'
        ]
      },
      {
        title: '2. LICENSE AND PAYMENT'
      },
      {
        title: '2.1. License',
        contents: [
          'Subject to your full and ongoing compliance with the Terms set forth herein, and to the extent that we are lawfully authorized to grant such rights, Genflix hereby confers upon you a limited, non-exclusive, non-sublicensable, and non-transferable license to access, view, and utilize the Works and the Platform. This license is granted solely for the purpose of engaging with the content available on the App, as well as for any other activities that are expressly authorized within the scope of these Terms. This license is granted solely for your personal, non-commercial use unless otherwise stated in writing.',
          'For the avoidance of any doubt, it is explicitly clarified that nothing in these Terms shall be construed as transferring to you any intellectual property rights owned by Genflix, its affiliates, or any third parties. All rights, title, and interest in and to any intellectual property, including but not limited to trademarks, copyrights, patents, and any other proprietary rights, shall remain the exclusive property of the respective owners, and no ownership rights are conferred upon you by this license.'
        ]
      },
      {
        title: '2.2. Payment Terms',
        contents: [
          'Access to the App and specific features within the Service, may require the payment of certain fees. These fees are necessary to unlock or gain full access to premium content, advanced features, or other services offered within the App. Before any charges are applied, you will be given a comprehensive and transparent opportunity to review the specific fees associated with your intended use of the App or particular Service features. This review process will allow you to fully understand the costs involved and to make an informed decision about whether to proceed with the payment. This process is designed to ensure clarity and transparency, allowing you to make fully informed decisions regarding your access to and use of the App, while also ensuring that you are aware of any financial obligations associated with specific features or services.',
          'Genflix reserves the exclusive right to determine and set the pricing for the Service, including but not limited to subscription fees, one-time charges, or any other fees associated with the use of the App and its features. Genflix will charge the payment method you specify at the time of purchase and the fees may be subject to taxes.',
          'Specifically for the ShortBite services offered by Genflix, Users have the option to top up their accounts with virtual Coins used exclusively within the ShortBite services. These Coin(s) are designed to facilitate access to premium content, features, or other services available within the ShortBite ecosystem. It is important to note that Coin(s) are intended solely for use within the ShortBite services and cannot be redeemed, converted, or exchanged for any other form of currency. Once acquired, Coin(s) are non-transferable and can only be spent on content or features within the ShortBite services, as outlined in the terms and conditions.'
        ]
      },
      {
        title: '3. USER RESPONSIBILITY',
        contents: [
          'You agree not to use this service for public display or broadcast.',
          'You are expressly prohibited from modifying, copying, duplicating, publishing, distributing, or commercially exploiting any part or all of the content, including the removal of Genflix trademarks or the re-transmission of content via hardware or software applications, unless such actions are expressly authorized in writing by Genflix.',
          'The user is fully responsible for indemnifying Genflix for any losses arising from any violation of the Terms and Conditions herein.'
        ]
      },
      {
        title: '4. RISK',
        contents: [
          'Genflix will use technical and security methods that are good, proper, and appropriate to maintain the security of the App and free from viruses and errors. However, notwithstanding the effectiveness of this technology, no security system is completely safe and unhackable.',
          'Therefore, Genflix is unable to warrant the complete security of Genflix database and Genflix is also unable to warrant that the services will be uninterrupted.',
          'When Users using a similar applications, including via the App, there will always be a risk of failure and/or loss due to:',
          'a. failures and damages on the computer, handphone or other gadgets that you use (including the battery);',
          'b. unstable internet connection, that result in the User unable to connect with the App or the User may be connected while such unstable connection result in delays that carry a risk in the placement and activity of your investment;',
          'c. Hacking performed by other parties, whether direct hacking on the App, hacking o the software, as well as malicious links that enable the hackers to gain or use access to information and/or asset of the User;',
          'd. incompactible device with, or does not meet with the App system specification, including due to errors in settings or operating system of your gadget does not with the requirements;',
          'e. system failures on the App, or failures on the hardwares and softwares on the gadget that the User use; and',
          'f. user incompliance of these Genflix Terms and Conditions that may result in suspension or blocking of the User Account.',
          'By opening an account on and registered, the User is deemed to approve and acknowledge that the User has read and understood the potential risks.'
        ]
      },
      {
        title: '5. COLLECTION AND PROTECTION OF USER DATA',
        contents: [
          'In conducting its business activities and in improving services on the App, Genflix shall also request for and collect data, as well as transfer Users data if there is a legal obligation to disclose data to the government agencies as well as to the party in cooperation with Genflix <strong>(“Vendor”)</strong> to enable the User to undertake activities on App. Please beware that to protect User data and to comply with the applicable laws, Genflix will only provide data to Vendors upon approval from Users.',
          'Before Users may use the App and services provided by Genflix, the User shall firstly requested to approve this Genflix Terms and Conditions (including on the collection of personal data as mentioned above). Specifically on personal data collection via local data access and system data that are in the User gadget, Genflix shall request prior approval before having such access.',
          'Completeness of Users data will be useful for the App to present great impressions and experiences to the User in using the App. Genflix is also of the view that protection of personal information is a very important requirement as required under the applicable laws and regulations. Genflix, with its best efforts, will maintain, protect and use User data with tight and appropriate control procedures.'
        ]
      },
      {
        title: '6. DATA DELETION',
        contents: [
          'Genflix as the Electronic System provider shall store the User Personal Data for a minimum period of 5 (five) years as of the last date when the owner of personal data is an App User. Data deletion shall be undertaken by Genflix in accordance with the applicable laws and regulations.',
          'To the extent permitted by the applicable laws, the User is entitled to request us to delete User personal data that are stored in Genflix’s systems. Upon the User request, we will attempt to delete Your personal data from our system, unless permitted or required otherwise by the applicable laws. Genflix shall also cease to collect, use, or dislose User personal data, unless permitted or required by the applicable laws. Please note that by requesting us to delete Your personal data, we may not be able to continue providing our services to You.',
          'In relation to this Data Deletion, the User may contact us via contacts as set out under customer care center on the App.'
        ]
      },
      {
        title: '7. USER TERMS OF SECURITY',
        contents: [
          'In supporting User data safety quality, Genflix shall implement a policy that the User must register a password so that the User account is not easily hackable. Genflix shall suggest Users to use such configuration while registering its password.',
          'Furthermore, to maintain data security and use of App, the App is arranged as such to allow maintenance of User data. In addition to password, and/or OTP, the App is also arranged as such to have forced log out from the User Account in the event that the App is idle for 30 consecutive days.'
        ]
      },
      {
        title: '8. SUPPORTING SYSTEMS',
        contents: [
          'Genflix shall apply certain specific terms on the User to use the latest version of the App. When the user access the Genflix section, the App shall automatically detect whether the system used by the User may support the Genflix activities. If during such condition, the App deems that the User gadget is not compactible, the App shall suggest the User to firstly update.'
        ]
      },
      {
        title: '9. MAINTENANCE',
        contents: [
          'To maintain and improve the quality of Services, Genflix will always update and maintain the App <strong>(“Maintenance”)</strong>. Maintenance may be performed by Genflix periodically or on an ad hoc basis. Periodical Maintenance will be performed 1 (one) time per month, and Genflix is obligated to notify the Maintenance schedule to the User at least 2 days (48 hours) in advance prior to the commencement of the periodical Maintenance.',
          'In the event of unavoidable circumstances that require Genflix to perform Maintenances, Genflix shall notify the User immediately with the reasons of such sudden Maintenances. In its notice, Genflix is obligated to remind that during the Maintenances, no User activities that will be processed by the system.',
          'In the event that Genflix has complied with the appropriate notification procedures concerning the Maintenances to the User, Genflix may not be claimed for any failed transactions that are made during the Maintenances.'
        ]
      },
      {
        title: '10. FORCE MAJEURE',
        contents: [
          'Force Majeure means any events outside of the control and capability of Genflix that affects the implementation of services that are provided by Genflix (including via the App) to the User in relation the Genflix, among others including but not limited to:',
          'a. Earthquakes, hurricanes, floods, landslides, volcanic eruptions and other natural disasters;',
          'b. War, demonstrations, riots, terrorism, sabotages, embargoes, and mass strikes; and',
          'c. Economic and government policies that has direct effects.',
          'Provided that Genflix has performed all of its obligations in accordance with the applicable laws and regulations in relation to the occurence of such Force Majeure, Genflix will not provide any indemnities and/or accountabilities in any form to the User or other party of any risks, liabilities and claims that may arise in connection with any delays as well as non-fulfilment of obligations due to a Force Majeure.'
        ]
      },
      {
        title: '11. DISPUTE RESOLUTION',
        contents: [
          'In the event of disputes between Genflix with Users, the dispute settlement will be subject to the courts jurisdiction in Indonesia, to the extent that the parties agree not to select other jurisdictions. All agreements between the User and Genflix will be construed in accordance with the laws and regulations in Indonesia.'
        ]
      },
      {
        title: '12. PASSING AWAY OF THE USER',
        contents: [
          'In the event that the User passes away, Genflix is entitled to request a valid copy of the death certificate, inheritance statements, wills, and other documents that Genflix, in its opinion, deems necessary to know the entitled party to receive the User’s inheritances.'
        ]
      },
      {
        title: '13. MISCELLANEOUS',
        contents: [
          'These Genflix Terms and Conditions may be modified and amended from time to time. Genflix will notify Users via the Application and/or email for modifications to, and/or changes to the Genflix Terms and Conditions. The User`s continuous use of the Application after receiving the notification constitutes the User`s approval and acceptance of modifications to, and/or changes to the Genflix Application Terms and Conditions.'
        ]
      }
    ]
  }
};

export const TNCCoinsList = {
  id: {
    languageTitle: 'Indonesian',
    nextLanguage: 'English',
    header: ['Ketentuan Penggunaan Coins ShortBite'],
    contentList: [
      {
        title: '1. Apa itu Coins dan bagaimana cara mendapatkannya?',
        contents: [
          'Coins adalah saldo virtual yang dapat dibeli pada fitur Top Up Coins yang digunakan untuk dapat menikmati konten premium ShortBite'
        ]
      },
      {
        title: '2. Berapa harga Coins?',
        contents: [
          'Harga Coins tunduk pada daftar harga yang ditetapkan oleh platform. Pengguna disarankan untuk merujuk pada daftar harga di platform untuk menentukan biaya yang berlaku untuk pembelian Coins, karena dapat berubah berdasarkan kebijakan Genflix ShortBite sendiri tanpa ada pemberitahuan sebelumnya.'
        ]
      },
      {
        title: '3. Metode pembayaran apa saja yang tersedia di ShortBite?',
        contents: [
          'Pengguna dapat menggunakan metode pembayaran melalui In-App Purchase yang tersedia pada platform resmi seperti Google Play Store (untuk perangkat Android) dan Apple App Store (untuk perangkat iOS).',
          'Metode pembayaran diatas tunduk pada syarat dan ketentuan dari penyedia layanan pembayaran masing-masing (Google Play Store atau Apple App Store).',
          'Setelah memilih jumlah dan metode pembayaran yang Anda inginkan, Anda akan diminta untuk memasukkan rincian pembayaran Anda. Pastikan Anda meninjau dengan saksama jumlah dan metode pembayaran yang dipilih sebelum mengonfirmasi transaksi.'
        ]
      },
      {
        title:
          '4. Dapatkah saya mentransfer, menjual, atau mengonversi Coins menjadi uang fiat?',
        contents: [
          'Harap diperhatikan bahwa Coins yang terkumpul di platform ShortBite tidak dapat dipindahtangankan dan tidak dapat dikonversi menjadi mata uang riil atau bentuk nilai moneter lainnya. Coins ini semata-mata ditujukan untuk penggunaan dalam platform untuk mengakses konten dan layanan, dan tidak dapat ditebus atau ditukar dengan uang tunai atau nilai finansial yang setara.'
        ]
      },
      {
        title:
          '5. Bagaimana cara mengecek Coins di Akun Genflix Shortbite saya?',
        contents: [
          'a) Cukup klik pada akun profil Anda.',
          'b) Anda akan melihat Coins Anda saat ini.'
        ]
      },
      {
        title:
          '6. Berapa lama waktu yang dibutuhkan untuk menerima Coins saya?',
        contents: [
          'Coins akan diterima ke akun Pengguna setelah pembayaran dilakukan oleh Pengguna telah sukses.'
        ]
      },
      {
        contents: [
          'Ketentuan Penggunaan Coins ShortBite ini Merupakan bagian yang tidak terpisahkan dari Syarat & Ketentuan Genflix.'
        ]
      }
    ]
  },
  en: {
    languageTitle: 'English',
    nextLanguage: 'Indonesian',
    header: ['Terms of Use of ShortBite’ Coins'],
    contentList: [
      {
        title: '1. What is Coins and how to get?',
        contents: [
          'Coins are a virtual balance that can be purchased on the Top Up Coins feature which is used to enjoy ShortBite premium content.'
        ]
      },
      {
        title: '2. How much are Coins price?',
        contents: [
          'The price of the Coins is subject to the pricing list established by the platform. Users are advised to refer to the pricing list on the platform to determine the applicable cost for purchasing Coins, as it is subject to change based Genflix ShortBite own discretion without prior notice.'
        ]
      },
      {
        title: '3. What are payment method available on ShortBite?',
        contents: [
          'Users can use the following payment methods through In-App Purchase available on official platforms such as Google Play Store (for Android devices) and Apple App Store (for iOS devices).',
          'The above payment methods are subject to the terms and conditions of the respective payment service providers (Google Play Store or Apple App Store).',
          'Upon selecting your preferred amount and payment method, you will be required to enter your payment details. Please ensure that you carefully review both the selected amount and payment method before confirming the transaction.'
        ]
      },
      {
        title: '4. Can I transfer, sell, or convert the Coins into fiat money?',
        contents: [
          'Please be advised that the Coins accumulated on the ShortBite platform are non-transferable and cannot be converted into real currency or any other form of monetary value. These Coins are solely intended for use within the platform to access content and services, and cannot be redeemed or exchanged for cash or equivalent financial value.'
        ]
      },
      {
        title: '5. How to check Coins in my Genflix ShortBite Account?',
        contents: [
          'a) Simply just click on your profile account.',
          'b) You will see your current Coins balance.'
        ]
      },
      {
        title: '6. How long does it take to receive my Coins?',
        contents: [
          'Coins will be received into the User`s account after the payment made by the User has been successful.'
        ]
      },
      {
        contents: [
          'This Terms of Use of Shortbite’ Coins is an integral part of the Terms & Conditions Genflix.'
        ]
      }
    ]
  }
};

export const EULAList = {
  en: {
    languageTitle: 'English',
    nextLanguage: 'Indonesian',
    header: 'End User License Agreement',
    contentList: [
      {
        subtitle: 'Introduction',
        contents: [
          'This End User License Agreement (the "Agreement") is a binding agreement between you ("End User","you" or "your") and PT Festival Citra Lestari ("Company", "we", "us" or "our"). This Agreement governs the relationship between you and us, and your use of the Company Genflix. Throughout this Agreement, End User and Company may each be referred to as a "Party" or collectively, the "Parties".',
          'If you are using the both on behalf of your employer or other entity (an "Organisation") for whose benefit you utilise the both or who owns or otherwise controls the means through which you utilise or access the both, then the terms "End User", "you", and "your" shall apply collectively to you as an individual and to the Organisation. If you use, or purchase a license or to, the both on behalf of an Organisation, you hereby acknowledge, warrant, and covenant that you have the authority to 1) purchase a license to the both on behalf of the Organisation; 2) bind the Organisation to the terms of this Agreement.',
          'By downloading, installing, accessing, or using the both you: (a) affirm that you have all of the necessary permissions and authorisations to access and use the both; (b) if you are using the both pursuant to a license purchased by an organisation, that you are authorised by that organisation to access and use the both(c) acknowledge that you have read and that you understand this agreement; (d) represent that you are of sound mind and of legal age (18 years of age or older) to enter into a binding agreement; and (e) accept and agree to be legally bound by the terms and conditions of this agreement.',
          'If you do not agree to these terms, do not download, install, access, or use the software. if you have already downloaded the software, delete it from your computing device.',
          'The Application is licensed, not sold, to you by Genflix for use strictly in accordance with the terms of this Agreement.'
        ]
      },
      {
        subtitle: 'License',
        contents: [
          'Subject to the terms of this Agreement and, if applicable, those terms provided in the License Agreement, Genflix grants you a limited, non-exclusive, perpetual, revocable, and non-transferable license to:',
          {
            list: {
              order: 'ol',
              type: 'a',
              items: [
                'Download, install and use the Software on one (1) Computing Device per single user license that you have purchased and been granted. If you have multiple Computer Devices in which you wish to use the Software, you agree to acquire a license for the number of devices you intend to use.',
                'Access, view, and use on such Computing Device the End User Provided Materials made available in or otherwise accessible through the Software, strictly in accordance with this Agreement, and any other terms and conditions applicable to such End User Provided Materials',
                'Install and use the trial version of the Software on any number of Computing Devices for a trial period of fifteen (15) unique days after installation.',
                'Receive updates and new features that become available during the one (1) year period from the date on which you purchased the license to the Software.'
              ]
            }
          }
        ]
      },
      {
        subtitle: 'Restrictions',
        contents: [
          'You agree not to, and you will not permit others to:',
          {
            list: {
              order: 'ol',
              type: 'a',
              items: [
                'License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application or make the Application available to any third party.',
                'Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the Application.',
                'Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of Genflix or its affiliates, partners, suppliers or the licensors of the Application.'
              ]
            }
          }
        ]
      },
      {
        subtitle: 'Intellectual Property',
        contents: [
          'All intellectual property rights, including copyrights, patents, patent disclosures and inventions (whether patentable or not), trademarks service marks, trade secrets, know-how and other confidential information, trade dress, trade names, logos, corporate names and domain names, together with all of the good will associated there with, derivative works and all other rights (collectively, "Intellectual Property Rights")  that are part of the Software that are otherwise owned by Genflix shall always remain the exclusive property of Genflix (or of its suppliers or licensors, if and when applicable). Nothing in this Agreement grants you (or any Organisation) a license to Genflix\'s Intellectual Property Rights.',
          "You agree that this is Agreement conveys a limited license to use Genflix's Intellectual Property Rights, solely as part of the Software (and not independently of it), and only for the effective Term of the license granted to you hereunder. Accordingly, your use of any of Genflix's Intellectual Property Rights independently of the Software or outside the scope of this Agreement shall be considered an infringement of Genflix's Intellectual Property Rights. This shall not limit, however, any claim Genflix may have for a breach of contract in the event you breach a term or condition of this Agreement. You shall use the highest standard of care to safeguard all Software (including all copies thereof) from infringement, misappropriation, theft, misuse or unauthorised access. Except as expressly granted in this Agreement, Genflix reserves and shall retain all rights, title, and interest in the Software, including all copyrights and copyrightable subject matter, trademarks and trademark able subject matter, patents and patentable subject matter, trade secrets, and other intellectual property rights, registered, unregistered, granted, applied-for, or both now in existence or that may be created, relating to the thereto.",
          'You (or the Organisation, if and as applicable) shall retain ownership of all Intellectual Property Rights in and to the work products that you create through or with the assistance of the Software.'
        ]
      },
      {
        subtitle: 'Your Suggestions',
        contents: [
          'Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions") provided by you to Genflix with respect to the Application shall remain the sole and exclusive property of Genflix.',
          'Genflix shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.'
        ]
      },
      {
        subtitle: 'Modifications to Application',
        contents: [
          'Genflix reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to which it connects, with or without notice and without liability to you.'
        ]
      },
      {
        subtitle: 'Updates to Application',
        contents: [
          'Genflix may from time to time provide enhancements or improvements to the features/ functionality of the Application, which may include patches, bug fixes, updates, upgrades and other modifications ("Updates").',
          'Updates may modify or delete certain features and/or functionalities of the Application. You agree that Genflix has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the Application to you.',
          'You further agree that all Updates will be (i) deemed to constitute an integral part of the Application, and (ii) subject to the terms and conditions of this Agreement.'
        ]
      },
      {
        subtitle: 'Third-Party Services',
        contents: [
          'The Application may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services").',
          'You acknowledge and agree that Genflix shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Genflix does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.',
          "Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties' terms and conditions."
        ]
      },
      {
        subtitle: 'Term and Termination',
        contents: [
          'This Agreement shall remain in effect until terminated by you or Genflix.',
          'Genflix may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.',
          'This Agreement will terminate immediately, without prior notice from Genflix, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your computer.',
          'Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the Application from your computer.',
          "Termination of this Agreement will not limit any of Genflix's rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement."
        ]
      },
      {
        subtitle: 'Indemnification',
        contents: [
          "You agree to indemnify, defend and hold harmless Genflix and its officers, directors, employees, agents, affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys' fees, arising from or relating to: i) your use or misuse of the Software; ii) your failure to comply with any applicable law, regulation, or government directive; iii) your breach of this Agreement; or iv) your agreement or relationship with an Organisation (if applicable) or any third party. Furthermore, you agree that Genflix assumes no responsibility for the information or content you submit or make available through this Software or the content that is made available to you by third parties."
        ]
      },
      {
        subtitle: 'No Warranties',
        contents: [
          'The Application is provided to you "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, Genflix, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, Genflix provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.',
          "Without limiting the foregoing, neither Genflix nor any Genflix's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, its servers, the content, or e-mails sent from or on behalf of Genflix are free of viruses, scripts, trojan horses, worms, malware, time bombs or other harmful components.",
          'Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.'
        ]
      },
      {
        subtitle: 'Limitation of Liability',
        contents: [
          'Notwithstanding any damages that you might incur, the entire liability of Genflix and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for the Application.',
          'To the maximum extent permitted by applicable law, in no event shall Genflix or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the Application, third-party software and/or third-party hardware used with the Application, or otherwise in connection with any provision of this Agreement), even if Genflix or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.',
          'Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.'
        ]
      },
      {
        subtitle: 'Severability',
        contents: [
          'If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.'
        ]
      },
      {
        subtitle: 'Waiver',
        contents: [
          'No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under this Agreement preclude further exercise of that or any other right granted herein. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.'
        ]
      },
      {
        subtitle: 'Amendments to this Agreement',
        contents: [
          "Genflix reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.",
          'By continuing to access or use our Application after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Application.'
        ]
      },
      {
        subtitle: 'Governing Law',
        contents: [
          'The laws of Indonesia, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws.'
        ]
      },
      {
        subtitle: 'Changes to this agreement',
        contents: [
          'We reserve the exclusive right to make changes to this Agreement from time to time. Your continued access to and use of the both constitutes your agreement to be bound by, and your acceptance of, the terms and conditions posted at such time. You acknowledge and agree that you accept this Agreement (and any amendments thereto) each time you load, access, or use the both. Therefore, we encourage you to review this Agreement regularly.',
          'If, within thirty (80) days of us posting changes or amendments to this Agreement, you decide that you do not agree to the updated terms, you may withdraw your acceptance to the amended terms by providing us with written notice of your withdrawal. Upon providing us with the written notice of the withdrawal of your acceptance, you are no longer authorised to access or use the both.'
        ]
      },
      {
        subtitle: 'No Employment or Agency Relationship',
        contents: [
          'No provision of this Agreement, or any part of relationship between you and Genflix, is intended to create nor shall they be deemed or construed to create any relationship between you and Genflix other than that of and end user of the both and services provided.'
        ]
      },
      {
        subtitle: 'Equitable Relief',
        contents: [
          'You acknowledge and agree that your breach of this Agreement would cause Genflix irreparable harm for which money damages alone would be inadequate. In addition to damages and any other remedies to which Genflix may be entitled, you acknowledge and agree that we may seek injunctive relief to prevent the actual, threatened or continued breach of this Agreement.'
        ]
      },
      {
        subtitle: 'Headings',
        contents: [
          'The headings in this Agreement are for reference only and shall not limit the scope of, or otherwise affect, the interpretation of this Agreement.'
        ]
      },
      {
        subtitle: 'Geographic Restrictions',
        contents: [
          'The Company is based in Indonesia and provided for access and use primarily by persons located in Indonesia, and is maintains compliance with Indonesia laws and regulations. If you use the both from outside Indonesia, you are solely and exclusively responsible for compliance with local laws.'
        ]
      },
      {
        subtitle: 'Limitation of Time to File Claims',
        contents: [
          'Any cause of action or claim you may have arising out of or relating to this agreement or the both must be commenced within one (1) year after the cause of action accrues, otherwise, such cause of action or claim is permanently barred.'
        ]
      },
      {
        subtitle: 'Entire Agreement',
        contents: [
          'The Agreement constitutes the entire agreement between you and Genflix regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between you and Genflix.',
          "You may be subject to additional terms and conditions that apply when you use or purchase other Genflix's services, which Genflix will provide to you at the time of such use or purchase."
        ]
      },
      {
        subtitle: 'Contact Us',
        contents: [
          "Don't hesitate to contact us if you have any questions about this Agreement.",
          {
            list: {
              order: 'ul',
              linkStyle: true,
              items: [
                {
                  contentUrl: {
                    label: 'Email',
                    url: (
                      <a
                        target='_top'
                        href={`mailto:${social.GENFLIX_EMAIL}`}
                      >
                        {social.GENFLIX_EMAIL}
                      </a>
                    )
                  }
                },
                {
                  contentUrl: {
                    label: 'Via Phone Number:',
                    url: (
                      <a
                        target='_top'
                        href={social.GENFLIX_WHATSAPP}
                      >
                        {social.GENFLIX_NUMBER}
                      </a>
                    )
                  }
                },
                {
                  contentUrl: {
                    label: 'Via this Link:',
                    url: <a href='https://genflix.co.id/faqs'>FAQs</a>
                  }
                }
              ]
            }
          }
        ]
      }
    ]
  }
};

const whatsappLink = `${social.GENFLIX_WHATSAPP}?text=UNSUBSCRIBE%0D%0A%0D%0AFULL%20NAME:%0D%0APHONE%20NUMBER:%0D%0AEMAIL:`;

export const FAQsList = {
  en: {
    languageTitle: 'English',
    nextLanguage: 'Indonesian',
    header: 'FAQs',
    contentList: [
      {
        title: 'Getting Started',
        contents: [
          {
            list: {
              order: 'ol',
              type: '2',
              linkStyle: true,
              items: [
                'Do I have to register first?\nANS: YES. You must register and create an account to access Genflix',
                'Why should I subscribe to Genflix? \nANS: Genflix is ​a video streaming portal where you can watch e-Sport, hundreds of Hollywood, Indonesian films and favorite Asian dramas for only IDR 49,000 / month'
              ]
            }
          }
        ]
      },
      {
        title: 'Account & Registration',
        contents: [
          {
            list: {
              order: 'ol',
              type: '1',
              items: [
                `How to unsubscribe?\nANS: You can cancel / unsubscribe at any time by:\nFor Telco users:\n1. WhatsApp <a href="${whatsappLink}" target="_blank" rel="noopener noreferrer">+62 895-1363-4343</a> with subject: UNSUBSCRIBE\n2. Include FULL NAME, PHONE NUMBER, USER ID / EMAIL\n3. Our team will follow it up with the provider\n4. You will be unsubscribed within 3x24 hours (working days).\n\nHowever, it should be noted that if you unsubscribe halfway, your money is not refundable.`,
                'When I forget my password, what should I do?\nANS: You can get a new password with the feature Forgot Password. But before use it you have to know your email address and make sure that you can access your email\nHow to use it:\n1. Go to <a href="https://genflix.co.id/login/email" target="_blank" rel="noopener noreferrer">genflix.co.id/login/email</a>  or go to <a href="https://genflix.co.id/login/email" target="_blank" rel="noopener noreferrer">Login with Email/User ID</a> page on your Genflix application\n2. Select Forgot your password?\n3. Type your email address and select Reset Password\n4. Check your email (check your promotion or social mail, if you don\'t see the email)\n5. Open the e-mail from Genflix\n6. Select Here\n7. Type your new password and confirm your password\n8. Select Update Password\n9. Enjoy !',
                'When i want to login with my Google Account. Notification "popup_closed_by_user" appears, what should i do?\nANS: Please remove your cache and cookies on your browser. Make sure there is no other account that logged on your Browser (on another tab/window). If still not work, you can restart your browser'
              ]
            }
          }
        ]
      },
      {
        title: 'Purchase & Payment',
        contents: [
          {
            list: {
              order: 'ol',
              type: '1',
              items: [
                'How to use promo code?\nANS: Promo code can be used to subscribe for free Genflix premium access at the current period.\nHow to use it:\n1. Sign Up / Login to the website <a href="https://genflix.co.id/" target="_blank" rel="noopener noreferrer">genflix.co.id</a> or the Genflix application on your mobile\n2. Select Account\n3. Select Redeem Voucher\n4. Enter the Voucher Code (e.g. "GEN1234567890")\n5. Enjoy your Genflix premium access!',
                'How much does it cost to subscribe to Genflix?\nANS: Rp 49.000,- per month',
                'What are the available payment options?\nANS: These are the following available payment options:\n- Telecomunication partners: INDOSAT, XL, TRI, dan SMARTFREN\n- Voucher payment points : iLotte, JD.ID, AyoPop, Lazada, Blibli',
                'Can I redeem voucher on Genflix iOS?\nANS: Unfortunately, you can\'t redeem your voucher on Genflix iOS. But you can redeem it on Genflix Website\nHow to use it:\n1. Sign Up/Login to the website <a href="https://genflix.co.id/" target="_blank" rel="noopener noreferrer">genflix.co.id</a>\n2. Select Account\n3. Select Redeem Voucher\n4. Enter the Voucher Code (e.g. "GEN1234567890")\n5. Select Redeem Voucher\n6. Login with your account on your Genflix iOS\n7. Enjoy your Genflix premium access!'
              ]
            }
          }
        ]
      },
      {
        title: 'Using Genflix',
        contents: [
          {
            list: {
              order: 'ol',
              type: '1',
              items: [
                'What are the devices needed for online video streaming?\nANS: The recommended speed for normal streaming quality is minimum 500 Kbps stable internet access for small screens (handphone, tablet, PC/laptop)',

                'How many devices can be used in one account?\nANS: You can use up to 3 online devices simultaneously.',

                'Is there a minimum specification to be able to start and join a live show?\nANS:\nMinimum specification for Android and Android Tablet:\n* Android Minimum Version 8.0\n* Device capabilities (has touch, network connectivity)\n* Average memory use to open Genflix/Sushi: 256MB [Prefer Device with 4GB | Minimum 3GB]\nMinimum specification for iOS and iPad:\n* iOS Minimum Version 11.0\n* Device capabilities (has touch, network connectivity)\n* Average memory use to open Genflix/Sushi: 100MB\n* Average Network use 1.5 MB/s',

                'When I click on a movie or live show, a notification "jwt must be provided" appears, what should I do?\nANS: Please clear the application cache then re-install Genflix application.',

                'When opening the application or watching a live show, the application force closes or crashes, what should I do?\nANS: Please ensure that the specifications of the device used meet the minimum specifications mentioned above',

                'What platforms can be used to access the latest live show features in Genflix?\nANS: Currently, only Genflix Android Apps & Website that can access the live show feature.'
              ]
            }
          }
        ]
      },
      {
        title: 'Technical Support',
        contents: [
          {
            list: {
              order: 'ol',
              type: '1',
              items: [
                'Can I stop (paused) then continue the video again without having to start over?\nANS: Yes you can. You can watch the video and pause anytime, once you go back you do not need to continue it from the beginning.'
              ]
            }
          }
        ]
      },
      {
        title: 'Others',
        contents: [
          {
            list: {
              order: 'ol',
              type: '1',
              items: [
                'Why is my image quality poor?\nANS: The poor image quality is often caused by the unstable internet connection and it is not our responsibility. You are fully responsible for internet costs arising from access to Genflix services. Please check your internet service provider for information about possible internet data usage costs.'
              ]
            }
          }
        ]
      }
    ]
  }
};

export const AboutList = {
  en: {
    languageTitle: 'English',
    nextLanguage: 'Indonesian',
    header: 'GENFLIX - Best Place for Movies',
    contentList: [
      {
        contents: [
          'Genflix is the first over the top (OTT) that commercially broadcasts football services directly (LIVE) and is an original work of Indonesian children. It once earned a MURI record in 2014. Genflix is a portal and application for watching live broadcasts, collections of films, both new films and memorable films that have triumphed in their time.',
          'As a film portal that targets young people, Genflix provides quality films in both box office films that win international awards, as well as short films. In addition, Genflix also produces services that can be enjoyed by the community and is very attentive to interactions with its customers, so Genflix customers will enjoy the benefits of not only watching movies but also from merchants working with Genflix.',
          'Genflix is a trademark that is relied on by PT Festival Citra Lestari, and has collaborated with various parties to make it easier for subscribers to subscribe. Payment partners who have collaborated with us are Doku, BCA Internet Banking, Mandiri, Bank Sinarmas, Go PAY, as well as Telecommunications companies to facilitate payment with pulses of INDOSAT, XL, TRI, and SMARTFREN.',
          'If movies are the rhythm of your life and you like to spend hours of your time by modeling anywhere and anytime, GENFLIX is your right choice.'
        ]
      }
    ]
  }
};

export const ContactUsList = {
  en: {
    languageTitle: 'English',
    nextLanguage: 'Indonesian',
    header: 'Contact Us',
    contentList: [
      {
        contents: [
          {
            list: {
              order: 'ul',
              bullets: false,
              items: [
                {
                  contentUrl: {
                    label: 'Email',
                    url: (
                      <a
                        target='_top'
                        href={`mailto:${social.GENFLIX_EMAIL}`}
                      >
                        {social.GENFLIX_EMAIL}
                      </a>
                    )
                  }
                },
                {
                  contentUrl: {
                    label: 'Whatsapp',
                    url: (
                      <a
                        target='_top'
                        href={social.GENFLIX_WHATSAPP}
                      >
                        {social.GENFLIX_NUMBER}
                      </a>
                    )
                  }
                },
                {
                  contentUrl: {
                    label: 'Facebook',
                    url: (
                      <a
                        target='_top'
                        href={social.GENFLIX_FACEBOOK}
                      >
                        {social.GENFLIX_FACEBOOK}
                      </a>
                    )
                  }
                },
                {
                  contentUrl: {
                    label: 'Instagram',
                    url: (
                      <a
                        target='_top'
                        href={social.GENFLIX_INSTAGRAM}
                      >
                        {social.GENFLIX_INSTAGRAM}
                      </a>
                    )
                  }
                },
                {
                  contentUrl: {
                    label: 'X',
                    url: (
                      <a
                        target='_top'
                        href={social.GENFLIX_TWITTER}
                      >
                        {social.GENFLIX_TWITTER}
                      </a>
                    )
                  }
                }
              ]
            }
          }
        ]
      }
    ]
  }
};
