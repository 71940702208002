export const surgePartnerList = [
  'surge',
  'adakita',
  'damri',
  'bisku',
  'lorena',
  'kai',
  'kaiaccess'
];

export const surgeMobileAutologin = ['damri', 'kai', 'kaiaccess'];

export const partnerList = ['oxygen', ...surgePartnerList];
