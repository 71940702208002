import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleUserRelation } from '../../redux/actions/publicProfile';
import styles from './userinfo.module.css';
import UserAvatar from './UserAvatar';
// import { authPopup } from '../../redux/actions/auth';

import viewsIcn from '../../assets/views-icon.svg';
import gencashIcn from '../../assets/ic-gencash.svg';

const UserInfo = ({
  limitName,
  endDivider = true,
  onFollowAdditionalAction,
  profileLive,
  liveData
}) => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { profileInfo } = useSelector((state) => state.publicProfile);
  const { hosts } = useSelector((state) => state.liveShowComments);
  const streamerId = profileInfo?.profile.id;
  const dispatch = useDispatch();
  const userToken = auth?.token;
  const userId = auth?.user?.profile?.id;
  const handleStreamerProfile = () => {
    navigate(`/profile/${streamerId}`);
  };

  const handleFollowUser = async () => {
    await dispatch(handleUserRelation(profileInfo, true)); // add 'true' parameter to refresh followed state after following
    if (onFollowAdditionalAction && !userToken) {
      onFollowAdditionalAction();
    }
  };

  return (
    profileInfo && (
      <div className={styles['listInfoProfile']}>
        <UserAvatar
          profile={profileInfo}
          profileLive={profileLive}
          onClick={profileInfo && handleStreamerProfile}
        />
        <div
          className={styles['profileName']}
          onClick={profileInfo && handleStreamerProfile}
        >
          <div className={styles['name']}>
            {limitName
              ? profileInfo?.profile?.name?.substring(0, limitName)
              : profileInfo?.profile?.name}
          </div>
          <div className={styles['profileFollower']}>
            {profileInfo?.relation?.totalFollowers} Followers
          </div>
        </div>
        {streamerId !== userId && profileInfo && (
          <div
            className={styles['followBtn']}
            onClick={handleFollowUser}
          >
            {profileInfo?.profile?.profileRelationId ? 'Unfollow' : '+ Follow'}
          </div>
        )}
        {endDivider && <div className={styles['endDivider']} />}
        {liveData && (
          <div className={styles['badge-wrapper']}>
            <div className={styles['streamBadgeInfo']}>
              <img
                src={gencashIcn}
                alt='gencash'
              />
              <span>{hosts?.[0]?.gencash || liveData?.totalGencash || 0}</span>
            </div>
            <div className={styles['streamBadgeInfo']}>
              <img
                src={viewsIcn}
                alt='views'
              />
              <span>{liveData?.views || 0}</span>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default UserInfo;
