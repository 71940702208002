import React, { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Slider from '../Slider/Slider';
import { getMediaInfo } from '../../redux/actions/media';
import { generatePlaylistUrl } from '../../utils';
import ItemDetail from '../MediaDetail/ItemDetail';
import ClipsCollectionItem from '../CollectionItem/ClipsCollectionItem';
import styles from './Section.module.css';
import LiveTVCollectionItem from '../CollectionItem/LiveTVCollectionItem';
// import StreamerDetail from '../PopularStreamerList/streamerDetails';
// import { useUserDetail } from '../../hooks';
import MediaItem from '../Slider/MediaItem';
import ItemComponent from '../Slider/Item';

const listItemProperty = {
  default: {
    width: 205,
    playlistUrl: generatePlaylistUrl() // item 185, padding 10 (each right and left padding)
  },
  clips: {
    width: 330 // item 310, padding 10 (each right and left padding)
  },
  'live-tv': {
    width: 130 // item 160, padding 10 (each right and left padding)
  },
  'popular-streamers': {
    width: 160 // item 310, padding 10 (each right and left padding)
  }
};

const Section = ({
  id,
  name,
  sectionData,
  allowSeeAll = true,
  onChannelSelected,
  exclusiveContents,
  itemType = 'default',
  // checkBlockUnblock,
  playOnClick,
  additionalURL,
  className
}) => {
  const data = sectionData;
  const preloadTimeout = useRef();
  const dispatch = useDispatch();
  const { partner } = useParams();
  // const { findUserSubsByName } = useUserDetail();
  // const hasPartnerSubs = findUserSubsByName(partner);

  const preloadDetail = (media) => () => {
    const { id: mediaId, type } = media;
    preloadTimeout.current = setTimeout(
      () => dispatch(getMediaInfo(mediaId, type, true)),
      500
    );
  };

  const clearPreLoadDetail = () => {
    if (preloadTimeout.current) {
      clearTimeout(preloadTimeout.current);
    }
  };

  return data && data.length ? (
    <div className={`${styles['section-container']} ${className}`}>
      {name && allowSeeAll && (
        <div className={styles['section-info']}>
          {name === 'ugc clips' ? (
            <p className={styles['section-name']}>Clips</p>
          ) : (
            <p className={styles['section-name']}>{name}</p>
          )}
          {allowSeeAll && (
            <Link
              to={
                (partner ? `/mobile/${partner}` : '') + generatePlaylistUrl(id)
              }
            >
              <p className={styles['see-all']}>See All</p>
            </Link>
          )}
        </div>
      )}
      <Slider
        hasArrows
        slidesToShow='auto'
        slidesToScroll='auto'
        itemWidth={window.innerWidth > 500 && listItemProperty[itemType].width} // itemWidth only needed in desktop view and give the space error in mobile view.
        skipTrack
        exactWidth
        /* fetchMore={fetchMore}
        fetchComplete={fetchComplete} */
        sliderId={`slider-section-${id}`}
        itemType={itemType}
      >
        {data.map((item) => {
          if (name === 'ugc clips') {
            return (
              <ClipsCollectionItem
                key={item.id}
                data={item}
                clearPreLoadDetail={clearPreLoadDetail}
                onChannelSelected={onChannelSelected}
                preloadDetail={preloadDetail}
              />
            );
          }
          if (itemType === 'live-tv') {
            return (
              // <LiveTVCollectionItem
              //   key={item.id}
              //   data={item}
              //   clearPreLoadDetail={clearPreLoadDetail}
              //   onChannelSelected={onChannelSelected}
              //   preloadDetail={preloadDetail}
              // />
              <ItemDetail
                key={item.id}
                item={item}
                component={LiveTVCollectionItem}
                clearPreLoadDetail={clearPreLoadDetail}
                onChannelSelected={onChannelSelected}
                preloadDetail={preloadDetail}
              />
            );
          }

          // if (itemType === 'popular-streamers') {
          //   return (
          //     <StreamerDetail
          //       checkBlockUnblock={checkBlockUnblock}
          //       key={item.id}
          //       item={item}
          //     />
          //   );
          // }
          // if (name === 'ugc clips') {
          //   return <div>ugc clips</div>;
          // }

          return (
            <ItemComponent
              key={item.id}
              item={item}
              component={MediaItem}
              exclusiveContents={exclusiveContents}
              clearPreLoadDetail={clearPreLoadDetail}
              onChannelSelected={onChannelSelected}
              preloadDetail={preloadDetail}
              playOnClick={playOnClick}
              additionalURL={additionalURL}
            />
          );
        })}
      </Slider>
    </div>
  ) : null;
};

export default Section;
