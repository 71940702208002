import React from 'react';
import styles from './LoginDialog.module.css';

const TNCFooter = ({ onClick, dialogstate, type }) => {
  const dialogType =
    dialogstate?.toLowerCase() === 'login' ? 'Login' : 'Register';
  const buttonText = type ? 'Agree' : dialogType;
  return (
    <div className={`${styles['footerWrapper']}`}>
      By clicking {buttonText}
      , you're accepting the <br />
      <span
        onClick={() => {
          onClick('ugc');
        }}
      >
        Terms and Conditions
      </span>{' '}
      and{' '}
      <span
        onClick={() => {
          onClick('privacy');
        }}
      >
        Privacy Policy
      </span>
      .
    </div>
  );
};

export default TNCFooter;
