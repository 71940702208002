import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnimatedModal, Button } from '..';
import SubscriptionIntro from '../SubscriptionIntro/SubscriptionIntro';
import bigLogo from '../../assets/logo-big.svg';
import geoblockart from '../../assets/geoblock_art.svg';
import close from '../../assets/Close.svg';
import styles from './VideoPlayer.module.css';
import { errorCode } from '../../constants';
import { useUserDetail } from '../../hooks';
import PackageItem from '../../pages/shop/PackageItem';

export default function PlayerSubscription({
  isInteractive,
  onDismissPlayer,
  error
}) {
  const navigate = useNavigate();
  const params = useParams();
  const partner = params.partner;
  const [customMessage, setCustomMessage] = useState(false);
  const { findUserSubsByName } = useUserDetail();
  const isSurgeUser = findUserSubsByName('surge');
  const hasPremium =
    Array.isArray(error.details) &&
    error.details.some((item) => item.name === 'Genflix Premium');
  // check if the genflix premium is included in the package
  // check if the details is array so that it doesn't break on other types or error

  const filteredDetails = Array.isArray(error.details)
    ? error.details.filter((detail) => detail.name !== 'Genflix Premium')
    : [];
  // filter the genflix premium for details

  const goSubscribe = () => {
    localStorage.setItem('gfRedirectPath', window.location.pathname);
    localStorage.setItem('fromFWT', true);
    const redirectURL = partner
      ? `/mobile/${partner}/subscription`
      : '/profile/subscription/buy-new';
    navigate(redirectURL, {
      replace: true
    });
  };
  const goRedeem = () => {
    const redirectURL = partner
      ? `/mobile/${partner}/redeem-voucher`
      : '/profile/redeem-voucher';
    navigate(redirectURL, {
      replace: true
    });
  };
  let content = (
    <div className={styles['subscriptionErrorPopup']}>
      <p>{`${error.message} (# ${error.code})`}</p>
    </div>
  );
  // does not have video asset
  if (error.code === errorCode.MISSING_VIDEO) {
    content = <p>{`${error.message}`}</p>;
  }

  if (error.code === errorCode.VIDEO_GEOBLOCK) {
    content = (
      <div className={styles['ErrorPopup']}>
        <img
          alt='geoblock art'
          src={geoblockart}
        />
        <h2>We're Sorry.</h2>
        <p>This content is not available in your region.</p>
        <Button
          width='80%'
          textWrap={{ maxLine: 2 }}
          onClick={onDismissPlayer}
          shape='rounded'
          style={{ fontWeight: 800 }}
        >
          Back
        </Button>
      </div>
    );
  }

  // not subscribed
  if (error.code === errorCode.NO_SUBSCRIPTION) {
    if (isInteractive) {
      const redirectURL = partner
        ? `/mobile/${partner}/subscription`
        : '/profile/subscription';
      navigate(redirectURL);
      return null;
    }

    content = !hasPremium ? (
      <div className={styles['subscriptionErrorPopup']}>
        <div
          className={styles['closeButton']}
          onClick={onDismissPlayer}
        >
          <img
            src={close}
            alt='close'
          />
        </div>
        <p className={styles['subtitle']}>
          You need to subscribe to watch this premium content
        </p>
        <div>
          {filteredDetails.map((item, index) => (
            <PackageItem
              data={item}
              key={index}
            />
          ))}
        </div>
      </div>
    ) : (
      <div className={styles['subscriptionError']}>
        <img
          src={bigLogo}
          alt='big-logo'
        />
        <p className={styles['subtitle']}>
          You need to subscribe to watch this premium content
        </p>
        <SubscriptionIntro />

        {!isSurgeUser && (
          <>
            {/* <div className={styles['subscriptionList']}></div> */}
            <button
              type='button'
              className={styles['subscribeButton']}
              onClick={goSubscribe}
            >
              Pick subscription plan
            </button>

            <span
              className={styles['redeemtion']}
              onClick={goRedeem}
            >
              Have a voucher? Redeem now.
            </span>
          </>
        )}
      </div>
    );
  }
  return (
    <>
      <div className={styles['errorWrapper']}>
        {hasPremium && (
          <div
            className='vjs-dismiss-btn-wrapper'
            onClick={onDismissPlayer}
          >
            <i className='vjs-dismiss-player-icon' />
          </div>
        )}
        {content}
      </div>
      <AnimatedModal
        open={customMessage}
        isSmall
      >
        <div className={styles['customMessageWrapper']}>
          <h5>{customMessage}</h5>
          <button
            type='button'
            onClick={() => setCustomMessage(false)}
          >
            OK
          </button>
        </div>
      </AnimatedModal>
    </>
  );
}
