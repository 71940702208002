import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';
import React, { useState, useEffect, useRef, memo } from 'react';
import { Animated } from 'react-animated-css';
import {
  registerIVSTech,
  isPlayerSupported,
  registerIVSQualityPlugin
} from 'amazon-ivs-player';
import videojs from 'video.js';
import { connect } from 'react-redux';
import { withRouter } from '../../hooks';
import Loading from '../Loading/Loading';
import VideoInterval from './VideoInterval';
import userIcon from '../../assets/ic-user.svg';

import './CustomControlButton/CustomControlButton';
import PlayerSubscription from './PlayerSubscription';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import FullScreenMode from './FullScreenMode';
import { getInitial } from '../../utils';

import styles from './VideoPlayer.module.css';
import icGencash from '../../assets/ic-gencash.svg';
import icMute from '../../assets/liveshow/mute.svg';

const createAbsolutePath = (assetPath) =>
  new URL(assetPath, document.URL).toString();

const Counter = memo(({ show, amount, duration }) => {
  const [num, setNum] = useState(0);
  const interval = useRef();
  // eslint-disable-next-line no-console
  console.log('duration', duration);

  useEffect(() => {
    const time = (duration * 1000) / 4 / amount;
    interval.current = setInterval(() => {
      setNum((n) => n + 1);
    }, time);
    // eslint-disable-next-line no-console
    console.log(time);
    return () => {
      clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if ((interval.current && num >= amount) || (interval.current && !show)) {
    // eslint-disable-next-line no-console
    console.log('clearing', interval.current);
    clearInterval(interval.current);
  }
  return `x ${num}`;
});
Counter.displayName = 'Counter';

class LiveShowPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.premiumGiftImg = React.createRef();
    this.state = {
      showDissmissIcon: false,
      runInterval: false,
      mediaStart: false,
      loading: false,
      error: null,
      // showBullet: false,
      videoPlayed: false, // state for video is played for the first time
      isFullscreenNow: false,
      isHideComment: false,
      videoVertical: true,
      isShowStreamerInfo: true
    };
  }

  async componentDidMount() {
    const { stream, contentType, channelID, user, streamInfo } = this.props;
    if (!stream || (stream && stream.error)) return;

    const videoOptions = {
      controls: true,
      autoplay: true,
      liveui: true,
      responsive: true,
      controlBar: {
        pictureInPictureToggle: false
      },
      aspectRatio: '16:9',
      html5: {
        nativeCaptions: false
      },
      // make the text track settings dialog not initialize
      textTrackSettings: false
    };

    // register Amazon IVS as playback technology for videojs
    if (isPlayerSupported) {
      // eslint-disable-next-line no-console
      console.log('IVS supported');
      registerIVSTech(videojs, {
        wasmWorker: createAbsolutePath(wasmWorkerPath),
        wasmBinary: createAbsolutePath(wasmBinaryPath)
      });
      registerIVSQualityPlugin(videojs);
      // integrate aws IVS plugin
      videoOptions.techOrder = ['AmazonIVS', 'html5'];
    }

    // instantiate Video.js
    this.player = videojs(
      this.videoNode,
      videoOptions,
      function onPlayerReady() {
        // eslint-disable-next-line no-console
        console.log('onPlayerReady', this);
        const _player = this.player();
        // auto play
        _player.autoplay('any');
      }
    );

    isPlayerSupported && this.player.enableIVSQualityPlugin();
    this.player.src(stream);
    const paramsEvent = {
      contentType,
      streamID: channelID,
      streamTitle: streamInfo.name,
      streamCategory: streamInfo.showCategory,
      streamViewers: streamInfo.viewers.toString(),
      streamerProfile: streamInfo.profile
    };

    analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_LAUNCH, {
      params: paramsEvent,
      user
    });
    analytic(analyticTypes.trackPage, 'LiveShowPlayer', { user });

    this.addEventListener();
  }

  componentDidUpdate() {
    const { premiumGift, streamStop, streamBlock } = this.props;

    if (premiumGift && this.premiumGiftImg) {
      this.premiumGiftImg.current.src = premiumGift.gift.icon;
    }

    if (streamStop !== null && this.player.isFullscreen()) {
      this.player.exitFullscreen();
    }

    if (streamBlock !== null && this.player.isFullscreen()) {
      this.player.exitFullscreen();
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    const { contentType, channelID, user, streamInfo } = this.props;

    if (this.player) {
      const paramsEvent = {
        contentType,
        streamID: channelID,
        streamTitle: streamInfo.name,
        streamCategory: streamInfo.showCategory,
        streamViewers: streamInfo.viewers.toString(),
        streamerProfile: streamInfo.profile
      };

      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_EXIT, {
        params: paramsEvent,
        user
      });
      this.player.dispose();
      document.removeEventListener('fullscreenchange', this.handleFullScreen);
    }
  }

  exitFullScreen = () => {
    if (this.player.isFullscreen()) {
      this.player.exitFullscreen();
    }
  };

  updateHistoryCallback = () => {
    const { handleUpdateHistory } = this.props;
    const { mediaStart } = this.state;

    if (this.player && mediaStart) {
      handleUpdateHistory(this.player.currentTime(), this.player.duration());
    }
  };

  addEventListener = () => {
    const {
      channelID,
      contentType,
      handleUpdateHistory,
      user,
      streamInfo,
      setIsVideoStarted
    } = this.props;
    const { videoPlayed } = this.state;

    this.player.on('play', () => {
      // console.log('video started', e);
      this.setState({ mediaStart: true });
      this.setState({ runInterval: true });
      if (!this.pausing && !videoPlayed) {
        handleUpdateHistory(0);

        // set state for video is played for the first time
        this.setState({
          videoPlayed: true
        });
      }
      if (this.pausing) {
        this.pausing = false;
        const paramsEvent = {
          contentType,
          streamID: channelID,
          streamTitle: streamInfo.name,
          streamCategory: streamInfo.showCategory,
          streamViewers: streamInfo.viewers.toString(),
          streamerProfile: streamInfo.profile
        };

        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_RESUME,
          {
            params: paramsEvent,
            user
          }
        );
      }

      this.setState(() => ({
        showDissmissIcon: true
      }));

      setIsVideoStarted(true);
    });

    this.player.on('pause', () => {
      this.pausing = true;
      this.setState({ runInterval: false });
      const paramsEvent = {
        contentType,
        streamID: channelID,
        streamTitle: streamInfo.name,
        streamCategory: streamInfo.showCategory,
        streamViewers: streamInfo.viewers.toString(),
        streamerProfile: streamInfo.profile
      };

      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_PAUSE, {
        params: paramsEvent,
        user
      });

      setIsVideoStarted(false);
    });

    this.player.on('useractive', () => {
      this.setState(() => ({
        showDissmissIcon: true,
        isShowStreamerInfo: true
      }));
    });

    this.player.on('ended', () => {
      const paramsEvent = {
        contentType,
        streamID: channelID,
        streamTitle: streamInfo.name,
        streamCategory: streamInfo.showCategory,
        streamViewers: streamInfo.viewers.toString(),
        streamerProfile: streamInfo.profile
      };
      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_END, {
        params: paramsEvent,
        user
      });
      this.setState({ mediaStart: false });
    });

    this.player.on('userinactive', () => {
      this.setState(() => ({
        showDissmissIcon: false,
        isShowStreamerInfo: false
      }));
    });

    this.player.on('error', (e) => {
      // eslint-disable-next-line no-console
      console.log('error', e);
      return this.setState(() => ({
        error: {
          code: 'player-error',
          message: 'General Player Error!'
        }
      }));
    });

    document.addEventListener('fullscreenchange', this.handleFullScreen);
  };

  handleFullScreen = () => {
    const { isHideComment } = this.state;
    const videoWidth = document.querySelector('.vjs-tech').videoWidth;
    const videoHeight = document.querySelector('.vjs-tech').videoHeight;

    const isVertical = videoHeight > videoWidth;
    if (this.player.isFullscreen()) {
      this.player.getChild('controlBar').addChild('CommentButton', {
        onCommentButtonClick: () =>
          this.setState({ isHideComment: !isHideComment })
      });
      this.setState({
        isFullscreenNow: true,
        videoVertical: isVertical
      });
    } else {
      this.player.getChild('controlBar').removeChild('CommentButton');
      this.setState({
        isFullscreenNow: false,
        isHideComment: false,
        videoVertical: isVertical
      });
    }
  };

  renderError = (error) => {
    const { onDismissPlayer } = this.props;
    const content = <p>{`${error.message} (# ${error.code})`}</p>;

    return (
      <div className={styles['errorWrapper']}>
        <div
          className='vjs-dismiss-btn-wrapper'
          onClick={onDismissPlayer}
        >
          <i className='vjs-dismiss-player-icon' />
        </div>
        {content}
      </div>
    );
  };

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  // isRelative flag for the interactive live stream
  render() {
    const {
      subtitles,
      onDismissPlayer,
      stream,
      handleUpdateHistory,
      isRelative,
      bulletComment,
      basicGift,
      premiumGift,
      lastJoined,
      publicProfile,
      streamInfo,
      totalGencash = 0,
      isVideoStop = false,
      views = 0,
      // hosts,
      liveShowComments,
      isVideoStarted,
      sendHistoryError,
      isUpComing,
      ...otherProps
    } = this.props;
    const {
      loading,
      error,
      runInterval,
      showDissmissIcon,
      videoPlayed,
      isFullscreenNow,
      isShowStreamerInfo,
      videoVertical,
      isHideComment
    } = this.state;
    const { hosts } = liveShowComments;

    if (stream && stream.error) {
      return (
        <PlayerSubscription
          isInteractive={isRelative} // Give flag is relative when the player is for interactive
          onDismissPlayer={onDismissPlayer}
          otherProps={otherProps}
          error={stream.error}
        />
      );
    }

    if (error) {
      return this.renderError(error);
    }

    if (isVideoStop && this?.player?.pause) {
      this.player.pause();
    }

    return loading ? (
      <Loading height='100vh' />
    ) : (
      <div
        className='live-player'
        data-multi={!!streamInfo?.multi}
      >
        {isVideoStarted && !sendHistoryError && (
          <>
            {isUpComing && (
              <div
                className={styles['playerBadges']}
                data-position='top'
              >
                <div data-item='upcoming'>UPCOMING</div>
              </div>
            )}

            {!isUpComing && (
              <div
                className={styles['playerBadges']}
                data-position='bottom'
                data-onhover={showDissmissIcon}
              >
                <div data-item='live'>LIVE</div>
              </div>
            )}
          </>
        )}

        {/* bullet comment section */}
        <section className={styles['bulletWrapper']}>
          {bulletComment.map((comment, index) => (
            <Animated
              key={index}
              animationIn='bounceInRight'
              animationOut='fadeOut'
              className={styles['bulletCommentDisplay']}
              isVisible={comment.show}
              style={{
                marginLeft: index / 2 === 0 ? '0px' : '30%'
              }}
            >
              <div className={styles['bulletContainer']}>
                {comment.profile?.avatar ? (
                  <img
                    className={styles['bulletAvatar']}
                    src={comment.profile.avatar || userIcon}
                    alt='profile'
                    // onError={() => (this.src = userIcon)}
                  />
                ) : (
                  <div
                    className={`${styles['user-profile-icon']} ${styles['bulletAvatar']}`}
                    style={{
                      background: '#F49832',
                      borderRadius: '50%',
                      height: '40px',
                      width: '40px'
                    }}
                    data-initial-name
                  >
                    {getInitial(comment?.profile?.name || '')}
                  </div>
                )}
                <div
                  style={{
                    paddingLeft: '1rem',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <span
                    className={styles['bulletMessageName']}
                    style={{ color: comment.commentNameColor }}
                  >
                    {comment && comment.profile && comment.profile.name}{' '}
                  </span>
                  <span className={styles['bulletCommentData']}>
                    {comment && comment.data}
                  </span>
                </div>
              </div>
            </Animated>
          ))}
        </section>

        {/* gift basic */}
        <section className={styles['basicGiftWrapper']}>
          {basicGift.map((gift, index) => (
            <Animated
              key={index}
              animationIn='bounceInLeft'
              animationOut='fadeOut'
              isVisible={gift.show}
              className={styles['basicGiftDisplay']}
            >
              <div className={styles['basicWrapper']}>
                {/* <img
                  alt='profile'
                  src={gift.profile.avatar ? gift.profile.avatar : userIcon}
                  className={styles['basicAvatar']}
                /> */}

                {gift.profile.avatar ? (
                  <img
                    className={styles['basicAvatar']}
                    src={gift.profile.avatar || userIcon}
                    alt='profile'
                    // onError={() => (this.src = userIcon)}
                  />
                ) : (
                  <div
                    className={`${styles['user-profile-icon']} ${styles['basicAvatar']}`}
                    style={{
                      background: '#F49832',
                      borderRadius: '50%',
                      height: '40px',
                      width: '40px'
                    }}
                    data-initial-name
                  >
                    {getInitial(gift?.profile?.name || '')}
                  </div>
                )}

                <span>{gift.data}</span>
                <img
                  alt='love'
                  src={gift.gift.icon}
                  className={styles['basicIcon']}
                />
                <div className={styles['basicAmount']}>
                  <Counter
                    show={gift.show}
                    amount={gift.amount}
                    duration={gift.gift.duration}
                  />
                </div>
              </div>
            </Animated>
          ))}
        </section>

        {/* gift premium */}
        {premiumGift && (
          <div className={styles['premiumGiftDisplay']}>
            <div className={styles['premiumWrapper']}>
              <div className={styles['premiumInfo']}>
                {premiumGift.profile.avatar ? (
                  <img
                    className={styles['basicAvatar']}
                    src={premiumGift.profile.avatar || userIcon}
                    alt='profile'
                    // onError={() => (this.src = userIcon)}
                  />
                ) : (
                  <div
                    className={`${styles['user-profile-icon']} ${styles['basicAvatar']}`}
                    style={{
                      background: '#F49832',
                      borderRadius: '50%',
                      height: '40px',
                      width: '40px'
                    }}
                    data-initial-name
                  >
                    {getInitial(premiumGift?.profile?.name || '')}
                  </div>
                )}

                <span>{premiumGift.data}</span>
              </div>
              <img
                alt='animate'
                ref={this.premiumGiftImg}
              />
            </div>
          </div>
        )}

        {/* new joined */}
        {lastJoined && (
          <Animated
            animationIn='bounceInLeft'
            animationOut='bounceOutLeft'
            isVisible={lastJoined !== null}
            className={styles['lastJoinedNotif']}
          >
            <span>{lastJoined?.data?.replace(/<strong>|<\/strong>/g, '')}</span>
          </Animated>
        )}

        {runInterval && (
          <VideoInterval updateHistoryCallback={this.updateHistoryCallback} />
        )}
        {/* {!!this.state.showDissmissIcon && (
          <div className={`liveshow-close vjs-dismiss-btn-wrappers ${isRelative && 'relative'}`} onClick={onDismissPlayer}>
            <i className='vjs-dismiss-player-icon' />
          </div>
        )} */}
        {/* <div className="vjs-viewer-btn-wrappers" onClick={onDismissPlayer}>
            <i className='vjs-viewer-player-icon' /><span>{viewers}</span>
          </div> */}
        <div data-vjs-player>
          <video
            ref={(node) => (this.videoNode = node)}
            className='video-js video-js-relative vjs-big-play-centered liveshow'
            // style={{
            //   '--videoWidth': this.player?.el()?.querySelector('video')?.videoWidth,
            //   '--videoHeight': this.player?.el()?.querySelector('video')?.videoHeight,
            // }}
          >
            {subtitles?.map((item, index) => (
              <track
                key={index}
                src={item.url}
                kind='subtitles'
                srcLang={item.language}
                label={item.label}
              />
            ))}
          </video>
          {videoPlayed &&
            hosts?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles['hostdata']}
                  data-position={index}
                  data-odd={!!((hosts?.length || 0) % 2)}
                  data-fullscreen={!!isFullscreenNow}
                >
                  <div className={styles['host']}>
                    <div>
                      {!!item?.mute && (
                        <img
                          src={icMute}
                          alt='mute'
                        />
                      )}
                      <p className={styles['hostname']}>{item.name}</p>
                    </div>
                    {index === 0 && (
                      <span className={styles['hostLabel']}>Host</span>
                    )}
                  </div>
                  {index !== 0 && (
                    <div className={styles['totalGencash']}>
                      <img
                        src={icGencash}
                        alt='gencash'
                      />
                      <span>{item.gencash}</span>
                    </div>
                  )}
                </div>
              );
            })}
          {isFullscreenNow && (
            <FullScreenMode
              isShowStreamerInfo={isShowStreamerInfo}
              isVerticalVideo={videoVertical}
              isHideComment={isHideComment}
              totalGencash={totalGencash}
              views={views}
              isLiveShow
              onExitFullScreen={this.exitFullScreen}
              streamInfo={streamInfo}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const liveShow = state.liveShow;
  const liveShowComments = state.liveShowComments;

  return {
    liveShow,
    liveShowComments
  };
}

const ConnectedLiveShowPlayer = connect(mapStateToProps, null)(LiveShowPlayer);
export default withRouter(ConnectedLiveShowPlayer);
