const initialState = {
  bankAccountData: null,
  loadingBankAccount: false,
  errBankAccount: null,

  cashoutDetail: null,
  loadingCashout: false,
  errCashout: null,
  isCashoutPage: false
};

export default function cashoutReducer(state = initialState, actions) {
  const { type, payload, err } = actions;

  switch (type) {
    case 'GET_WALLET_ACCOUNT':
    case 'SET_WALLET_ACCOUNT':
      return {
        ...state,
        // bankAccountData: null,
        loadingBankAccount: true,
        errBankAccount: null
      };
    case 'GET_WALLET_ACCOUNT_SUCCESS':
    case 'SET_WALLET_ACCOUNT_SUCCESS':
      return {
        ...state,
        bankAccountData: {
          ...state?.bankAccountData,
          ...payload
        },
        loadingBankAccount: false,
        errBankAccount: null
      };
    case 'GET_WALLET_ACCOUNT_SUCCESS_FROM_STATE':
      return {
        ...state,
        bankAccountData: payload,
        loadingBankAccount: false,
        errBankAccount: null
      };
    case 'GET_WALLET_ACCOUNT_FAILED':
    case 'SET_WALLET_ACCOUNT_FAILED':
      return {
        ...state,
        bankAccountData: null,
        loadingBankAccount: false,
        errBankAccount: err
      };

    case 'CASHOUT_REQUEST':
      return {
        ...state,
        cashoutDetail: null,
        loadingCashout: true,
        errCashout: null
      };
    case 'CASHOUT_REQUEST_SUCCESS':
      return {
        ...state,
        cashoutDetail: payload,
        loadingCashout: false,
        errCashout: null
      };
    case 'CASHOUT_REQUEST_FAILED':
      return {
        ...state,
        cashoutDetail: null,
        loadingCashout: false,
        errCashout: err
      };
    case 'RESET_CASHOUT_STATE':
      return {
        ...initialState
      };

    case 'IS_FROM_CASH_OUT_PAGE':
      return {
        ...initialState,
        isCashoutPage: payload
      };

    default:
      return state;
  }
}
