import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';
import { showToastNotif } from './notification';

// const URL = '/catalog/v1.0/watchlist';
const URLS = '/catalog/v1.0/watchlists';

export const getMyList = (page, perPage) => async (dispatch, getState) => {
  const state = getState();
  const { data, meta, dataMustUpdate } = state.myList;

  dispatch({
    type: 'GET_MY_LIST_PROCESS'
  });

  const getResMyList = async () => {
    return axios.get(`${BASE_URL}${URLS}?page=${page}&perPage=${perPage}`, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID,
        'cloudfront-jwt-profileId': state.auth.user.profile.id
      }
    });
  };

  try {
    if (
      dataMustUpdate ||
      (!data && page === 1) ||
      (data.length && meta.page !== page)
    ) {
      // fetch axios
      // when first loaded
      // when page amount not same with meta page
      // user clicked another tab and go back to my list
      // when any update addToMyList or removeFromMylist clicked

      const res = await getResMyList();
      dispatch({
        type: 'GET_MY_LIST_SUCCESS',
        payload: {
          ...res.data
        },
        page
      });

      return;
    }

    if (data.length === meta.total) {
      // dispatch get my list complete
      // when data completed total data and
      // meta total is same we get from state
      return dispatch({ type: 'GET_MY_LIST_COMPLETE' });
    }

    // dispatch get my list success from state
    dispatch({
      type: 'GET_MY_LIST_SUCCESS_FROM_STATE',
      payload: { data, meta },
      page
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch(
      showToastNotif('error', 'Some error occured, please refresh the page!')
    );
    dispatch({
      type: 'GET_MY_LIST_FAILED',
      payload: errorData.message
    });
  }
};

export const addToMyList = (id, type) => async (dispatch, getState) => {
  const state = getState();
  try {
    const url = `${BASE_URL}/catalog/v1.0/watchlists`;
    await axios.post(
      url,
      {
        itemId: id,
        itemType: type
      },
      {
        headers: {
          Authorization: state.auth.token,
          'Cloudfront-JWT-AppId': JWT_APP_ID,
          'cloudfront-jwt-profileId': state.auth.user.profile.id
        }
      }
    );
    dispatch({
      type: 'ADD_LIST_SUCCESS'
    });
    dispatch({ type: 'UPDATE_MY_LIST' });
  } catch (err) {
    dispatch({
      type: 'ADD_LIST_ERROR',
      err: err?.response?.data?.error
    });
  }
};

export const getListStatus = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    const url = `${BASE_URL}/catalog/v1.0/watchlists/getStatusByItemId?itemId=${id}`;
    const res = await axios.get(url, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID,
        'cloudfront-jwt-profileId': state.auth.user.profile.id
      }
    });
    dispatch({
      type: 'SET_LIST_STATUS',
      payload: res?.data?.data?.addedToMyList
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'ADD_LIST_ERROR',
      err: errorData
    });
  }
};

export const removeFromMyList = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    const url = `${BASE_URL}/catalog/v1.0/watchlist/${id}`;
    await axios.delete(url, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID,
        'cloudfront-jwt-profileId': state.auth.user.profile.id
      }
    });
    dispatch({
      type: 'REMOVE_LIST_SUCCESS'
    });
    dispatch({ type: 'UPDATE_MY_LIST' });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'REMOVE_LIST_ERROR',
      err: errorData
    });
  }
};
