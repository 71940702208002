import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { reportUserOrContent } from '../../redux/actions/auth';

import GeneralNotifModal from '../GeneralModal/generalNotifModal';

import radioChecked from '../../assets/radio-checked.svg';
import radioUnChecked from '../../assets/radio-unchecked.svg';
import styles from './style.module.css';
import useSettings from '../../hooks/useSettings';

let reportOptions = ['Improper Chats', 'Racism', 'Spamming', 'Others'];

function ReportModal({
  profile,
  idEntity,
  isTriggeredReport,
  onReportClose,
  optionsType,
  type = 'user',
  valueEntity,
  idCase = null,
  titleCase = null
}) {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const [selectedReportOption, setSelectedOption] = useState('');
  const [isReported, setReported] = useState(false);

  const { reportReason, commentReason } = useSettings();

  if (reportReason || commentReason) {
    reportOptions =
      optionsType && optionsType === 'comment' ? commentReason : reportReason;
  }

  const report = async () => {
    const reason = selectedReportOption;
    const idReportee = profile?.id;
    if (!idReportee || !selectedReportOption) {
      return;
    }
    try {
      // setLoading(true);

      const payload = {
        idReportee,
        type,
        reason,
        idEntity,
        valueEntity,
        titleCase,
        idCase
      };

      const res = await dispatch(reportUserOrContent(payload));
      if (res.status === 200) {
        setReported(true);
      }
      // setLoading(false);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  if (!isTriggeredReport) {
    return null;
  }

  return (
    <GeneralNotifModal open={isTriggeredReport}>
      {!isReported ? (
        <div className={styles['reportContainer']}>
          <div className={styles['reportTitle']}>Report</div>
          <div className={styles['optionList']}>
            <div className={styles['reportSubTitle']}>
              What would you like to report?
            </div>
            {reportOptions?.map((option, index) => {
              return (
                <div
                  key={index}
                  className={styles['reportOptionsWrapper']}
                  onClick={() => setSelectedOption(option)}
                >
                  <img
                    src={
                      selectedReportOption === option
                        ? radioChecked
                        : radioUnChecked
                    }
                    alt='radio'
                  />
                  <span style={{ textTransform: 'capitalize' }}>{option}</span>
                </div>
              );
            })}
          </div>
          <div
            className={styles['reportButton']}
            onClick={() => report()}
          >
            Report
          </div>
          <div
            className={styles['cancelButton']}
            onClick={() => {
              setReported(false);
              onReportClose('cancel');
              setSelectedOption('');
            }}
          >
            Cancel
          </div>
        </div>
      ) : (
        <div className={styles['reportContainer']}>
          <img
            src={radioChecked}
            alt='radio'
            height='40'
            width='40'
          />
          <div className={styles['reportThanksTitle']}>Reported, Thank You</div>
          <div className={styles['reportThanksSubTitle']}>
            Thanks for helping keep Genflix community safe.
          </div>
          <div
            className={styles['closeButton']}
            onClick={() => {
              setReported(false);
              onReportClose('close');
              setSelectedOption('');
            }}
          >
            Close
          </div>
        </div>
      )}
    </GeneralNotifModal>
  );
}

export default ReportModal;
