import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RecentItem from '../RecentItem/RecentItem';
import { getPaymentGatewayPackages } from '../../redux/actions/packageGroup';
import Loading from '../Loading/Loading';
import { numberFormat } from '../../utils';

import styles from './SubscriptionIntro.module.css';

const SubscriptionIntro = () => {
  const dispatch = useDispatch();
  const {
    // packages,
    paymentGatewayPackages,
    loading
  } = useSelector((state) => state.packageGroups);
  const cheapPackage = useMemo(() => {
    const packagesPrice = paymentGatewayPackages?.data?.map(
      (item) => item.price
    );

    return packagesPrice && Math.min(...packagesPrice);
  }, [paymentGatewayPackages]);

  useEffect(() => {
    dispatch(getPaymentGatewayPackages());
  }, [dispatch]);

  return (
    <div className={styles['info-wrapper']}>
      <RecentItem />
      <div className={styles['premium-info']}>
        <p className={styles['info-title']}>
          Premium lets you play any movie or series anytime. No restrictions.
        </p>
        <p className={styles['upgrade']}>Upgrade to Premium</p>
        {loading ? (
          <Loading />
        ) : (
          <p className={styles['package']}>
            Start From IDR {numberFormat.format(cheapPackage)}
          </p>
        )}
        <div>
          <p className={styles['profit']}>Unlock Premium Content</p>
          <p className={styles['profit']}>Get Access to latest releases</p>
          <p className={styles['profit']}>Unlimited Plays</p>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionIntro;
