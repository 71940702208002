import React from 'react';
import { useSelector } from 'react-redux';
import plusIcn from '../../assets/btn-plus-circle.svg';
import checkIcn from '../../assets/ic-check-circle.svg';

const AddToListButton = ({ onAddList, onRemoveList }) => {
  const addedToMyList = useSelector(
    (state) => state.media?.detailData?.addedToMyList
  );

  return addedToMyList ? (
    <img
      src={checkIcn}
      alt='remove from list'
      onClick={() => onRemoveList()}
    />
  ) : (
    <img
      src={plusIcn}
      alt='add to list'
      onClick={() => onAddList()}
    />
  );
};

export default AddToListButton;
