import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';

const URL_PLAYLIST = '/catalog/v1.1/playlist/';
const URL_PLAYLIST_V1_0 = '/catalog/v1.0/playlist/';
const SUBS_PLAYLIST = '/subscription/v1.1/packages/getPackageByGateway';

export const getPlaylistItemsConfig =
  (id, currentPage) => (_dispatch, getState) => {
    const state = getState();

    return {
      url: `${
        BASE_URL + URL_PLAYLIST + id
      }/items?locale=en&page=${currentPage}`,
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    };
  };

const getAllPlayListDetailItems =
  (id, page, options) => async (_dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;
    const isSurgeUser = options?.isSurgeUser;

    const [playlistDetail, playlistItem] = await Promise.all([
      axios.get(`${BASE_URL + URL_PLAYLIST + id}?page=${page}&locale=en`, {
        headers: {
          Authorization: state.auth.token,
          'Cloudfront-JWT-AppId': JWT_APP_ID,
          ...(isSurgeUser && {
            'cloudfront-jwt-profileid': user?.profile?.id,
            'cloudfront-jwt-accountid': user?.account?.id
          })
        },
        params: {
          ...(isSurgeUser && { showEligibleContent: true })
        }
      }),
      axios.get(
        `${BASE_URL + URL_PLAYLIST + id}/items?page=${page}&locale=en`,
        {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID,
            ...(isSurgeUser && {
              'cloudfront-jwt-profileid': user?.profile?.id,
              'cloudfront-jwt-accountid': user?.account?.id
            })
          },
          params: {
            ...(isSurgeUser && { showEligibleContent: true })
          }
        }
      )
    ]);

    playlistDetail.data.data.items = playlistItem.data.data;
    playlistDetail.data.meta = playlistItem.data.meta;
    const res = playlistDetail;

    return res;
  };

export const getPlaylistDetailItems =
  (id, page, options) => async (dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;
    const isSurgeUser = options?.isSurgeUser;
    const { loading: isLoading, data, meta } = state.playlist;

    if (isLoading) {
      return null;
    }

    dispatch({
      type: 'GET_PLAYLIST_DETAIL',
      page
    });

    try {
      let res;
      if (data?.id !== id) {
        res = await dispatch(getAllPlayListDetailItems(id, page, options));
        dispatch({
          type: 'GET_PLAYLIST_DETAIL_SUCCESS',
          payload: {
            ...res.data
          },
          page
        });
      } else if (data?.items?.length !== meta?.total && meta?.page !== page) {
        res = await axios.get(
          `${BASE_URL + URL_PLAYLIST + id}/items?page=${page}&locale=en`,
          {
            headers: {
              Authorization: state.auth.token,
              'Cloudfront-JWT-AppId': JWT_APP_ID,
              ...(isSurgeUser && {
                'cloudfront-jwt-profileid': user?.profile?.id,
                'cloudfront-jwt-accountid': user?.account?.id
              })
            },
            params: {
              ...(isSurgeUser && { showEligibleContent: true })
            }
          }
        );
        dispatch({
          type: 'GET_PLAYLIST_DETAIL_SUCCESS',
          payload: {
            ...res.data
          },
          page
        });
      } else {
        dispatch({
          type: 'GET_PLAYLIST_DETAIL_SUCCESS_FROM_STATE',
          payload: state.playlist,
          page
        });
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_PLAYLIST_DETAIL_ERROR',
        err: errorData
      });
    }
  };

export const getPlaylist =
  (id, page = 1) =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: 'GET_PLAYLIST_DETAIL',
      page
    });

    try {
      const res = await axios.get(
        `${BASE_URL + URL_PLAYLIST + id}?page=${page}&locale=en`,
        {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'GET_PLAYLIST_DETAIL_SUCCESS',
        payload: {
          ...res.data
        },
        page
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_PLAYLIST_DETAIL_ERROR',
        err: errorData
      });
    }
  };

export const fetchPlaylist = (id) => async (_dispatch, getState) => {
  const state = getState();

  const res = await axios.get(`${BASE_URL + URL_PLAYLIST + id}?locale=en`, {
    headers: {
      Authorization: state.auth.token,
      'Cloudfront-JWT-AppId': JWT_APP_ID
    }
  });
  return res.data;
};

export const getPlaylistItems =
  (id, page, perPage) => async (_dispatch, getState) => {
    const state = getState();
    const url = `${BASE_URL + URL_PLAYLIST + id}/items`;

    const res = await axios.get(url, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      },
      params: {
        locale: 'en',
        ...(page && { page, perPage })
      }
    });

    return res.data;
  };

export const getEpisodes =
  (
    seriesId,
    page,
    perPage,
    showAllEpisode = true,
    { dispatchPayload = true } = {}
  ) =>
  async (dispatch, _getState) => {
    const state = _getState();
    const { episode } = state.media;

    dispatchPayload &&
      dispatch({
        type: 'GET_EPISODE_ITEMS'
      });

    try {
      const findEpisode = episode.find((el) => el.seriesId === seriesId);

      if (!findEpisode || perPage > 1) {
        const res = await axios.get(
          `${BASE_URL + URL_PLAYLIST_V1_0 + seriesId}/items`,
          {
            headers: {
              'Cloudfront-JWT-AppId': JWT_APP_ID
            },
            params: {
              locale: 'en',
              page,
              perPage,
              showAllEpisode
            }
          }
        );

        dispatchPayload &&
          dispatch({
            type: 'GET_EPISODE_ITEMS_SUCCESS',
            payload: res.data,
            page,
            perPage
          });

        const dataEpisode = {
          seriesId,
          data: res.data
        };
        dispatch({
          type: 'SET_EPISODES',
          payload: dataEpisode
        });

        return res.data;
      }
      dispatchPayload &&
        dispatch({
          type: 'GET_EPISODE_ITEMS_SUCCESS_FROM_STATE'
        });

      return findEpisode?.data;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatchPayload &&
        dispatch({
          type: 'GET_EPISODE_ITEMS_FAILED',
          err: errorData
        });

      throw err;
    }
  };

export const resetEpisodes = () => (dispatch) => {
  dispatch({
    type: 'RESET_EPISODES'
  });
};

export const getCarouselPlaylistItems =
  (id, page = 1, perPage = 20, type = 'carousel', reminder = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const { upcomingVod, upcomingVodNotEmpty } = state.playlist;
    const url = `${BASE_URL + URL_PLAYLIST_V1_0 + id}/items`;

    dispatch({
      type: type === 'upcoming' ? 'GET_UPCOMING_ITEMS' : 'GET_CAROUSAL_ITEMS'
    });

    try {
      let res;
      if (upcomingVod?.length > 0 && !reminder && upcomingVodNotEmpty) {
        if (upcomingVod) {
          type === 'upcoming'
            ? dispatch({
                type: 'GET_UPCOMING_ITEM_SUCCESS_FROM_STATE',
                payload: upcomingVod,
                upcomingVodNotEmpty: upcomingVod.length > 0
              })
            : dispatch({
                type: 'GET_CAROUSEL_ITEM_SUCCESS',
                payload: upcomingVod,
                upcomingVodNotEmpty: upcomingVod.length > 0
              });
        }

        return upcomingVod;
      }
      if (upcomingVod?.length > 0 && reminder) {
        res = await axios.get(url, {
          headers: {
            // Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          },
          params: {
            locale: 'en',
            upcomingShowUsage: type,
            remindProfileId: state.auth?.user?.profile?.id,
            ...(page && type !== 'upcoming' && { page, perPage })
          }
        });

        if (res?.data) {
          type === 'upcoming'
            ? dispatch({
                type: 'GET_UPCOMING_ITEM_SUCCESS',
                payload: res.data.data,
                upcomingVodNotEmpty: res.data.data.length > 0
              })
            : dispatch({
                type: 'GET_CAROUSEL_ITEM_SUCCESS',
                payload: res.data.data,
                upcomingVodNotEmpty: res.data.data.length > 0
              });
        }

        return res.data;
      }
      res = await axios.get(url, {
        headers: {
          // Authorization: state.auth.token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        },
        params: {
          locale: 'en',
          upcomingShowUsage: type,
          remindProfileId: state.auth?.user?.profile?.id,
          ...(page && type !== 'upcoming' && { page, perPage })
        }
      });

      if (res?.data) {
        type === 'upcoming'
          ? dispatch({
              type: 'GET_UPCOMING_ITEM_SUCCESS',
              payload: res.data.data,
              upcomingVodNotEmpty: res.data.data.length > 0
            })
          : dispatch({
              type: 'GET_CAROUSEL_ITEM_SUCCESS',
              payload: res.data.data,
              upcomingVodNotEmpty: res.data.data.length > 0
            });
      }

      return res.data;
    } catch (error) {
      const errorData = generateErrorDetail(error);
      dispatch({
        type: 'GET_UPCOMING_ITEM_FAILED',
        upcomingVodError: errorData
      });
    }
  };

export const setUpcomingLiveshowReminder =
  (liveshowId, reminder = true) =>
  async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;

    try {
      dispatch({
        type: 'SET_UPCOMING_LIVE_SHOW_REMINDER',
        payload: { liveshowId }
      });

      const res = await axios.post(
        `${BASE_URL}/catalog/v1.0/upcomingLiveshow/${liveshowId}/${
          reminder ? 'setReminder' : 'deleteReminder'
        }`,
        {},
        {
          headers: {
            Authorization: token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );
      if (res) {
        return res;
      }
    } catch (error) {
      throw new Error(error);
    }
  };
export const getPlaylistByPackageGroup =
  ({ page, perPage, gatewayPackageId, gatewayType }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;

    dispatch({
      type: 'GET_PLAYLIST_ITEMS'
    });

    try {
      const res = await axios.get(`${BASE_URL + SUBS_PLAYLIST}`, {
        headers: {
          'Cloudfront-JWT-AppId': JWT_APP_ID,
          Authorization: token
        },
        params: {
          page,
          perPage,
          gatewayPackageId,
          gatewayType
        }
      });

      dispatch({
        type: 'GET_PLAYLIST_ITEMS_SUCCESS',
        payload: res.data,
        page,
        perPage
      });

      return res.data;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_PLAYLIST_ITEMS_FAILURE',
        err: errorData
      });

      throw err;
    }
  };
