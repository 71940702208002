const initialState = {
  views: 0,
  comments: [],
  bulletComment: [],
  hosts: [],
  loading: false,
  err: null
};

export default function liveShowCommentsReducer(state = initialState, action) {
  const { type, payload, err } = action;

  switch (type) {
    case 'ADD_LIVE_SHOW_COMMENTS':
      return {
        ...state,
        comments: [...state.comments, payload.data]
      };

    case 'SET_LIVE_SHOW_COMMENTS':
      return {
        ...state,
        comments: payload.data.reverse()
      };

    case 'SET_BULLET_COMMENT':
      return {
        ...state,
        bulletComment: [...state.bulletComment, payload.data],
        comments: [...state.comments, payload.data]
      };

    case 'HIDE_BULLET_COMMENT':
      const mappedBullet = state.bulletComment.map((bullet) => {
        if (bullet.id === payload.id) {
          return { ...bullet, show: false };
        }
        return bullet;
      });
      return {
        ...state,
        bulletComment: mappedBullet
      };

    case 'SET_LIVE_SHOW_VIEWS':
      return {
        ...state,
        views: payload.data
      };

    case 'RESET_LIVE_SHOW_COMMENTS':
      return {
        ...state,
        views: 0,
        comments: []
      };

    case 'GET_LIVE_SHOW_COMMENTS_FAILURE':
      return {
        ...state,
        loading: false,
        err
      };

    case 'SET_HOSTS_POSITION':
      return {
        ...state,
        hosts: payload?.map((item) => ({
          ...item,
          mute: item?.isMute || item?.mute
        }))
      };

    case 'UPDATE_HOSTS_GENCASH':
      const hostData = state.hosts;
      const findIndex = hostData.findIndex(
        (item) => item.id === payload?.receiverProfile?.id
      );

      hostData[findIndex].gencash += payload?.gencash || 0;

      return {
        ...state,
        hosts: hostData
      };

    case 'SET_HOSTS_MUTED':
      return {
        ...state,
        hosts: state.hosts?.map((item) => {
          if (item.id === payload?.receiverProfile?.id) {
            return {
              ...item,
              mute: !!Number(payload?.data)
            };
          }

          return item;
        })
      };

    default:
      return state;
  }
}
