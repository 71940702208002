import React from 'react';
import { SLIDER_ITEM_THUMBNAIL } from '../../config/Thumbnail';
import { getThumbnail } from '../../utils';

import styles from './LiveTVCollectionItem.module.css';

const LiveTVCollectionItem = ({ item }) => {
  const thumbnail = getThumbnail(item, SLIDER_ITEM_THUMBNAIL);

  return (
    <div className={styles['item-wrapper']}>
      <div>
        {thumbnail ? (
          <img
            src={thumbnail}
            alt='thumbnail'
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default LiveTVCollectionItem;
