import React, { useMemo } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { formatSecsDuration } from '../../utils';
import styles from './ClipsCollectionItem.module.css';

// import BlockUnblockModal from '../BlockUnblockModal';
// import { isBlockBlockedProfile } from '../../redux/actions/auth';

const MediaItem = ({ item }) => {
  const initial = useMemo(() => {
    if (!item?.profile?.name) return '';

    return item.profile.name
      .split(' ')
      .slice(0, 2)
      .map((n) => n.charAt(0).toUpperCase())
      .reduce((n, space) => n + space);
  }, [item]);

  const thumbnail =
    item && item.attachments && item.attachments.thumbnail_landscape
      ? `url(${item.attachments.thumbnail_landscape.fileUrl})`
      : 'white';
  const style = item && {
    background: `${thumbnail} no-repeat center center`,
    backgroundSize: 'cover'
  };

  return (
    <div className={styles['item-wrapper']}>
      <div
        title={item.titleLocalized || item.nameLocalized}
        className={styles['item-image']}
        style={style}
      >
        <div className={styles['video-duration']}>
          {formatSecsDuration(item.duration, { format: 'mm ss' })}
        </div>
      </div>
      <div className={styles['item-description']}>
        <h3 title={item.titleLocalized}>{item.titleLocalized}</h3>
        <span>{item.views ? item.views : 0} Views</span>
        {item.profile && (
          <div className={styles['item-profile']}>
            {item.profile.avatar ? (
              <img
                src={item.profile.avatar}
                alt='profile'
              />
            ) : (
              <div>{initial}</div>
            )}
            <span>{item?.profile.name}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const ClipsCollectionItem = ({
  data,
  empty,
  playlistType
  // onBlockUnblockDone
}) => {
  const navigate = useNavigate();
  // const { token, user } = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  // const [isTriggerBlockModal, setTriggerBlockModal] = useState(false);
  // const [blockType, setBlockType] = useState('block');

  // const checkBlockUnblock = useCallback(
  //   async (blockData) => {
  //     if (!token && !user) {
  //       return navigate(`/clips/${blockData.id}`);
  //     }
  //     // const res = await dispatch(
  //     //   isBlockBlockedProfile(blockData.profile.accountId, blockData.profile.id)
  //     // );
  //     // if (res?.data?.status === 'BLOCKING') {
  //     //   setBlockType('unblock');
  //     //   setTriggerBlockModal(true);
  //     //   return false;
  //     // }
  //     // if (res?.data?.status === 'BLOCKED') {
  //     //   setTriggerBlockModal(true);
  //     //   setBlockType('access');
  //     //   return false;
  //     // }

  //     return navigate(`/clips/${blockData.id}`);
  //   },
  //   [dispatch, navigate, user, token]
  // );

  const handleClick = (item) => {
    return navigate(`/movie/${item.id}?autoplay=true`);
  };

  // const onCloseBlockModal = (type) => {
  //   setTriggerBlockModal(false);
  //   if ((type === 'blocked' || type === 'unblocked') && onBlockUnblockDone) {
  //     onBlockUnblockDone();
  //   }
  // };
  return (
    <>
      <div
        className={`${styles['collection-item']} ${
          empty ? styles['empty'] : ''
        }`}
        data-list={playlistType}
        onClick={() => {
          handleClick(data);
        }}
      >
        {!empty && <MediaItem item={data} />}
      </div>
      {/* {isTriggerBlockModal && data?.profile && (
        <BlockUnblockModal
          profile={data?.profile}
          blockType={blockType}
          isTriggered={isTriggerBlockModal}
          onClose={onCloseBlockModal}
        />
      )} */}
    </>
  );
};

export default ClipsCollectionItem;
