import React, { useCallback } from 'react';
import { useBlocker } from './useBlocker';
import { useConfirm } from './useConfirmation';
import styles from './styles.module.css';

const Prompt = ({ when = false, message }) => {
  const {
    isOpen,
    proceed,
    cancel,
    onConfirm,
    hasConfirmed,
    resetConfirmation
  } = useConfirm();

  const blocker = useCallback(
    async (event) => {
      if (await onConfirm()) {
        resetConfirmation();
        event.retry();
      }
    },
    [resetConfirmation, onConfirm]
  );

  useBlocker(blocker, when && !hasConfirmed);

  if (!isOpen) return;

  return (
    <div className={styles['wrapper']}>
      <div className={styles['content']}>
        <div className={styles['header']}>Warning</div>
        <p>{message}</p>
      </div>
      <div className={styles['action']}>
        <button
          type='button'
          className={styles['buttonCancel']}
          onClick={cancel}
        >
          Cancel
        </button>
        <button
          type='button'
          className={styles['buttonProceed']}
          onClick={proceed}
        >
          Oke
        </button>
      </div>
    </div>
  );
};

export default Prompt;
