import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { parseQuery } from '../../utils';
import styles from './TextContent.module.css';

const isHTML = (str) => {
  const a = document.createElement('div');
  a.innerHTML = str;

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true;
  }

  return false;
};
const generateContent = (contents) => {
  if (contents instanceof Array && contents?.length) {
    return contents.map((item) => {
      return generateContent(item);
    });
  }

  if (contents instanceof Object && !contents?.length) {
    if (contents?.list) {
      const title = contents?.list?.title;
      const items = contents?.list?.items;
      const order = contents?.list?.order;
      const type = contents?.list?.type;
      const bullets = contents?.list?.bullets;
      const linkStyle = contents?.list?.linkStyle;

      return (
        <React.Fragment key={uuidv4()}>
          {title && <p>{title}</p>}
          {items &&
            (order === 'ol' ? (
              <ol type={type || '1'}>
                {items?.map((list, index) => (
                  <li
                    key={index}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {generateContent(list)}
                  </li>
                ))}
              </ol>
            ) : (
              <ul
                className={linkStyle && styles['linkHighlight']}
                style={bullets ? {} : { listStyleType: 'none' }}
              >
                {items?.map((list, index) => (
                  <li key={index}>{generateContent(list)}</li>
                ))}
              </ul>
            ))}
        </React.Fragment>
      );
    }

    if (contents?.subtitle) {
      return <h5>{contents}</h5>;
    }

    if (contents?.contentUrl) {
      const label = contents?.contentUrl?.label || '';
      const url = contents?.contentUrl?.url || '';
      return (
        <p>
          {label} : {url}
        </p>
      );
    }
  }

  if (typeof contents === 'string') {
    if (isHTML(contents)) {
      return (
        <p key={uuidv4()}>
          <div dangerouslySetInnerHTML={{ __html: contents }} />
        </p>
      );
    }
    return <p key={uuidv4()}>{contents}</p>;
  }

  return null;
};

const generateLanguages = (language = 'id', data) => {
  const languages = [];

  for (const i in data) {
    if (i) languages.push(i);
  }

  const findLang = languages.findIndex((i) => i === language);
  const verifyLang = findLang >= 0 ? findLang : 0;

  const selectedLanguage = languages[verifyLang];
  const selectedData = data[selectedLanguage];
  const nextLanguage = languages[(verifyLang + 1) % languages.length];

  return {
    selectedData,
    nextLanguage,
    languages
  };
};

const TextContent = ({ data, showLanguageOption, mobileStyle }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = parseQuery(location.search);
  const [language, setLanguage] = useState('id');
  const langQuery = params?.lang;
  const { selectedData, nextLanguage, languages } = generateLanguages(
    langQuery || language,
    data
  ); // langQuery is required, this is used by mobile to display TnC and they use the query

  const header = useMemo(() => selectedData?.header, [selectedData]);
  const languageTitle = useMemo(
    () => selectedData?.nextLanguage,
    [selectedData]
  );
  const contentList = useMemo(() => selectedData?.contentList, [selectedData]);

  const handleChangeLanguage = () => {
    navigate(`${location.pathname}?lang=${nextLanguage}`);
    setLanguage(nextLanguage);
  };

  return (
    <div
      className={styles['background-wrapper']}
      data-mobile={mobileStyle}
    >
      <div
        className={styles['wrapper']}
        data-mobile={mobileStyle}
      >
        <div className={styles['title-wrapper']}>
          {header instanceof Array ? (
            <div>
              {header?.map((item, i) => (
                <h1 key={i}>{item}</h1>
              ))}
            </div>
          ) : (
            <h1>{header}</h1>
          )}
          {showLanguageOption && languages?.length && languageTitle && (
            <span
              className={styles['language']}
              onClick={handleChangeLanguage}
            >
              {languageTitle}
            </span>
          )}
        </div>

        {contentList.map((contentItem, index) => {
          const { title, contents, subtitle } = contentItem;

          return (
            <div
              key={index}
              className={styles['content-item']}
            >
              <h2 className={styles['content-title']}>{title}</h2>
              {subtitle && (
                <h2 className={styles['content-subtitle']}>{subtitle}</h2>
              )}
              <div className={styles['content-data']}>
                {contents && generateContent(contents)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TextContent;
