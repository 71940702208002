import React, { useMemo } from 'react';
import Tab from './tab';

const TabWrapper = ({
  tabs = [],
  options = [],
  tab,
  onTabChange,
  activeStyle,
  wrapperStyle,
  className
}) => {
  const Component = useMemo(() => {
    if (typeof tab === 'string') {
      const findTab = tabs?.find(({ link, id }) => {
        return id ? tab.includes(id) : tab === link;
      });

      return findTab?.component;
    }
    if (typeof tab === 'number') return tabs[tab].component;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    Component && (
      <>
        <Tab
          links={tabs}
          activeTab={tab}
          onTabChange={onTabChange}
          options={options}
          activeStyle={activeStyle}
          wrapperStyle={wrapperStyle}
          className={className}
        />
        <Component tabs={tabs} />
      </>
    )
  );
};

export default TabWrapper;
