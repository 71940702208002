import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  closeDialogNotif,
  getInAppNotificationById
} from '../../redux/actions/inAppNotification';
import { capitalizeFirstLetter, cleanText } from '../../utils';
import styles from './NotificationDialog.module.css';

const SliderCard = ({ data, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickNotification = () => {
    dispatch(closeDialogNotif());
    if (data?.refId && data?.type !== 'promo') {
      const ref = data?.refId.split(':');
      dispatch(getInAppNotificationById(data?.id));
      return navigate(`/${ref[0]}/${ref[1]}`);
    }
    return navigate(`/notification/${data?.id}`);
  };

  const typeFormatting = (typeName) => {
    let processed = '';
    if (typeName.includes('_')) {
      const words = typeName.split('_');

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      processed = words.join(' ');
    } else {
      processed = capitalizeFirstLetter(typeName);
    }
    return processed;
  };

  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        onClickNotification();
      }}
      className={type === 'dialog' ? styles['cardDialog'] : styles['card']}
    >
      <div className={styles['category']}>{typeFormatting(data?.type)}</div>
      <div className={styles['header']}>
        {data?.title?.length > 50
          ? `${data?.title.substring(0, 50)}...`
          : data?.title}
      </div>
      <div className={styles['content']}>
        {data?.desc && cleanText(data?.desc).length > 80
          ? `${cleanText(data?.desc).substring(0, 140)}...`
          : cleanText(data?.desc)}
      </div>
    </div>
  );
};

export default SliderCard;
