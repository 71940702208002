export const errorCode = {
  INVALID_SESSION_TOKEN: 'gfu4010104',
  INVALID_SESSION_AUTH: 'gfx4220203',
  MISSING_VIDEO: 'missing_video',
  INVALID_PARAMETERS: 'gfx4000003',
  EMAIL_ALREADY_EXIST: 'gfu4000202',
  INVALID_CREDENTIAL: 'gfu4000201',
  DIAMOND_NOT_ENOUGH: 'gfl4000601',
  NO_SUBSCRIPTION: 'gfc4030205',
  VIDEO_CONTENT_PREMIUM: 'gfc4030210',
  VIDEO_NOT_FOUND: 'gfc4040201',
  INVALID_OTP: 'gfu4010501',
  INVALID_NUMBER: 'gfx4000601',
  TOO_MANY_ATTEMPTS: 'gfu4030503',
  TOO_QUICK_RETRY: 'gfu4030502',
  INVALID_PHONE_NUMBER: 'gfx4000601',
  PHONE_ALREADY_USED: 'gfu4000204',
  INVALID_OTP_CODE: 'gfu4000504',
  NANOVEST_NUM_NO_REGISTERED: 'gfl4001101',
  SHOW_NOT_FOUND: 'gfc4040802',
  VIDEO_GEOBLOCK: 'gfc4030207'
};

export const social = {
  GENFLIX_FACEBOOK: 'https://m.facebook.com/genflix',
  GENFLIX_INSTAGRAM: 'https://www.instagram.com/Genflixid/',
  GENFLIX_TWITTER: 'https://www.x.com/genflix_id/',
  GENFLIX_WHATSAPP: 'https://wa.me/6289513634343',
  GENFLIX_EMAIL: 'hello@genflix.co.id',
  GENFLIX_NUMBER: '+6289513634343',
  GENFLIX_FORM:
    'https://docs.google.com/forms/d/e/1FAIpQLSdMaspcncLQ3I3sI3xsuI4n00Mx65rSSPmCpWJTtkhrThzeVg/formResponse'
};

export const appURL = {
  APP_STORE: '//apps.apple.com/id/app/genflix/id1454786569',
  GOOGLE_PLAY: '//play.google.com/store/apps/details?id=com.linkit360.genflix'
};

export const vodLiveShowId =
  window.location.host === 'genflix.co.id'
    ? 'a631405c-c716-4b5d-be36-a31ba055d5aa'
    : 'a6983f27-1e4e-4854-8e1b-b2b05f22d27e';

export const playlistCarouselId =
  window.location.host === 'genflix.co.id'
    ? 'd7644565-6490-49bb-8121-3147963b3282'
    : '3ec81cbf-527a-4e8d-b6b4-8eb75144fa0a';

export const clipsID =
  window.location.host === 'genflix.co.id'
    ? '5d130fa2-c92e-4dd1-85ef-d32da54781db'
    : 'f8bdeac3-fc4d-4a6a-8028-665c3c26a73b';

export const packageid =
  window.location.host === 'genflix.co.id'
    ? '60a0e4b447a76142'
    : '606453cdaaba5358';
