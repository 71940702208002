import React, { useState, useRef, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { errorCode } from '../../constants';
import {
  getAccountInfo,
  requestOtpChangeNumber,
  updatePhoneNumber
} from '../../redux/actions/auth';
import { showToastNotif } from '../../redux/actions/notification';
import Button from '../Button/Button';
import {
  validationReducer,
  validationState
} from '../Cashout/useReducers/addBankReducer';
import InputForm from '../InputForm/InputForm';
import OtpModal from './OtpModal';
import PasswordModal from './passwordModal';
import styles from './profileForm.module.css';
import { parseQuery } from '../../utils';

const ProfileContact = () => {
  const isMounted = useRef(null);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [showPassModal, setShowPassModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const { account, telco } = auth.user;
  const inputFormRef = useRef();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [validation, dispatchValidation] = useReducer(
    validationReducer,
    validationState
  );
  const { phoneInvalid, validationInvalid } = validation;
  const location = useLocation();
  const { defaultPassword } = parseQuery(location?.search);

  const handleFormRef = (e) => {
    const elements = e?.elements;
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        if (!elements[i].value) {
          return setSubmitDisabled(true);
        }
        if (validationInvalid) setSubmitDisabled(true);
        else setSubmitDisabled(false);
        inputFormRef.current = elements;
      }
    }
  };

  const handleInputForm = (event) => {
    const elementId = event.target.id;
    const eventType = event.target.type;
    const eventValue = event.target.value;

    if (eventType === 'number' && eventValue?.toString()?.length > 50) return;

    dispatchValidation({ type: elementId, payload: eventValue });
  };

  const togglePassModal = () => {
    setShowPassModal(!showPassModal);
  };

  const toggleOtpModal = async () => {
    setShowOtpModal(!showOtpModal);
  };

  const handleSendOtp = async () => {
    setLoading(true);
    try {
      await requestOtpChangeNumber(inputFormRef.current.phone.value.trim());
      setShowOtpModal(!showOtpModal);
      setSentOtp(true);
    } catch (err) {
      const errCode = err?.response?.data?.error?.code;
      if (errCode === errorCode.PHONE_ALREADY_USED) {
        return dispatch(
          showToastNotif(
            'error',
            'Error: Number already used by another account.'
          )
        );
      }
      if (errCode === errorCode.INVALID_PHONE_NUMBER) {
        return dispatch(
          showToastNotif('error', 'Error: Invalid phone number.')
        );
      }
      dispatch(showToastNotif('error', 'Error sending OTP, please try again.'));
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    await requestOtpChangeNumber(inputFormRef?.current?.phone?.value?.trim());
  };

  const handleVerifyOtp = async (payload) => {
    await dispatch(updatePhoneNumber(payload));
    dispatch(getAccountInfo());
  };

  useEffect(() => {
    isMounted.current = true;
  }, [isMounted]);

  useEffect(() => {
    if (defaultPassword && defaultPassword !== undefined) {
      setShowPassModal(true);
    }
  }, [defaultPassword, location.search]);

  return (
    <>
      {isMounted.current && (
        <PasswordModal
          open={showPassModal}
          onDismiss={togglePassModal}
        />
      )}
      <OtpModal
        content={`Please type the verification code sent to ${inputFormRef?.current?.phone?.value?.trim()}`}
        open={showOtpModal}
        codeLength={6}
        onDismiss={toggleOtpModal}
        onResendOtp={handleResendOtp}
        onVerifyOtp={handleVerifyOtp}
        phoneNumber={inputFormRef?.current?.phone?.value?.trim()}
      />
      <div className={styles['formContact']}>
        <div className={styles['formInput']}>
          <div className={styles['inputLabel']}>Email</div>
          <div className={styles['columns']}>
            <span className={styles['email']}>{account.email}</span>
            {account.emailVerifiedAt && (
              <span className={styles['emailHelper']}>
                <b>Verified.</b> Thank you for verifying your email
              </span>
            )}
          </div>
        </div>
        <div className={styles['phoneForm']}>
          <div className={styles['inputLabel']}>Mobile No.</div>
          <div className={styles['wrapperPhoneInput']}>
            <form
              className={styles['phoneInput']}
              ref={handleFormRef}
            >
              <InputForm
                type='number'
                id='phone'
                errorClassName={styles['inputError']}
                placeholder='e.g. 81234567890'
                startAddOn={{ type: 'phone-code', defaultValue: '+62' }}
                dataError={phoneInvalid}
                defaultValue={account?.mobile}
                className={styles['inputForm']}
                required
                onChange={(e) => handleInputForm(e)}
              />
            </form>
            {!submitDisabled && (
              <Button
                className={styles['buttonSave']}
                disabled={loading}
                onClick={sentOtp ? toggleOtpModal : handleSendOtp}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        <div className={styles['formInput']}>
          <div className={styles['inputLabel']}>Password</div>
          <div
            data-password-hide
            className={styles['password']}
          >
            **************
            {!telco && (
              <span
                className={styles['passwordEdit']}
                onClick={togglePassModal}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileContact;
