import axios from 'axios';
import { BASE_URL, BASE_SOCKET_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';

const URL_INTERACTIVE = `${BASE_URL}/catalog/v1.1/liveChannels`;
const URL_COMMENTS = `${BASE_URL}/catalog/v1.1/liveChannel`;
const SOCKET_URL = `${BASE_SOCKET_URL}/interaction/v1/comments`;

export const getInteractive =
  (page = 1, perPage = 20) =>
  async (dispatch, getState) => {
    const state = getState();
    const { data, meta } = state.interactive;
    dispatch({
      type: 'GET_INTERACTIVE'
    });

    try {
      if (
        (!data?.length && !meta) ||
        (data?.length && data?.length !== meta?.total)
      ) {
        // first load page 1 || data.length !== meta.total
        const res = await axios.get(
          `${URL_INTERACTIVE}?interactive=true&page=${page}&perPage=${perPage}&locale=en`,
          {
            headers: {
              Authorization: state.auth.token,
              'Cloudfront-JWT-AppId': JWT_APP_ID
            }
          }
        );

        dispatch({
          type: 'GET_INTERACTIVE_SUCCESS',
          payload: {
            ...res.data
          },
          page
        });
      } else {
        // data.length === meta.total
        dispatch({
          type: 'GET_INTERACTIVE_SUCCESS_FROM_STATE',
          payload: state.interactive,
          page
        });
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_INTERACTIVE_FAILURE',
        err: errorData
      });
    }
  };

export const getComments = (channelID, limit) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${URL_COMMENTS}/${channelID}/comments?limit=${limit}`
    );

    dispatch({
      type: 'SET_COMMENTS',
      payload: res.data.data
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_INTERACTIVE_FAILURE',
      err: errorData
    });
  }
};

function waitForSocketConnection(socket, callback) {
  setTimeout(function () {
    if (socket.readyState === 1) {
      // eslint-disable-next-line no-console
      console.log('Connection is made');
      if (callback != null) {
        callback();
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('wait for connection...');
      waitForSocketConnection(socket, callback);
    }
  }, 5); // wait 5 milisecond for the connection...
}

export const connectSocket = (channelID) => (dispatch) => {
  const ws = new WebSocket(`${`${SOCKET_URL}/${channelID}`}`);
  // eslint-disable-next-line no-console
  ws.onopen = () => console.log('connected to server');
  // eslint-disable-next-line no-console
  ws.onclose = (e) => console.log('socket closed', e.reason);
  ws.onerror = (err) => {
    // eslint-disable-next-line no-console
    console.error(err.message);
    ws.close();
  };
  ws.onmessage = (event) => {
    const message = JSON.parse(event.data);

    // eslint-disable-next-line no-console
    console.log('message', message);
    message.type === 'comment' &&
      dispatch({
        type: 'ADD_COMMENT',
        payload: { data: message }
      });
    message.type === 'viewers' &&
      dispatch({
        type: 'SET_VIEWS',
        payload: { data: Number(message.data) }
      });
  };
  return ws;
};

export const sendAuth = (ws, token) => () => {
  waitForSocketConnection(ws, () => {
    const payload = { type: 'authorization', data: token };
    ws.send(JSON.stringify(payload));
  });
};

export const addComment = (ws, comment) => () => {
  waitForSocketConnection(ws, () => {
    const payload = { type: 'comment', data: comment };
    ws.send(JSON.stringify(payload));
  });
};

export const resetInteractive = () => (dispatch) => {
  dispatch({
    type: 'RESET_INTERACTIVE'
  });
};
