import React, { useLayoutEffect } from 'react';
import Portal from './Portal';
import styles from './Modal.module.css';

const ModalContent = ({ children, isSmall, classNames, opacity = 1 }) => {
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Portal>
      <div
        className={styles['overlay']}
        style={{ backgroundColor: `rgba(0, 0, 0, ${opacity})` }}
      >
        <div
          className={`${styles['modal']} ${
            isSmall && styles['center']
          } ${classNames}`}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default ModalContent;
