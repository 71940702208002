const initialState = {
  clipsData: [],
  clipsCategory: {},
  clipsCategoryData: [],
  clipsCategoryDataCompleted: false,
  clipsCompleted: false,
  clipsDetail: null,
  clipsMeta: null,
  clipsComments: null,
  clipsCommentsMeta: null,
  loadingTags: false,
  loadingMetadata: false,
  loadingClips: false,
  loadingClipsDetail: false,
  loadingClipsComments: false,
  loadingSendComment: false,
  recommendedClips: null,
  loadingRecommendedClips: false,
  errorRecommendedClips: null,
  err: null,
  clipsViewError: null,
  clipsCommentError: null,
  clipsLikesMeta: null,
  likesDetailsLoading: true,
  likesDetailError: null,
  showClipsModal: false
};

export default function clipsReducer(state = initialState, action) {
  const { type, payload, page, latestComment, category, err } = action;

  switch (type) {
    case 'SHOW_CLIPS_MODAL':
      return {
        ...state,
        showClipsModal: true
      };
    case 'HIDE_CLIPS_MODAL':
      return {
        ...state,
        showClipsModal: false
      };
    case 'CREATE_TAGS':
      return {
        ...state,
        loadingTags: true,
        err: null
      };
    case 'CREATE_TAGS_SUCCESS':
      return {
        ...state,
        loadingTags: false,
        err: null
      };
    case 'CREATE_TAGS_FAILED':
      return {
        ...state,
        loadingTags: false,
        err
      };
    case 'CREATE_CLIPS_METADATA':
      return {
        ...state,
        loadingMetadata: true,
        err: null
      };
    case 'CREATE_CLIPS_METADATA_SUCCESS':
      return {
        ...state,
        loadingMetadata: false,
        err: null
      };
    case 'CREATE_CLIPS_METADATA_FAILED':
      return {
        ...state,
        loadingMetadata: false,
        err
      };
    case 'GET_CLIPS_PLAYLIST':
      return {
        ...state,
        clipsData: page === 1 ? [] : state.clipsData,
        loadingClips: true,
        err: null
      };

    case 'SET_CLIPS_PLAYLIST_CATEGORIES':
      for (let i = 0; i < payload.length; i++) {
        const key = payload[i].replace(/\s+/g, ' ');
        state.clipsCategory[key] = [];
      }
      return {
        ...state,
        clipsCategoryDataCompleted: true,
        clipsCategoryData: payload
      };
    case 'GET_CLIPS_PLAYLIST_SUCCESS':
      const categoryData = Object.keys(state.clipsCategory).filter(
        (key) => key.toLowerCase() === category.toLowerCase()
      );
      const clipsCategoryData =
        state.clipsCategory?.[`${categoryData}`]?.data || [];
      return {
        ...state,
        err: null,
        loadingClips: false,
        clipsCategory: {
          ...state.clipsCategory,
          [`${categoryData}`]: {
            data:
              page === 1
                ? payload.data
                : [...clipsCategoryData, ...payload.data],
            meta: payload.meta
          }
        }
      };
    case 'GET_CLIPS_PLAYLIST_SUCCESS_FROM_STATE':
      return {
        ...state,
        clipsCategory: payload.clipsCategory,
        clipsMeta: payload.clipsMeta,
        loadingClips: false,
        err: null
      };
    case 'GET_CLIPS_PLAYLIST_FAILED':
      return {
        ...state,
        loadingClips: false,
        err
      };
    case 'GET_CLIPS_DETAIL':
      return {
        ...state,
        loadingClipsDetail: true,
        err: null
      };
    case 'GET_CLIPS_DETAIL_SUCCESS':
      return {
        ...state,
        clipsDetail: payload,
        loadingClipsDetail: false,
        err: null
      };
    case 'GET_CLIPS_DETAIL_FAILED':
      return {
        ...state,
        loadingClipsDetail: false,
        err
      };
    case 'GET_RECOMMENDED_CLIPS':
      return {
        ...state,
        loadingRecommendedClips: true,
        errorRecommendedClips: null
      };
    case 'GET_RECOMMENDED_CLIPS_SUCCESS':
      return {
        ...state,
        loadingRecommendedClips: false,
        recommendedClips: payload,
        errorRecommendedClips: null
      };
    case 'GET_RECOMMENDED_CLIPS_FAILED':
      return {
        ...state,
        loadingRecommendedClips: false,
        errorRecommendedClips: err
      };
    case 'RESET_CLIPS_DETAIL':
      return {
        ...state,
        clipsDetail: null
      };
    case 'ADD_CLIPS_VIEW':
      return {
        ...state,
        clipsViewError: null
      };
    case 'ADD_CLIPS_VIEW_SUCCESS':
      return {
        ...state,
        clipsViewError: null
      };
    case 'ADD_CLIPS_VIEW_FAILED':
      return {
        ...state,
        clipsViewError: err
      };
    case 'GET_CLIPS_COMMENTS':
      return {
        ...state,
        loadingClipsComments: true,
        clipsCommentError: null
      };
    case 'GET_CLIPS_COMMENTS_SUCCESS':
      const newComments =
        latestComment && state.clipsComments && state.clipsComments.length
          ? [...state.clipsComments, ...payload.data.comments]
          : payload.data.comments;

      const newMeta = latestComment
        ? state.clipsCommentsMeta
        : payload.data.meta;

      return {
        ...state,
        clipsComments: newComments,
        clipsCommentsMeta: newMeta,
        loadingClipsComments: false,
        clipsCommentError: null
      };
    case 'GET_CLIPS_COMMENTS_FAILED':
      return {
        ...state,
        loadingClipsComments: false,
        clipsCommentError: err
      };
    case 'GET_CLIPS_LIKES_DETAIL':
      return {
        ...state,
        likesDetailsLoading: true,
        likesDetailError: null
      };
    case 'GET_CLIPS_LIKES_DETAIL_SUCCESS':
      return {
        ...state,
        clipsLikesMeta: payload,
        likesDetailsLoading: false,
        likesDetailError: null
      };
    case 'GET_CLIPS_LIKES_DETAIL_FAIL':
      return {
        ...state,
        likesDetailsLoading: false,
        likesDetailError: err
      };
    case 'CHANGE_CLIPS_PROFILE_FOLLOW':
      const newClipsDetail = state.clipsDetail;

      if (newClipsDetail?.profileData?.relation) {
        newClipsDetail.profileData.relation.isFollowing = payload;
      }

      return {
        ...state,
        clipsDetail: newClipsDetail
      };
    default:
      return state;
  }
}
