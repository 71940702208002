import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { errorCode } from '../../constants';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { generateErrorDetail } from '../../utils';
import { callHeartbeat } from './heartbeat';
import { showToastNotif } from './notification';
import { getPublicProfile } from './publicProfile';

export const getLiveGame =
  ({ campaignId } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const { token, user } = state.auth;

    dispatch({
      type: 'GET_AVAILABLE_LIVE_GAME'
    });

    try {
      const res = await axios.get(
        `${BASE_URL}/catalog/v1.0/shows/getLiveGame`,
        {
          headers: {
            Authorization: token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'GET_AVAILABLE_LIVE_GAME_SUCCESS',
        payload: res.data.data
      });
      dispatch(getStreamUrlKey());
    } catch (err) {
      const errorData = generateErrorDetail(err);

      if (errorData.code === errorCode.SHOW_NOT_FOUND && user?.profile?.id) {
        await dispatch(initializeNewLive({ campaignId }));
        return;
      }

      dispatch({
        type: 'GET_AVAILABLE_LIVE_GAME_FAILED',
        err: errorData
      });
    }
  };

export const initializeNewLive =
  ({ campaignId } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;
    const { liveGameInfo } = state.liveGame;

    try {
      const profile = await dispatch(getPublicProfile(user?.profile?.id)); // get own public profile as object to prevent overlap
      const latestShow = profile?.latestShow;

      if (latestShow && !liveGameInfo) {
        await dispatch(
          createGameStream({
            name: latestShow.name,
            description: latestShow.description,
            category: latestShow.showCategory,
            thumbnail: latestShow.thumbnail,
            campaignId
          })
        );
        return;
      }

      dispatch({
        type: 'SET_LIVE_GAME_AVAILABLE'
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);

      dispatch({
        type: 'GET_AVAILABLE_LIVE_GAME_FAILED',
        err: errorData
      });
    }
  };

export const createGameStream = (data) => async (dispatch, getState) => {
  const state = getState();
  const { token, user } = state.auth;
  const formData = new FormData();

  data?.campaignId && formData.append('ugcCampaignId', data?.campaignId);
  dispatch({ type: 'CREATE_LIVE_GAME' });
  try {
    if (data.thumbnail instanceof File) {
      // new livegame has thumbnail data as file
      formData.append('thumbnail', data.thumbnail);
    } else {
      // Fetch the image data from the URL and create a blob
      const response = await fetch(data.thumbnail);
      const blob = await response.blob();
      // Create a file object from the blob
      const file = new File([blob], 'thumbnail.jpg', { type: blob.type });
      formData.append('thumbnail', file);
    }

    formData.append('name', data.name);
    formData.append('description', data.description);
    await formData.append('categoryId', data.category.id);
    // formData.append('gameTitle', data.gameTitle)
    formData.append('appId', JWT_APP_ID);
    formData.append('type', 'GAME');
    formData.append('isBroadcasting', false);
    const res = await axios.post(`${BASE_URL}/catalog/v1.0/shows`, formData, {
      headers: {
        Authorization: token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });

    const payload = res.data.data;
    payload.show.showCategory = data.category;

    dispatch({
      type: 'CREATE_LIVE_GAME_SUCCESS',
      payload
    });

    const paramsEvent = {
      showID: payload.show.id,
      showName: payload.show.name,
      showCategoryID: payload.show.showCategory.id,
      showCategoryName: payload.show.showCategory.name,
      showType: payload.show.type
    };
    analytic(analyticTypes.event, analyticEvents.LIVEGAME.CREATE_STREAM, {
      params: paramsEvent,
      user
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'CREATE_LIVE_GAME_FAILED',
      err: errorData
    });
    throw errorData;
  }
};

export const editGameStreamInfo = (id, data) => async (dispatch, getState) => {
  const state = getState();
  const { token, user } = state.auth;
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('description', data.description);
  formData.append('categoryId', data.category.id);
  // formData.append('gameTitle', data.gameTitle)
  if (data.thumbnail instanceof File) {
    formData.append('thumbnail', data.thumbnail);
  }

  dispatch({ type: 'UPDATE_LIVE_GAME' });
  try {
    const res = await axios.put(
      `${BASE_URL}/catalog/v1.0/show/${id}`,
      formData,
      {
        headers: {
          Authorization: token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      }
    );
    const payload = res.data.data.show;

    dispatch({
      type: 'UPDATE_LIVE_GAME_INFO_SUCCESS',
      payload
    });

    const paramsEvent = {
      showID: payload.id,
      showName: payload.name,
      showCategoryID: payload.showCategory.id,
      showCategoryName: payload.showCategory.name,
      showType: payload.type
    };
    analytic(analyticTypes.event, analyticEvents.LIVEGAME.EDIT_STREAM, {
      params: paramsEvent,
      user
    });

    dispatch(showToastNotif('success', 'Success update stream info'));
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'UPDATE_LIVE_GAME_INFO_FAILED',
      err: errorData
    });
    dispatch(showToastNotif('success', 'Failed to update stream info'));
  }
};

export const getStreamUrlKey = () => async (dispatch, getState) => {
  const state = getState();
  const token = state.auth.token;

  dispatch({
    type: 'GET_STREAM_URL_KEY'
  });
  try {
    const res = await axios.get(`${BASE_URL}/catalog/v1.0/shows/getRtmp`, {
      headers: {
        Authorization: token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });

    dispatch({
      type: 'GET_STREAM_URL_KEY_SUCCESS',
      payload: res.data.data
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_STREAM_URL_KEY_FAILED',
      err: errorData
    });
  }
};

export const resetStreamKey = () => async (dispatch, getState) => {
  const state = getState();
  const token = state.auth.token;

  dispatch({
    type: 'RESET_STREAM_KEY'
  });
  try {
    const res = await axios.post(
      `${BASE_URL}/catalog/v1.0/shows/resetRtmp`,
      null,
      {
        headers: {
          Authorization: token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      }
    );

    dispatch({
      type: 'RESET_STREAM_KEY_SUCCESS',
      payload: res.data.data
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'RESET_STREAM_KEY_FAILED',
      err: errorData
    });
  }
};

export const broadcasting = () => async (dispatch, getState) => {
  const state = getState();
  const { token, user } = state.auth;
  const { liveGameInfo, isLiveGameBroadcasting } = state.liveGame;
  const isBroadcasting = liveGameInfo && isLiveGameBroadcasting;

  dispatch({
    type: 'BROADCASTING'
  });
  try {
    if (isBroadcasting) {
      await dispatch(stopLiveGame());
    } else {
      await axios.post(
        `${BASE_URL}/catalog/v1.0/show/${liveGameInfo.id}/startBroadcasting`,
        null,
        {
          headers: {
            Authorization: token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'START_LIVE_GAME_SUCCESS'
      });
      await dispatch(setLiveGameHeartbeat());
      dispatch(showToastNotif('success', 'Start Streaming'));
      if (liveGameInfo) {
        const paramsEvent = {
          showID: liveGameInfo.id,
          showName: liveGameInfo.name,
          showCategoryID: liveGameInfo.showCategory.id,
          showCategoryName: liveGameInfo.showCategory.name,
          showType: liveGameInfo.type
        };
        analytic(analyticTypes.event, analyticEvents.LIVEGAME.START_STREAM, {
          params: paramsEvent,
          user
        });
      }
    }
  } catch (err) {
    dispatch(showToastNotif('error', 'Connection Error'));
  }
};

export const stopLiveGame = () => async (dispatch, getState) => {
  const state = getState();
  const { token, user } = state.auth;
  const { liveGameInfo, isLiveGameBroadcasting } = state.liveGame;
  const liveId = liveGameInfo && liveGameInfo.id;

  if (!isLiveGameBroadcasting) return;

  dispatch({
    type: 'STOP_LIVE_GAME'
  });
  try {
    await axios.post(`${BASE_URL}/catalog/v1.0/show/${liveId}/stop`, null, {
      headers: {
        Authorization: token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });

    dispatch(clearLiveGameHeartbeat());
    dispatch({
      type: 'STOP_LIVE_GAME_SUCCESS'
    });
    if (liveGameInfo) {
      const paramsEvent = {
        showID: liveGameInfo.id,
        showName: liveGameInfo.name,
        showCategoryID: liveGameInfo.showCategory.id,
        showCategoryName: liveGameInfo.showCategory.name,
        showType: liveGameInfo.type
      };
      analytic(analyticTypes.event, analyticEvents.LIVEGAME.END_STREAM, {
        params: paramsEvent,
        user
      });
    }
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'STOP_LIVE_GAME_FAILED',
      err: errorData
    });
  }
};

export const handleCheckEditButtonLiveShow =
  (id) => async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;

    try {
      const response = await axios.get(
        `${BASE_URL}/catalog/v1.0/videos/existed?showId=${id}`,
        {
          headers: {
            Authorization: token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );
      return response?.data?.data;
    } catch (err) {
      throw new Error(err);
    }
  };

export const setLiveGameHeartbeat =
  ({ retry } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const { liveGameInfo, isLiveGameBroadcasting, liveGameHeartbeat } =
      state.liveGame;

    if (!liveGameInfo?.id || !isLiveGameBroadcasting) return;
    if (liveGameHeartbeat) dispatch(clearLiveGameHeartbeat());

    try {
      await dispatch(callHeartbeat(liveGameInfo?.id));

      const callHeartBeat = setInterval(() => {
        dispatch(callHeartbeat(liveGameInfo?.id));
      }, 60000);

      dispatch({
        type: 'SET_LIVE_STREAM_HEARTBEAT',
        payload: callHeartBeat
      });
    } catch (err) {
      dispatch({
        type: 'SET_LIVE_STREAM_HEARTBEAT_FAILED'
      });

      if (retry <= 3) {
        setTimeout(
          () => dispatch(setLiveGameHeartbeat({ retry: (retry || 0) + 1 })),
          1000
        );
      }
    }
  };

export const clearLiveGameHeartbeat = () => async (dispatch, getState) => {
  const state = getState();
  const { liveGameHeartbeat } = state.liveGame;

  if (liveGameHeartbeat) {
    clearInterval(liveGameHeartbeat);
    dispatch({
      type: 'CLEAR_LIVE_STREAM_HEARTBEAT',
      payload: liveGameHeartbeat
    });
  }
};
