import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ToastNotification from '../Notification/toast';
import PopModalDialog from '../PopModalDialog/PopModalDialog';
import Button from '../Button/Button';

import styles from './Header.module.css';
import logo from '../../assets/logo.svg';
import icMoviesMenu from '../../assets/ic-movie.svg';
import icSeriesMenu from '../../assets/ic-series.svg';
import icAnimationMenu from '../../assets/ic-animation.svg';
import icLiveTVMenu from '../../assets/ic-live-tv.svg';
import icCrown from '../../assets/ic-crown.svg';
import icSideMenu from '../../assets/ic-side-menu.svg';
import icClose from '../../assets/ic-close.svg';
import icArrowBack from '../../assets/ic-arrow-back.svg';
import AnimatedModal from '../Modal/AnimatedModal';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';
import LoginDialog from '../LoginDialog/LoginDialog';
import { authPopup } from '../../redux/actions/auth';

const pageOrderCondition = ['movies', 'series', 'animation'];
const sortPage = (page1, page2) => {
  for (let i = 0; i < pageOrderCondition.length; i++) {
    const item = pageOrderCondition[i];
    if (page1 === item) return -1;
    if (page2 === item) return 1;
  }
};

const subMenuIcon = [icMoviesMenu, icSeriesMenu, icAnimationMenu, icCrown];

const MobileHeader = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageData = useSelector((state) => state.page.data);
  const { authPopupStatus } = useSelector((state) => state.auth);
  const headerRef = useRef();
  const mainMenu = useMemo(
    () => [
      {
        name: 'Home',
        path: `/mobile/${params?.partner}`,
        type: 'menu'
      },
      {
        type: 'submenu'
      }
    ],
    [params]
  );

  const subMenu = useMemo(() => {
    const subMenuList = pageData
      ?.filter((item) => item.friendlyId !== 'home')
      .sort((firstPage, secondPage) => {
        const { friendlyId: firstId } = firstPage;
        const { friendlyId: secondId } = secondPage;
        const sortValue = sortPage(firstId, secondId);
        return sortValue || 0;
      })
      .map((item, i) => {
        const name =
          item.friendlyId.toLowerCase() === 'allowed-content'
            ? 'Exclusive'
            : item.titleLocalized;
        const friendlyId = item.friendlyId.toLowerCase();

        return {
          name,
          path: `/mobile/${params?.partner}/${friendlyId}`,
          icon: subMenuIcon[i]
        };
      });

    subMenuList &&
      subMenuList.push({
        name: 'Live TV',
        path: `/mobile/${params?.partner}/live-tv`,
        icon: icLiveTVMenu
      });

    return subMenuList;
  }, [pageData, params]);
  const newMainMenuData = useMemo(() => {
    if (subMenu && subMenu.length) {
      return mainMenu.map((item) => {
        if (item.type === 'submenu') {
          item.subMenuItem = subMenu;
        }
        return item;
      });
    }
  }, [subMenu, mainMenu]);
  const activeSubMenu = useMemo(() => {
    if (subMenu && subMenu.length) {
      const active = subMenu.find((item) => item.path === location?.pathname);

      return active || subMenu[0];
    }
  }, [subMenu, location?.pathname]);

  const [showSideMenu, toggleSideMenu] = useState(false);
  const [selectedSubMenu, setSelectedSubMenu] = useState(activeSubMenu);

  useEffect(() => {
    setSelectedSubMenu(activeSubMenu || selectedSubMenu);
  }, [activeSubMenu, selectedSubMenu]);

  return (
    <>
      <div className={styles['headerSpace']}>
        <div
          className={styles['header']}
          ref={headerRef}
        >
          <ToastNotification />
          {/* <SideMenu /> */}
          <img
            className={`${styles['side-menu-toggle']} show-mobile`}
            src={showSideMenu ? icClose : icSideMenu}
            alt='side-menu-toggle'
            onClick={() => toggleSideMenu(!showSideMenu)}
          />
          <Link to={`/mobile/${params?.partner}`}>
            <img
              src={logo}
              alt='logo'
            />
          </Link>
          <ul className={`${styles['menu']} hide-mobile`}>
            {newMainMenuData?.map((item) => {
              const selected = location?.pathname === item.path;

              if (
                item.type === 'submenu' &&
                item.subMenuItem.length &&
                activeSubMenu
              ) {
                const selectedPath = location?.pathname === activeSubMenu.path;
                return (
                  <li
                    key={activeSubMenu.name}
                    className={selectedPath ? styles['selected'] : ''}
                  >
                    <PopModalDialog
                      content={
                        <div className={styles['sub-menu-wrapper']}>
                          {item.subMenuItem.map((subMenuItem) => {
                            return (
                              <Button
                                key={subMenuItem.name}
                                icon={subMenuItem.icon}
                                type='text'
                                style={{
                                  margin: '0 10px',
                                  fontSize: 14
                                }}
                                onClick={() => navigate(subMenuItem.path)}
                              >
                                {subMenuItem.name}
                              </Button>
                            );
                          })}
                        </div>
                      }
                      action='hover'
                      modalStyle={{
                        borderRadius: 50
                      }}
                      customYBase={headerRef.current}
                    >
                      <span>
                        {activeSubMenu.name}
                        <img
                          src={icArrowBack}
                          alt='drop-down'
                          data-dropdown
                        />
                      </span>
                    </PopModalDialog>
                  </li>
                );
              }

              return (
                <li
                  key={item.name}
                  className={selected ? styles['selected'] : ''}
                >
                  <Link to={item.path}>{item.name}</Link>
                </li>
              );
            })}
          </ul>

          {showSideMenu && (
            <ul className={`${styles['menu-mobile']}`}>
              {[...newMainMenuData, ...subMenu]?.map((item) => {
                const selected = location?.pathname === item.path;

                if (!item.name && !item.path) return null;

                return (
                  <li
                    key={item.name}
                    className={selected ? styles['selected'] : ''}
                  >
                    <Link to={item.path}>{item.name}</Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>

      <AnimatedModal
        duration={10}
        open={authPopupStatus}
        opacity={0.5}
      >
        <GeneralNotifModal open={authPopupStatus}>
          <LoginDialog onClose={() => dispatch(authPopup.close())} />
        </GeneralNotifModal>
      </AnimatedModal>
    </>
  );
};

export default MobileHeader;
