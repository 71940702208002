import { appSettings } from '../../config/app-settings';

const initialState = {
  settings: appSettings,
  captionSettings: {
    fontSize: '100%',
    fontColor: 'White',
    fontEdges: 'Shadow',
    bgTransparancy: '0%'
  },
  loading: false,
  err: null
};

export default function appSettingsReducer(state = initialState, action) {
  const { type, payload, err } = action;

  switch (type) {
    case 'GET_APP_SETTINGS':
      return {
        ...state,
        loading: true,
        err: null
      };
    case 'GET_APP_SETTINGS_SUCCESS':
      return {
        ...state,
        settings: { ...state.settings, ...payload },
        loading: false,
        err: null
      };
    case 'GET_APP_SETTINGS_FAILED':
      return {
        ...state,
        settings: {
          ...state.settings
        },
        loading: false,
        err
      };
    case 'SET_CAPTION_SETTINGS':
      return {
        ...state,
        captionSettings: {
          ...state.captionSettings,
          ...payload,
          loading: false,
          err
        }
      };
    case 'RESET_CAPTION_SETTINGS':
      return {
        ...state,
        captionSettings: {
          ...initialState.captionSettings,
          loading: false,
          err
        }
      };
    default:
      return state;
  }
}
