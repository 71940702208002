import React from 'react';
import { Button } from '..';
import styles from './StreamSetupModal.module.css';

import streamerImg from '../../assets/streamer.svg';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';

const StreamTipsModal = ({ onCloseModal, open }) => {
  return (
    <GeneralNotifModal
      open={open}
      contentClass={styles['modalWrapper']}
    >
      <div className={styles['modalBox']}>
        <div className={styles['modalHeader']}>
          <h3>Liveshow Tips</h3>
        </div>
        <div className={styles['modalBanner']}>
          <img
            src={streamerImg}
            alt='streamer'
          />
        </div>
        <div className={styles['modalContent']}>
          <p>
            Some tips that can be done by streamers to minimize issues during
            liveshows:
          </p>
          <ol>
            <li>
              <p>Make sure to re-login first before start liveshows</p>
            </li>
            <li>
              <p>
                Only open 1 Genflix web tab (streamer dashboard) in the browser
              </p>
            </li>
            <li>
              <p>
                Make sure not to refresh or close the "streamer dashboard" tab
              </p>
            </li>
            <li>
              <p>Make sure not to edit stream info after start streaming</p>
            </li>
          </ol>
          <div className={styles['center']}>
            <Button
              width='120px'
              onClick={onCloseModal}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </GeneralNotifModal>
  );
};

export default StreamTipsModal;
