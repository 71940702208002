const initialState = {
  data: [],
  dataHighlight: [],
  dataDetail: null,
  meta: [],
  error: null,
  errorHighlight: null,
  errorDetail: null,
  loading: false,
  loadingHighlight: false,
  loadingDetail: false,
  dataComplete: false,
  unread: 0,
  openDialog: false
};

export default function inAppNotificationReducer(state = initialState, action) {
  const { type, payload, page } = action;
  switch (type) {
    case 'TOGGLE_IN_APP_NOTIFICATION_DIALOG':
      return {
        ...state,
        openDialog: !state.openDialog
      };
    case 'CLOSE_IN_APP_NOTIFICATION_DIALOG':
      return {
        ...state,
        openDialog: false
      };
    case 'GET_IN_APP_NOTIFICATION':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'GET_IN_APP_NOTIFICATION_DETAIL':
      return {
        ...state,
        loadingDetail: true,
        error: null
      };

    case 'GET_IN_APP_NOTIFICATION_SUCCESS':
      return {
        ...state,
        loading: false,
        data: page === 1 ? payload.data : [...state.data, ...payload.data],
        meta: payload.meta,
        page
      };
    case 'GET_IN_APP_NOTIFICATION_HIGHLIGHT_SUCCESS':
      return {
        ...state,
        loadingHighlight: false,
        loading: false,
        dataHighlight: payload.data
      };
    case 'GET_IN_APP_DETAIL_NOTIFICATION_SUCCESS':
      return {
        ...state,
        loadingDetail: false,
        dataDetail: payload
      };

    case 'GET_IN_APP_NOTIFICATION_COMPLETE':
      return {
        ...state,
        dataComplete: true
      };
    case 'GET_IN_APP_UNREAD_NOTIFICATION_SUCCESS':
      return {
        ...state,
        unread: payload.unread
      };
    case 'RESET_IN_APP_NOTIFICATION':
      return {
        ...state,
        unread: 0
      };
    case 'GET_IN_APP_DETAIL_NOTIFICATION_FAILED':
      return {
        ...state,
        loading: false,
        errorDetail: payload ?? true
      };
    case 'GET_IN_APP_NOTIFICATION_HIGHLIGHT_FAILED':
      return {
        ...state,
        loading: false,
        errorHighlight: payload ?? true
      };
    case 'GET_IN_APP_NOTIFICATION_FAILED':
      return {
        ...state,
        loading: false,
        error: payload ?? true
      };

    case 'GET_DETAIL_IN_APP_NOTIFICATION':
      return {
        ...state,
        loading: true,
        error: null
      };

    case 'GET_DETAIL_IN_APP_NOTIFICATION_SUCCESS':
      const data = payload?.data || [];

      return {
        ...state,
        loading: false,
        data: page === 1 ? data : [...state.data, ...data],
        meta: payload.meta
      };

    case 'GET_DETAIL_IN_APP_NOTIFICATION_COMPLETE':
      return {
        ...state,
        dataComplete: true
      };

    case 'GET_DETAIL_IN_APP_NOTIFICATION_FAILED':
      return {
        ...state,
        loading: false,
        error: payload ?? true
      };
    default:
      return state;
  }
}
