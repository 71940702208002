import React, { Suspense, useEffect, useState } from 'react';
import {
  Route,
  Navigate,
  Routes,
  useParams,
  useLocation
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Loading,
  MobileHeader,
  MobileMediaPageView,
  Spinner
} from '../components';
import {
  AboutUs,
  Terms,
  TermsCoins,
  Faqs,
  ContactUs,
  PrivacyPolicy,
  UserLicenseAgreement,
  Detail,
  NotFound,
  Playlist,
  LiveTv,
  Stream,
  TermsCashout,
  TermsUgc,
  MobileAppWrapper,
  History,
  PartnerPlayer,
  Subscription,
  PlayPage,
  FormPage
} from '../pages';
// import useUserDetail from '../hooks/useUserDetail';
import {
  ContentPage,
  LiveTvPage,
  PageNotFound,
  RedeemVoucher
} from '../pages/ioh';
import HistoryList from '../pages/ioh/HistoryList';
import { parseQuery } from '../utils';
import { loginIOH } from '../redux/actions/auth';
import { getAppSettings } from '../redux/actions/settings';
import { partnerList, surgePartnerList } from '../config/partners';
import VoucherRedeem from '../pages/voucher';
import ToastNotification from '../components/Notification/toast';

const routes = [
  {
    path: '/',
    element: <MobileMediaPageView />,
    mainPage: true
  },
  {
    path: '/playlists/:playlistId',
    element: <Playlist />
  },
  {
    path: '/live-tv',
    element: {
      default: <LiveTv />,
      ioh: <LiveTvPage />
    }
  },
  {
    path: '/live-tv/:channelID?',
    element: {
      default: <LiveTv />,
      ioh: <LiveTvPage />
    }
  },
  {
    path: '/stream/:channelID?',
    element: <Stream />
  },
  {
    path: '/histories',
    element: {
      default: <History />,
      ioh: <HistoryList />
    }
  },
  {
    path: '/redeem-voucher',
    element: {
      default: <VoucherRedeem />,
      ioh: <RedeemVoucher />
    }
  },
  {
    path: '/subscription',
    element: <Subscription />
  },
  {
    path: '/play',
    element: <PlayPage />
  },
  {
    path: '/not-found',
    element: {
      default: <NotFound />,
      ioh: <PageNotFound />
    }
  },
  {
    path: '/:programType/:mediaId',
    element: {
      default: <Detail />,
      ioh: <ContentPage />
    }
  },
  {
    path: '/:pageName',
    element: <MobileMediaPageView />
  }
];

export const PartnerRoutes = (props) => {
  const params = useParams();
  const location = useLocation();
  const partner = params?.partner;
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  // const { checkPartnerUser } = useUserDetail();
  // const isPartnerUser = checkPartnerUser();
  const { jwt_token, jwt_client, msisdn } = parseQuery(location?.search);
  const [checkStatus, setCheckStatus] = useState(false);
  const [authError, setAuthError] = useState(false);
  const iohPartner = partner === 'ioh';
  const oxygenPartner = partner === 'oxygen';
  const hideHeader = iohPartner || oxygenPartner;

  useEffect(() => {
    if (partner === 'ioh') dispatch(getAppSettings(['myim3.package.url']));
    if (jwt_token && jwt_client) {
      const auth = async () => {
        try {
          await dispatch(loginIOH(jwt_token, jwt_client, msisdn));
          setCheckStatus(true);
        } catch (err) {
          setAuthError(true);
        }
      };

      auth();
    } else {
      setCheckStatus(true);
    }
  }, [dispatch, partner, jwt_token, jwt_client, msisdn]);

  if (partner === 'ioh' && !checkStatus) {
    const checkAuth = authError ? (
      <>
        <span style={{ color: '#f00', fontSize: 18, fontWeight: 600 }}>
          Authentication Failed.
        </span>
        <span style={{ color: '#000' }}>
          Please contact our customer service for more information.
        </span>
      </>
    ) : (
      <>
        <Spinner height='min-content' />
        <span style={{ color: '#000' }}>Checking authentication...</span>
      </>
    );

    return (
      <div
        style={{
          height: '100vh',
          background: partner === 'ioh' ? '#fff' : '',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        {partner === 'ioh' ? checkAuth : <Loading />}
      </div>
    );
  }

  return (
    <Suspense fallback={<Loading height='100vh' />}>
      <MobileAppWrapper
        iohPartner={iohPartner}
        oxygenPartner={oxygenPartner}
      >
        {!hideHeader && <MobileHeader {...props} />}
        <ToastNotification />
        <Routes>
          {routes?.map(({ path, element }) => {
            return (
              <Route
                key={path}
                path={path}
                Component={() => {
                  if (partner !== 'ioh') {
                    if (!partnerList.includes(partner))
                      return <Navigate to='/mobile/not-found' />;
                    if (!token && surgePartnerList.includes(partner)) {
                      return (
                        <p
                          style={{
                            height: '70vh',
                            color: 'red',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          Authentication failed. Please try again.
                        </p>
                      );
                    }
                  }

                  return element?.default
                    ? element[partner] || element.default
                    : element;
                }}
              />
            );
          })}
          <Route
            key='/not-found'
            path='*'
            element={<Navigate to='/mobile/not-found' />}
          />
        </Routes>
      </MobileAppWrapper>
    </Suspense>
  );
};

export default function MobileRoutes() {
  return (
    <Routes>
      <Route
        key='/contact-us'
        path='/contact-us'
        element={<ContactUs showLanguageOption={false} />}
      />
      <Route
        key='/form'
        path='/form'
        element={<FormPage mobile />}
      />
      <Route
        key='/faqs'
        path='/faqs'
        element={<Faqs showLanguageOption={false} />}
      />
      <Route
        key='/privacy-policy'
        path='/privacy-policy'
        Component={(props) => (
          <PrivacyPolicy
            showLanguageOption={false}
            {...props}
          />
        )}
      />
      <Route
        key='/about-us'
        path='/about-us'
        element={<AboutUs showLanguageOption={false} />}
      />
      <Route
        key='/terms'
        path='/terms'
        element={<Terms showLanguageOption={false} />}
      />
      <Route
        key='/terms-coins'
        path='/terms-coins'
        element={<TermsCoins showLanguageOption={false} />}
      />
      <Route
        key='/end-user-license-agreement'
        path='/end-user-license-agreement'
        element={<UserLicenseAgreement showLanguageOption={false} />}
      />
      <Route
        key='/terms-cashout'
        path='/terms-cashout'
        Component={(props) => (
          <TermsCashout
            showLanguageOption={false}
            {...props}
          />
        )}
      />
      <Route
        key='/terms-ugc'
        path='/terms-ugc'
        Component={(props) => (
          <TermsUgc
            showLanguageOption={false}
            {...props}
          />
        )}
      />
      <Route
        key='/partner-player/:mediaId'
        path='/partner-player/:mediaId'
        element={<PartnerPlayer />}
      />
      <Route
        key='/not-found'
        path='/not-found'
        element={<NotFound />}
      />
      <Route
        key='/partner/*'
        path='/:partner/*'
        Component={(props) => <PartnerRoutes {...props} />}
      />
      <Route
        path='*'
        element={<Navigate to='/mobile/terms' />}
      />
      <Route
        path='*'
        element={<Navigate to='/mobile/terms-coins' />}
      />
      <Route
        path='/*'
        element={<Navigate to='/mobile/not-found' />}
      />
    </Routes>
  );
}
