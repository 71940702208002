import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';
import { settingKeys } from '../../hooks/useSettings';

// eslint-disable-next-line import/prefer-default-export
export const getPackages =
  ({ paymentType } = {}) =>
  async (dispatch, getState) => {
    const { auth } = getState();

    dispatch({
      type: 'GET_PACKAGES'
    });

    try {
      const res = await axios.get(`${BASE_URL}/subscription/v1.1/packages`, {
        headers: {
          Authorization: auth.token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        },
        params: {
          ...(paymentType && { paymentType })
        }
      });

      const payload = res.data.data;

      dispatch({
        type: 'GET_PACKAGES_SUCCESS',
        payload
      });

      return payload;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_PACKAGES_FAILED',
        err: errorData
      });
    }
  };

export const getPaymentGatewayPackages = () => async (dispatch, getState) => {
  const { auth, packageGroups } = getState();
  const memberId = auth.user && auth.user.account.id;
  const { paymentGatewayPackages } = packageGroups;

  dispatch({
    type: 'GET_PAYMENT_GATEWAY_PACKAGES'
  });

  try {
    if (paymentGatewayPackages && !paymentGatewayPackages.data) {
      const res = await axios.get(
        `${BASE_URL}/subscription/v1.1/paymentGateways/getSubscriberPlan?accountId=${memberId}`,
        {
          headers: {
            Authorization: auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );
      if (!packageGroups.packages) {
        packageGroups.packages = await dispatch(
          getPackages({ paymentType: 'partner-payment-gateway' })
        );
      }

      // Filter out the items with packageGroup name 'Genflix Premium'
      const filteredRes = packageGroups.packages.filter(
        (item) => item.packageGroup.name !== 'Genflix Premium'
      );
      // Match 'name' in 'resPackage' to 'res' and append packageGroup name into res
      res.data.data.data.forEach((item2) => {
        const matchedItem = filteredRes.find(
          (item) => item.name === item2.title
        );
        if (matchedItem) {
          item2.packageGroupName = matchedItem.packageGroup.name;
        }
      });

      dispatch({
        type: 'GET_PAYMENT_GATEWAY_PACKAGES_SUCCESS',
        payload: res.data.data
      });
    } else {
      dispatch({
        type: 'GET_PAYMENT_GATEWAY_PACKAGES_SUCCESS_FROM_STATE',
        payload: paymentGatewayPackages
      });
    }
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_PAYMENT_GATEWAY_PACKAGES_ERROR',
      err: errorData
    });
  }
};

export const getRecurringPaymentPackages = () => async (dispatch, getState) => {
  const { appSettings } = getState();
  const { settings } = appSettings;

  dispatch({
    type: 'GET_RECURRING_PACKAGES'
  });

  try {
    const res = await dispatch(getPackages({ paymentType: 'play' }));
    const basePackage = await dispatch(getPackages());
    const payload = res
      ?.filter((item) => {
        const recurringPackIds = settings[settingKeys.recurringPackKey]
          .split(',')
          .map((id) => id.trim());
        return recurringPackIds.includes(item.packageGroup.id);
      })
      ?.map((item) => {
        const findSameDuration = basePackage.filter(
          (packageItem) =>
            packageItem.duration === item.duration &&
            packageItem.durationType === item.durationType &&
            packageItem.payments.play
        );
        findSameDuration.sort(
          (a, b) => parseInt(b.price, 10) - parseInt(a.price, 10)
        );
        const highestPrice = findSameDuration[0];
        return {
          ...item,
          originalPrice: highestPrice?.price || 0
        };
      });
    dispatch({
      type: 'GET_RECURRING_PACKAGES_SUCCESS',
      payload
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_RECURRING_PACKAGES_ERROR',
      err: errorData
    });
  }
};
