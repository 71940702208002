import React from 'react';
import { useSelector } from 'react-redux';
import { PAYMENT_GATEWAY_URL } from '../../config/URL';
import dablena from '../../service/dableIo';
import styles from './Shop.module.css';
import logo from '../../assets/logo.svg';
import { currencyFormatter, generateDayFormat } from '../../utils';

const PackageItem = ({ data, recurring, onPurchase }) => {
  const user = useSelector((state) => state.auth && state.auth.user);
  const handlePurchase = (id) => {
    // dable.io tracker
    dablena('InitiateCheckout');

    localStorage.setItem('gfRedirectPath', window.location.pathname);
    localStorage.setItem('fromFWT', true);
    window.location.href = `${PAYMENT_GATEWAY_URL}/?member=${user.account.id}&plan=${id}`;
  };

  return (
    <div className={styles['section-buy-package-container']}>
      <h2 className={styles['section-buy-package-header']}>
        {data.name || data.groupName}
      </h2>
      <div
        className={styles['section-buy-package-divider']}
        key={data.name}
      >
        {data.packages.map((item) => {
          return (
            <div
              className={styles['section-buy-package-wrapper']}
              key={item.id}
            >
              <span className={styles['wrapper-name']}>{item.name}</span>
              <div className={styles['box']}>
                <div className={styles['text']}>
                  <img
                    src={logo}
                    alt='logo'
                  />
                  <span
                    className={`${styles['text-price']} ${styles['discount']}`}
                    style={{
                      visibility:
                        recurring && !!item.originalPrice ? 'visible' : 'hidden'
                    }}
                  >
                    {currencyFormatter.format(item.originalPrice)}
                  </span>

                  <span className={styles['text-price']}>
                    {currencyFormatter.format(item.price)}
                  </span>
                  <span className={styles['text-valid']}>
                    Valid for {generateDayFormat(item)}
                  </span>
                  {recurring && (
                    <div className={styles['recurring-desc']}>
                      <span className={styles['text-valid']}>(Recurring)</span>
                      <span className={styles['text-valid']}>
                        {`Billed every ${item.duration} days`}
                      </span>
                    </div>
                  )}
                </div>

                <button
                  type='button'
                  className={styles['button']}
                  onClick={() => {
                    onPurchase ? onPurchase(item) : handlePurchase(item);
                  }}
                >
                  <p className={styles['button-text']}>Purchase</p>
                </button>
                {/* datapackage end */}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PackageItem;
