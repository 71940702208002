import React, { lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
// import { PublicProfile } from '..';
import { Loading } from '../../components';
import { authPopup } from '../../redux/actions/auth';
// import UserProfile from './profile';
const UserProfile = lazy(() => import('./profile'));

const profileTabs = [
  'subscription/*',
  'redeem-voucher',
  'my-list',
  'watch-history',
  'transactions/*',
  'transactions',
  'blocked-accounts',
  'cashout/*',
  'campaign/*',
  '/privacy'
];

export const withStandByLoaderTabs = ['/redeem-voucher'];

const profileTabRoutes = profileTabs.map((item) => {
  const withStandbyLoader = withStandByLoaderTabs.includes(item);

  return {
    path: `/${item}`,
    element: <UserProfile />,
    loggedIn: true,
    ...(withStandbyLoader && { standby: true })
  };
});

const profileRoutes = [
  ...profileTabRoutes,
  // {
  //   path: '/:profileID',
  //   element: <PublicProfile />,
  //   loggedIn: false
  // },
  {
    path: '/',
    element: <UserProfile />,
    loggedIn: true
  }
];

// This StandByLoader component and all related standby loader will show loading and login popup in the required logged in page to hold the user on log in process
const StandByLoader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authPopupStatus } = useSelector((state) => state.auth);
  const [status, setStatus] = useState('initial');

  useEffect(() => {
    dispatch(authPopup.show());
    setStatus('show');
  }, [dispatch]);

  useEffect(() => {
    if (!authPopupStatus && status === 'show') {
      navigate('/');
    }
  }, [navigate, authPopupStatus, status]);

  return <Loading height='70vh' />;
};

const ProfileRoutes = () => {
  const authState = useSelector((state) => state.auth);
  return (
    <Routes>
      {profileRoutes.map(({ path, element: Element, loggedIn, standby }) => {
        const renderStandbyLoader = standby ? (
          <StandByLoader />
        ) : (
          <Navigate to='/' />
        );
        const renderElement =
          loggedIn && !authState.token ? renderStandbyLoader : Element;

        return (
          <Route
            key={path}
            path={path}
            element={renderElement}
          />
        );
      })}
      <Route
        path='/*'
        element={<Navigate to='/profile' />}
      />
    </Routes>
  );
};

export default ProfileRoutes;
