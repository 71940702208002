import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './Comments.module.css';

// import multiUsrIcn from '../../assets/liveshow/ic-multi-user.svg';

const CommentItem = ({
  name,
  comment,
  commentNameColor,
  type,
  gift,
  amount,
  receiver
}) => {
  return (
    <div className={styles['commentItem']}>
      <div>
        <span
          className={styles['commentName']}
          style={{ color: commentNameColor }}
        >
          {name}
        </span>
        {type !== 'gift' && type !== 'bullet' && (
          <span className={styles['commentContent']}>{comment}</span>
        )}

        {type === 'bullet' && (
          <span className={styles['commentContent']}>
            Sent a bullet message
          </span>
        )}

        {type === 'gift' && gift && (
          <span className={styles['commentContent']}>
            Sent a {gift.name}{' '}
            {receiver && (
              <>
                to <span className={styles['giftReceiver']}>{receiver}</span>
              </>
            )}
          </span>
        )}
      </div>

      {type === 'bullet' && (
        <span className={styles['bulletMessage']}>{comment}</span>
      )}
      {type === 'gift' && gift && (
        <div className={styles['giftCommentContainer']}>
          <img
            src={gift.icon}
            alt='gift'
          />{' '}
          <div>
            <p>X</p>
            {amount}
          </div>
        </div>
      )}
    </div>
  );
};

const CommentList = ({ multi }) => {
  const listWrapper = useRef();
  const list = useRef();
  const [overflow, setOverflow] = useState(false);
  const { wsError } = useSelector((state) => state.liveShow);
  const { comments } = useSelector((state) => state.liveShowComments);

  useEffect(() => {
    setOverflow(list.current.offsetHeight > listWrapper.current.offsetHeight);
  }, [listWrapper, list, comments]);

  return (
    <>
      <div
        ref={listWrapper}
        className={`${styles['commentListWrapper']} ${
          overflow && styles['overflow']
        }`}
      >
        <ul
          ref={list}
          className={styles['commentList']}
        >
          {comments &&
            comments.map((com, index) => {
              return (
                <li key={index}>
                  <CommentItem
                    name={com.profile.name}
                    comment={com.data}
                    commentNameColor={com.commentNameColor}
                    gift={com.gift}
                    type={com.type}
                    amount={com.amount}
                    receiver={multi ? com?.receiverProfile?.name : ''}
                  />
                </li>
              );
            })}
        </ul>
        {wsError && <p>Connection lost, reconnecting...</p>}
      </div>
      {/* <div className={styles['userJoin']}>
        <img src={multiUsrIcn} alt='multi-user' />
        <p className={styles['joinMessage']}>
          Jessica Angelica will join Multiroom with Sheila Marissa
        </p>
        <p className={styles['timeout']}>30s</p>
      </div> */}
    </>
  );
};

export default CommentList;
