import React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import ModalContent from './ModalContent';

const AnimatedModal = ({
  duration = 300,
  open,
  children,
  isSmall,
  classNames,
  opacity
}) => {
  return (
    <CSSTransition
      in={open}
      timeout={duration}
      classNames='fade'
      unmountOnExit
    >
      <ModalContent
        isSmall={isSmall}
        classNames={classNames}
        opacity={opacity}
      >
        {children}
      </ModalContent>
    </CSSTransition>
  );
};

export default AnimatedModal;
