import React, { useLayoutEffect, useRef, useState } from 'react';
import StartAddOn from './InputFormComponents/StartAddOn';
import { replacePhoneCountryCode } from '../../utils';

import styles from './InputForm.module.css';

const handleDefaultValue = (value, type) => {
  const isAddonPhoneCode = type === 'phone-code';

  if (isAddonPhoneCode) {
    const val = replacePhoneCountryCode(value);
    return val;
  }

  return value;
};

const InputForm = ({
  label,
  description,
  startAddOn,
  endAddOn,
  required,
  dataError,
  onChange,
  className,
  inputClassName,
  descriptionClassName,
  showInputError = true,
  defaultValue,
  errorClassName,
  disabled,
  ...inputProps
}) => {
  const inputRef = useRef();
  const startAddonRef = useRef();
  const endAddonRef = useRef();
  const [value, setValue] = useState(
    handleDefaultValue(defaultValue, startAddOn?.type) || ''
  );
  const [error, setError] = useState();
  const showError = error || dataError;
  const isAddonPhoneCode = startAddOn?.type === 'phone-code';
  const addonSelectRef = useRef();

  const handleInputOnBlur = (e) => {
    if (required && !e.target.value) setError('Field is required');
  };

  const handleChange = (e) => {
    if (e.target.value && !dataError) setError();
    const selectedAddonValue = addonSelectRef.current?.selected;

    isAddonPhoneCode && setValue(selectedAddonValue + e.target.value);
    onChange && onChange(e);
  };

  useLayoutEffect(() => {
    if (startAddOn) {
      const startAddonEl = startAddonRef.current;
      const startAddonWidth = startAddonEl?.offsetWidth;

      inputRef.current.style.paddingLeft = `${startAddonWidth + 20}px`;
    }
  }, [startAddOn, value]);

  useLayoutEffect(() => {
    if (endAddOn) {
      const endAddonEl = endAddonRef.current;
      const endAddonWidth = endAddonEl?.offsetWidth;

      inputRef.current.style.paddingRight = `${endAddonWidth + 30}px`;
    }
  }, [endAddOn]);

  return (
    <div className={`${styles['inputForm']} ${className}`}>
      <label htmlFor={inputProps.id}>{label}</label>
      <div className={styles['inputWrapper']}>
        {startAddOn && (
          <span
            ref={startAddonRef}
            data-addon='start'
          >
            <StartAddOn
              ref={addonSelectRef}
              startAddOn={startAddOn}
              inputValue={defaultValue}
              onChange={setValue}
            />
          </span>
        )}
        <input
          {...inputProps}
          {...(isAddonPhoneCode && { id: `input-${inputProps.id}` })}
          className={inputClassName}
          ref={inputRef}
          defaultValue={handleDefaultValue(defaultValue, startAddOn?.type)}
          onChange={handleChange}
          onBlur={handleInputOnBlur}
          data-error={!!showError}
        />
        {isAddonPhoneCode && (
          <input
            id={inputProps?.id}
            style={{ display: 'none' }}
            value={value}
          />
        )}
        {endAddOn && (
          <span
            ref={endAddonRef}
            data-addon='end'
          >
            {endAddOn}
          </span>
        )}
        {disabled && <div className={styles['disabled']} />}
      </div>
      {description && (
        <div className={`${styles['description']} ${descriptionClassName}`}>
          {description}
        </div>
      )}
      {showInputError && showError && (
        <span
          data-error='true'
          className={errorClassName}
        >
          {showError}
        </span>
      )}
    </div>
  );
};

export default InputForm;
