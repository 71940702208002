import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './tab.module.css';
import downArrow from '../../assets/down-arrow.svg';
import PopModalDialog from '../PopModalDialog/PopModalDialog';

const ProfileNavigation = ({
  links = [],
  activeTab = 0,
  onTabChange,
  options = [],
  activeStyle = '',
  wrapperStyle = '',
  className = ''
}) => {
  const navigate = useNavigate();

  const subTabs = links?.filter((item) => item.subTab);
  const [showMoreTab, setShowMoreTab] = useState(false);

  const renderMoreDialog = () => {
    if (subTabs?.length > 0) {
      return (
        <div className={styles['popup-menu']}>
          {subTabs.map((item, key) => {
            const stringLink = typeof item.link === 'string';
            const selected = stringLink ? item.link : key;
            const active = selected === activeTab;

            return (
              <div
                key={key}
                className={styles['popupMenuWrapper']}
                data-active={active}
              >
                <span
                  onClick={() => {
                    stringLink ? navigate(item?.link) : handleOnTabChange(key);
                    setShowMoreTab(!showMoreTab);
                  }}
                >
                  {item.label}
                </span>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const handleOnTabChange = (key) => {
    onTabChange && onTabChange(key);
  };

  return (
    <div className={`${styles['navWrapper']} ${className}`}>
      <ul className={`${wrapperStyle} ${styles['navigator']}`}>
        {links.map((item, key) => {
          const stringLink = typeof item.link === 'string';
          const selectedLink = stringLink && activeTab === item.link;
          const active = selectedLink || key === activeTab;

          if (item.subTab || item.hideMenu) return null;

          return (
            <React.Fragment key={key}>
              {item.verticalLine && <div className={styles['verticalLine']} />}
              <li className={active ? activeStyle : ''}>
                {stringLink && (
                  <div onClick={() => navigate(item.link)}>
                    <span>{item.label}</span>
                  </div>
                )}
                {!stringLink && (
                  <div onClick={() => handleOnTabChange(key)}>
                    <span>{item.label}</span>
                  </div>
                )}
                <p />
              </li>
            </React.Fragment>
          );
        })}
        {!!subTabs?.length && (
          <li
            className={`${
              subTabs.find((item) => activeTab?.includes(item.link))
                ? activeStyle
                : ''
            }`}
          >
            <PopModalDialog
              placement='bottom'
              spacing={30}
              content={renderMoreDialog(subTabs)}
            >
              <div
                className={styles['moreTab']}
                onClick={() => {
                  setShowMoreTab(!showMoreTab);
                }}
              >
                <span>More</span>
                <img
                  src={downArrow}
                  alt='dwnArrow'
                />
              </div>
            </PopModalDialog>

            {/* {renderMoreDialog(subTabs)} */}
          </li>
        )}
      </ul>
      <ul className={styles['navigator']}>
        {options.map(({ link, label, onClick }, key) => {
          return (
            <li
              className={styles['optionItem']}
              key={key}
            >
              {link && (
                <div onClick={() => navigate(link)}>
                  <span>{label} </span>
                </div>
              )}
              {!link && (
                <div onClick={() => onClick && onClick(key)}>{label}</div>
              )}
              <p />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProfileNavigation;
