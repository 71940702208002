import React, { useCallback, useEffect, useRef, useState } from 'react';

import styles from './styles.module.css';

const ResumePopup = ({
  watchedLength,
  onResume,
  onStartOver,
  showResumePopup
}) => {
  const actionRef = useRef();
  const [selectedButton, setSelectedButton] = useState(0);

  const handleKeyDown = useCallback(
    (event) => {
      if (!showResumePopup) return;

      const action = actionRef.current;
      if (event.code === 'ArrowLeft' || event.code === 'ArrowUp') {
        setSelectedButton(0);
      }
      if (event.code === 'ArrowRight' || event.code === 'ArrowDown') {
        setSelectedButton(1);
      }
      if (event.code === 'Enter') {
        action.children[selectedButton].click();
      }
    },
    [selectedButton, showResumePopup]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const action = actionRef.current;

    if (action) {
      action.children[selectedButton].style.transition = '0.3s';
      action.children[selectedButton].style.backgroundColor = '#f49832';

      action.children[(selectedButton + 1) % 2].style.backgroundColor = '';
    }
  }, [selectedButton]);

  return (
    <div className={styles['wrapper']}>
      <div className={styles['content']}>
        <div className={styles['header']}>Resume Watching</div>
        <p>{`Do you want to resume from ${watchedLength}?`}</p>
      </div>
      <div
        ref={actionRef}
        className={styles['action']}
      >
        <button
          type='button'
          className={styles['button']}
          onClick={onResume}
        >
          Resume
        </button>
        <button
          type='button'
          className={styles['button']}
          onClick={onStartOver}
        >
          Start Over
        </button>
      </div>
    </div>
  );
};

export default ResumePopup;
