import { combineReducers } from 'redux';
import auth from './auth';
import page from './page';
import playlist from './playlist';
import media from './media';
import search from './search';
import channel from './channel';
import history from './history';
import notification from './notification';
import interactive from './interactive';
import packageGroups from './packageGroup';
import publicProfile from './publicProfile';
import pack from './pack';
import liveShow from './liveShow';
import liveShowComments from './liveShowComment';
import liveGame from './liveGame';
import appSettings from './settings';
import clips from './clips';
import campaign from './campaign';
import myList from './myList';
import cashout from './cashout';
import autoscroll from './autoscroll';
import quiz from './quiz';
import inAppNotification from './inAppNotification';
import promo from './promo';
import subscriptions from './subscriptions';

export default combineReducers({
  auth,
  page,
  playlist,
  media,
  channel,
  search,
  history,
  notification,
  interactive,
  packageGroups,
  publicProfile,
  pack,
  liveShow,
  liveShowComments,
  liveGame,
  appSettings,
  clips,
  campaign,
  myList,
  cashout,
  autoscroll,
  quiz,
  inAppNotification,
  promo,
  subscriptions
});
