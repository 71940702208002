const initialState = {
  position: null
};

export default function autoscroll(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case 'SET_POSITION_AUTO_SCROLL':
      return {
        position: payload.position
      };

    default:
      return {
        ...state
      };
  }
}
