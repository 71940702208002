import React from 'react';
import { Link } from 'react-router-dom';
import appStoreBtn from '../../assets/btn-app-store.svg';
import ggPlayBtn from '../../assets/btn-gg-play.svg';
import { appURL } from '../../constants';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';
import styles from './TopupGuideline.module.css';
import Button from '../Button/Button';

const TopupGuideline = ({ open, maintenance, header, dismiss = () => {} }) => {
  return (
    <GeneralNotifModal
      open={open}
      contentClass={styles['topUpGuidelineModal']}
    >
      <div className={styles['wrapper']}>
        {header && (
          <div className={styles['header']}>
            <h3>{header}</h3>
          </div>
        )}
        {maintenance ? (
          <span className={styles['subtitle']}>
            We are sorry, this feature is currently under maintenance.
          </span>
        ) : (
          <>
            <h2 className={styles['title']}>How to top-up Diamond?</h2>
            <div className={styles['subtitle']}>
              You can top up diamond via Genflix App. Please download Genflix
              app on App Store or Google Play store
            </div>
            <div className={styles['footer']}>
              <Link
                to={appURL.APP_STORE}
                target='_blank'
              >
                <img
                  alt='app-store-button'
                  src={appStoreBtn}
                />
              </Link>
              <Link
                to={appURL.GOOGLE_PLAY}
                target='_blank'
              >
                <img
                  alt='google-play-button'
                  src={ggPlayBtn}
                />
              </Link>
            </div>
          </>
        )}
        <Button
          type='primary'
          shape='rounded'
          size='default'
          style={{
            marginTop: '3rem',
            padding: '10px',
            width: '70%',
            fontSize: 16,
            fontWeight: 600
          }}
          onClick={dismiss}
        >
          Close
        </Button>
      </div>
    </GeneralNotifModal>
  );
};

export default TopupGuideline;
