const emailRegex =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validationState = {
  idCardInvalid: '',
  fullNameInvalid: '',
  emailInvalid: '',
  phoneInvalid: '',
  bankNameInvalid: '',
  bankNumberInvalid: '',
  bankAccountInvalid: '',
  validationInvalid: false
};

export function validationReducer(state = validationState, action) {
  const { payload, type } = action;

  switch (type) {
    case 'idcard':
      const idCardLength = payload?.toString()?.length;
      const validateIdCard =
        idCardLength < 16 || idCardLength > 16
          ? 'NIK must be a 16 digit number'
          : '';

      return {
        ...state,
        idCardInvalid: validateIdCard,
        validationInvalid: !!validateIdCard
      };

    case 'fullname':
      const validateName =
        !payload || /^[a-zA-Z ]+$/.test(payload)
          ? ''
          : 'Full name can’t contains an invalid character';

      return {
        ...state,
        fullNameInvalid: validateName,
        validationInvalid: !!validateName
      };

    case 'phone':
    case 'input-phone':
      const strPayload = payload?.toString();
      const validatePhone =
        !strPayload ||
        (strPayload && !strPayload?.startsWith('0') && strPayload?.length < 13)
          ? ''
          : 'Invalid phone number';

      return {
        ...state,
        phoneInvalid: validatePhone,
        validationInvalid: !!validatePhone
      };

    case 'email':
      const validateEmail =
        !payload || (emailRegex.test(payload) && payload.includes('@'))
          ? 'Invalid email'
          : 'An email address must be contain a single @';

      return {
        ...state,
        emailInvalid: validateEmail || '',
        validationInvalid: !!(validateEmail || '')
      };

    case 'bankname':
      const validateBankName = payload ? '' : 'Please select the bank name';

      return {
        ...state,
        bankNameInvalid: validateBankName,
        validationInvalid: !!validateBankName
      };

    case 'banknumber':
      const validateBankNumber =
        !payload || /^\d+$/.test(payload) ? '' : 'Invalid bank account number';

      return {
        ...state,
        bankNumberInvalid: validateBankNumber,
        validationInvalid: !!validateBankNumber
      };

    case 'bankaccount':
      const validateBankAccount =
        !payload || /^ |^[a-zA-Z ]+$/.test(payload)
          ? ''
          : 'Invalid bank account name';
      return {
        ...state,
        bankAccountInvalid: validateBankAccount,
        validationInvalid: !!validateBankAccount
      };

    default:
      return state;
  }
}
