import React from 'react';
import { useSelector } from 'react-redux';
import { History } from '..';

import styles from './HistoryList.module.css';

const HistoryList = () => {
  const { err } = useSelector((state) => state.history);

  return (
    <div className={styles['historyWrapper']}>
      {err && <p className='error'>You are not logged in</p>}
      <History
        title=''
        titleClassName={styles['title']}
        containerClassName={styles['container']}
        itemClassName={styles['item']}
      />
    </div>
  );
};

export default HistoryList;
