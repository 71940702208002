import React from 'react';

import styles from './UploadStatusDialog.module.css';

const UploadStatusDialog = ({ status, onConfirmDialog }) => {
  const isSuccess = status === 'success';

  return (
    <div className={styles['uploadStatusModal']}>
      <h3
        className={styles['uploadStatusHeader']}
        data-upload-status={status}
      >
        {isSuccess ? 'Upload Successful' : 'Upload Failed'}
      </h3>
      <p>
        {isSuccess
          ? 'Your video will be reviewed prior to publication.'
          : 'It appears that we were not be able to upload the file as expected, please try to upload again.'}
      </p>
      <div
        className={styles['actionButton']}
        data-upload-status={status}
        onClick={onConfirmDialog}
      >
        {isSuccess ? 'Got it' : 'Try again'}
      </div>
    </div>
  );
};

export default UploadStatusDialog;
