import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import App from './App';
import configureStore from './redux/store';
import NetworkService from './network-service';
// import firebaseConfig from './service/firebaseConfig';
import { messaging } from './service/firebase';
import { isFirebaseMessagingSupported } from './utils';
import history from './components/Prompt/history';

const store = configureStore();
NetworkService.setupInterceptors(store);

if ('serviceWorker' in navigator && isFirebaseMessagingSupported()) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(function (registration) {
      // eslint-disable-next-line no-console
      console.log('Registration successful, scope is:', registration.scope);
      messaging.useServiceWorker(registration);
    })
    .catch(function (err) {
      // eslint-disable-next-line no-console
      console.log('Service worker registration failed, error:', err);
    });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// register();
