import React from 'react';
import { Button } from '..';

import styles from './passwordConfirm.module.css';

const PasswordConfirm = ({ onClose, onResume }) => {
  return (
    <div className={styles['container']}>
      <p className={styles['warning']}>
        Change password to secure your account
      </p>
      <p>Are you sure you want to exit this setting?</p>
      <div className={styles['buttonWrapperDefault']}>
        <Button
          onClick={onResume}
          shape='rounded'
          size='large'
          width='100%'
        >
          Secure Your Account
        </Button>
        <Button
          onClick={onClose}
          shape='rounded'
          size='large'
          width='100%'
          color='#F3131329'
          textColor='#F20303'
        >
          Exit Change Password
        </Button>
      </div>
    </div>
  );
};

export default PasswordConfirm;
