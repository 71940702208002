import React from 'react';
import styles from './PageWrapper.module.css';

const PageWrapper = ({ children }) => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['contentWrapper']}>{children}</div>
      <div className={styles['bottom-gradient']} />
    </div>
  );
};

export default PageWrapper;
