import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Loading } from '..';
import {
  forgotPassword,
  loginByEmail,
  registerByEmail,
  selectConsent
} from '../../redux/actions/auth';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';

import styles from './EmailAuthentication.module.css';
import TNCFooter from './TNCFooter';
import ConsentCheckbox from './ConsentCheckbox';
import icView from '../../assets/views-icon.svg';
import icViewDisabled from '../../assets/view-disabled-icon.svg';

const RenderForm = ({
  formType,
  setName,
  setEmail,
  setPassword,
  setRepassword
}) => {
  const [showPass, setShowPass] = useState(false);

  const toggleShowPass = () => {
    setShowPass((prev) => !prev);
  };

  const handleAutofill = () => {
    setEmail(localStorage.getItem('autoFillUserId'));
    return localStorage.getItem('autoFillUserId');
  };

  switch (formType) {
    case 'login':
      return (
        <>
          <input
            type='text'
            name='email'
            id='email'
            placeholder='Email/UserID'
            defaultValue={
              localStorage.getItem('autoFillUserId') ? handleAutofill() : ''
            }
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className={styles['input-placeholder']}>Email/UserID</span>

          <input
            type='password'
            name='password'
            id='password'
            placeholder='Password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className={styles['input-placeholder']}>Password</span>
        </>
      );
    case 'register':
      return (
        <>
          <input
            type='text'
            name='name'
            id='name'
            placeholder='Full Name'
            onChange={(e) => setName(e.target.value)}
          />
          <span className={styles['input-placeholder']}>Full Name</span>

          <input
            type='email'
            name='email'
            id='email'
            placeholder='Email Address'
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className={styles['input-placeholder']}>Email Address</span>
          <div className={styles['inputGroup']}>
            <input
              type={showPass ? 'text' : 'password'}
              name='password'
              id='password'
              placeholder='Password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className={styles['input-placeholder']}>Password</span>
            <button
              type='button'
              onClick={toggleShowPass}
              className={styles['showPasswordBtn']}
            >
              <img
                src={showPass ? icView : icViewDisabled}
                alt='view'
              />
            </button>
          </div>
          <div className={styles['inputGroup']}>
            <input
              type={showPass ? 'text' : 'password'}
              name='repassword'
              id='repassword'
              placeholder='Confirm Password'
              onChange={(e) => setRepassword(e.target.value)}
            />
            <span className={styles['input-placeholder']}>
              Confirm Password
            </span>
            <button
              type='button'
              onClick={toggleShowPass}
              className={styles['showPasswordBtn']}
            >
              <img
                src={showPass ? icView : icViewDisabled}
                alt='view'
              />
            </button>
          </div>
          <ConsentCheckbox language='id' />
        </>
      );
    case 'reset-password':
      return (
        <>
          <input
            type='email'
            name='email'
            id='email'
            placeholder='Email Address'
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className={styles['input-placeholder']}>Email Address</span>
        </>
      );
    default:
      return null;
  }
};

const EmailAuthentication = ({
  type,
  onCloseModal,
  showResetPassword,
  setShowResetPassword,
  onClickTNC
}) => {
  const [err, setErr] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const auth = useSelector((state) => state.auth);
  const { loading, success, consentSelection } = auth;
  const dispatch = useDispatch();
  const isLogin = type === 'login';

  const isFormFilled = () => {
    if (showResetPassword) {
      return email;
    }
    if (type === 'register') {
      return (
        name && email && password && repassword && consentSelection !== null
      );
    }
    if (type === 'login') {
      return email && password;
    }
    return false;
  };

  useEffect(() => {
    if (showResetPassword) {
      analytic(analyticTypes.trackPage, 'ForgotPassword');
    } else {
      analytic(
        analyticTypes.event,
        isLogin
          ? analyticEvents.AUTH.START_LOGIN_BY_EMAIL
          : analyticEvents.AUTH.START_REGISTER_BY_EMAIL
      );
      analytic(
        analyticTypes.trackPage,
        isLogin ? 'LoginMobile' : 'RegisterMobile'
      );
    }
  }, [isLogin, showResetPassword]);

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    setErr('');

    const emailVal = e.target.elements['email'].value;
    const passwordVal = e.target.elements['password'].value;

    if (!emailVal) {
      return setErr('Invalid email!');
    }

    if (!passwordVal) {
      return setErr('Invalid password!');
    }

    try {
      selectConsent(null);
      await dispatch(loginByEmail(emailVal, passwordVal));
      onCloseModal();
      // eslint-disable-next-line no-empty
    } catch (loginError) {}
  };

  const handleSubmitRegister = async (e) => {
    e.preventDefault();
    setErr('');

    const nameVal = e.target.elements['name'].value;
    const emailVal = e.target.elements['email'].value;
    const passwordVal = e.target.elements['password'].value;
    const repasswordVal = e.target.elements['repassword'].value;

    if (!nameVal) {
      return setErr('Invalid name!');
    }

    if (!emailVal) {
      return setErr('Invalid email!');
    }

    if (!passwordVal) {
      return setErr('Invalid password!');
    }

    if (!repasswordVal || repasswordVal !== passwordVal) {
      return setErr('Invalid repassword!');
    }

    if (consentSelection === null) {
      return setErr('Please accept or reject the Consent Selection.');
    }

    try {
      await dispatch(registerByEmail(nameVal, emailVal, passwordVal));

      onCloseModal();
      // eslint-disable-next-line no-empty
    } catch (registerError) {}
  };

  const handleSubmitForgotPassword = async (e) => {
    e.preventDefault();
    setErr('');

    const emailVal = e.target.elements['email'].value;

    if (!emailVal) {
      return setErr('Invalid email!');
    }

    try {
      await dispatch(forgotPassword(emailVal));
      // eslint-disable-next-line no-empty
    } catch (forgotPwError) {}
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (!isFormFilled()) return;
    if (showResetPassword) return handleSubmitForgotPassword(e);
    if (type === 'login') return handleSubmitLogin(e);
    if (type === 'register') return handleSubmitRegister(e);
  };

  return (
    <div className={styles['form-wrapper']}>
      <form
        className={styles['form']}
        onSubmit={handleSubmitForm}
      >
        <RenderForm
          formType={showResetPassword ? 'reset-password' : type}
          setName={setName}
          setEmail={setEmail}
          setPassword={setPassword}
          setRepassword={setRepassword}
        />
        {loading ? (
          <Loading height='80px' />
        ) : (
          <Button
            type='submit'
            style={{ width: '100%', padding: '1rem', fontWeight: 600 }}
            disabled={!isFormFilled()}
          >
            {showResetPassword && 'Reset Password'}
            {!showResetPassword && (isLogin ? 'Login' : 'Register')}
          </Button>
        )}
        {!isLogin && (
          <TNCFooter
            dialogstate={type}
            onClick={onClickTNC}
          />
        )}
        {!!success && <div className='success'>Please check your email!</div>}
        {(!!err || !!auth.err) && (
          <div className='error'>{err || auth.err}</div>
        )}
      </form>

      {isLogin && !showResetPassword && (
        <div
          className={styles['remind']}
          data-reset
          onClick={() => setShowResetPassword(true)}
        >
          <span>Forgot your password?</span>
        </div>
      )}
    </div>
  );
};

export default memo(EmailAuthentication);
