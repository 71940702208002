import { logEvent, setUserProperties } from 'firebase/analytics';
import { generateDeviceInfo } from '../utils';
import { analytics } from './firebase';

export default function trackGa(event, options = { params: {}, user: null }) {
  const { params = {}, user = null } = options;
  const account = user && user.account;
  const userData = generateUserData(user);
  const telco = user && user.telco;
  const partner = localStorage.getItem('partner_id');
  const eventParams = {
    ...params,
    ...userData,
    ...(account && { userId: account.userId }),
    ...(telco && { telco: telco.operator }),
    ...(partner && { partner })
  };

  if (telco) {
    setUserProperties(analytics, { telco: telco.operator });
  }
  logEvent(analytics, event, eventParams);
  window.gtag('event', event, eventParams);
}

export function trackPage(pageName) {
  const param = {
    screen_name: pageName
  };
  logEvent(analytics, 'screen_view', param);
  window.gtag('event', 'screen_view', param);
}

const capitalizeWord = (word) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

const formatObject = (object, customKeyName) => {
  const newData = {};

  for (const key in object) {
    if (object[key] && key !== 'topicArn') {
      const newKeyName = customKeyName
        ? `${customKeyName}${capitalizeWord(key)}`
        : key;

      if (object[key] instanceof Array || object[key] instanceof Object) {
        newData[newKeyName] = JSON.stringify(object[key]);
      } else {
        newData[newKeyName] = object[key];
      }
    }
  }

  return newData;
};

const generateUserData = (user) => {
  const deviceInfo = generateDeviceInfo();
  const formatDeviceInfo = formatObject(deviceInfo, 'device');
  const userData = {
    ...formatDeviceInfo
  };

  if (user) {
    const { account, profile, telco } = user;

    if (telco) {
      userData.telcoOperator = telco.msisdn;
      userData.telcoMsisdn = telco.operator;
    }

    Object.assign(userData, formatObject(account, 'account'));
    Object.assign(userData, formatObject(profile, 'profile'));
  }

  return userData;
};
