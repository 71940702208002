import axios from 'axios';
import { generateErrorDetail } from '../../utils';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';

const URL = `${BASE_URL}/catalog/v1.0/show`;

// eslint-disable-next-line import/prefer-default-export
export const callHeartbeat = (id) => async (dispatch, getState) => {
  const state = getState();

  try {
    const res = await axios.post(`${URL}/${id}/heartbeat`, null, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });
    return res;
  } catch (error) {
    generateErrorDetail(error);
    throw error;
  }
};
