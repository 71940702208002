import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { currencyFormatter, formatDateV2 } from '../../utils';
import { getAppSettings } from '../../redux/actions/settings';
import styles from '../Transaction/transaction.module.css';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';

import icDanger from '../../assets/ic-danger.svg';
import { settingKeys } from '../../hooks/useSettings';

const { recurringPackKey, gracePeriodPackKey } = settingKeys;

const MySubscriptionHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const userSubs = useMemo(() => {
    return user?.account?.subscriptions;
  }, [user?.account?.subscriptions]);
  const { settings } = useSelector((state) => state.appSettings);
  const { recurringPackIds, gracePeriodPackIds } = useMemo(() => {
    return {
      recurringPackIds: settings?.[recurringPackKey] || '',
      gracePeriodPackIds: settings?.[gracePeriodPackKey] || ''
    };
  }, [settings]);
  const hasGracePeriodSubs = userSubs?.find((item) =>
    gracePeriodPackIds.includes(item?.packageGroup?.id)
  );

  useEffect(() => {
    if (!recurringPackIds || !gracePeriodPackIds)
      dispatch(getAppSettings([recurringPackKey, gracePeriodPackKey]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const calculateExpirationDate = (date) => {
    const dateNow = new Date().getTime();
    const days = ((date - dateNow) / 86400000).toFixed(); // in seconds
    const numberOfDays = days <= 0 ? 0 : days; // get days

    return `${numberOfDays} day${numberOfDays <= 1 ? '' : 's'}`;
  };

  const onClickRenew = (item) => {
    const paramsEvent = {
      packageID: item?.id,
      packageCode: item?.packageId,
      packageTitle: item?.packageName,
      packagePrice: item?.packagePrice,
      packageDuration: `${item?.packageDuration} ${item?.packageDurationType}`
    };
    analytic(
      analyticTypes.event,
      analyticEvents.ONGOING.CLICK_RENEW_ONE_TIME_SUBS,
      {
        product_item: paramsEvent,
        user
      }
    );
    navigate('/profile/subscription/buy-new');
  };

  const formatPriceDuration = (pack) => {
    if (pack.packageDuration || pack.packagePrice) {
      return `${currencyFormatter.format(pack.packagePrice)} for${' '}
      ${pack.packageDuration} ${pack.packageDurationType}${
        pack.packageDuration !== 1 ? 's' : ''
      }`;
    }
    return '';
  };

  return (
    <div className={styles['listWrapper']}>
      {!userSubs?.length ? (
        <div className={styles['packagesWrapper']}>
          <div className={styles['noData']}>
            <div>No Active Subscription</div>
            <div>Your active subscription will show up here.</div>
          </div>
        </div>
      ) : (
        userSubs?.map((subsItem, key) => {
          const currentDate = new Date().getTime();
          const recurringSubs =
            subsItem?.packageGroup?.id &&
            recurringPackIds?.includes(subsItem?.packageGroup?.id);
          const isGracePeriodSubs =
            subsItem?.packageGroup?.id &&
            gracePeriodPackIds.includes(subsItem?.packageGroup?.id);

          if (isGracePeriodSubs) return null;

          return (
            <div
              className={styles['itemWrapper']}
              key={key}
            >
              <div className={styles['detail']}>
                <h3 className={styles['title']}>{subsItem.packageName}</h3>
                <span className={styles['subtitle']}>
                  {formatPriceDuration(subsItem)}
                </span>

                <hr />

                {subsItem.endAt && (
                  <div className={styles['amount']}>
                    <span>Expire in: </span>
                    <span>{calculateExpirationDate(subsItem?.endAt)}</span>
                  </div>
                )}
                {recurringSubs && (
                  <div
                    className={`${styles['amount']} ${styles['next-billing-date']}`}
                  >
                    <span>Next billing date: </span>
                    <span data-grace-periode={currentDate >= subsItem.endAt}>
                      {hasGracePeriodSubs
                        ? formatDateV2(subsItem.endAt, 'dd mmm yyyy')
                        : 'Canceled By User'}
                    </span>
                  </div>
                )}
                {recurringSubs && subsItem && currentDate >= subsItem.endAt && (
                  <div className={`${styles['warning-message']}`}>
                    <img
                      src={icDanger}
                      alt='ic-danger'
                    />
                    Insufficient balance on payment. Please check your payment
                    method. Otherwise, after a 3 days grace period, your
                    subscription will be stopped.
                  </div>
                )}
                {!recurringSubs &&
                  subsItem.packagePrice &&
                  subsItem.packageDuration && (
                    <div className={styles['button-wrapper']}>
                      <button
                        type='button'
                        onClick={() => onClickRenew(subsItem)}
                        className={styles['renewButton']}
                      >
                        <span>Renew</span>
                      </button>
                    </div>
                  )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default MySubscriptionHistory;
