import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withRouter } from '../../hooks';
import { Section, Carousel, Loading, ContinueWatchingSection, Error } from '..';
import { getChannel } from '../../redux/actions/channel';
import analytic, { analyticTypes } from '../../service/analytic';
import dablena from '../../service/dableIo';
import { generateSelectChannelURL } from '../../utils';
import Metadata from '../Metadata/Metadata';

const HOME_CARD_INDEX = 3;

const metadata = {
  Home: {
    description:
      'Tonton online streaming film HD Indonesia, film Hollywood, Live Show, TV Channel, dan drama Korea terbaru dengan subtitle Indonesia dan Inggris di Genflix.'
  },
  Movies: {
    description:
      'Saksikan film Indonesia dan persembahan original terbaru Genflix.'
  },
  Series: {
    description:
      'Nikmati drama Korea dan serial original persembahan original Genflix.'
  },
  Animation: {
    description: 'Nikmati film dan serial animasi persembahan original Genflix.'
  }
};

const SectionRails = ({ item, topSection, itemType, handleSelectChannel }) => {
  return (
    <Section
      id={item.id}
      name={item.titleLocalized}
      sectionData={item.items}
      topSection={topSection}
      onChannelSelected={handleSelectChannel}
      itemType={itemType}
    />
  );
};

const MediaPageView = ({ data, loading, err }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isHomePage = useMemo(() => data && data.friendlyId === 'home', [data]);
  const user = useSelector((state) => state.auth.user);
  const { data: channelData } = useSelector((state) => state.channel);
  const liveChannelPlaylist = channelData &&
    !!channelData.length && {
      titleLocalized: 'Live TV',
      items: channelData,
      id: 'live-tv'
    };

  useEffect(() => {
    if (data) analytic(analyticTypes.trackPage, data.titleLocalized, { user });
  }, [data, user]);

  // dable.io tracker
  useEffect(() => {
    if (data?.titleLocalized) dablena('PageView');
  }, [data]);

  useEffect(() => {
    dispatch(getChannel(1));
  }, [dispatch]);

  if (loading || (!data && !err)) {
    return <Loading height='80vh' />;
  }

  if (err) {
    return <Error />;
  }

  const carouselData = data.items[0];
  const homeCardData = data.items[HOME_CARD_INDEX];
  const topSectionsData = data.items.filter(
    (o, i) => i > 0 && i < HOME_CARD_INDEX
  );
  const botSectionsData = data.items.filter((o, i) => i > HOME_CARD_INDEX);

  const handleSelectChannel = (item) => {
    const channelURL = generateSelectChannelURL(item);
    navigate(channelURL);
  };

  return (
    <>
      <Metadata
        name='Genflix'
        title='Genflix - Bioskop Online Terbaik'
        description={data && metadata[data.titleLocalized].description}
        url={window.location.href}
      />
      <div>
        <Carousel
          data={carouselData}
          detailOrder={['title', 'tags', 'description', 'actions']}
          buttonActions={[
            {
              label: 'Watch Now',
              icon: 'play',
              iconStyle: {
                backgroundColor: '#F49832',
                borderRadius: '50%'
              },
              textColor: 'primary',
              type: 'text',
              size: 'large',
              onclick: 'play'
            },
            {
              label: 'More Info',
              icon: 'info',
              iconStyle: {
                backgroundColor: '#fff',
                borderRadius: '50%'
              },
              textColor: 'primary',
              type: 'text',
              size: 'large',
              onclick: 'detail'
            }
          ]}
          onChannelSelected={handleSelectChannel}
        />
        {isHomePage && <ContinueWatchingSection />}
        {isHomePage && liveChannelPlaylist && (
          <SectionRails
            item={liveChannelPlaylist}
            itemType='live-tv'
            handleSelectChannel={handleSelectChannel}
          />
        )}
        {topSectionsData.map((item, index) => {
          const topSection = index === 0 && !isHomePage;
          return (
            <SectionRails
              item={item}
              topSection={topSection}
              key={index}
              handleSelectChannel={handleSelectChannel}
            />
          );
        })}
        <SectionRails
          item={homeCardData}
          handleSelectChannel={handleSelectChannel}
        />
        {botSectionsData.map((item, index) => {
          return (
            <SectionRails
              item={item}
              key={index}
              handleSelectChannel={handleSelectChannel}
            />
          );
        })}
      </div>
    </>
  );
};

export default withRouter(MediaPageView);
