import React, { useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { updatePassword } from '../../redux/actions/auth';
import { showToastNotif } from '../../redux/actions/notification';

import styles from './passwordModal.module.css';
import { generateErrorDetail, parseQuery } from '../../utils';
import Button from '../Button/Button';

import icView from '../../assets/views-icon.svg';
import icViewDisabled from '../../assets/view-disabled-icon.svg';
import PasswordConfirm from './PasswordConfirm';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';
import AnimatedModal from '../Modal/AnimatedModal';

const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/;

const minLength = 8;
const PasswordModal = ({ open, onDismiss }) => {
  const auth = useSelector((state) => state.auth);
  const { account } = auth.user;
  const whatsappLink = auth.whatsappLink?.value;
  const pass = useRef();
  const [error, setError] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const confPass = useRef();
  const oldPass = useRef();
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const { defaultPassword } = parseQuery(location?.search);

  const toggleShowPass = useCallback(() => {
    setShowPass((prev) => !prev);
  }, []);

  const toggleShowCancel = useCallback(() => {
    setShowConfirmCancel((prev) => !prev);
  }, []);

  const Dismiss = useCallback(() => {
    if (defaultPassword) {
      const queryParams = '';
      navigate({
        search: queryParams,
        replace: true
      });
    }

    setError(false);
    onDismiss();
  }, [onDismiss, defaultPassword, navigate]);

  const mapErrors = (err) => {
    switch (err.code) {
      case 'gfx4000003':
        setError('Old Password should NOT be shorter than 8 characters');
        break;
      case 'gfu4030207':
        setError('Your old password is incorrect');
        break;
      default:
        setError('Something went wrong, please try again later');
        break;
    }
  };

  const onSubmit = useCallback(async () => {
    if (pass.current.value !== confPass.current.value) {
      return setError('Your confirmation password is not matching');
    }
    if (!strongRegex.test(pass.current.value)) {
      return setError(
        'Your password must contain at least 8 characters with lowercase, uppercase, number and special character'
      );
    }
    try {
      const res = await dispatch(
        updatePassword(
          oldPass.current.value,
          pass.current.value,
          confPass.current.value
        )
      );
      if (!res.error) {
        dispatch(showToastNotif('success', 'Your password has been updated'));
        Dismiss();
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      mapErrors(errorData);
    }
  }, [pass, confPass, dispatch, Dismiss]);

  return (
    <>
      <AnimatedModal
        duration={10}
        open={showConfirmCancel}
        opacity={0.5}
      >
        <GeneralNotifModal open={showConfirmCancel}>
          <PasswordConfirm
            onClose={() => {
              toggleShowCancel(false);
              Dismiss();
            }}
            onResume={() => {
              toggleShowCancel(false);
            }}
          />
        </GeneralNotifModal>
      </AnimatedModal>
      <div className={`${styles['passModal']} ${open ? styles['show'] : ''}`}>
        <div className={styles['passWrapper']}>
          <div className={styles['passContent']}>
            <h3>Change Password</h3>
            <span className={styles['passHelper']}>
              For security, please enter your old password.
            </span>
            <span className={styles['passWarning']}>{error}</span>
            <section>
              <span>Old Password</span>
              <div className={styles['inputGroup']}>
                <input
                  ref={oldPass}
                  type={showPass ? 'text' : 'password'}
                  className={styles['pwd']}
                  defaultValue={
                    defaultPassword && decodeURIComponent(defaultPassword)
                  }
                />
                <button
                  type='button'
                  onClick={toggleShowPass}
                  className={styles['showPasswordBtn']}
                >
                  <img
                    src={showPass ? icView : icViewDisabled}
                    alt='view'
                  />
                </button>
              </div>
              {account.email && (
                <Link
                  to={`/forgot-password?email=${encodeURIComponent(
                    account.email
                  )}`}
                  className={styles['forgot']}
                >
                  <span className={styles['forgotText']}>Forgot password?</span>
                </Link>
              )}
              {!account.email && whatsappLink && (
                <a
                  className={styles['forgot']}
                  href={whatsappLink}
                  target='__blank'
                >
                  <span>Forgot password? Contact our support.</span>
                </a>
              )}
            </section>
            <section>
              <span>New Password</span>

              <div className={styles['inputGroup']}>
                <input
                  ref={pass}
                  type={showPass ? 'text' : 'password'}
                  minLength={minLength}
                  className={styles['pwd']}
                />
                <button
                  type='button'
                  onClick={toggleShowPass}
                  className={styles['showPasswordBtn']}
                >
                  <img
                    src={showPass ? icView : icViewDisabled}
                    alt='view'
                  />
                </button>
              </div>
            </section>
            <section>
              <span>Re enter New Password</span>
              <div className={styles['inputGroup']}>
                <input
                  ref={confPass}
                  type={showPass ? 'text' : 'password'}
                  minLength={minLength}
                  className={styles['pwd']}
                />
                <button
                  type='button'
                  onClick={toggleShowPass}
                  className={styles['showPasswordBtn']}
                >
                  <img
                    src={showPass ? icView : icViewDisabled}
                    alt='view'
                  />
                </button>
              </div>
            </section>
            <div className={styles['buttonAction']}>
              <Button
                style={{
                  padding: 16,
                  marginRight: '0.5rem',
                  fontWeight: 600,
                  fontSize: 14,
                  width: '100%'
                }}
                color='dark'
                shape='rounded'
                onClick={toggleShowCancel}
              >
                Cancel
              </Button>
              <Button
                style={{
                  padding: 16,
                  marginLeft: '0.5rem',
                  fontWeight: 600,
                  fontSize: 14,
                  width: '100%'
                }}
                shape='rounded'
                onClick={onSubmit}
              >
                Change Password
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordModal;
