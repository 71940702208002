export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_DEV;

export const BASE_SOCKET_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BASE_SOCKET_URL
    : process.env.REACT_APP_BASE_SOCKET_URL_DEV;

export const COOKIES_DOMAIN = window.location.hostname.includes('genflix.co.id')
  ? 'genflix.co.id'
  : window.location.hostname;

export const JWT_APP_ID = 'a597d124-03e7-43e6-8435-13d667c13c15';
export const SMARTFREN_CG_URL = process.env.REACT_APP_SMARTFREN_CG;
export const PAYMENT_GATEWAY_URL = process.env.REACT_APP_PAYMENT_GATEWAY_URL;
export const ADS_TAG_URL =
  'https://googleads.g.doubleclick.net/pagead/ads?ad_type=video&client=ca-video-pub-3284894714869864&sdmax=15000&min_ad_duration=15000&max_ad_duration=15000&hl=id&videoad_start_delay=0';
