import React from 'react';
import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';
import {
  registerIVSTech,
  isPlayerSupported,
  registerIVSQualityPlugin
} from 'amazon-ivs-player';
import videojs from 'video.js';
import { withRouter } from '../../hooks';
import Loading from '../Loading/Loading';

import './CustomControlButton/CustomControlButton';
import PlayerSubscription from './PlayerSubscription';
import styles from './VideoPlayer.module.css';
import VideoInterval from './VideoInterval';
import analytic, { analyticTypes } from '../../service/analytic';
import StreamSetupModal from '../StreamerDashboard/StreamSetupModal';
import 'video.js/dist/video-js.css';

const createAbsolutePath = (assetPath) =>
  new URL(assetPath, document.URL).toString();

class LiveShowPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.premiumGiftImg = React.createRef();
    this.state = {
      // showDissmissIcon: false,
      runInterval: false,
      mediaStart: false,
      loading: false,
      error: null,
      // showBullet: false,
      showSetup: false
    };
  }

  async componentDidMount() {
    const { stream } = this.props;
    if (!stream || (stream && stream.error)) return;

    const videoOptions = {
      controls: true,
      autoplay: true,
      muted: true,
      liveui: true,
      responsive: true,
      controlBar: {
        pictureInPictureToggle: false
      },
      aspectRatio: '16:9',
      html5: {
        nativeCaptions: false
      },
      // make the text track settings dialog not initialize
      textTrackSettings: false
    };

    // register Amazon IVS as playback technology for videojs
    if (isPlayerSupported) {
      // eslint-disable-next-line no-console
      console.log('IVS supported');
      registerIVSTech(videojs, {
        wasmWorker: createAbsolutePath(wasmWorkerPath),
        wasmBinary: createAbsolutePath(wasmBinaryPath)
      });
      registerIVSQualityPlugin(videojs);
      // integrate aws IVS plugin
      videoOptions.techOrder = ['AmazonIVS', 'html5'];
    }

    // instantiate Video.js
    this.player = videojs(
      this.videoNode,
      videoOptions,
      function onPlayerReady() {
        // eslint-disable-next-line no-console
        console.log('onPlayerReady', this);
      }
    );

    isPlayerSupported && this.player.enableIVSQualityPlugin();
    this.player.src(stream);

    const playerEvent = this.player.getIVSEvents().PlayerEventType;
    const playerState = this.player.getIVSEvents().PlayerState;
    this.player.getIVSPlayer().addEventListener(playerEvent.ERROR, (e) => {
      if (e.code === 404) {
        this.setState({ loading: true });
        this.player.src(stream);
      }
    });
    this.player.getIVSPlayer().addEventListener(playerState.READY, () => {
      this.setState({ loading: false });
      this.player.play();
    });

    analytic(analyticTypes.trackPage, 'StreamerDashboard');

    this.addEventListener();
  }

  componentDidUpdate() {
    const { premiumGift } = this.props;

    if (premiumGift && this.premiumGiftImg) {
      this.premiumGiftImg.current.src = premiumGift.gift.icon;
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  addEventListener = () => {
    const { handleUpdateHistory } = this.props;

    this.player.on('play', (e) => {
      // eslint-disable-next-line no-console
      console.log('video started', e);
      this.setState({ mediaStart: true });
      this.setState({ runInterval: true });
      if (!this.pausing) handleUpdateHistory(0);
      if (this.pausing) {
        this.pausing = false;
      }

      // this.setState(() => ({
      //   showDissmissIcon: true
      // }));
    });

    this.player.on('pause', () => {
      if (!this.player.seeking()) {
        this.pausing = true;
        this.setState({ runInterval: false });
      }
    });

    this.player.on('useractive', () => {
      // this.setState(() => ({
      //   showDissmissIcon: true
      // }));
    });

    this.player.on('ended', () => {
      this.setState({ mediaStart: false });
    });

    this.player.on('userinactive', () => {
      // this.setState(() => ({
      //   showDissmissIcon: false
      // }));
    });

    this.player.on('error', (e) => {
      // eslint-disable-next-line no-console
      console.log('error', e);
      return this.setState(() => ({
        error: {
          code: 'player-error',
          message: 'General Player Error!'
        }
      }));
    });
  };

  updateHistoryCallback = () => {
    const { handleUpdateHistory } = this.props;
    const { mediaStart } = this.state;

    if (this.player && mediaStart) {
      handleUpdateHistory(this.player.currentTime(), this.player.duration());
    }
  };

  renderError = () => {
    const { error } = this.state;
    const content = <p>{`${error.message} (# ${error.code})`}</p>;

    return (
      <div className={styles['errorWrapper']}>
        <div className='vjs-dismiss-btn-wrapper'>
          <i className='vjs-dismiss-player-icon' />
        </div>
        {content}
      </div>
    );
  };

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  // isRelative flag for the interactive live stream
  render() {
    const {
      subtitles,
      onDismissPlayer,
      stream,
      viewers,
      isRelative,
      bulletComment,
      basicGift,
      premiumGift,
      lastJoined,
      rtmp,
      ...otherProps
    } = this.props;
    const { loading, error, runInterval, showSetup } = this.state;

    if (stream && stream.error) {
      return (
        <PlayerSubscription
          isInteractive={isRelative} // Give flag is relative when the player is for interactive
          onDismissPlayer={onDismissPlayer}
          otherProps={otherProps}
          error={stream.error}
        />
      );
    }

    if (error) {
      return this.renderError();
    }

    return (
      <div className='live-player live-game-player'>
        {runInterval && (
          <VideoInterval updateHistoryCallback={this.updateHistoryCallback} />
        )}
        {loading && (
          <div className={styles['videoLoading']}>
            <div style={{ margin: '5%' }}>
              <Loading height={1} />
            </div>
            <p>Connect to streaming software to start livestream,</p>
            <p>Viewers will be able to find your stream once you go live</p>
            <p
              className={styles['setupButton']}
              onClick={() => this.setState({ showSetup: !showSetup })}
            >
              How to setup in OBS
            </p>
          </div>
        )}
        <div data-vjs-player>
          <video
            ref={(node) => (this.videoNode = node)}
            className='video-js video-js-relative vjs-big-play-centered live-game-player'
          >
            {subtitles?.map((item, index) => (
              <track
                key={index}
                src={item.url}
                kind='subtitles'
                srcLang={item.language}
                label={item.label}
              />
            ))}
          </video>
        </div>
        {showSetup && (
          <StreamSetupModal
            rtmpInfo={rtmp}
            onCloseModal={() => this.setState({ showSetup: false })}
          />
        )}
      </div>
    );
  }
}

export default withRouter(LiveShowPlayer);
