import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionPackage } from '..';
import { getAppSettings } from '../../redux/actions/settings';
import styles from './PackagePayment.module.css';
import { settingKeys } from '../../hooks/useSettings';

const { telcoPackageKey } = settingKeys;
const PackagePayment = ({ packages, paymentGateway }) => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.appSettings);

  useEffect(() => {
    if (settings && !settings[telcoPackageKey]) {
      dispatch(getAppSettings([telcoPackageKey]));
    }
  }, [dispatch, settings]);

  const renderPackageList = () => {
    const telcoPacks = packages.filter((item) => {
      return Object.entries(item.payments).some(
        ([key, value]) => key.startsWith('telco') && value.description
      );
    });

    const result = [];

    if (telcoPacks.length > 0) {
      result.splice(
        1,
        0,
        <div key='telco'>
          <p className={styles['title']}>Subscribe Via Telco Plan</p>
          {renderPaymentOptions(telcoPacks, 'telco')}
        </div>
      );
    }
    return result;
  };

  const renderPaymentOptions = (packageList, gateway) => {
    return (
      <div className={styles['list-wrapper']}>
        {/* <p className={styles.helperText}>
          {!packageList.length ? 'Please use Genflix Mobile App for purchase package' : 'Please use Genflix Mobile App for more package'}
        </p> */}
        <div className={styles['list-plan']}>
          {packageList
            ?.filter((item) => item.package_code === 'premium')
            ?.map((item) => {
              if (item.name) item.title = item.name;
              return (
                <div key={item.id}>
                  <SubscriptionPackage
                    key={item.id}
                    {...item}
                    gateway={gateway}
                    paymentGateway={paymentGateway}
                  />
                </div>
              );
            })}
        </div>
        <div className={styles['list-plan']}>
          {packageList
            ?.filter((item) => item.package_code !== 'premium')
            ?.map((item) => {
              if (item.name) item.title = item.name;
              return (
                <div key={item.id}>
                  <p className={styles['title']}>{item?.packageGroupName}</p>
                  <SubscriptionPackage
                    key={item.id}
                    {...item}
                    gateway={gateway}
                    paymentGateway={paymentGateway}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  return paymentGateway ? renderPaymentOptions(packages) : renderPackageList();
};

export default PackagePayment;
