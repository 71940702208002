import { getCookie, setCookie } from '../../utils';

const initialState = {
  user: getCookie('user') ? JSON.parse(getCookie('user')) : null,
  token: getCookie('token')
    ? getCookie('token')
    : localStorage.getItem('token') || null,
  refreshToken: getCookie('token')
    ? getCookie('token')
    : localStorage.getItem('refreshToken') || null,
  err: null,
  loading: false,
  LoggingOut: false,
  success: false,
  authPopupStatus: false,
  isLoadingPopularStreamers: false,
  watchTime: null, // persisting watch time state before login
  popularStreamers: [],
  whatsappLink: null,
  sessionStatus: localStorage.getItem('sessionStatus') || null,
  authDialogConfigs: {
    showClose: true,
    state: null,
    type: null,
    showFooter: true,
    onCloseCallback: null
  },
  showConsent: false,
  consentSelection: null
};

export default function authReducer(state = initialState, action) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log(action);
  }
  const { type, err, data, payload, msisdn, configs = {} } = action;

  switch (type) {
    case 'SHOW_AUTH_POPUP':
      return {
        ...state,
        authPopupStatus: true,
        ...(configs && {
          authDialogConfigs: { ...state.authDialogConfigs, ...configs }
        })
      };
    case 'CLOSE_AUTH_POPUP':
      state.authDialogConfigs.onCloseCallback?.();

      return {
        ...state,
        authPopupStatus: false,
        authDialogConfigs: {
          showClose: true,
          state: null,
          type: null,
          showFooter: true,
          onCloseCallback: null
        }
      };
    case 'OPEN_CONSENT_POPUP':
      return {
        ...state,
        showConsent: true
      };
    case 'CLOSE_CONSENT_POPUP':
      return {
        ...state,
        showConsent: false,
        consentSelection: null
      };
    case 'CONSENT_SELECT':
      return {
        ...state,
        consentSelection: payload
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.user
      };

    case 'SET_TOKEN':
      localStorage.setItem('token', action.token);
      localStorage.setItem('refreshToken', action.refreshToken);
      setCookie('token', action.token);
      setCookie('refreshToken', action.refreshToken);
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken
      };
    case 'UPDATE_TOKEN_STATE':
      const newToken = data.token;
      const newRefreshToken = data.refreshToken;
      const newUser = data.user;

      const isTokenDifferent = newToken !== state.token;
      const isRefreshTokenDifferent = newRefreshToken !== state.refreshToken;
      const isUserDifferent =
        JSON.stringify(newUser) !== JSON.stringify(state.user);

      isTokenDifferent && setCookie('token', newToken);
      isRefreshTokenDifferent && setCookie('refreshToken', newRefreshToken);
      isUserDifferent && setCookie('user', JSON.stringify(newUser));
      return {
        ...state,
        ...(isTokenDifferent && { token: newToken }),
        ...(isRefreshTokenDifferent && { refreshToken: newRefreshToken }),
        ...(isUserDifferent && { user: newUser })
      };
    case 'LOGGING_OUT':
      return {
        ...state,
        LoggingOut: true
      };

    case 'LOGOUT':
      return {
        ...initialState,
        user: null,
        token: null,
        refreshToken: null
      };

    case 'REGISTER_USER':
    case 'LOGIN':
    case 'FORGOT_PASSWORD':
    case 'UPDATE_PROFILE':
    case 'RESET_PASSWORD':
    case 'GET_ACCOUNT_INFO':
      return {
        ...state,
        loading: true,
        err: null,
        success: false
      };

    case 'REGISTER_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        err: null
      };

    case 'UPDATE_PASSWORD_SUCCESS':
    case 'UPDATE_PROFILE_SUCCESS':
    case 'FORGOT_PASSWORD_SUCCESS':
    case 'RESET_PASSWORD_SUCCESS':
      return {
        ...state,
        loading: false,
        err: null,
        success: true
      };

    case 'GET_ACCOUNT_INFO_SUCCESS':
      const accProfile = {
        ...data.profile,
        name: data.profile.name || '',
        avatar: data.profile.avatar || '',
        bio: data.profile.bio || ''
      };
      const userAccount = {
        ...state.user,
        profile: accProfile,
        account: data.account,
        telco: data.telco || null
      };
      setCookie('user', JSON.stringify(userAccount));
      return {
        ...state,
        user: userAccount,
        loading: false,
        err: null
      };

    case 'LOGIN_SUCCESS':
      const profile = {
        ...data.profile,
        name: data.profile.name || '',
        avatar: data.profile.avatar || '',
        bio: data.profile.bio || ''
      };
      const user = {
        profile,
        account: data.account,
        telco: data.telco || null,
        isNewDevice: data.isNewDevice,
        ...(msisdn && { msisdn })
      };
      const token = data.accessToken;
      const refreshToken = data.refreshToken;

      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      setCookie('token', token);
      setCookie('refreshToken', refreshToken);
      setCookie('user', JSON.stringify(user));

      return {
        ...state,
        loading: false,
        err: null,
        user,
        token,
        refreshToken
      };

    case 'GET_WHATSAPP_FAILURE':
    case 'UPDATE_PASSWORD_FAILURE':
    case 'UPDATE_PROFILE_FAILURE':
    case 'REGISTER_USER_FAILURE':
    case 'FORGOT_PASSWORD_FAILURE':
    case 'LOGIN_FAILURE':
    case 'RESET_PASSWORD_FAILURE':
    case 'GET_ACCOUNT_INFO_FAILURE':
      return {
        ...state,
        loading: false,
        err
      };

    case 'RESET_AUTH_STATE':
      return {
        ...state,
        loading: false,
        err: null,
        success: false
      };

    case 'GET_POPULAR_STREAMER_LIST':
      return {
        ...state,
        isLoadingPopularStreamers: true
      };
    case 'GET_POPULAR_STREAMER_LIST_SUCCESS':
      return {
        ...state,
        isLoadingPopularStreamers: false,
        popularStreamers: data
      };

    case 'GET_POPULAR_STREAMER_LIST_FAIL':
      return {
        ...state,
        isLoadingPopularStreamers: false
      };

    case 'VALIDATE_SESSION':
      localStorage.setItem('sessionStatus', 'validating');

      return {
        ...state,
        sessionStatus: 'validating',
        loading: true
      };
    case 'VALIDATE_SESSION_SUCCESS':
      localStorage.setItem('sessionStatus', 'success');

      return {
        ...state,
        sessionStatus: 'success',
        loading: false
      };
    case 'VALIDATE_SESSION_FAILED':
      localStorage.setItem('sessionStatus', 'failed');
      localStorage.setItem('logoutToken', data);

      return {
        ...state,
        sessionStatus: 'failed',
        loading: false
      };
    case 'SET_WATCH_TIME':
      return {
        ...state,
        watchTime: data
      };
    case 'RESET_WATCH_TIME':
      return {
        ...state,
        watchTime: null
      };
    case 'GET_WHATSAPP':
      return {
        ...state
      };
    case 'GET_WHATSAPP_SUCCESS':
      return {
        ...state,
        whatsappLink: payload.data
      };
    default:
      return state;
  }
}
