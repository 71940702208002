import axios from 'axios';
import platform from 'platform';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';

export const getPreviousLiveShowInfo = (id) => async (dispatch, getState) => {
  const state = getState();

  try {
    const url = `${BASE_URL}/catalog/v1.1/video/${id}`;
    const res = await axios.get(url, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });

    const data = res.data.data;

    return data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err?.message);
  }
};

export const getMediaInfo =
  (id, type, isPreload = false, { dispatchPayload = true } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const page = state.page.data;
    const { movies, series, season, detailData } = state.media;

    if (id === detailData?.id) return detailData;

    try {
      const url =
        type === 'series'
          ? `${BASE_URL}/catalog/v1.0/playlist/${id}?locale=en`
          : `${BASE_URL}/catalog/v1.0/video/${id}?locale=en`;

      if (isPreload) {
        return axios.get(url, {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        });
      }

      dispatchPayload &&
        dispatch({
          type: 'GET_MEDIA_DETAIL'
        });

      // optimize
      const dataId =
        type === 'movie'
          ? movies.find((el) => el.id === id)
          : series.find((el) => el.id === id);

      const seasonData = season.find((el) => el.id === id);

      const existingData =
        (dataId && dataId.length && seasonData.length) || seasonData;

      let data = dataId;

      if (!existingData) {
        const res = await axios.get(url, {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        });
        data = res.data.data;
      }

      const dataHome = page.find((el) => el.friendlyId === 'home');
      const dataMovie = dataHome?.items.map((el) => {
        return el.items.filter((item) => {
          return item.type === 'movie';
        });
      });
      const dataSeries = dataHome?.items.map((el) => {
        return el.items.filter((item) => {
          return item.type === 'series';
        });
      });
      const moviesData = [].concat(...(dataMovie || [])).filter(Boolean);
      const seriesData = [].concat(...(dataSeries || [])).filter(Boolean);

      dispatchPayload &&
        dispatch({
          type: existingData
            ? 'GET_MEDIA_DETAIL_SUCCESS_FROM_STATE'
            : 'GET_MEDIA_DETAIL_SUCCESS',
          detailData: data || dataId || existingData,
          payload: [moviesData, seriesData, data]
        });

      return existingData ? dataId || seasonData : data;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatchPayload &&
        dispatch({
          type: 'GET_MEDIA_DETAIL_ERROR',
          err: errorData
        });
    }
  };

export const getMediaAccess =
  (id, { sessionId, deviceId } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const platformName = platform.name && platform.name.toLowerCase();
    const browserName = platformName.includes('safari') ? 'safari' : 'chrome';

    dispatch({
      type: 'GET_MEDIA_ACCESS'
    });

    try {
      const res = await axios.get(
        `${BASE_URL}/catalog/v1.4/video/${id}/access?platform=${browserName}`,
        {
          headers: {
            Authorization: state.auth.token
          },
          params: {
            ...(sessionId && { sessionId }),
            ...(deviceId && { deviceId })
          }
        }
      );

      dispatch({
        type: 'GET_MEDIA_ACCESS_SUCCESS',
        payload: res.data.data
      });

      return res.data;
    } catch (err) {
      dispatch({
        type: 'GET_MEDIA_ACCESS_FAILED',
        err
      });
      throw err;
    }
  };

export const getPublicAccess =
  (id, { sessionId, deviceId } = {}) =>
  async (dispatch) => {
    const platformName = platform.name && platform.name.toLowerCase();
    const browserName = platformName.includes('safari') ? 'safari' : 'chrome';

    dispatch({
      type: 'GET_MEDIA_ACCESS'
    });

    try {
      const res = await axios.get(
        `${BASE_URL}/catalog/v1.4/video/${id}/publicAccess?platform=${browserName}`,
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          },
          params: {
            ...(sessionId && { sessionId }),
            ...(deviceId && { deviceId })
          }
        }
      );

      dispatch({
        type: 'GET_MEDIA_ACCESS_SUCCESS',
        payload: res.data.data
      });

      return res.data;
    } catch (err) {
      dispatch({
        type: 'GET_MEDIA_ACCESS_FAILED',
        err
      });
      throw err;
    }
  };

export const clearMediaDetail = () => () => {
  return {
    type: 'CLEAR_MEDIA_DETAIL'
  };
};

export const clearMediaAccess = () => () => {
  return {
    type: 'CLEAR_MEDIA_ACCESS'
  };
};

export const getSingleMediaInfo = async (id) => {
  const res = await axios.get(
    `${BASE_URL}/catalog/v1.0/video/${id}?locale=en`,
    {
      headers: {
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    }
  );
  return res.data.data;
};
