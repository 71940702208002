import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuth } from '../../redux/actions/liveShow';
import CommentList from './CommentList';
import CommentInput from './CommentInput';
import CommentListFullscreen from './CommentListFullScreen';

import styles from './Comments.module.css';

const Comments = ({ isFullScreenMode, isVerticalVideo, multi }) => {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.token);
  const { webSocket } = useSelector((state) => state.liveShow);

  useEffect(() => {
    dispatch(sendAuth(webSocket, userToken));
  }, [webSocket, dispatch, userToken]);

  if (isFullScreenMode) {
    return <CommentListFullscreen isVerticalVideo={isVerticalVideo} />;
  }
  return (
    <div className={styles['commentWrapper']}>
      <div className={styles['titleWrapper']}>
        <h1 className={styles['title']}>Live Chat</h1>
      </div>
      <CommentList multi={multi} />
      <CommentInput />
    </div>
  );
};

export default Comments;
