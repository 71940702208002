import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './Comments.module.css';

const CommentItem = ({
  name,
  comment,
  commentNameColor,
  type,
  gift,
  amount
}) => {
  return (
    <div className={styles['commentItem']}>
      <div>
        <span
          className={styles['commentName']}
          style={{ color: commentNameColor }}
        >
          {name}
        </span>
        {type !== 'gift' && type !== 'bullet' && (
          <span className={styles['commentContent']}>{comment}</span>
        )}

        {type === 'bullet' && (
          <span className={styles['commentContent']}>
            Sent a bullet message
          </span>
        )}

        {type === 'gift' && gift && (
          <span className={styles['commentContent']}>Sent a {gift.name}</span>
        )}
      </div>

      {type === 'bullet' && (
        <span className={styles['bulletMessage']}>{comment}</span>
      )}
      {type === 'gift' && gift && (
        <div className={styles['giftCommentContainer']}>
          <img
            src={gift.icon}
            alt='gift'
          />{' '}
          <div>
            <p>X</p>
            {amount}
          </div>
        </div>
      )}
    </div>
  );
};

const CommentList = ({ isVerticalVideo }) => {
  const listWrapper = useRef();
  const list = useRef();
  const { wsError } = useSelector((state) => state.liveShow);
  const { comments } = useSelector((state) => state.liveShowComments);

  useEffect(() => {
    // setOverflow(list.current.offsetHeight > listWrapper.current.offsetHeight);
    listWrapper.current.scroll({
      top: listWrapper.current.offsetHeight + 200,
      behavior: 'smooth'
    });
  }, [listWrapper, list, comments]);

  return (
    <div
      ref={listWrapper}
      className={`${styles['commentListWrapper']} ${styles['hideScrollBar']}`}
    >
      <ul
        ref={list}
        className={styles['commentList']}
        data-fullscreen-comment-list
      >
        {comments &&
          comments.map((com, index) => {
            return (
              <li
                key={index}
                className={!isVerticalVideo ? styles['transparant'] : ''}
              >
                <CommentItem
                  name={com.profile.name}
                  comment={com.data}
                  commentNameColor={com.commentNameColor}
                  gift={com.gift}
                  type={com.type}
                  amount={com.amount}
                />
              </li>
            );
          })}
      </ul>
      {wsError && <p>Connection lost, reconnecting...</p>}
    </div>
  );
};

export default CommentList;
