import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button/Button';

import transactionRejected from '../../assets/transaction_rejected.svg';
import checkGreen from '../../assets/checkmark_green.svg';
import styles from './styles.module.css';
import { getMediaQuizChance } from '../../redux/actions/quiz';

const quizStatus = {
  success: {
    title: 'Selamat! Jawaban kamu benar!',
    description:
      'Kamu mendapatkan 1 undian/kesempatan untuk memenangkan hadiah.',
    icon: checkGreen
  },
  failed: {
    title: 'Jawaban kamu ada yang salah.',
    description: 'Kamu masih punya 1 (satu) kesempatan lagi.',
    icon: transactionRejected
  },
  failedAgain: {
    title: 'Jawaban kamu masih salah!',
    description: 'Coba lagi di episode berikutnya ya!',
    icon: transactionRejected
  },
  notEligible: {
    title: 'Kamu sudah menjawab pertanyaan kuis pada episode ini.',
    description: 'Silahkan mengikuti kuis di episode selanjutnya.'
  }
};

const QuizResult = ({
  status,
  onQuizStateChange,
  onQuizClose,
  onQuizStatusChange
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const videoId = useSelector((state) => state.quiz.quizDetail.videoId);
  const quizChance = useSelector((state) => state.quiz.quizChance);
  const quizCampaignId = useSelector(
    (state) => state.quiz.quizDetail.quizCampaign.id
  );
  return (
    <div className={styles['wrapper']}>
      <div className={styles['content']}>
        <img
          src={quizStatus[status]?.icon}
          alt=''
        />
        <div className={styles['header']}>
          {quizStatus[status]?.title}{' '}
          <div className={styles['counter']}>
            {quizStatus[status]?.description}
          </div>
        </div>
      </div>
      <div className={styles['action']}>
        {status === 'failed' && (
          <>
            <Button
              id='yes'
              width='343px'
              shape='rounded'
              onClick={async () => {
                await dispatch(getMediaQuizChance(videoId));
                if (quizChance) {
                  onQuizStateChange('quizQuestions');
                } else {
                  onQuizStatusChange('notEligible');
                }
              }}
            >
              Coba Lagi
            </Button>
            <Button
              id='no'
              color='dark'
              textColor='#F49832'
              width='343px'
              shape='rounded'
              onClick={onQuizClose}
            >
              Lanjutkan Menonton
            </Button>
          </>
        )}
        {status === 'failedAgain' && (
          <Button
            id='yes'
            width='343px'
            shape='rounded'
            onClick={() => {
              onQuizClose();
            }}
          >
            Tutup
          </Button>
        )}
        {status === 'notEligible' && (
          <Button
            id='yes'
            width='343px'
            shape='rounded'
            onClick={() => {
              onQuizClose();
            }}
          >
            Lanjutkan Menonton
          </Button>
        )}
        {status === 'success' && (
          <>
            <Button
              id='yes'
              width='343px'
              shape='rounded'
              onClick={onQuizClose}
            >
              Lanjutkan Menonton
            </Button>
            <Button
              id='no'
              color='dark'
              textColor='#F49832'
              width='343px'
              shape='rounded'
              onClick={() => {
                navigate(`/quiz/${quizCampaignId}`);
              }}
            >
              Lihat Campaign
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default QuizResult;
