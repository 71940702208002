const initialState = {
  // quiz list state
  data: [],
  loading: false,
  err: false,
  isNotEmpty: false,
  count: 0,

  // quiz detail state
  detailData: null,
  detailLoading: false,
  detailError: null,

  // quizQuestion
  quizDetail: null,
  quizChance: false
};

export default function quizReducer(state = initialState, action) {
  const { type, payload, err, isNotEmpty, count } = action;

  switch (type) {
    case 'GET_QUIZ_LIST':
      return {
        ...state,
        loading: true
      };

    case 'GET_QUIZ_LIST_SUCCESS':
      return {
        ...state,
        data: payload,
        loading: false,
        isNotEmpty,
        count
      };

    case 'GET_QUIZ_LIST_SUCCESS_FROM_STATE':
      return {
        ...state,
        data: payload,
        loading: false
      };

    case 'GET_QUIZ_LIST_FAILED':
      return {
        ...state,
        loading: false,
        err: true
      };

    case 'GET_QUIZ_DETAIL':
      return {
        ...state,
        detailLoading: true,
        detailError: null
      };

    case 'GET_QUIZ_DETAIL_SUCCESS':
      return {
        ...state,
        detailData: payload,
        detailLoading: false,
        detailError: null
      };

    case 'GET_QUIZ_DETAIL_FAILED':
      return {
        ...state,
        detailLoading: false,
        detailError: err
      };
    case 'GET_MEDIA_QUIZ_DETAIL':
      return {
        ...state,
        loading: true
      };

    case 'GET_MEDIA_QUIZ_DETAIL_SUCCESS':
      return {
        ...state,
        quizDetail: payload,
        loading: false
      };

    case 'GET_MEDIA_QUIZ_CHANCE_SUCCESS':
      return {
        ...state,
        quizChance: payload
      };

    case 'GET_MEDIA_QUIZ_DETAIL_ERROR':
      return {
        loading: false,
        err
      };

    default:
      return {
        ...state
      };
  }
}
