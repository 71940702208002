import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateHistory } from '../../redux/actions/history';
import { AnimatedModal, LivePlayer, IframePlayer } from '..';

const channelDuration = 86400;
const positionInSecond = 1000;

const ChannelPlayer = ({
  openPlayer,
  openIframe,
  dismissPlayer,
  sessionId
}) => {
  const position = useRef(0);
  const interval = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth && state.auth.user);
  const { partner } = useParams();

  useEffect(() => {
    const channelData = openPlayer || openIframe;
    if (partner === 'ioh' && user && channelData) {
      window.clevertap.event.push('IOH_Genflix_Watch_TV', {
        uid: user?.account?.id,
        msisdn: user?.msisdn || '',
        category: 'channel',
        partner_name: 'genflix',
        video_title: channelData?.titleLocalized,
        video_url: window.location.href.includes(channelData.id)
          ? window.location.href
          : window.location.href + channelData.id
      });
    }
  }, [user, openPlayer, openIframe, partner]);

  useEffect(() => {
    interval.current = setInterval(() => position.current++, positionInSecond);
    return () => {
      clearInterval(interval.current);
    };
  }, [openIframe]);

  const handleDismissPlayer = useCallback(() => {
    clearInterval(interval.current);
    position.current = 0;
    dismissPlayer();
  }, [dismissPlayer]);

  const handleUpdateHistory = (currentTime) => {
    const channel = openPlayer || openIframe;
    // handle current time 0 when user play for the first time
    // if the channel type is "webview" then send "position" from interval
    const timeToSend =
      currentTime >= 0 && channel.type !== 'webview'
        ? currentTime
        : position.current;
    try {
      const historyData = {
        mediaType: 'channel',
        mediaSubType: channel.type,
        mediaId: channel.id,
        position: timeToSend,
        length: channelDuration
      };

      dispatch(updateHistory(historyData, sessionId));
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  return (
    <AnimatedModal
      duration={300}
      open={!!openPlayer || !!openIframe}
      onClose={dismissPlayer}
    >
      <div className='video-player-wrapper'>
        {openPlayer && (
          <LivePlayer
            handleUpdateHistory={handleUpdateHistory}
            channelID={openPlayer.id}
            channelDetail={openPlayer}
            stream={openPlayer && openPlayer.url}
            onDismissPlayer={handleDismissPlayer}
            user={user}
          />
        )}

        {openIframe && (
          <IframePlayer
            handleUpdateHistory={handleUpdateHistory}
            src={openIframe.url}
            title={openIframe.titleLocalized}
            onDismissPlayer={handleDismissPlayer}
          />
        )}
      </div>
    </AnimatedModal>
  );
};

export default ChannelPlayer;
