import React from 'react';
import { TextContent } from '..';

import styles from './LoginDialog.module.css';
import icClose from '../../assets/ic-close.svg';
import { termUGCList, privacyPolicyList } from './TNCText';

const TNCDialog = ({ onClose, type }) => {
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <div className={styles['container-tnc']}>
      <div className={styles['header-tnc']}>
        <img
          src={icClose}
          alt='close'
          onClick={handleCloseModal}
        />
      </div>
      <div className={styles['scrollable']}>
        <TextContent
          data={
            type === 'ugc'
              ? termUGCList
              : type === 'privacy' && privacyPolicyList
          }
          showLanguageOption
        />
      </div>
    </div>
  );
};

export default TNCDialog;
