import { useEffect } from 'react';
import history from './history';

// eslint-disable-next-line import/prefer-default-export
export function useBlocker(blocker, when = false) {
  useEffect(() => {
    if (!when) return;

    const unblock = history.block((event) => {
      const autoUnblockingTx = {
        ...event,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          event.retry();
        }
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [blocker, when]);
}
