import React, { Suspense, useLayoutEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { getValue, fetchAndActivate } from 'firebase/remote-config';
import { Header, Footer, Loading } from './components';
import { AppWrapper, MaintenancePage } from './pages';
import ScrollToTop from './ScrollToTop';
import IndexRoute, { MobileRoutes } from './routes';
// import { FreeContentsRoutes } from './routes/mobile';

import FirebasePushNotif from './components/Notification/FirebasePushNotif';
import { configKeys, remoteConfig } from './service/firebase';
import { pruneSession, updateTokenState } from './redux/actions/auth';
import { generateErrorDetail } from './utils';

function App() {
  const [loading, setLoading] = useState(true);
  const [isMaintenance, setIsMaintenance] = useState();
  const dispatch = useDispatch();
  const location = useLocation();

  const getRemoteConfigValue = async () => {
    setLoading(true);
    try {
      await fetchAndActivate(remoteConfig);
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({ type: 'GET_APP_SETTINGS_FAILED', err: errorData });
    } finally {
      setLoading(false);

      const maintenance = getValue(remoteConfig, configKeys.isMaintenance);
      setIsMaintenance(maintenance.asBoolean());
    }
  };

  useLayoutEffect(() => {
    async function initialize() {
      await getRemoteConfigValue();
      dispatch(pruneSession());
      window.addEventListener('storage', (e) => {
        if (e.key === 'token' || e.key === 'refreshToken') {
          dispatch(updateTokenState());
        }
      });
    }
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || typeof isMaintenance !== 'boolean') {
    return <Loading height='100vh' />;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Routes>
        <Route
          path='*'
          element={
            <ScrollToTop>
              <Suspense fallback={<Loading height='100vh' />}>
                {isMaintenance ? (
                  <MaintenancePage />
                ) : (
                  <Routes>
                    <Route
                      path='/mobile/*'
                      Component={MobileRoutes}
                    />
                    <Route
                      path='/*'
                      Component={() => {
                        const showMenu =
                          location.pathname !== '/streamer-dashboard';
                        return (
                          <AppWrapper>
                            <FirebasePushNotif />
                            <Header showHeaderMenu={showMenu} />
                            <IndexRoute />
                            <Footer />
                          </AppWrapper>
                        );
                      }}
                    />
                  </Routes>
                )}
              </Suspense>
            </ScrollToTop>
          }
        />
      </Routes>
    </GoogleOAuthProvider>
  );
}

export default App;
