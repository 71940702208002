import React from 'react';
import { Helmet } from 'react-helmet';

export default function Metadata({
  url,
  title,
  description,
  quote,
  image,
  hashtag,
  name
}) {
  return (
    <Helmet>
      <meta charset='utf-8' />
      <meta
        httpEquiv='X-UA-Compatible'
        content='IE=edge'
      />
      <meta
        name='csrf_token'
        content=''
      />
      <meta
        property='type'
        content='website'
      />
      <meta
        property='url'
        content={url}
      />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, shrink-to-fit=no'
      />
      <meta
        name='msapplication-TileColor'
        content='#ffffff'
      />
      <meta
        name='msapplication-TileImage'
        content='/ms-icon-144x144.png'
      />
      <meta
        name='theme-color'
        content='#ffffff'
      />
      <meta
        name='_token'
        content=''
      />
      <meta
        name='robots'
        content='noodp'
      />

      <meta
        name='title'
        content={title}
      />
      <meta
        name='description'
        content={description}
      />

      <meta
        property='title'
        content={title}
      />
      <meta
        property='quote'
        content={quote}
      />
      <meta
        property='description'
        content={description}
      />
      <meta
        property='image'
        content={image}
      />

      <meta
        itemProp='name'
        content={title}
      />
      <meta
        itemProp='description'
        content={description}
      />
      <meta
        itemProp='image'
        content={image}
      />
      <meta
        itemProp='url'
        content={url}
      />

      <meta
        property='og:locale'
        content='en_US'
      />
      <meta
        property='og:type'
        content='website'
      />
      <meta
        property='og:site_name'
        content={name}
      />
      <meta
        property='og:title'
        content={title}
      />
      <meta
        property='og:quote'
        content={quote}
      />
      <meta
        property='og:hashtag'
        content={hashtag}
      />
      <meta
        property='og:description'
        content={description}
      />
      <meta
        property='og:image'
        content={image}
      />
      <meta
        property='og:image:type'
        content='image/*'
      />
      <meta
        property='og:url'
        content={url}
      />

      <meta
        name='twitter:card'
        content='summary_large_image'
      />
      <meta
        name='twitter:title'
        content={title}
      />
      <meta
        name='twitter:description'
        content={description}
      />
      <meta
        name='twitter:image'
        content={image}
      />
      <meta
        name='twitter:url'
        content={url}
      />
    </Helmet>
  );
}
