import axios from 'axios';
import { BASE_URL, JWT_APP_ID, BASE_SOCKET_URL } from '../../config/URL';
import { showToastNotif } from './notification';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { generatedRandomColor, generateErrorDetail } from '../../utils';
import { getPublicProfile } from './publicProfile';

// const URL_LIVE_SHOWS = `${BASE_URL}/catalog/v1.0/liveShows`;
const URL_LIVE_SHOW = `${BASE_URL}/catalog/v1.0/liveShow`;
const URL_CATEGORY_LIVE_SHOWS = `${BASE_URL}/catalog/v1.0/showCategories`;
const URL_COMMENTS = `${BASE_URL}/catalog/v1.0/liveShow`;
const SOCKET_URL = `${BASE_SOCKET_URL}/interaction/v1/comments`;
const URL_PLAYLIST = '/catalog/v1.1/playlist/';

export const getLiveShows =
  ({
    page,
    perPage,
    categoryId = null,
    profileId = null,
    status = 'live',
    autoLoadOnScroll
  }) =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: 'GET_LIVE_SHOWS',
      page
    });

    try {
      const res = await axios.get(
        `${BASE_URL}/catalog/${autoLoadOnScroll ? 'v1.0' : 'v1.1'}/liveShows`,
        {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          },
          params: {
            ...(page && { page }),
            ...(perPage && { perPage }),
            ...(categoryId && { categoryId }),
            ...(profileId && { profileId }),
            ...(status && { status }),
            mostwatch: 1
          }
        }
      );
      const payload = {
        data: res.data?.data?.map((item) => {
          item.subType = item.type;
          item.type = 'liveshow';

          return item;
        }),
        meta: res.data?.meta
      };
      dispatch({
        type: 'GET_LIVE_SHOWS_SUCCESS',
        payload,
        page
      });
      return payload;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_LIVE_SHOWS_FAILURE',
        err: errorData
      });
    }
  };

export const sendBulletComment = (comment) => async (dispatch, getState) => {
  const state = getState();
  const { user, token } = state.auth;
  const liveShow = state.liveShow.selectedLiveshow;
  const bulletOption = state.pack.bulletOption;
  const bullet = bulletOption[0];
  const diamonds = user.account.diamonds;

  const payload = {
    purpose: 'transfer',
    packId: bullet.packs[0].id.toString(),
    receiverId: liveShow.profile.accountId,
    showId: liveShow.id,
    comment
  };

  try {
    if (diamonds <= 0) {
      return dispatch(showToastNotif('Notification', 'Diamonds is not enough'));
    }
    const res = await axios.post(
      `${BASE_URL}/loyalty/v1.0/transactions`,
      payload,
      {
        headers: {
          Authorization: token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      }
    );

    analytic(analyticTypes.event, analyticEvents.LIVESHOW.SEND_COMMENT, {
      params: payload,
      user
    });

    return res.data;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    // if (err.response.data.error.code === errorCode.DIAMOND_NOT_ENOUGH) {
    //    dispatch(showToastNotif('error', 'Transfer diamonds is not enough'))
    // }
    dispatch({
      type: 'TRANSEFER_GIFT_FAILURE',
      err: errorData
    });
  }
};

// export const sendGift =
//   ({ packId, receiverId, showId, videoId, giftType }) =>
//   async (dispatch, getState) => {
//     const state = getState();
//     const { user, token } = state.auth;

//     const payload = {
//       purpose: 'transfer',
//       packId: packId.toString(),
//       receiverId,
//       giftType,
//       ...(videoId && { videoId }),
//       ...(showId && { showId })
//     };

//     try {
//       const res = await axios.post(
//         `${BASE_URL}/loyalty/v1.0/transactions`,
//         payload,
//         {
//           headers: {
//             Authorization: token,
//             'Cloudfront-JWT-AppId': JWT_APP_ID
//           }
//         }
//       );

//       analytic(analyticTypes.event, analyticEvents.LIVESHOW.SEND_GIFT, {
//         params: payload,
//         user
//       });

//       return res.data;
//     } catch (err) {
//       const errorData = generateErrorDetail(err);
//       // if (err.response.data.error.code === errorCode.DIAMOND_NOT_ENOUGH) {
//       //   dispatch(showToastNotif('error', 'Transfer diamonds is not enough'))
//       // }
//       dispatch({
//         type: 'GET_LIVE_SHOWS_FAILURE',
//         err: errorData
//       });

//       throw err;
//     }
//   };

export const getCategoryLiveShows =
  ({ page = 1, perPage = 10 }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { dataCategory, metaCategory, pageCategory } = state.liveShow;
    dispatch({
      type: 'GET_CATEGORY_LIVE_SHOWS'
    });

    try {
      if (!dataCategory.length) {
        const res = await axios.get(
          `${URL_CATEGORY_LIVE_SHOWS}?page=${page}&perPage=${perPage}`,
          {
            headers: {
              Authorization: state.auth.token,
              'Cloudfront-JWT-AppId': JWT_APP_ID
            }
          }
        );
        if (page === 1) {
          res.data.data.unshift({
            id: null,
            name: 'All',
            iconUrl: null
          });
        }

        dispatch({
          type: 'GET_CATEGORY_LIVE_SHOWS_SUCCESS',
          payload: {
            ...res.data
          },
          page
        });
      } else {
        dispatch({
          type: 'GET_CATEGORY_LIVE_SHOWS_SUCCESS_FROM_STATE',
          payload: { data: dataCategory, meta: metaCategory },
          page: pageCategory
        });
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_CATEGORY_LIVE_SHOWS_FAILURE',
        err: errorData
      });
    }
  };

export const getLiveShowDetail = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    const res = await axios.get(`${URL_LIVE_SHOW}/${id}`, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });
    await dispatch(getPublicProfile(res.data.data.profile.id));
    const liveShowData = res.data.data;
    dispatch({
      type: 'SET_SELECTED_LIVE_SHOW',
      payload: liveShowData
    });

    if (liveShowData?.guests) {
      dispatch({
        type: 'SET_HOSTS_POSITION',
        payload: liveShowData?.guests || []
      });
    }

    return liveShowData;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    return errorData;
  }
};

// handle when liveshow ended
export const setStreamStop = (message) => (dispatch) => {
  dispatch({
    type: 'SET_STREAM_STOP',
    payload: { data: message }
  });
};

export const setStreamBlock = (message) => (dispatch) => {
  dispatch({
    type: 'SET_STREAM_BLOCK',
    payload: { data: message }
  });
};

// live show comments
export const getComments = (liveShowID, limit) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${URL_COMMENTS}/${liveShowID}/comments?limit=${limit}`
    );

    const commentList = res.data.data.map((comment) => {
      return {
        ...comment,
        commentNameColor: generatedRandomColor(comment.profile.id)
      };
    });

    dispatch({
      type: 'SET_LIVE_SHOW_COMMENTS',
      payload: {
        data: commentList
      }
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_LIVE_SHOW_COMMENTS_FAILURE',
      err: errorData
    });
  }
};

function waitForSocketConnection(socket, callback) {
  setTimeout(function () {
    switch (socket.readyState) {
      case WebSocket.OPEN:
        // eslint-disable-next-line no-console
        console.log('Connection is made');
        if (callback != null) {
          callback();
        }

        break;
      case WebSocket.CONNECTING:
        // eslint-disable-next-line no-console
        console.log('wait for connection...');
        waitForSocketConnection(socket, callback);

        break;
      case WebSocket.CLOSING:
        // eslint-disable-next-line no-console
        console.log('closing connection');

        break;
      case WebSocket.CLOSED:
        // eslint-disable-next-line no-console
        console.log('connection closed');

        break;
      default:
        break;
    }
  }, 5); // wait 5 milisecond for the connection...
}

export const connectSocket = (liveShowID) => (dispatch, getState) => {
  const { auth, liveShow, liveShowComments } = getState();
  const accountId = auth?.user?.profile?.id;
  const ws = new WebSocket(`${`${SOCKET_URL}/${liveShowID}`}`);
  ws.onopen = () => {
    // eslint-disable-next-line no-console
    console.log('connected to server');
    dispatch({
      type: 'SET_WEB_SOCKET_ERROR',
      payload: false
    });
  };
  ws.onclose = (e) => {
    // eslint-disable-next-line no-console
    console.log('socket closed', {
      code: e.code,
      reason: e.reason,
      wasClean: e.wasClean
    });

    // error code 1006 = abnormal closure (Indicates that a connection was closed with no close frame being sent)
    if (e.code === 1006 && !e.wasClean) {
      setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log('connection lost, reconnecting socket');

        dispatch({
          type: 'SET_WEB_SOCKET_ERROR',
          payload: true
        });

        dispatch(connectSocket(liveShowID));
      }, 3000);
    }

    if (e.wasClean) {
      dispatch({
        type: 'CLOSE_WEB_SOCKET_CONNECTION'
      });
    }
  };
  ws.onerror = (err) => {
    if (err && err.target && err.target.readyState === WebSocket.OPEN) {
      dispatch({
        type: 'SET_WEB_SOCKET_ERROR',
        payload: true
      });

      return dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
    }

    // eslint-disable-next-line no-console
    console.error(err.message);
    ws.close();
  };
  ws.onmessage = (event) => {
    const message = JSON.parse(event.data);
    // console.log(JSON.stringify(message));

    switch (message.type) {
      case 'comment':
        dispatch({
          type: 'ADD_LIVE_SHOW_COMMENTS',
          payload: {
            data: {
              ...message,
              commentNameColor: generatedRandomColor(message.profile.id)
            }
          }
        });

        break;
      case 'viewers':
        dispatch({
          type: 'SET_LIVE_SHOW_VIEWS',
          payload: { data: Number(message.data) }
        });

        break;
      case 'stop':
        dispatch(setStreamStop(message));

        break;
      case 'block':
        message.data === accountId && dispatch(setStreamBlock(message));

        break;
      case 'news':
        dispatch({
          type: 'SET_LAST_JOIN',
          payload: { data: message }
        });

        dispatch({
          type: 'ADD_LIVE_SHOW_COMMENTS',
          payload: {
            data: {
              ...message,
              data: 'has entered chat',
              commentNameColor: generatedRandomColor(message.profile.id)
            }
          }
        });

        setTimeout(() => {
          dispatch({
            type: 'SET_LAST_JOIN',
            payload: { data: null }
          });
        }, 3000);

        break;
      case 'gift':
        const giftid = new Date().getTime().toString(36);
        if (message.gift.category === 'premium') {
          dispatch({
            type: 'SET_PREMIUM_GIFT',
            payload: { data: message }
          });
        } else {
          dispatch({
            type: 'SET_BASIC_GIFT',
            payload: { data: { ...message, id: giftid, show: true } }
          });
          if (liveShowComments.hosts.length > 0) {
            // check if host is in live show. If it's only solo, don't update hosts gencash
            dispatch({
              type: 'UPDATE_HOSTS_GENCASH',
              payload: message
            });
          }
        }

        dispatch({
          type: 'ADD_LIVE_SHOW_COMMENTS',
          payload: {
            data: {
              ...message,
              commentNameColor: generatedRandomColor(message.profile.id)
            }
          }
        });

        setTimeout(
          () => {
            dispatch({
              type: 'HIDE_BASIC_GIFT',
              payload: { id: giftid }
            });
          },
          message.gift.duration * 1000 + 500
        );

        break;
      case 'bullet':
        const bulletid = new Date().getTime().toString(36);
        dispatch({
          type: 'SET_BULLET_COMMENT',
          payload: {
            data: {
              ...message,
              id: bulletid,
              show: true,
              commentNameColor: generatedRandomColor(message.profile.id)
            }
          }
        });

        setTimeout(
          () => {
            dispatch({
              type: 'HIDE_BULLET_COMMENT',
              payload: { id: bulletid }
            });
            // setTimeout(() => {
            //   dispatch({
            //     type: 'REMOVE_BULLET_COMMENT',
            //     payload: { id }
            //   })
            // }, 1000);
          },
          message.amount * 1000 + 500
        );

        break;
      case 'position':
        const hostProfile = liveShow?.profile;
        const guests = JSON.parse(message?.data) || [];

        dispatch({
          type: 'SET_HOSTS_POSITION',
          payload:
            hostProfile && !guests.find((item) => item.id === hostProfile?.id)
              ? [hostProfile, ...guests]
              : guests
        });

        break;
      case 'mute':
        dispatch({
          type: 'SET_HOSTS_MUTED',
          payload: message
        });

        break;
      default:
        break;
    }

    dispatch(getLiveShowViewers({ liveshowId: liveShowID }));
  };

  dispatch({
    type: 'SET_WEB_SOCKET_CONNECTION',
    payload: ws
  });

  return ws;
};

export const sendAuth = (ws, token) => () => {
  waitForSocketConnection(ws, () => {
    const payload = { type: 'authorization', data: token };
    ws.send(JSON.stringify(payload));
  });
};

export const addComment = (ws, comment) => (dispatch, getState) => {
  const state = getState();
  const user = state.auth.user;
  const { selectedLiveshow } = state.liveShow;

  waitForSocketConnection(ws, () => {
    const payload = { type: 'comment', data: comment };
    ws.send(JSON.stringify(payload));

    const paramsEvent = {
      comment,
      streamID: selectedLiveshow.id,
      streamerProfile: selectedLiveshow.profile
    };
    analytic(analyticTypes.event, analyticEvents.LIVESHOW.SEND_COMMENT, {
      params: paramsEvent,
      user
    });
  });
};

export const resetLiveShowComments = () => (dispatch) => {
  dispatch({
    type: 'RESET_LIVE_SHOW_COMMENTS'
  });
};

export const resetLiveshowError = () => (dispatch) => {
  dispatch({
    type: 'RESET_LIVESHOW_ERROR'
  });
};

export const nextPremiumGift = () => (dispatch) => {
  dispatch({
    type: 'NEXT_PREMIUM_GIFT'
  });
};

export const getLiveShowVod =
  ({ id, page, perPage = 10, categoryId }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { vodData, vodMeta } = state.liveShow;

    dispatch({
      type: 'GET_LIVE_SHOW_VOD'
    });
    try {
      if (
        vodData?.length > 1 &&
        (page === vodMeta?.page ||
          vodMeta.page === vodMeta?.totalPages ||
          page <= vodMeta?.page)
      ) {
        dispatch({
          type: 'GET_LIVE_SHOW_VOD_SUCCESS_FROM_STATE',
          payload: state.liveShow,
          page
        });
      } else {
        const res = await axios.get(`${BASE_URL + URL_PLAYLIST + id}/items`, {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          },
          params: {
            page,
            perPage,
            locale: 'en',
            ...(categoryId && { categoryId })
          }
        });

        dispatch({
          type: 'GET_LIVE_SHOW_VOD_SUCCESS',
          payload: res.data,
          page
        });
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_LIVE_SHOW_VOD_FAILED',
        err: errorData
      });
    }
  };

export const getLiveShowViewers =
  ({ liveshowId, page, perPage }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;

    dispatch({
      type: 'GET_LIVE_SHOW_VIEWERS'
    });

    try {
      const res = await axios.get(
        `${URL_LIVE_SHOW}/${liveshowId}/liveViewers`,
        {
          params: {
            ...(page && { page }),
            ...(perPage && { perPage })
          },
          headers: {
            Authorization: token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'GET_LIVE_SHOW_VIEWERS_SUCCESS',
        payload: res.data.data
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_LIVE_SHOW_VIEWERS_FAILED',
        err: errorData
      });
    }
  };

export const getUpcomingLiveShowById =
  (upcomingShowId, remindProfileId) => async (dispatch) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/catalog/v1.0/upcomingLiveshow/${upcomingShowId}?remindProfileId=${
          remindProfileId || ''
        }`
      );

      return res?.data?.data;
    } catch (error) {
      dispatch(showToastNotif('error', 'Failed to get upcoming live detail'));
    }
  };
