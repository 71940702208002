import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';
import { getGencashTransactions } from '../../redux/actions/pack';
import { currencyFormatter, formatDateV2, numberFormat } from '../../utils';
import Loading from '../Loading/Loading';
import styles from './transaction.module.css';

const statusMessage = {
  processing: 'In process',
  completed: 'Completed',
  failed: 'Rejected'
};

const GencashHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { gencashTransactions, loading, err } = useSelector(
    (state) => state.pack
  );
  const { page } = gencashTransactions;
  const loadCompleted =
    !loading &&
    gencashTransactions?.data &&
    gencashTransactions?.meta &&
    gencashTransactions?.meta?.total <= gencashTransactions?.data?.length;

  useEffect(() => {
    dispatch(getGencashTransactions({ page }));
  }, [dispatch, page]);

  const fetchNextPage = () => {
    dispatch({ type: 'FETCH_NEXT_PAGE_GENCASH_TRANSACTION' });
  };

  const handleClickItem = (item) => {
    navigate({
      state: item,
      pathname: `${location.pathname}/${item.id}`
    });
  };

  return (
    <div className={styles['listWrapper']}>
      {!gencashTransactions.data.length && (
        <div className={styles['packagesWrapper']}>
          <div className={styles['noData']}>
            <div>No History Cash out</div>
            <div>Your history Cash out will show up here.</div>
          </div>
        </div>
      )}
      {gencashTransactions?.data?.map((item) => {
        const expenses = item?.expenses?.[0];

        return (
          <div
            key={item.id}
            className={styles['itemWrapper']}
          >
            <div
              className={styles['detail']}
              data-clickable
              onClick={() => handleClickItem(item)}
            >
              <h3
                className={styles['title']}
                data-cursor-pointer
              >
                Cash out {numberFormat.format(expenses?.gencash)} Gencash to{' '}
                {currencyFormatter.format(+(expenses?.amount || 0))}
              </h3>
              <span
                className={styles['subtitle']}
                data-cursor-pointer
              >
                {expenses?.invoiceNumber}
              </span>
              <span className={styles['date']}>
                {formatDateV2(item?.createdAt, 'dd mmm yyyy, hh:mm')}
              </span>
            </div>
            <div className={styles['action']}>
              <span className={styles['status']}>
                Status:{' '}
                <span data-status={item.status}>
                  {statusMessage[item.status]}
                </span>
              </span>
            </div>
          </div>
        );
      })}
      {!err && !loadCompleted && (
        <InView
          onChange={(inView) => {
            inView && gencashTransactions?.data?.length && fetchNextPage();
          }}
        >
          <Loading />
        </InView>
      )}
    </div>
  );
};

export default GencashHistory;
