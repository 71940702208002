import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';
import { showToastNotif } from './notification';

export const redeemVoucher = (code) => async (dispatch, getState) => {
  const state = getState();
  const response = await axios.post(
    `${BASE_URL}/subscription/v1.0/transactions`,
    {
      type: 'voucher',
      code
    },
    {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    }
  );
  return response.data;
};

export const getSubscriptionTransactions =
  ({ page, perPage = 10 }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { subscriptionTransactions } = state.pack;
    const { data, meta, noData } = subscriptionTransactions;

    dispatch({ type: 'GET_SUBSCRIPTION_TRANSACTION' });

    const getResSubscriptionTransactions = async () => {
      return axios.get(`${BASE_URL}/subscription/v1.1/subscriptions`, {
        headers: {
          Authorization: state.auth.token
        },
        params: {
          page,
          perPage
        }
      });
    };

    try {
      if (noData || (data?.length && (page === 1 || page === meta?.page))) {
        dispatch({
          type: 'GET_SUBSCRIPTION_TRANSACTION_SUCCESS_FROM_STATE',
          payload: { data, meta }
        });
        return;
      }
      const res = await getResSubscriptionTransactions();
      dispatch({
        type: 'GET_SUBSCRIPTION_TRANSACTION_SUCCESS',
        payload: res.data,
        page
      });
      return res.data;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_SUBSCRIPTION_TRANSACTION_ERROR',
        payload: errorData
      });
      throw err;
    }
  };

export const getAccountSubscriptions =
  ({ page, perPage }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { data, meta } = state.subscriptions;

    if (data?.length && meta?.page <= page) {
      return;
    }

    dispatch({ type: 'GET_ACCOUNT_SUBSCRIPTIONS' });

    try {
      const res = await axios.get(
        `${BASE_URL}/subscription/v1.1/subscriptions`,
        {
          headers: {
            Authorization: state.auth.token
          },
          params: {
            page,
            perPage
          }
        }
      );
      dispatch({
        type: 'GET_ACCOUNT_SUBSCRIPTIONS_SUCCESS',
        payload: res.data,
        page
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_ACCOUNT_SUBSCRIPTIONS_FAILED',
        payload: errorData
      });
    }
  };
