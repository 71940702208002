import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useMatch,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { errorCode } from '../../constants';
import Loading from '../Loading/Loading';
import Section from '../Section/Section';
import { useUserDetail } from '../../hooks';
import { generateSelectChannelURL } from '../../utils';
import PlaylistPageView from '../PlaylistPageView/PlaylistPageView';

const SectionRails = ({
  item,
  topSection,
  itemType,
  isExclusive,
  handleSelectChannel,
  additionalURL
}) => {
  if (isExclusive) {
    return (
      <PlaylistPageView
        title={item.titleLocalized}
        data={item.items}
        completed
      />
    );
  }
  return (
    <Section
      id={item.id}
      name={item.titleLocalized}
      sectionData={item.items}
      topSection={topSection}
      allowSeeAll={!!item.id}
      exclusiveContents={isExclusive}
      onChannelSelected={handleSelectChannel}
      itemType={itemType}
      additionalURL={additionalURL}
    />
  );
};

const MobileMediaPageView = () => {
  const location = useLocation();
  const match = useMatch(location.pathname);
  const params = useParams();
  const navigate = useNavigate();
  const { data: pageData, err } = useSelector((state) => state.page);
  const pageName = useMemo(() => params?.pageName || 'home', [params]);
  const currentPage = useMemo(() => {
    return pageData?.find((page) => page.friendlyId === pageName);
  }, [pageData, pageName]);
  const isExclusive =
    currentPage?.friendlyId?.toLowerCase() === 'allowed-content';
  const partner = match?.params?.partner;
  const { findUserSubsByName } = useUserDetail();
  const hasPartnerSubs = findUserSubsByName(partner);
  const additionalURL = `/mobile/${partner}`;

  const handleSelectChannel = (item) => {
    const channelURL = generateSelectChannelURL(item, {
      ...(hasPartnerSubs && { additionalFrontURL: additionalURL })
    });

    navigate(channelURL);
  };

  if (err) {
    return (
      <div className='flex-middle-container'>
        <p className='error'>
          {err?.code === errorCode.INVALID_SESSION_AUTH
            ? 'Authentication Failed, Please Try Again.'
            : 'An Error Occurred, Please Try Again Later.'}
        </p>
      </div>
    );
  }

  if (!pageData?.length && !err) {
    return <Loading height='100vh' />;
  }

  if (!currentPage) {
    return navigate('/mobile/not-found');
  }

  return currentPage?.items?.map((section) => {
    return (
      <SectionRails
        key={section?.id}
        item={section}
        isExclusive={isExclusive}
        handleSelectChannel={handleSelectChannel}
        additionalURL={additionalURL}
      />
    );
  });
};

export default MobileMediaPageView;
