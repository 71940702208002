import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';
import { getProfilesById } from './clips';
import { clipsID } from '../../constants';

// eslint-disable-next-line import/prefer-default-export
export const getPages =
  ({ showEligibleContent } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const { user, token } = state.auth;
    const isLoggedIn = user && token;

    try {
      const response = await axios.get(
        `${BASE_URL}/catalog/v1.1/pages?locale=en`,
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID,
            ...(isLoggedIn && { Authorization: state.auth.token }),
            ...(isLoggedIn && {
              'cloudfront-jwt-accountid': user?.account?.id
            }),
            ...(isLoggedIn && {
              'cloudfront-jwt-profileid': user?.profile?.id
            })
          },
          params: {
            showEligibleContent
          }
        }
      );

      const pageData = response.data.data;
      const formatPageData = pageData.map((data) => ({
        ...data,
        friendlyId: data.friendlyId.toLowerCase()
      }));

      if (formatPageData.some((item) => item.friendlyId === 'home')) {
        const homeIndex = formatPageData.findIndex(
          (page) => page.friendlyId === 'home'
        );
        if (
          formatPageData[homeIndex].items.some(
            (playlist) => playlist.id === clipsID
          )
        ) {
          const clipsIndex = formatPageData[homeIndex].items.findIndex(
            (playlist) => playlist.id === clipsID
          );

          const ugcList = formatPageData[homeIndex].items[clipsIndex].items;

          const profileIds = ugcList.flatMap((item) => item.tags.profileIds);
          const profileData = await dispatch(
            getProfilesById({
              profileIds,
              page: 1,
              perPage: 1
            })
          );

          const updatedClips = ugcList.map((clip) => {
            const profileForMovie = profileData.find((profile) =>
              clip.tags.profileIds.includes(profile.id)
            );
            return { ...clip, profile: profileForMovie };
          });

          formatPageData[homeIndex].items[clipsIndex].items = updatedClips;
        }
      }

      dispatch({
        type: 'GET_PAGES'
      });

      dispatch({
        type: 'GET_PAGES_SUCCESS',
        payload: formatPageData
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);

      dispatch({
        type: 'GET_PAGES_FAILED',
        err: errorData
      });
    }
  };
