import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';
import { showToastNotif } from './notification';

const CAMPAIGN_CAROUSEL_URL = `${BASE_URL}/catalog/v1.0/quizzes`;
const CAMPAIGN_DETAIL_URL = `${BASE_URL}/catalog/v1.0/quiz`;

export const getMediaQuizInfo = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_MEDIA_QUIZ_DETAIL'
    });

    const res = await axios.get(
      `${BASE_URL}/catalog/v1.0/video/${id}/quizForContent`,
      {
        headers: {
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      }
    );

    const data = res.data.data;

    dispatch({
      type: 'GET_MEDIA_QUIZ_DETAIL_SUCCESS',
      payload: data
    });
    return data;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_MEDIA_QUIZ_DETAIL_ERROR',
      err: errorData
    });
  }
};

export const submitMediaQuiz =
  (quizContentId, answers) => async (dispatch, getState) => {
    const state = getState();
    try {
      const res = await axios.post(
        `${BASE_URL}/catalog/v1.0/quizzes/submitAnswer`,
        {
          quizContentId,
          answer: answers
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID,
            Authorization: state.auth.token
          }
        }
      );

      const data = res.data.data.isAllCorrect;

      return data;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      throw errorData;
    }
  };

export const getMediaQuizChance = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    const res = await axios.get(
      `${BASE_URL}/catalog/v1.0/video/${id}/quizChance`,
      {
        headers: {
          Authorization: state.auth.token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      }
    );

    const data = res.data.data.isAllowed;
    dispatch({
      type: 'GET_MEDIA_QUIZ_CHANCE_SUCCESS',
      payload: data
    });
    return data;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    return errorData;
  }
};

export const getQuizList = () => async (dispatch, getState) => {
  const state = getState();
  const { data, isNotEmpty, count } = state.quiz;

  dispatch({
    type: 'GET_QUIZ_LIST'
  });

  try {
    if ((!data.length || !isNotEmpty) && count < 1) {
      const res = await axios.get(CAMPAIGN_CAROUSEL_URL, {
        headers: {
          'Cloudfront-JWT-AppId': JWT_APP_ID,
          Authorization: state.auth.token
        }
      });

      dispatch({
        type: 'GET_QUIZ_LIST_SUCCESS',
        payload: res.data?.data
          ? res.data?.data?.map((item) => ({ ...item, type: 'campaign' }))
          : [],
        isNotEmpty: res.data?.data?.length > 0 || res.data?.data?.length > 0,
        count: count + 1
      });
    } else {
      dispatch({
        type: 'GET_QUIZ_LIST_SUCCESS_FROM_STATE',
        payload: data
      });
    }
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch(
      showToastNotif('error', 'Some error occured, please refresh the page!')
    );
    dispatch({
      type: 'GET_QUIZ_LIST_FAILED',
      err: errorData
    });
  }
};

export const getQuizDetail = (campaignId) => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: 'GET_QUIZ_DETAIL'
  });

  try {
    const res = await axios.get(`${CAMPAIGN_DETAIL_URL}/${campaignId}`, {
      headers: {
        'Cloudfront-JWT-AppId': JWT_APP_ID,
        Authorization: state.auth.token
      }
    });

    dispatch({
      type: 'GET_QUIZ_DETAIL_SUCCESS',
      payload: res.data.data
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_QUIZ_DETAIL_FAILED',
      err: errorData
    });
  }
};
