import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '..';
import {
  formatDateV2,
  formatDuration,
  formatSecsDuration,
  generateDetailUrl,
  generateGenres,
  generateSelectChannelURL,
  getDetailPoster,
  getInitial
} from '../../utils';
import MediaPlayer from '../MediaPlayer/MediaPlayer';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';

import styles from './ContentBanner.module.css';
import icPlay from '../../assets/ic-play.svg';
import icInfo from '../../assets/ic-info.svg';
import icNotif from '../../assets/ic-notif.svg';
import icDate from '../../assets/ic-date.svg';
import icClock from '../../assets/ic-clock.svg';
import icCheck from '../../assets/following-check.svg';

const generateDetailData = (data, carouselType) => {
  switch (carouselType) {
    case 'campaign':
      return {
        title: data.props.header,
        thumbnail: getDetailPoster(data)
      };
    case 'upcoming-live':
      return data;
    case 'clips':
      return {
        title: data.titleLocalized,
        thumbnail: getDetailPoster(data),
        tags: data?.tags,
        profile: data?.profile
      };
    default:
      return {
        title: data.titleLocalized,
        description: data.descriptionLongLocalized,
        rating: data.rating,
        genres: generateGenres(data.tags),
        duration: data.duration,
        year: data.year,
        thumbnail: getDetailPoster(data)
      };
  }
};

const defaultDetailOrder = ['title', 'tags', 'description', 'actions'];

const icons = {
  play: icPlay,
  info: icInfo,
  notif: icNotif,
  check: icCheck
};

const defaultButtonActions = [
  {
    label: 'See Detail',
    textColor: 'primary',
    shape: 'rounded',
    size: 'large',
    onclick: 'play'
  }
];

const ContentBanner = ({
  item,
  carouselType,
  itemDirection,
  alignItems = 'center',
  detailOrder = defaultDetailOrder,
  showBannerBackground = true,
  height,
  padding,
  buttonActions = defaultButtonActions,
  checkBlockUnblock,
  handleClickRemind
}) => {
  const navigate = useNavigate();
  const [playVideo, setPlayVideo] = useState();

  const { token, user } = useSelector((state) => state.auth);
  const clipsType = carouselType === 'clips';
  const upcomingLiveType = carouselType === 'upcoming-live';

  const detailData = generateDetailData(item, carouselType);

  const thumbnailRef = useRef();
  const [width, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.outerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const onResize = () => {
      setTimeout(() => {
        const slickDots = document.querySelector(
          ".carousel-container[data-carousel-type='upcoming-live'] .slick-slider .slick-dots"
        );
        const thumbnailClientRect =
          thumbnailRef?.current?.getBoundingClientRect();
        const slickDotsClientRect = slickDots?.getBoundingClientRect();
        if (thumbnailClientRect && slickDotsClientRect) {
          const updatedThumbnailRect =
            thumbnailClientRect.width / 2 + 80 - slickDotsClientRect.width / 2;
          slickDots.style.left = `${updatedThumbnailRect}px`;
          slickDots.style.bottom = '10px';
        }
      }, 500);
    };
    onResize();
  }, [width]);

  const handleClickDetail = async () => {
    const paramsAnalytic = {
      itemID: item.id,
      type: item.type,
      title: item.titleLocalized
    };
    analytic(analyticTypes.event, analyticEvents.CATALOGUE.SELECT_CAROUSEL, {
      params: paramsAnalytic,
      user
    });

    if (item.url === 'https://genflix.co.id/live-shows') {
      return (window.location.href = 'https://genflix.co.id/live-shows');
    }

    if (token) {
      if (
        checkBlockUnblock &&
        (carouselType === 'upcoming-live' || carouselType === 'clips')
      ) {
        const res = await checkBlockUnblock(item);
        if (res) return;
      }
    }

    if (carouselType === 'upcoming-live') {
      return navigate(
        `/liveshow/${detailData.latestShow.id}?isUpComing=${true}&startedAt=${detailData?.startedAt}&finishedAt=${detailData?.finishedAt}&upcomingShowId=${detailData.id}`
      );
    }

    navigate(generateDetailUrl(item));
  };

  const handleClickWatch = () => {
    // if (item.premium && !token) {
    //   return dispatch(authPopup.show());
    // }

    if ('interactive' in item) {
      const channelURL = generateSelectChannelURL(item);
      return navigate(channelURL);
    }

    if (item.type === 'series' && item?.episodes)
      return setPlayVideo(item?.episodes?.data?.[0]);

    return setPlayVideo(item);
  };

  const handleClickButtonAction = (onclick) => {
    if (onclick instanceof Function) onclick();
    if (onclick === 'play') handleClickWatch();
    if (onclick === 'detail') handleClickDetail();
    if (onclick === 'remind') handleClickRemind(item);
  };
  const thumbnail = detailData.thumbnail;

  const upcomingLiveBg = upcomingLiveType
    ? `url(${thumbnail}) no-repeat`
    : `url(${thumbnail}) no-repeat center`;
  const style = {
    background: thumbnail ? upcomingLiveBg : 'white',
    backgroundSize: upcomingLiveType ? 'contain' : 'cover'
  };

  return (
    <>
      {width < 950 &&
        (upcomingLiveType ? (
          <div className={styles['upcoming-live']}>
            <div
              key={item.id}
              className={styles['upcoming-live-carousel-wrapper']}
              onClick={handleClickDetail}
            >
              <div
                className={styles['upcoming-live-carousel-item']}
                style={style}
              >
                <span className={styles['upcoming-live-item']}>
                  <span className={styles['title']}>{item?.title}</span>
                  <div className={styles['upcoming-live-item-detail']}>
                    <img
                      src={icDate}
                      width={12}
                      alt='date'
                      style={{ marginRight: '5px' }}
                    />
                    <span style={{ marginRight: '15px' }}>
                      {formatDateV2(item?.startedAt)}
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={icClock}
                        width={12}
                        alt='clock'
                        style={{ marginRight: '5px' }}
                      />
                      <span>
                        {formatSecsDuration(item.startedAt / 1000, {
                          format: 'hh mm',
                          delimiter: ':'
                        })}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className={styles['upcoming-live-item-detail']}>
                      <div
                        className={styles['avatar']}
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '0.5rem'
                        }}
                      >
                        {item?.profile?.avatar ? (
                          <img
                            src={item.profile.avatar || user}
                            alt='Avatar'
                          />
                        ) : (
                          <div
                            className={styles['user-profile-icon']}
                            data-initial-name
                          >
                            {getInitial(item?.profile?.name || '')}
                          </div>
                        )}
                      </div>
                      <span className={styles['name']}>
                        {item.profile && item.profile.name.substring(0, 15)}
                        {item.profile.name.length > 15 ? '...' : null}
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div
            key={detailData.id}
            className={styles['carousel-wrapper']}
            onClick={handleClickDetail}
          >
            <div
              className={
                item.type === 'campaign'
                  ? styles['carousel-item-campaign']
                  : styles['carousel-item']
              }
              style={style}
            >
              <div
                className={
                  carouselType === 'home-card'
                    ? styles['content-home-card']
                    : styles['content']
                }
              >
                {item.type !== 'campaign' && (
                  <>
                    <div className={styles['watch']}>Watch Now</div>
                    <div className={styles['title']}>{detailData.title}</div>
                    <div className={styles['description']}>
                      {!!detailData.year && <span>{detailData.year}</span>}
                      {!!detailData.genres && <span>{detailData.genres}</span>}
                      {!!detailData.duration && (
                        <span>{formatDuration(detailData.duration)}</span>
                      )}
                      {!!detailData?.rating && (
                        <div className={styles['rating-wrapper']}>
                          <span className={styles['rating']}>
                            {detailData?.rating}
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      {width > 950 && (
        <div
          key={item.id}
          className={styles['carousel-wrapper']}
          style={{ height: height || '' }}
        >
          {showBannerBackground && (
            <img
              src={detailData.thumbnail}
              alt='background-poster'
            />
          )}
          <div
            className={styles['detail-wrapper']}
            style={{ padding }}
            data-no-banner={!showBannerBackground}
            data-direction={itemDirection}
          >
            <div
              className={styles['detail']}
              style={{ alignItems: alignItems || '' }}
            >
              <div className={styles['item-detail']}>
                {detailOrder?.map((orderItem, index) => {
                  if (orderItem === 'title') {
                    return (
                      <div
                        className={styles['title']}
                        onClick={handleClickDetail}
                        key={index}
                      >
                        {detailData.title}
                      </div>
                    );
                  }

                  if (orderItem === 'description') {
                    return (
                      <span
                        className={styles['description']}
                        onClick={handleClickDetail}
                        key={index}
                      >
                        {detailData.description}
                      </span>
                    );
                  }

                  if (orderItem === 'tags') {
                    if (clipsType) {
                      return (
                        <div className={styles['content-genres']}>
                          {detailData?.tags?.genres?.map((genre) => {
                            return <div key={genre}>{genre}</div>;
                          })}
                        </div>
                      );
                    }

                    if (upcomingLiveType) {
                      return (
                        <span className={styles['content-details']}>
                          <div>
                            <img
                              src={icDate}
                              width={16}
                              alt='date'
                            />
                            <span>{formatDateV2(detailData?.startedAt)}</span>
                          </div>
                          <div>
                            <img
                              src={icClock}
                              width={16}
                              alt='clock'
                            />
                            <span>
                              {formatSecsDuration(detailData.startedAt / 1000, {
                                format: 'hh mm',
                                delimiter: ':'
                              })}
                            </span>
                          </div>
                        </span>
                      );
                    }

                    return (
                      <div
                        className={styles['content-details']}
                        key={index}
                      >
                        {!!detailData.year && <span>{detailData.year}</span>}
                        {!!detailData.genres && (
                          <span>{detailData.genres}</span>
                        )}
                        {!!detailData.duration && (
                          <span>{formatDuration(detailData.duration)}</span>
                        )}
                        {!!detailData?.rating && (
                          <span className={styles['rating-wrapper']}>
                            <span className={styles['rating']}>
                              {detailData?.rating}
                            </span>
                          </span>
                        )}
                      </div>
                    );
                  }

                  if (orderItem === 'profile') {
                    return (
                      <span
                        className={styles['profile']}
                        onClick={() => {
                          return navigate(`/profile/${detailData.profile.id}`);
                        }}
                      >
                        {detailData?.profile?.avatar ? (
                          <img
                            src={detailData.profile.avatar}
                            alt='avatar'
                            className={styles['avatar']}
                          />
                        ) : (
                          <div className={styles['avatar']}>
                            {getInitial(detailData?.profile?.name)}
                          </div>
                        )}
                        <span style={{ color: '#FFFFFF' }}>
                          {detailData?.profile?.name}
                        </span>
                      </span>
                    );
                  }

                  if (orderItem === 'actions') {
                    return (
                      <div
                        className={styles['content-actions']}
                        key={index}
                      >
                        {buttonActions?.map((button, buttonIndex) => {
                          if (button?.id === 'remind') {
                            if (detailData.remind) {
                              button.label = 'Reminder ON';
                              button.icon = 'check';
                              button.color = '#1C1C1C';
                            } else {
                              button.label = 'Remind Me';
                              button.icon = 'notif';
                              button.color = '#E15A33';
                            }
                          }

                          return (
                            <Button
                              icon={icons[button?.icon] || button?.icon || ''}
                              iconStyle={{
                                width: 40,
                                height: 40,
                                backgroundColor: 'transparent',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%',
                                ...button?.iconStyle
                              }}
                              style={{
                                fontWeight: 600,
                                ...button?.style
                              }}
                              color={button?.color}
                              shape={button?.shape}
                              textColor={button?.textColor}
                              type={button?.type}
                              fontSize={16}
                              size={button?.size}
                              onClick={() =>
                                handleClickButtonAction(button.onclick)
                              }
                              key={buttonIndex}
                            >
                              {button?.label}
                            </Button>
                          );
                        })}
                      </div>
                    );
                  }

                  return null;
                })}
              </div>
            </div>
            <div
              ref={thumbnailRef}
              className={styles['item-thumbnail']}
              style={{ alignItems: alignItems || '' }}
            >
              <img
                src={detailData.thumbnail}
                alt='thumbnail'
                onClick={handleClickDetail}
              />
            </div>
          </div>
        </div>
      )}
      {playVideo && (
        <MediaPlayer
          mediaType={playVideo.type}
          mediaId={playVideo.id}
          autoPlay
          onClose={() => setPlayVideo()}
        />
      )}
    </>
  );
};

export default ContentBanner;
