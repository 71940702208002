import React, { useCallback, useEffect, useRef, useState } from 'react';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getDetailPoster,
  generateGenres,
  generateCasts,
  formatDuration
} from '../../utils';

import shareIcn from '../../assets/ic-share-circle.svg';
import TextTruncated from '../TextTruncated/TextTruncated';
import ShareModal from '../ShareModal/ShareModal';
import AddToListButton from './AddToListButton';
import { showToastNotif } from '../../redux/actions/notification';
import {
  addToMyList,
  getListStatus,
  removeFromMyList
} from '../../redux/actions/myList';
import { authPopup } from '../../redux/actions/auth';
import dablena from '../../service/dableIo';
import { surgePartnerList } from '../../config/partners';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';

import styles from './MediaInfo.module.css';

const WatchButton = ({
  onWatchClicked,
  subscribed,
  loadingMedia,
  userSubscriptions,
  isFWT,
  isPremium
}) => {
  const btnRef = useRef();
  useEffect(() => {
    btnRef.current.focus();
  }, []);
  const watchByPress = (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      onWatchClicked();
    }
  };

  const fwt = isPremium && isFWT;
  const fwtText = fwt ? 'Try Now' : 'Subscribe to Play';
  const buttonText = !isPremium || userSubscriptions ? 'Watch Now' : fwtText;
  return (
    <button
      type='button'
      className={`${styles['watchButton']} ${subscribed}`}
      onKeyDown={watchByPress}
      ref={btnRef}
      onClick={onWatchClicked}
      data-loading={loadingMedia}
      disabled={loadingMedia}
      style={{
        whiteSpace: 'nowrap'
      }}
    >
      {loadingMedia ? 'Loading...' : buttonText}
    </button>
  );
};

const MediaInfo = ({
  detail,
  access,
  selectedSeason,
  onWatchClicked,
  loadingMedia,
  episodeDetail
}) => {
  const location = useLocation();
  const poster = getDetailPoster(detail);
  const duration = detail.duration && formatDuration(detail.duration);
  const tags = detail.tags;
  const genres = generateGenres(tags);
  const dispatch = useDispatch();
  const casts = generateCasts(tags);
  const subscribed =
    !access || access?.error || access?.response?.status === 403
      ? styles['not-subscribed-button']
      : '';
  const [showShareModal, setShowShareModal] = useState(false);
  const userToken = useSelector((state) => state.auth.token);
  const isPartnerPage = surgePartnerList.find((item) =>
    location.pathname.includes(`/mobile/${item}`)
  );
  const user = useSelector((state) => state.auth && state.auth.user);

  const userSubscriptions = user?.account.subscriptions.length >= 1;
  const addAnalytics = () => {
    if (access?.freeWatchTime) {
      if ((userToken && !userSubscriptions) || !userToken) {
        if (detail.type === 'movie') {
          const paramsEvent = {
            movieID: detail.id,
            movieName: detail.titleLocalized,
            movieGenres: detail?.tags?.genres,
            contentType: detail.type
          };

          analytic(
            analyticTypes.event,
            analyticEvents.FREEWATCHTIME.FWT_MOVIE_TRYNOW,
            {
              params: paramsEvent,
              user
            }
          );
        } else if (detail.type === 'series') {
          const paramsEvent = {
            seriesID: detail.id,
            seriesName: detail.titleLocalized,
            seriesGenres: detail.tags.genres,
            contentType: detail.type,
            seasonTotal: detail.itemCount,
            seasonID: selectedSeason?.id,
            seasonNumber: selectedSeason?.titleLocalized
          };

          analytic(
            analyticTypes.event,
            analyticEvents.FREEWATCHTIME.FWT_SERIES_TRYNOW,
            {
              params: paramsEvent,
              user
            }
          );
        }
      }
    }
  };
  const addBuyAnalytics = () => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('pg')) {
      if (userSubscriptions) {
        if (detail.type === 'movie') {
          const paramsEvent = {
            movieID: detail.id,
            movieName: detail.titleLocalized,
            movieGenres: detail?.tags?.genres,
            contentType: detail.type
          };

          analytic(
            analyticTypes.event,
            detail?.premium
              ? analyticEvents.FREEWATCHTIME.FWT_PREMIUM_PLAYER_SUBSCRIBED
              : analyticEvents.FREEWATCHTIME.FWT_FREE_PLAYER_SUBSCRIBED,
            {
              params: paramsEvent,
              user
            }
          );
        } else if (detail.type === 'series') {
          const paramsEvent = {
            seriesID: detail.id,
            seriesName: detail.titleLocalized,
            seriesGenres: detail.tags.genres,
            contentType: detail.type,
            seasonTotal: detail.itemCount,
            seasonID: selectedSeason?.id,
            seasonNumber: selectedSeason?.titleLocalized
          };

          analytic(
            analyticTypes.event,
            detail?.premium
              ? analyticEvents.FREEWATCHTIME.FWT_PREMIUM_PLAYER_SUBSCRIBED
              : analyticEvents.FREEWATCHTIME.FWT_FREE_PLAYER_SUBSCRIBED,
            {
              params: paramsEvent,
              user
            }
          );
        }
      }
      // queryParams.delete('pg')
      // router.history.replace({
      //   search: queryParams.toString(),
      // })
    }
  };

  useEffect(() => {
    if (userToken && detail.id) {
      dispatch(getListStatus(detail.id, detail.mediaType));
    }
  }, [detail.id, detail.mediaType, dispatch, userToken]); // check if it's in get list already

  useEffect(() => {}, [access]); // check if it's in get list already
  const addToList = useCallback(() => {
    const doAddList = async () => {
      if (userToken) {
        try {
          await dispatch(addToMyList(detail.id, detail.type));

          // dable.io tracker
          dablena('AddToWishlist');

          return dispatch(showToastNotif('success', 'Added to My List.'));
        } catch (err) {
          return dispatch(showToastNotif('error', 'Error adding to My list.'));
        }
      } else {
        dispatch(authPopup.show());
      }
    };
    doAddList();
  }, [detail.id, detail.type, dispatch, userToken]);
  // fire 'add to list, if not logged in, show popup login/register

  const removeFromList = useCallback(() => {
    const doRemoveList = async () => {
      try {
        await dispatch(removeFromMyList(detail.id));
        return dispatch(showToastNotif('success', 'Removed from My List.'));
      } catch (err) {
        return dispatch(
          showToastNotif('error', 'Error removing from My list.')
        );
      }
    };
    doRemoveList();
  }, [detail.id, dispatch]);

  if (userSubscriptions) {
    addBuyAnalytics();
  }
  return (
    <div>
      <div
        key={detail.id}
        className='carousel-wrapper-container'
      >
        <img
          src={poster}
          alt='background-poster'
        />
        <div className='detail-wrapper'>
          <div className='detail'>
            <div className={styles['media-metadata-wrapper']}>
              <div className={styles['media-title']}>
                {detail.titleLocalized}{' '}
                {selectedSeason?.titleLocalized &&
                  `: ${selectedSeason?.titleLocalized}`}
              </div>
              <div className={styles['media-metadata']}>
                {!!detail.year && (
                  <span className={styles['year']}>{detail.year}</span>
                )}
                {!!genres && <span className={styles['genre']}>{genres}</span>}
                {!!duration && (
                  <span className={styles['duration']}>{duration}</span>
                )}
                {!!detail?.rating && (
                  <span className={styles['rating']}>{detail?.rating}</span>
                )}
              </div>
              <div className={styles['btn-wrapper']}>
                <WatchButton
                  subscribed={subscribed}
                  loadingMedia={loadingMedia}
                  onWatchClicked={() => {
                    addAnalytics();
                    onWatchClicked();
                  }}
                  userSubscriptions={userSubscriptions}
                  isFWT={!!access?.freeWatchTime}
                  isPremium={
                    detail.type === 'movie'
                      ? detail?.premium
                      : episodeDetail?.premium
                  }
                />
                <div className={styles['wrapper-btn-circle']}>
                  {detail.id &&
                    !isPartnerPage &&
                    !location.pathname.includes('episode') && (
                      <>
                        <AddToListButton
                          addedToMyList={detail.addedToMyList}
                          onAddList={addToList}
                          onRemoveList={removeFromList}
                        />
                        <img
                          src={shareIcn}
                          alt='share'
                          onClick={() => setShowShareModal(!showShareModal)}
                        />
                      </>
                    )}
                </div>
              </div>
              <div className={styles['description']}>
                <TextTruncated
                  content={detail.descriptionLongLocalized || ''}
                  maxLength={250}
                />
              </div>
              {casts && (
                <div className={styles['casting']}>
                  <h3>Cast:</h3>
                  <span>{casts}</span>
                </div>
              )}
            </div>
          </div>
          <div className='item-thumbnail'>
            <img
              src={poster}
              alt='thumbnail'
            />
          </div>
        </div>
      </div>
      <ShareModal
        onCloseModal={() => setShowShareModal(!showShareModal)}
        type={detail.type}
        id={detail.id}
        showShareModal={showShareModal}
        open={showShareModal}
      />
    </div>
  );
};

export default MediaInfo;
