import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';

import { useSelector, useDispatch } from 'react-redux';
import {
  Loading
  // BlockUnblockModal
} from '..';

// import { isBlockBlockedProfile, authPopup } from '../../redux/actions/auth';

import ContentBanner from '../ContentBanner/ContentBanner';
import {
  // getCarouselPlaylistItems,
  getEpisodes
  // setUpcomingLiveshowReminder
} from '../../redux/actions/playlist';
import { getMediaInfo } from '../../redux/actions/media';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';

import icArrowBack from '../../assets/ic-arrow-back.svg';
// import { playlistCarouselId } from '../../constants';

const ArrowButton = ({ className, onClick }) => {
  return (
    <div
      className={`${className} custom-arrow`}
      onClick={onClick}
    >
      <img
        src={icArrowBack}
        alt='arrow'
      />
    </div>
  );
};

function Carousel({
  label,
  data,
  type = '',
  detailOrder = [],
  itemDirection = 'ltr',
  buttonActions,
  showBannerBackground,
  alignItems,
  height
}) {
  const slider = useRef();
  const dispatch = useDispatch();

  // const { token } = useSelector((state) => state.auth);
  const { trackLiveshowReminderId } = useSelector((state) => state.playlist);

  const [carouselData, setCarouselData] = useState(null);
  // const [isTriggerBlockModal, setTriggerBlockModal] = useState(false);
  // const [blockType, setBlockType] = useState('block');
  // const [profile, setProfile] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  // const checkBlockUnblock = async (checkBlockData) => {
  //   if (!token && !user) {
  //     dispatch(authPopup.show());
  //     return true;
  //   }
  //   const res = await dispatch(
  //     isBlockBlockedProfile(
  //       checkBlockData?.profile?.accountId,
  //       checkBlockData?.profile?.id
  //     )
  //   );
  //   if (res?.data?.status === 'BLOCKING') {
  //     setProfile(checkBlockData.profile);
  //     setBlockType('unblock');
  //     setTriggerBlockModal(true);
  //     return true;
  //   }
  //   if (res?.data?.status === 'BLOCKED') {
  //     setProfile(checkBlockData.profile);
  //     setTriggerBlockModal(true);
  //     setBlockType('access');
  //     return true;
  //   }

  //   return false;
  // };

  // const onCloseBlockModal = (closeType) => {
  //   if (closeType === 'cancel') {
  //     setTriggerBlockModal(false);
  //     setProfile(null);
  //   }
  //   setTriggerBlockModal(false);
  // };

  const settings = {
    mobile: {
      className: 'center',
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      centerPadding: '30%',
      speed: 500,
      focusOnSelect: true,
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '20%'
          }
        },
        {
          breakpoint: 500,
          settings: {
            centerPadding: '20%'
          }
        }
      ],
      ...(type === 'middle' &&
        trackLiveshowReminderId && {
          initialSlide: data.items.findIndex(
            (item) => item.id === trackLiveshowReminderId
          )
        })
    },
    web: {
      className: 'center',
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      focusOnSelect: true,
      dots: true,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 5000,
      cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '20%'
          }
        },
        {
          breakpoint: 500,
          settings: {
            centerMode: false
          }
        }
      ],
      nextArrow: <ArrowButton />,
      prevArrow: <ArrowButton />,
      ...(type === 'middle' &&
        trackLiveshowReminderId && {
          initialSlide: data.items.findIndex(
            (item) => item.id === trackLiveshowReminderId
          )
        })
    }
  };
  useEffect(() => {
    if (data?.items?.length) {
      const getDetailCarousel = async () => {
        const formatCarouselData = await Promise.all(
          data?.items?.map(async (item) => {
            if (item.type === 'series') {
              const seriesDetail = await dispatch(
                getMediaInfo(item?.id, item?.type, false, {
                  dispatchPayload: true
                })
              );

              const seriesId =
                seriesDetail?.id ||
                seriesDetail?.[0]?.id ||
                seriesDetail?.items?.[0]?.id;
              const seriesEpisodes = await dispatch(
                getEpisodes(seriesId, 1, 1, true, {
                  dispatchPayload: false
                })
              );

              return {
                ...item,
                episodes: seriesEpisodes
              };
            }

            return item;
          })
        );

        setCarouselData(formatCarouselData);
      };

      getDetailCarousel();
    } else {
      setCarouselData([]);
    }
    function updateSize() {
      setWidth(window.outerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [data, dispatch]);

  // const handleClickRemind = async (item) => {
  //   // event.stopPropagation();

  //   const isBlockUser = await checkBlockUnblock(item);
  //   if (isBlockUser) return;
  //   let isSetReminder = true;

  //   if (!token) {
  //     return dispatch(authPopup.show());
  //   }

  //   if (item.remind) {
  //     isSetReminder = false;
  //   }
  //   const res = await dispatch(
  //     setUpcomingLiveshowReminder(item.id, isSetReminder)
  //   );
  //   if (res) {
  //     const reminder = true;
  //     dispatch(
  //       getCarouselPlaylistItems(
  //         playlistCarouselId,
  //         1,
  //         10,
  //         'upcoming',
  //         reminder
  //       )
  //     );
  //   }
  // };

  if (!carouselData) {
    return <Loading height='50vh' />;
  }

  if (!carouselData?.length) return null;

  const settingOption =
    width > 950 ? { ...settings.web } : { ...settings.mobile };
  return (
    <div
      className='carousel-container'
      data-carousel-type={type}
    >
      {label && <h1 className='label'>{label}</h1>}
      {settingOption && (
        <Slider
          ref={slider}
          {...settingOption}
        >
          {carouselData.map((item) => {
            return (
              <ContentBanner
                key={item.id}
                item={item}
                carouselType={type}
                detailOrder={detailOrder}
                itemDirection={itemDirection}
                showBannerBackground={showBannerBackground}
                alignItems={alignItems}
                height={height}
                buttonActions={buttonActions}
                // checkBlockUnblock={checkBlockUnblock}
                // handleClickRemind={handleClickRemind}
              />
            );
          })}
        </Slider>
      )}
      {/* {profile && (
        <BlockUnblockModal
          profile={profile}
          blockType={blockType}
          isTriggered={isTriggerBlockModal}
          onClose={onCloseBlockModal}
        />
      )} */}
    </div>
  );
}

export default Carousel;
