import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateDeviceInfo, generateErrorDetail } from '../../utils';
import { showToastNotif } from './notification';

const URL_HISTORY = '/heartbeat/v1.1/histories';
const URL_HISTORY_v10 = '/heartbeat/v1.0/histories';
const URL_GUEST_HISTORY = '/heartbeat/v1.1/histories/guest';

const formatContinueWatchingData = (data) => {
  if (data.length) {
    const formatData = data.map((item) => {
      const mediaInfo = item.mediaInfo;
      delete item.mediaInfo;
      return {
        ...item,
        ...mediaInfo
      };
    });
    return formatData;
  }
};

export const getHistories =
  (page = 1, perPage = 20) =>
  async (dispatch, getState) => {
    const state = getState();
    const { data, meta } = state.history;

    dispatch({
      type: 'GET_HISTORIES'
    });

    try {
      if (
        data?.length &&
        (page === 1 || page === meta?.page || page === meta?.totalPages)
      ) {
        dispatch({ type: 'GET_HISTORIES_SUCCESS_FROM_STATE' });
      } else {
        const res = await axios.get(
          `${BASE_URL}${URL_HISTORY_v10}?page=${page}&perPage=${perPage}`,
          {
            headers: {
              Authorization: state.auth.token,
              'Cloudfront-JWT-AppId': JWT_APP_ID
            }
          }
        );
        dispatch({
          type: 'GET_HISTORIES_SUCCESS',
          payload: {
            ...res.data
          },
          page
        });
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_HISTORIES_ERROR',
        err: errorData
      });
    }
  };

export const updateHistory =
  (historyData, sessionId) => async (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: 'UPDATE_HISTORY'
    });

    try {
      const heartbeatURL = state.auth.token
        ? `${BASE_URL}${URL_HISTORY}`
        : `${BASE_URL}${URL_GUEST_HISTORY}`;
      const isLoggedIn = state.auth.user && state.auth.token;
      const hasSubscription = state.auth.user?.account?.subscriptions?.length;
      historyData.device = generateDeviceInfo();
      historyData.sessionId = sessionId;
      if (isLoggedIn) historyData.freeWatch = false;
      if (
        isLoggedIn &&
        !hasSubscription &&
        state.media.mediaAccess?.freeWatchTime
      ) {
        historyData.freeWatch = true;
      }
      const res = await axios.post(heartbeatURL, historyData, {
        headers: {
          Authorization: state.auth.token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      });
      dispatch({
        type: 'UPDATE_HISTORY_SUCCESS'
      });
      return res.data || historyData;
    } catch (err) {
      dispatch({
        type: 'UPDATE_HISTORY_ERROR'
      });
      throw err;
    }
  };

export const getContinueWatching = () => async (dispatch, getState) => {
  const state = getState();
  const { noDataContinueWatching } = state.history;

  dispatch({
    type: 'GET_CONTINUE_WATCHING'
  });

  try {
    if (!noDataContinueWatching) {
      const res = await axios.get(`${BASE_URL}${URL_HISTORY_v10}/continue`, {
        headers: {
          Authorization: state.auth.token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      });
      dispatch({
        type: 'GET_CONTINUE_WATCHING_SUCCESS',
        payload: formatContinueWatchingData(
          res.data.data?.filter((media) => media.mediaInfo.type !== '-')
        )
          ?.filter((media) => media.mediaSubType !== 'show')
          ?.filter(
            (item, index, self) =>
              self.findIndex((search) => search.id === item.id) === index
          )
      });
    }
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_CONTINUE_WATCHING_ERROR',
      err: errorData
    });
  }
};

export const getMediaHistory = (id) => async (dispatch, getState) => {
  const state = getState();
  const res = await axios.get(
    `${BASE_URL}${URL_HISTORY_v10}/selected?mediaIds[]=${id}`,
    {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    }
  );
  return res.data;
};

export const getSeriesHistory = (seriesID) => async (dispatch, getState) => {
  const state = getState();
  const res = await axios.get(
    `${BASE_URL}${URL_HISTORY_v10}/series/${seriesID}`,
    {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    }
  );
  return res.data;
};
