import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import shaka from 'shaka-player';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import platform from 'platform';
import ShakaPlayer from './ShakaPlayer';
import PlayerSubscription from './PlayerSubscription';
import { withRouter } from '../../hooks';
import { getFairplayCert } from './pallycon-helper';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { detectAgent, detectOS } from '../../utils';
import { errorCode } from '../../constants';
import { ADS_TAG_URL } from '../../config/URL';
import { getAppSettings } from '../../redux/actions/settings';
import Loading from '../Loading/Loading';
import './ShakaVideoPlayer.css';

import FreeWatchTimeOverlay from '../FreeWatchTimeOverlay/freeWatchTimeOverlay';
import { settingKeys } from '../../hooks/useSettings';

const { adTagUrlWebKey } = settingKeys;

function ShakaVideoPlayer({
  videoInfo,
  access,
  onDismissPlayer,
  handlePlayNext,
  handleUpdateHistory,
  historyData,
  maxResolution,
  handleEndHistory,
  showDismiss = true,
  width = '100vw',
  height = '100vh',
  loop,
  isClips = false,
  isVideoStop,
  showAds = true,
  autoPlay,
  fwtTimeEnd,
  setFwtTimeEnd,
  ...otherProps
}) {
  const dispatch = useDispatch();
  const { partner } = useParams();
  const location = useLocation();
  const controllerRef = useRef(null);
  const adRef = useRef(); // ref to see whether an ad is running or not
  const videoPlayer = useRef();
  const videoRef = useRef();
  const playerSection = useRef();

  const user = useSelector((state) => state.auth && state.auth.user);
  const appSettingAdsTagUrl = useSelector(
    (state) => state.appSettings?.settings
  );

  const [showClose, setShowClose] = useState(true);
  const [error, setError] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);

  const adsTagUrl = appSettingAdsTagUrl?.['ad.tag.url.web'];
  const userSubsciptions = user?.account?.subscriptions?.length >= 1;
  const isPartnerPage = location.pathname.includes('/mobile/');

  const accessError = useMemo(() => {
    return (
      error ||
      access?.error?.response?.data?.error ||
      access?.response?.data?.error ||
      fwtTimeEnd
    );
  }, [
    access?.error?.response?.data?.error,
    access?.response?.data?.error,
    fwtTimeEnd,
    error
  ]);
  const isFWT = useMemo(() => {
    if (isClips || partner || isPartnerPage) {
      return false;
    }

    return access?.freeWatchTime || (!videoInfo?.premium && !userSubsciptions);
  }, [
    access?.freeWatchTime,
    isClips,
    isPartnerPage,
    partner,
    videoInfo?.premium,
    userSubsciptions
  ]);

  useEffect(() => {
    playerSection.current?.focus();
    const focusElement = document.activeElement;
    const keyHandler = (event) => {
      if (event.keyCode === 13) {
        videoRef.current.paused
          ? videoRef.current.play()
          : videoRef.current.pause();
      }
      if (event.keyCode === 19) {
        playerSection.current.focus();
      }
    };
    focusElement.addEventListener('keyup', keyHandler);
    return () => {
      focusElement.removeEventListener('keyup', keyHandler);
    };
  }, [dispatch, partner]);

  // send heartbeat when user start media for the first time
  useEffect(() => {
    const hasErrorCode = accessError?.code;
    if (hasErrorCode) {
      const noSubscription = hasErrorCode === errorCode.NO_SUBSCRIPTION;

      if (!noSubscription && videoInfo && handleUpdateHistory) {
        const durationInSecond = videoInfo.duration * 60;
        const historyPositionInSeconds = historyData?.duration || 0;
        handleUpdateHistory(historyPositionInSeconds, durationInSecond);
      }
    }
  }, [handleUpdateHistory, videoInfo, accessError, historyData]);

  useEffect(() => {
    // Observe the shaka player control to show close button at the left top
    const intervalDismissBtn = setInterval(() => {
      const el = document.querySelector('.shaka-controls-container');
      const elState = el ? el.getAttribute('shown') : true;
      setShowClose(elState);
    }, 500);

    return () => {
      clearInterval(intervalDismissBtn);
    };
  }, []);

  const { stream, drmType, drmToken, drmProvider } = access;
  useEffect(() => {
    if (!access || accessError) return;

    try {
      const {
        /** @type {shaka.Player} */ player,
        /** @type {shaka.ui.Overlay} */ ui,
        /** @type {HTMLVideoElement} */ videoElement
        // /** @type {shaka.ui.Controls} */ controls,
      } = controllerRef.current;

      const loadedAsset = player?.getAssetUri();
      const playerLoadMode = player?.getLoadMode();
      // prevents shaka player from loading content
      // when there is still content being loaded with the same uri
      if (
        isFWT &&
        user &&
        (playerLoadMode === 1 || playerLoadMode === 2) &&
        loadedAsset === stream
      ) {
        return;
      }

      const showForNonFWT = !(isFWT && videoInfo?.premium);
      const uiConfig = {
        addSeekBar: showForNonFWT,
        addBigPlayButton: false,
        doubleClickForFullscreen: false,
        enableFullscreenOnRotation: true,
        forceLandscapeOnFullscreen: true,
        controlPanelElements: showForNonFWT
          ? [
              'play_pause',
              'time_and_duration',
              // ...(detectAgent('mobile') ? [] : ['rewind']),
              // ...(detectAgent('mobile') ? [] : ['fast_forward']),
              'jumpfwdback',
              'spacer',
              'mute',
              ...(detectAgent('mobile') ? [] : ['volume']),
              'fullscreen',
              !isClips && 'caption_format',
              'overflow_menu'
            ]
          : [],
        overflowMenuButtons: [
          'captions',
          'quality',
          'language',
          'cast',
          'playback_rate'
        ]
      };

      ui.configure(uiConfig);

      const adManager = player.getAdManager();

      // listen to ad started, or ad stopped to see whether ad is currently showing or not
      adManager.addEventListener(shaka.ads.AdManager.AD_STARTED, (e) => {
        adRef.current = true;
        const ad = e['ad'];
        adRef.current = ad;
        if (videoInfo) {
          if (historyData && historyData.mediaId === videoInfo.id) {
            if (Math.floor(videoElement?.currentTime) > 0) {
              ad.play();
            } else {
              ad.pause();
            }
          } else {
            ad.play();
          }
        }
      });

      adManager.addEventListener(shaka.ads.AdManager.AD_STOPPED, () => {
        adRef.current = null;
      });

      const initializeAdManager = () => {
        const video = videoRef.current;
        const container = video.ui.getControls().getClientSideAdContainer();
        adManager.initClientSide(container, video);
      };

      const handleRequestAds = () => {
        initializeAdManager();
        const adsRequest = new window.google.ima.AdsRequest();
        adsRequest.adTagUrl = adsTagUrl || ADS_TAG_URL;
        adManager.requestClientSideAds(adsRequest);
      };

      const initPlayer = async () => {
        try {
          setLoadingVideo(true);

          window.videoPlayerState = {
            state: 'loading',
            message: 'Loading the video'
          };
          let playerConfig;
          const contentUri = stream;
          // Listen for error events.
          player.addEventListener('error', onErrorEvent);

          if (drmType === 'FairPlay') {
            const fairplayCert = getFairplayCert(shaka);
            playerConfig = {
              drm: {
                servers: {
                  'com.apple.fps':
                    drmProvider === 'aws'
                      ? 'https://license.pallycon.com/ri/licenseManager.do'
                      : drmToken
                },
                advanced: {
                  'com.apple.fps':
                    drmProvider === 'aws'
                      ? {
                          serverCertificate: fairplayCert
                        }
                      : {
                          serverCertificateUri: '/fairplay.cer'
                        }
                }
              },
              streaming: {
                autoLowLatencyMode: true
              }
            };
          } else if (drmType === 'Widevine') {
            playerConfig = {
              drm: {
                servers: {
                  'com.widevine.alpha':
                    drmProvider === 'aws'
                      ? 'https://license.pallycon.com/ri/licenseManager.do'
                      : drmToken
                },
                advanced: {
                  'com.widevine.alpha': {
                    persistentStateRequired: true
                  }
                }
              },
              streaming: {
                autoLowLatencyMode: true
              }
            };
          } else if (drmType === 'PlayReady') {
            playerConfig = {
              drm: {
                servers: {
                  'com.microsoft.playready': {
                    serverURL:
                      drmProvider === 'aws'
                        ? 'https://license.pallycon.com/ri/licenseManager.do'
                        : drmToken,
                    systemStringPriority: [
                      'com.microsoft.playready.recommendation',
                      'com.microsoft.playready'
                    ]
                  }
                }
              },
              streaming: {
                autoLowLatencyMode: true
              }
            };
          }

          // limit the resolution depending on the requirement
          if (maxResolution) {
            playerConfig = {
              ...playerConfig,
              restrictions: { maxWidth: maxResolution }
            };
          }

          // Try to load a manifest.
          // This is an asynchronous process.
          if (playerConfig) {
            player.configure(playerConfig);
          }
          // console.log('player configured', player, playerConfig);
          player
            .load(contentUri)
            .then(async function () {
              const isByteplusDomain = getSubdomain(contentUri);
              if (isByteplusDomain && access?.subtitle) {
                // load sideload subtitle
                const subtitleUri = access?.subtitle;
                const subtitleLanguage = 'id';
                const subtitleKind = 'subtitles';
                const subtitleMimeType = 'text/srt';

                await player.addTextTrackAsync(
                  subtitleUri,
                  subtitleLanguage,
                  subtitleKind,
                  subtitleMimeType
                );

                const tracks = player.getTextTracks();
                if (tracks.length > 0) {
                  player.selectTextTrack(tracks[0]);
                  player.setTextTrackVisibility(true);
                }
              }
              // console.log('The video has now been loaded!');
              if (videoInfo) {
                const paramsEvent = {
                  contentType: videoInfo.type,
                  videoID: videoInfo.id,
                  videoTitle: videoInfo.titleLocalized,
                  videoDuration: videoInfo.duration
                };
                if (videoInfo.tags && videoInfo.tags.genres) {
                  paramsEvent.videoGenre = videoInfo.tags.genres;
                }
                if (videoInfo.season) {
                  paramsEvent.seasonID = videoInfo.season.id;
                  paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
                  paramsEvent.seriesID = videoInfo.series.id;
                  paramsEvent.seriesName = videoInfo.series.titleLocalized;
                }

                analytic(
                  analyticTypes.event,
                  analyticEvents.MEDIA_PLAYBACK.MEDIA_LAUNCH,
                  {
                    params: paramsEvent,
                    user
                  }
                );
              }
              try {
                if (autoPlay && !navigator.userActivation.isActive) {
                  window.autoplayAfterMuted = false;
                  videoRef.current.muted = true;
                  const interactions = ['click', 'keydown', 'touchstart'];

                  const onInteract = () => {
                    videoRef.current.muted = false;
                    window.autoplayAfterMuted = true;

                    interactions.forEach((item) => {
                      document.removeEventListener(item, onInteract);
                    });
                  };
                  interactions.forEach((item) => {
                    document.addEventListener(item, onInteract);
                  });
                }

                await videoElement.play();
              } catch (playError) {
                // eslint-disable-next-line no-console
                console.log(playError);
              }
            })
            .then(() => {
              if (
                showAds &&
                !isClips &&
                !userSubsciptions &&
                !videoInfo?.premium &&
                !partner
              ) {
                dispatch(getAppSettings([adTagUrlWebKey])).then(() => {
                  handleRequestAds();
                });
              }
            })
            .catch(onError); // onError is executed if the asynchronous load fails.
          videoPlayer.current = player;
          videoRef.current = videoElement;
          // eslint-disable-next-line no-empty
        } catch (err) {
        } finally {
          setLoadingVideo(false);
        }
      };

      const onErrorEvent = (event) => {
        // Extract the shaka.util.Error object from the event.
        // eslint-disable-next-line no-console
        console.error('Error code', event.detail.code, 'object', event.detail);

        const paramsEvent = {
          contentType: videoInfo.type,
          videoID: videoInfo.id,
          videoTitle: videoInfo.titleLocalized,
          videoDuration: videoInfo.duration,
          playerErrorDetail: event.detail
        };
        analytic(
          analyticTypes.event,
          analyticEvents.ERROR.VIDEO_UNABLE_TO_PLAY,
          {
            params: paramsEvent,
            user
          }
        );

        onError(event.detail);
      };

      const onError = (playerError) => {
        // Log the error.
        // eslint-disable-next-line no-console
        console.error('Error code', playerError.code, 'object', playerError);
        window.videoPlayerState = {
          state: 'failed',
          message: `Error: ${playerError}`
        };

        if (playerError.code === 3016 && playerError.data[0]) {
          if (detectOS() === 'ios' && platform.name !== 'Safari') {
            const err = {
              code: 'missing_video',
              message: 'Please open this content on safari browser.'
            };
            setError(err);
            err;
          }
        }
      };

      !isVideoStop && initPlayer();

      document.querySelector('.shaka-ad-counter-span').style.display = 'none';
    } catch (initPlayerError) {
      return setError({
        code: 'missing_video',
        message: 'Missing video asset to play.'
      });
    }

    return () => {
      setError(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    access,
    accessError,
    adsTagUrl,
    dispatch,
    fwtTimeEnd,
    historyData,
    isClips,
    isFWT,
    isVideoStop,
    maxResolution,
    partner,
    showAds,
    user,
    videoInfo
  ]);

  const handleMediaEnd = useCallback(
    (APIduration, currentTime, duration) => {
      handleEndHistory && handleEndHistory(APIduration, currentTime, duration);
    },
    [handleEndHistory]
  );

  if (accessError) {
    return (
      <PlayerSubscription
        onDismissPlayer={onDismissPlayer}
        otherProps={otherProps}
        error={accessError}
      />
    );
  }

  if (isVideoStop && controllerRef?.current?.videoElement?.pause) {
    const { videoElement } = controllerRef.current;
    videoElement.pause();
  }

  const backBtnAnalytics = () => {
    if (videoInfo) {
      const paramsEvent = {
        contentType: videoInfo.type,
        videoID: videoInfo.id,
        videoTitle: videoInfo.titleLocalized,
        videoDuration: videoInfo.duration
      };
      if (videoInfo.tags && videoInfo.tags.genres) {
        paramsEvent.videoGenre = videoInfo.tags.genres;
      }
      if (videoInfo.season) {
        paramsEvent.seasonID = videoInfo.season.id;
        paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
        paramsEvent.seriesID = videoInfo.series.id;
        paramsEvent.seriesName = videoInfo.series.titleLocalized;
      }

      analytic(
        analyticTypes.event,
        videoInfo.premium
          ? analyticEvents.FREEWATCHTIME.FWT_PREMIUM_PLAYER_CLICKBACK
          : analyticEvents.FREEWATCHTIME.FWT_FREE_PLAYER_CLICKBACK,
        {
          params: paramsEvent,
          user
        }
      );
    }
  };

  const unloadPlayer = () => {
    const { /** @type {shaka.Player} */ player } = controllerRef.current || {};
    player?.unload();
    setError(false);
  };

  const getSubdomain = (fullUrl) => {
    const url = new URL(fullUrl);

    const hostname = url.hostname;

    const parts = hostname.split('.');

    const subdomain = parts.length > 2 ? parts[0] : null;
    const isByteplus = subdomain === 'vod-stg' || subdomain === 'vod';
    return isByteplus;
  };

  return (
    <>
      {showDismiss && (
        <div
          className='vjs-dismiss-btn-wrapper'
          onClick={() => {
            unloadPlayer();
            backBtnAnalytics();
            onDismissPlayer();
          }}
          style={{
            visibility: showClose ? 'visible' : 'hidden'
          }}
        >
          <i className='vjs-dismiss-player-icon' />
        </div>
      )}
      <div
        ref={playerSection}
        tabIndex={0}
      >
        {loadingVideo && (
          <div className='shaka-loading-container'>
            <Loading height='min-content' />
            <p>Loading video asset...</p>
          </div>
        )}

        {!fwtTimeEnd && (
          <FreeWatchTimeOverlay
            videoInfo={videoInfo}
            onWatchTimeEnd={(e) => {
              setFwtTimeEnd(e);
            }}
            freeWatchTimeInSeconds={300}
            onDismissPlayer={onDismissPlayer}
            videoController={controllerRef}
            userSubs={userSubsciptions}
            isFWT={isFWT}
          />
        )}
        {drmProvider === 'aws' ? (
          <ShakaPlayer
            drmToken={access.drmToken}
            streamType={access.streamType}
            videoInfo={videoInfo}
            handlePlayNext={handlePlayNext}
            handleUpdateHistory={handleUpdateHistory}
            historyData={historyData}
            handleEndHistory={handleMediaEnd}
            height={height}
            width={width}
            loop={loop}
            adRef={adRef}
            ref={controllerRef}
            isFWT={access?.freeWatchTime}
          />
        ) : (
          <ShakaPlayer
            streamType={access.streamType}
            videoInfo={videoInfo}
            handlePlayNext={handlePlayNext}
            handleUpdateHistory={handleUpdateHistory}
            historyData={historyData}
            handleEndHistory={handleMediaEnd}
            height={height}
            width={width}
            loop={loop}
            adRef={adRef}
            ref={controllerRef}
            isFWT={access?.freeWatchTime}
          />
        )}
      </div>
    </>
  );
}

export default withRouter(React.memo(ShakaVideoPlayer));
