const initialState = {
  data: null,
  meta: null,
  loading: false,
  err: null,

  // episode items
  dataEpisode: [],
  upcomingVod: [],
  upcomingVodNotEmpty: false,
  upcomingVodError: [],
  carouselVod: [],
  loadingCarouselVod: false,
  loadingUpcomingVod: false,
  metaEpisode: null,
  loadingEpisode: false,
  errorEpisode: null,
  trackLiveshowReminderId: undefined
};

export default function playlistReducer(state = initialState, action) {
  const { type, payload, page, err, upcomingVodNotEmpty, upcomingVodError } =
    action;

  switch (type) {
    case 'GET_PLAYLIST_DETAIL':
    case 'GET_PLAYLIST_ITEMS':
      return {
        ...state,
        loading: true,
        err: null
      };

    case 'GET_PLAYLIST_DETAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        data:
          page === 1
            ? payload.data
            : {
                ...state.data,
                items: [...state.data.items, ...payload.data]
              },
        meta: payload.meta,
        page
      };

    case 'GET_PLAYLIST_DETAIL_SUCCESS_FROM_STATE':
      return {
        ...state,
        loading: false,
        data: payload.data,
        meta: payload.meta,
        page
      };

    case 'GET_PLAYLIST_ITEMS_SUCCESS':
      return {
        ...state,
        loading: false,
        data: page === 1 ? payload.data : [...state.data, ...payload.data],
        meta: payload.meta,
        page
      };

    case 'GET_PLAYLIST_DETAIL_FAILURE':
    case 'GET_PLAYLIST_ITEMS_FAILURE':
      return {
        ...state,
        loading: false,
        err
      };

    case 'GET_EPISODE_ITEMS':
      return {
        ...state,
        loadingEpisode: true,
        errorEpisode: null
      };

    case 'GET_EPISODE_ITEMS_SUCCESS_FROM_STATE':
      return {
        ...state,
        loadingEpisode: true,
        errorEpisode: null
      };

    case 'GET_EPISODE_ITEMS_SUCCESS':
      return {
        ...state,
        dataEpisode:
          page === 1 ? payload.data : [...state.dataEpisode, ...payload.data],
        metaEpisode: payload.meta,
        loadingEpisode: false,
        errorEpisode: null
      };

    case 'GET_EPISODE_ITEMS_FAILED':
      return {
        ...state,
        loadingEpisode: false,
        errorEpisode: err
      };

    case 'RESET_EPISODES':
      return {
        ...state,
        dataEpisode: [],
        metaEpisode: null,
        loadingEpisode: false,
        errorEpisode: null
      };

    case 'GET_UPCOMING_ITEMS':
      return {
        ...state,
        loadingUpcomingVod: true,
        upcomingVod: state.upcomingVod
      };

    case 'GET_UPCOMING_ITEM_SUCCESS':
      return {
        ...state,
        upcomingVod: payload,
        loadingUpcomingVod: false,
        upcomingVodNotEmpty
      };

    case 'GET_UPCOMING_ITEM_SUCCESS_FROM_STATE':
      return {
        ...state,
        upcomingVod: payload,
        loadingUpcomingVod: false,
        upcomingVodNotEmpty
      };

    case 'GET_UPCOMING_ITEM_FAILED':
      return {
        ...state,
        upcomingVodError
      };

    case 'GET_CAROUSAL_ITEMS':
      return {
        ...state,
        loadingCarouselVod: true
      };

    case 'GET_CAROUSEL_ITEM_SUCCESS':
      return {
        ...state,
        carouselVod: payload,
        loadingCarouselVod: false,
        upcomingVodNotEmpty
      };

    case 'SET_UPCOMING_LIVE_SHOW_REMINDER':
      return {
        ...state,
        trackLiveshowReminderId: payload?.liveshowId
      };

    default:
      return state;
  }
}
