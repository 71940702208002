import React, { useCallback, useEffect, useRef, useState } from 'react';
import shaka from 'shaka-player/dist/shaka-player.ui';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import VideoInterval from './VideoInterval';
import { secondsToTime, detectAgent } from '../../utils';
import ResumePopup from '../ResumePopup';
import 'shaka-player/dist/controls.css';
import { parsingResponse } from './pallycon-helper';
import './ShakaVideoPlayer.css';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import CutomSubTitlePopup from './CustomSubTitlePopup';
import { fontSizes } from './CustomSubTitlePopup/constants';

import QuizPopup from '../QuizPopup';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';
import { resetWatchHistoryBeforeLogin } from '../../redux/actions/auth';

/**
 * A React component for shaka-player.
 * @param {string} src
 * @param {shaka.extern.PlayerConfiguration} config
 * @param {boolean} autoPlay
 * @param {number} width
 * @param {number} height
 * @param ref
 * @returns {*}
 * @constructor
 */

function ShakaPlayer(
  {
    videoInfo,
    src,
    streamType,
    drmToken,
    width,
    height,
    handlePlayNext,
    handleUpdateHistory,
    historyData,
    handleEndHistory,
    loop,
    adRef,
    isFWT
  },
  ref
) {
  const match = useMatch('/');
  const dispatch = useDispatch();
  const mobilePath = /^\/mobile/g;
  const partner = match?.params?.partner;
  const isPartnerURL = mobilePath && partner;
  const [showResumePopup, setShowResumePopup] = useState(false);
  const [watchedLength, setWatchedLength] = useState();
  const [runInterval, setRunInterval] = useState(false);
  // const [quizTriggered, setQuizTriggered] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [subtitleFormatPopup, setSubtitleFormatPopup] = useState(false);
  const uiContainerRef = useRef(null);
  const videoRef = useRef(null);
  const controller = useRef({});
  const pausing = useRef();
  const quizTriggered = useRef(false);
  const user = useSelector((state) => state.auth.user);
  const watchTime = useSelector((state) => state.auth.watchTime);
  const authPopupStatus = useSelector((state) => state.auth.authPopupStatus);
  const captionSettings = useSelector(
    (state) => state.appSettings.captionSettings
  );
  const quizDetail = useSelector((state) => state?.quiz?.quizDetail);
  const quizChance = useSelector((state) => state?.quiz?.quizChance);

  const updateHistoryCallback = useCallback(() => {
    const { videoElement } = controller.current;
    const currentTime = videoElement.currentTime;
    const duration = videoElement.duration;
    handleUpdateHistory(currentTime, duration);
    if (videoInfo) {
      const paramsEvent = {
        videoID: videoInfo.id,
        videoTitle: videoInfo.titleLocalized,
        videoDuration: videoInfo.duration,
        videoRating: videoInfo.rating,
        contentType: videoInfo.type,
        contentRights: videoInfo.contentRights,
        length: duration,
        position: currentTime
      };
      if (videoInfo.tags && videoInfo.tags.genres) {
        paramsEvent.videoGenre = videoInfo.tags.genres;
      }
      if (videoInfo.season) {
        paramsEvent.seasonID = videoInfo.season.id;
        paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
        paramsEvent.seriesID = videoInfo.seriesID;
        paramsEvent.seriesName = videoInfo.series.titleLocalized;
      }
      const timeRange = 30;
      if (
        currentTime >= 0.25 * duration - timeRange &&
        currentTime <= 0.25 * duration + timeRange
      ) {
        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_REACH_25,
          {
            params: paramsEvent,
            user
          }
        );
      } else if (
        currentTime >= 0.5 * duration - timeRange &&
        currentTime <= 0.5 * duration + timeRange
      ) {
        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_REACH_50,
          {
            params: paramsEvent,
            user
          }
        );
      } else if (
        currentTime >= 0.75 * duration - timeRange &&
        currentTime <= 0.75 * duration + timeRange
      ) {
        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_REACH_75,
          {
            params: paramsEvent,
            user
          }
        );
      }
    }
  }, [handleUpdateHistory, videoInfo, user]);

  const handleResume = useCallback(() => {
    const { videoElement } = controller.current;
    setShowResumePopup(false);
    if (watchedLength) videoElement.currentTime = watchedLength;
    pausing.current = false;
    if (adRef.current) {
      adRef.current.play();
    } else {
      videoElement.play();
    }
  }, [watchedLength, adRef]);

  const handleStartOver = useCallback(() => {
    const { videoElement } = controller.current;
    setShowResumePopup(false);
    videoElement.currentTime = 0;
    if (adRef.current) {
      adRef.current.play();
    } else {
      videoElement.play();
    }
    pausing.current = false;
  }, [adRef]);

  const handleMediaStart = useCallback(() => {
    // console.log('video started', e);
    if (handleUpdateHistory) {
      setRunInterval(true);
    }

    if (pausing.current) {
      // console.log('resume');
      pausing.current = false;

      if (videoInfo) {
        const paramsEvent = {
          contentType: videoInfo.type,
          videoID: videoInfo.id,
          videoTitle: videoInfo.titleLocalized,
          videoDuration: videoInfo.duration
        };
        if (videoInfo.tags && videoInfo.tags.genres) {
          paramsEvent.videoGenre = videoInfo.tags.genres;
        }
        if (videoInfo.season) {
          paramsEvent.seasonID = videoInfo.season.id;
          paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
          paramsEvent.seriesID = videoInfo.seriesID;
          paramsEvent.seriesName = videoInfo.series.titleLocalized;
        }

        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_RESUME,
          {
            params: paramsEvent,
            user
          }
        );
      }
    }
  }, [videoInfo, user, handleUpdateHistory]);

  const handleMediaPause = useCallback(() => {
    if (handleUpdateHistory) {
      setRunInterval(false);
    }
    pausing.current = true;

    if (window.autoplayAfterMuted) {
      const { videoElement } = controller.current;
      videoElement.play();
      window.autoplayAfterMuted = false;
      return;
    }

    if (videoInfo) {
      const paramsEvent = {
        contentType: videoInfo.type,
        videoID: videoInfo.id,
        videoTitle: videoInfo.titleLocalized,
        videoDuration: videoInfo.duration
      };
      if (videoInfo.tags && videoInfo.tags.genres) {
        paramsEvent.videoGenre = videoInfo.tags.genres;
      }
      if (videoInfo.season) {
        paramsEvent.seasonID = videoInfo.season.id;
        paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
        paramsEvent.seriesID = videoInfo.seriesID;
        paramsEvent.seriesName = videoInfo.series.titleLocalized;
      }

      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_PAUSE, {
        params: paramsEvent,
        user
      });
    }
  }, [videoInfo, user, handleUpdateHistory]);

  const handleDurationChange = useCallback(() => {
    const { videoElement } = controller.current;
    if (videoInfo) {
      if (historyData && historyData.mediaId === videoInfo.id) {
        const mediaDuration = videoElement.duration;
        const historyPercentage = historyData.position.percentage
          ? historyData.position.percentage
          : (historyData.position / historyData.length) * 100;
        const historyPosition = (mediaDuration * historyPercentage) / 100;
        if (!isNaN(historyPosition)) {
          if (videoElement.currentTime > historyPosition) return;

          // force start over
          if (historyPercentage >= 95) {
            return setWatchedLength(0);
          }

          if (watchTime) {
            videoElement.currentTime = watchTime;
          }

          setWatchedLength(historyPosition);
          videoElement.pause();
          pausing.current = true;
          if (!watchTime & !isFWT) {
            return setShowResumePopup(true);
          }
        }
      } else {
        videoElement.play();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyData, videoInfo, watchTime]);

  const handleQuizTimer = useCallback(() => {
    const { videoElement } = controller.current;
    if (
      Math.floor(videoElement.currentTime) === quizDetail?.timeToShow &&
      !quizTriggered.current
    ) {
      dispatch(resetWatchHistoryBeforeLogin());
      quizTriggered.current = true;
      videoElement.pause();
      pausing.current = true;
      setShowQuiz(true);
    }
  }, [dispatch, quizDetail]); // code to trigger the quiz

  const handleCloseQuiz = () => {
    const { videoElement } = controller.current;
    videoElement.play();
    pausing.current = false;
    setShowQuiz(false);
    videoElement.removeEventListener('timeupdate', handleQuizTimer);
  };

  const handleMediaEnd = useCallback(async () => {
    const { videoElement } = controller.current;

    if (videoInfo) {
      const durationInSecond = videoInfo.duration * 60;
      // send last history when duration greater than position
      handleEndHistory &&
        handleEndHistory(
          durationInSecond,
          videoElement.currentTime,
          videoElement.duration
        );

      const paramsEvent = {
        contentType: videoInfo.type,
        videoID: videoInfo.id,
        videoTitle: videoInfo.titleLocalized,
        videoDuration: videoInfo.duration
      };
      if (videoInfo.tags && videoInfo.tags.genres) {
        paramsEvent.videoGenre = videoInfo.tags.genres;
      }
      if (videoInfo.season) {
        paramsEvent.seasonID = videoInfo.season.id;
        paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
        paramsEvent.seriesID = videoInfo.seriesID;
        paramsEvent.seriesName = videoInfo.series.titleLocalized;
      }

      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_END, {
        params: paramsEvent,
        user
      });
    }

    if (!handlePlayNext && handleUpdateHistory) {
      return setRunInterval(false);
    }

    if (!isPartnerURL && handlePlayNext) {
      const canPlayNext = await handlePlayNext();
      if (!canPlayNext && handleUpdateHistory) {
        setRunInterval(false);
      }

      return;
    }

    if (loop) {
      videoElement.currentTime = 0;
      videoElement.play();
    }
  }, [
    handlePlayNext,
    videoInfo,
    handleEndHistory,
    user,
    handleUpdateHistory,
    loop,
    isPartnerURL
  ]);

  const handleMediaSeek = useCallback(() => {
    const { videoElement } = controller.current;

    if (videoInfo) {
      const paramsEvent = {
        contentType: videoInfo.type,
        videoID: videoInfo.id,
        videoTitle: videoInfo.titleLocalized,
        videoDuration: videoInfo.duration,
        elapsedTime: videoElement.currentTime
      };
      if (videoInfo.tags && videoInfo.tags.genres) {
        paramsEvent.videoGenre = videoInfo.tags.genres;
      }
      if (videoInfo.season) {
        paramsEvent.seasonID = videoInfo.season.id;
        paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
        paramsEvent.seriesID = videoInfo.seriesID;
        paramsEvent.seriesName = videoInfo.series.titleLocalized;
      }

      analytic(analyticTypes.event, analyticEvents.MEDIA_PLAYBACK.MEDIA_SEEK, {
        params: paramsEvent,
        user
      });
    }
  }, [videoInfo, user]);

  const handlerError = useCallback((err) => {
    // eslint-disable-next-line no-console
    console.log('error in shaka player', err);
  }, []);

  const handlerLoaded = useCallback(() => {
    // eslint-disable-next-line no-console
    // console.log(event);
    // throw new Error(event);
  }, []);

  const handleSubtitleFormatPopup = useCallback(() => {
    setSubtitleFormatPopup(!subtitleFormatPopup);
  }, [subtitleFormatPopup]);
  const captionFormat = useCallback(() => {
    shaka.ui.captionFormat = class extends shaka.ui.Element {
      constructor(parent, controls) {
        super(parent, controls);
        this.isShowPopup = false;
        this.button_ = document.createElement('button');
        this.button_.classList.add('material-icons');
        this.button_.classList.add('caption-mobile');
        this.button_.textContent = 'Subtitle Format';
        this.parent.appendChild(this.button_);
        this.eventManager.listen(this.button_, 'click', () => {
          const event = new Event('subtitleFormatPopup');
          window.dispatchEvent(event);
        });
      }
    };
    shaka.ui.captionFormat.Factory = class {
      // eslint-disable-next-line class-methods-use-this
      create(rootElement, controls) {
        // eslint-disable-next-line new-cap
        return new shaka.ui.captionFormat(rootElement, controls);
      }
    };
    shaka.ui.Controls.registerElement(
      'caption_format',
      new shaka.ui.captionFormat.Factory()
    );
  }, []);

  const ForwardBackwardButton = useCallback(() => {
    shaka.ui.ForwardBackwardButton = class extends shaka.ui.Element {
      constructor(parent, controls) {
        super(parent, controls);

        this.forwardIcon_ = document.createElement('i');
        this.forwardIcon_.classList.add('material-icons-round');
        this.forwardIcon_.textContent = 'forward_10';

        this.backIcon_ = document.createElement('i');
        this.backIcon_.classList.add('material-icons-round');
        this.backIcon_.textContent = 'replay_10';

        this.button_ = document.createElement('button');
        this.button_.classList.add('material-icons-outlined');
        this.button_.setAttribute('aria-label', 'Forward 10');
        this.button_.appendChild(this.forwardIcon_);

        this.buttonBack_ = document.createElement('button');
        this.buttonBack_.classList.add('material-icons-outlined');
        this.buttonBack_.setAttribute('aria-label', 'Replay 10');
        this.buttonBack_.appendChild(this.backIcon_);

        this.parent.appendChild(this.buttonBack_);
        this.parent.appendChild(this.button_);

        this.eventManager.listen(this.button_, 'click', () => {
          const { videoElement } = controller.current;
          if (videoElement) {
            videoElement.currentTime += 10;
          }
        });

        this.eventManager.listen(this.buttonBack_, 'click', () => {
          const { videoElement } = controller.current;

          if (videoElement) {
            videoElement.currentTime -= 10;
          }
        });
      }
    };
    shaka.ui.ForwardBackwardButton.Factory = class {
      // eslint-disable-next-line class-methods-use-this
      create(rootElement, controls) {
        return new shaka.ui.ForwardBackwardButton(rootElement, controls);
      }
    };
    shaka.ui.Controls.registerElement(
      'jumpfwdback',
      new shaka.ui.ForwardBackwardButton.Factory()
    );
  }, []);

  // EVENT LISTENERS
  const addEventListener = useCallback(async () => {
    const { videoElement } = controller.current;
    videoElement.addEventListener('play', handleMediaStart);
    videoElement.addEventListener('pause', handleMediaPause);
    videoElement.addEventListener('durationchange', handleDurationChange);
    videoElement.addEventListener('seeked', handleMediaSeek);
    videoElement.addEventListener('ended', handleMediaEnd);
    videoElement.addEventListener('timeupdate', handleQuizTimer);
  }, [
    handleMediaStart,
    handleMediaPause,
    handleDurationChange,
    handleMediaSeek,
    handleMediaEnd,
    handleQuizTimer
  ]);

  const removeEventListener = useCallback(() => {
    const { videoElement } = controller.current;
    if (videoElement) {
      videoElement.removeEventListener('play', handleMediaStart);
      videoElement.removeEventListener('pause', handleMediaPause);
      videoElement.removeEventListener('durationchange', handleDurationChange);
      videoElement.removeEventListener('seeked', handleMediaSeek);
      videoElement.removeEventListener('ended', handleMediaEnd);
      videoElement.removeEventListener('timeupdate', handleQuizTimer);
    }
  }, [
    handleMediaStart,
    handleMediaPause,
    handleDurationChange,
    handleMediaSeek,
    handleMediaEnd,
    handleQuizTimer
  ]);

  // Effect to handle component mount & mount.
  // Not related to the src prop, this hook creates a shaka.Player instance.
  // This should always be the first effect to run.
  React.useEffect(() => {
    shaka.polyfill.installAll();
    if (!shaka.Player.isBrowserSupported()) {
      return false;
    }

    // Register custom caption format button
    // This should be always added before player initialization
    ForwardBackwardButton();
    captionFormat();
    const player = new shaka.Player();
    player.addEventListener('error', handlerError);
    // window.player = player;
    // const player = new shaka.Player(videoRef.current)
    const ui = new shaka.ui.Overlay(
      player,
      uiContainerRef.current,
      videoRef.current
    );
    const controls = ui.getControls();
    // Store Shaka's API in order to expose it as a handle.
    controller.current = {
      player,
      ui,
      videoElement: videoRef.current,
      controls
    };

    player.addEventListener('loaded', handlerLoaded);
    player.setTextTrackVisibility(true);
  }, [handlerError, handlerLoaded, captionFormat, ForwardBackwardButton]);

  // Load the source url when we have one.
  React.useEffect(() => {
    const { player } = controller.current;
    player.attach(videoRef.current);
    if (player) {
      addEventListener();
      if (streamType === 'hls') {
        player
          .getNetworkingEngine()
          .registerRequestFilter(function (type, request) {
            if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
              if (drmToken) {
                const originalPayload = new Uint8Array(request.body);
                const base64Payload =
                  shaka.util.Uint8ArrayUtils.toBase64(originalPayload);
                const params = `spc=${encodeURIComponent(base64Payload)}`;

                request.body = shaka.util.StringUtils.toUTF8(params);
                request.headers['Content-Type'] =
                  'application/x-www-form-urlencoded';
                request.headers['pallycon-customdata-v2'] = drmToken;
              }
            }
          });

        player
          .getNetworkingEngine()
          .registerResponseFilter(function (type, response) {
            if (drmToken) {
              // Alias some utilities provided by the library.
              if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                const responseText = shaka.util.StringUtils.fromUTF8(
                  response.data
                ).trim();
                response.data =
                  shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
                parsingResponse(response);
              }
            }
          });
      } else {
        player
          .getNetworkingEngine()
          .registerRequestFilter(function (type, request) {
            if (drmToken) {
              if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                request.headers['pallycon-customdata-v2'] = drmToken;
              }
            }
          });

        player
          .getNetworkingEngine()
          .registerResponseFilter(function (type, response) {
            if (drmToken) {
              // Alias some utilities provided by the library.
              if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                parsingResponse(response);
              }
            }
          });
      }
    }
    return () => {
      player.detach();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, drmToken, streamType]);

  useEffect(() => {
    const { videoElement } = controller.current;

    return () => {
      if (videoInfo && user) {
        const paramsEvent = {
          contentType: videoInfo.type,
          videoID: videoInfo.id,
          videoTitle: videoInfo.titleLocalized,
          videoDuration: videoInfo.duration,
          elapsedTime: videoElement.currentTime
        };
        if (videoInfo.tags && videoInfo.tags.genres) {
          paramsEvent.videoGenre = videoInfo.tags.genres;
        }
        if (videoInfo.season) {
          paramsEvent.seasonID = videoInfo.season.id;
          paramsEvent.seasonNumber = videoInfo.season.titleLocalized;
          paramsEvent.seriesID = videoInfo.seriesID;
          paramsEvent.seriesName = videoInfo.series.titleLocalized;
        }

        analytic(
          analyticTypes.event,
          analyticEvents.MEDIA_PLAYBACK.MEDIA_EXIT,
          {
            params: paramsEvent,
            user
          }
        );
      }
    };
  }, [user, videoInfo]);

  React.useEffect(() => {
    addEventListener();
    return () => {
      removeEventListener();
    };
  }, [addEventListener, removeEventListener]);

  React.useEffect(() => {
    window.addEventListener('subtitleFormatPopup', handleSubtitleFormatPopup);
    return () => {
      window.removeEventListener(
        'subtitleFormatPopup',
        handleSubtitleFormatPopup
      );
    };
  }, [handleSubtitleFormatPopup]);

  // React.useEffect(() => {
  //   if (videoInfo) {
  //     dispatch(getMediaQuizInfo(videoInfo.id));
  //     dispatch(getMediaQuizChance(videoInfo.id));
  //   }
  // }, [dispatch, videoInfo]); //media quiz

  React.useEffect(() => {
    if (detectAgent('mobile')) {
      setSubtitleFormatPopup(false);
      return;
    }
    const root = document.querySelector(':root');
    root.style.setProperty(
      '--captionSize',
      fontSizes[captionSettings.fontSize].fontSize
    );
    root.style.setProperty(
      '--captionLineHeight',
      fontSizes[captionSettings.fontSize].lineHeight
    );
    root.style.setProperty(
      '--captionColor',
      captionSettings.fontColor.toLowerCase() === 'yellow'
        ? '#FFC700'
        : '#FFFFFF'
    );
    root.style.setProperty(
      '--captionEdgesTextShadow',
      captionSettings.fontEdges.toLowerCase() === 'shadow'
        ? '0px 2px 2px rgba(0, 0, 0, 0.64)'
        : '0px 2px 2px rgba(0, 0, 0, 0.64), 0px 2px 2px rgba(0, 0, 0, 0.64),0px 2px 2px rgba(0, 0, 0, 0.64),0px 2px 2px rgba(0, 0, 0, 0.64)'
    );
    root.style.setProperty(
      '--captionEdgesTextBorder',
      captionSettings.fontEdges.toLowerCase() === 'border' ? '2px black' : ''
    );
    root.style.setProperty(
      '--captionBgTransparant',
      captionSettings.bgTransparancy
    );
  }, [captionSettings]);
  // Define a handle for easily referencing Shaka's player & ui API's.
  React.useImperativeHandle(ref, () => ({
    get player() {
      return controller.current.player;
    },
    get ui() {
      return controller.current.ui;
    },
    get videoElement() {
      return controller.current.videoElement;
    },
    get controls() {
      return controller.current.controls;
    }
  }));

  return (
    <>
      <div
        ref={uiContainerRef}
        style={{ position: 'relative' }}
      >
        {runInterval && (
          <VideoInterval updateHistoryCallback={updateHistoryCallback} />
        )}
        <video
          ref={videoRef}
          width={width}
          height={height}
          style={{ maxWidth: '100%', width, height }}
          autoPlay
        />
        <GeneralNotifModal open={showResumePopup && !adRef.current}>
          <ResumePopup
            showResumePopup={showResumePopup}
            watchedLength={secondsToTime(watchedLength)}
            onResume={handleResume}
            onStartOver={handleStartOver}
          />
        </GeneralNotifModal>{' '}
        <GeneralNotifModal
          open={showQuiz && !authPopupStatus && !adRef.current}
        >
          <QuizPopup
            onClose={handleCloseQuiz}
            questionJson={quizDetail?.questionJson}
            quizContentId={quizDetail?.id}
            eligible={quizChance}
          />
        </GeneralNotifModal>
        {subtitleFormatPopup && <CutomSubTitlePopup />}
      </div>
      <div id='ad-container' />
    </>
  );
}

export default React.memo(React.forwardRef(ShakaPlayer));
