import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

const ItemDetail = ({
  item,
  exclusiveContents,
  component: Component,
  onChannelSelected,
  onLiveshowClicked,
  preloadDetail,
  clearPreLoadDetail,
  playOnClick,
  ...otherProps
}) => {
  const location = useLocation();
  const params = useParams();

  switch (item.type) {
    case 'webview':
    case 'stream':
      return (
        <div
          style={{ width: '100%', height: '100%' }}
          onClick={() => onChannelSelected(item)}
        >
          <Component
            item={item}
            {...otherProps}
          />
        </div>
      );
    case 'featured':
      return (
        <Link
          onMouseEnter={preloadDetail && preloadDetail(item)}
          onMouseLeave={clearPreLoadDetail}
          key={item.id}
          to={`/playlists/${item.id}`}
        >
          <Component
            item={item}
            {...otherProps}
          />
        </Link>
      );
    case 'liveshow-vod':
    case 'liveshow':
      return (
        <div
          style={{ width: '100%', height: '100%' }}
          onClick={() => onLiveshowClicked(item)}
        >
          <Component
            item={item}
            {...otherProps}
          />
        </div>
      );

    default:
      const partner = params?.partner;
      const additionalUrl =
        /^\/mobile/g.test(location.pathname) && partner
          ? `/mobile/${partner}`
          : '';
      let contentId = item.id;
      let contentType = item.type;

      if (partner && item.seriesId) {
        contentId = item.seriesId;
        contentType = 'series';
      }
      const contentURL = `${additionalUrl}/${contentType}/${contentId}`;

      return playOnClick ? (
        <div
          style={{ width: '100%', height: '100%' }}
          onClick={() => playOnClick(item)}
        >
          <Component
            item={item}
            exclusiveContents={exclusiveContents}
            {...otherProps}
          />
        </div>
      ) : (
        <Link
          onMouseEnter={preloadDetail && preloadDetail(item)}
          onMouseLeave={clearPreLoadDetail}
          key={item.id}
          to={contentURL}
        >
          <Component
            item={item}
            exclusiveContents={exclusiveContents}
            {...otherProps}
          />
        </Link>
      );
  }
};

export default ItemDetail;
