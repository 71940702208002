const initialState = {
  bankList: [
    {
      name: 'BCA',
      code: '014'
    },
    {
      name: 'Mandiri',
      code: '008'
    },
    {
      name: 'BNI',
      code: '009'
    },
    {
      name: 'BRI',
      code: '002'
    },
    {
      name: 'BRI Syariah',
      code: '427'
    },
    {
      name: 'BSM',
      code: '451'
    },
    {
      name: 'CIMB Niaga',
      code: '022'
    }
  ],
  gencash: [],
  diamonds: [],
  diamondTransactions: {
    data: [],
    meta: [],
    page: 1,
    noData: false
  },
  subscriptionTransactions: {
    data: [],
    meta: [],
    page: 1,
    noData: false
  },
  gencashTransactions: {
    data: [],
    meta: [],
    page: 1,
    noData: false
  },
  offlineGifting: {
    data: [],
    meta: [],
    page: 1,
    noData: false
  },
  gencashTransaction: {},
  cashoutRequest: {},
  bulletOption: [],
  giftOption: [],
  goods: [],
  settings: {},
  loading: false,
  err: false
};

export default function PackReducer(state = initialState, action) {
  const { type, payload, page, err } = action;
  switch (type) {
    case 'GET_PACK_SETTINGS':
    case 'GET_GENCASH_TRANSACTION':
    case 'GET_GENCASH_TRANSACTION_DETAIL':
    case 'GET_DIAMOND_TRANSACTION':
    case 'GET_OFFLINE_GIFTING':
    case 'GET_BULLET_OPTION':
    case 'GET_GIFT_OPTION':
    case 'GET_PACK':
    case 'GET_SUBSCRIPTION_TRANSACTION':
      return {
        ...state,
        loading: true,
        err: false
      };
    case 'GET_GIFT_OPTION_SUCCESS':
      return {
        ...state,
        giftOption: payload,
        loading: false,
        err: false
      };
    case 'GET_BULLET_OPTION_SUCCESS':
      return {
        ...state,
        bulletOption: payload.data,
        loading: false,
        err: false
      };
    case 'GET_GENCASH_SUCCESS':
      return {
        ...state,
        gencash: payload.data,
        loading: false,
        err: false
      };
    case 'GET_DIAMONDS_SUCCESS':
      return {
        ...state,
        diamonds: payload.data,
        loading: false,
        err: false
      };
    case 'GET_GOODS_SUCCESS':
      return {
        ...state,
        goods: payload.data,
        loading: false,
        err: false
      };
    case 'GET_DIAMOND_TRANSACTION_SUCCESS':
      const diamondTransactions = state.diamondTransactions;
      return {
        ...state,
        diamondTransactions: {
          data:
            payload.meta?.page === 1
              ? payload.data
              : [...diamondTransactions.data, ...payload.data],
          meta: payload.meta,
          page,
          noData: !payload.data.length
        },
        loading: false,
        err: false
      };

    case 'GET_DIAMOND_TRANSACTION_SUCCESS_FROM_STATE':
      return {
        ...state,
        diamondTransactions: {
          ...state.diamondTransactions,
          noData: !payload.data.length
        },
        loading: false,
        err: false
      };

    case 'GET_GENCASH_TRANSACTION_SUCCESS':
      const gencashTransactions = state.gencashTransactions;
      return {
        ...state,
        gencashTransactions: {
          data:
            payload?.meta?.page === 1
              ? payload.data
              : [...gencashTransactions.data, ...payload.data],
          meta: payload.meta,
          page,
          noData: !payload.data.length
        },
        loading: false,
        err: false
      };

    case 'GET_GENCASH_TRANSACTION_SUCCESS_FROM_STATE':
      return {
        ...state,
        gencashTransactions: {
          ...state.gencashTransactions,
          noData: !payload.data.length
        },
        loading: false,
        err: false
      };

    case 'GET_SUBSCRIPTION_TRANSACTION_SUCCESS':
      const subscriptionTransactions = state.subscriptionTransactions;
      return {
        ...state,
        subscriptionTransactions: {
          data:
            payload?.meta?.page === 1
              ? payload.data
              : [...subscriptionTransactions.data, ...payload.data],
          meta: payload.meta,
          page,
          noData: !payload.data.length
        },
        loading: false,
        err: false
      };

    case 'GET_SUBSCRIPTION_TRANSACTION_SUCCESS_FROM_STATE':
      return {
        ...state,
        subscriptionTransactions: {
          ...state.subscriptionTransactions,
          noData: !payload.data.length
        },
        loading: false,
        err: false
      };

    case 'GET_OFFLINE_GIFTING_SUCCESS':
      const offlineGifting = state.offlineGifting;
      return {
        ...state,
        offlineGifting: {
          data:
            payload?.meta?.page === 1
              ? payload.data
              : [...offlineGifting.data, ...payload.data],
          meta: payload.meta,
          page,
          noData: !payload.data.length
        },
        loading: false,
        err: false
      };

    case 'GET_OFFLINE_GIFTING_SUCCESS_FROM_STATE':
      return {
        ...state,
        offlineGifting: {
          ...state.offlineGifting,
          noData: !payload.data.length
        },
        loading: false,
        err: false
      };

    case 'GET_GENCASH_TRANSACTION_DETAIL_SUCCESS':
      return {
        ...state,
        gencashTransaction: payload.data,
        loading: false,
        err: false
      };
    case 'CASHOUT_REQUEST_SUCCESS':
      return {
        ...state,
        cashoutRequest: payload.data,
        loading: false,
        err: false
      };
    case 'GET_PACK_SETTINGS_SUCCESS':
      return {
        ...state,
        settings: payload.data,
        loading: false,
        err: false
      };
    case 'GET_PACK_SETTINGS_SUCCESS_FROM_STATE':
      return {
        ...state,
        settings: payload,
        loading: false,
        err: false
      };
    case 'GET_DIAMOND_TRANSACTION_ERROR':
    case 'GET_GENCASH_TRANSACTION_ERROR':
    case 'GET_GENCASH_TRANSACTION_DETAIL_ERROR':
    case 'GET_OFFLINE_GIFTING_ERROR':
    case 'CASHOUT_REQUEST_ERROR':
    case 'GET_SUBSCRIPTION_TRANSACTION_ERROR':
    case 'GET_PACK_ERROR':
      return {
        ...state,
        loading: false,
        err: payload?.err || err || ''
      };
    case 'RESET_PACK_STATE':
      return {
        ...initialState
      };

    case 'FETCH_NEXT_PAGE_DIAMOND_TRANSACTION':
      return {
        ...state,
        diamondTransactions: {
          ...state.diamondTransactions,
          page: state.diamondTransactions.page + 1
        },
        loading: false,
        err: false
      };

    case 'FETCH_NEXT_PAGE_SUBSCRIPTION_TRANSACTION':
      return {
        ...state,
        subscriptionTransactions: {
          ...state.subscriptionTransactions,
          page: state.subscriptionTransactions.page + 1
        },
        loading: false,
        err: false
      };

    case 'FETCH_NEXT_PAGE_GENCASH_TRANSACTION':
      return {
        ...state,
        gencashTransactions: {
          ...state.gencashTransactions,
          page: state.gencashTransactions.page + 1
        },
        loading: false,
        err: false
      };

    case 'FETCH_NEXT_PAGE_OFFLINE_GIFTING':
      return {
        ...state,
        offlineGifting: {
          ...state.offlineGifting,
          page: state.offlineGifting.page + 1
        },
        loading: false,
        err: false
      };

    default:
      return state;
  }
}
