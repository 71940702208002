// eslint-disable-next-line import/prefer-default-export
export const appSettings = {
  'blockee.access_content.error_dialog.message':
    'You have been blocked by the user, and are unable to view this content anymore.',
  'blockee.access_content.error_dialog.title': 'Access Denied',
  'blockee.follow_profile.error_dialog.message':
    'You have been blocked by the user, and are unable to follow this profile.',
  'blockee.follow_profile.error_dialog.title': 'Access Denied',
  'blockee.send_diamond.error_dialog.message':
    'You have been blocked by the user, and are unable to send Diamonds to this profile.',
  'blockee.send_diamond.error_dialog.title': 'Access Denied',
  'blocker.access_content.error_dialog.message':
    'You are not able to view this content. To view this content, you need to unblock the user.\\n\\nAre you sure want to unblock [blockee_name]?',
  'blocker.access_content.error_dialog.title': 'You Blocked [blockee_name]',
  'blocker.block_dialog.message':
    'Block means he/she will no longer be able to send message, follow and watch your content.',
  'blocker.block_dialog.title': 'Block [blockee_name]?',
  'blocker.follow_profile.error_dialog.message':
    'You are not able to follow this profile. To follow this profile, you need to unblock the user.\\n\\nAre you sure want to unblock [blockee_name]?',
  'blocker.follow_profile.error_dialog.title': 'You Blocked [blockee_name]',
  'blocker.send_diamond.error_dialog.message':
    'You are not able to send Diamonds to this profile. To send Diamonds, you need to unblock the user.\\n\\nAre you sure want to unblock [blockee_name]?',
  'blocker.send_diamond.error_dialog.title': 'You Blocked [blockee_name]',
  'blocker.unblock_dialog.message':
    '[blockee_name] will now be able to see your liveshow, clips, follow and send chat/message on your content.',
  'blocker.unblock_dialog.title': 'Unblock [blockee_name]?',
  'userReport.reason.comment': 'improper chats,racism,spamming,others',
  'userReport.reason.content':
    'pornographic,violence content,illegal content,others',
  cancelUpdateAction: 'later'
};
