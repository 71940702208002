import React, {
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
  useMemo,
  useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSTransition from 'react-transition-group/CSSTransition';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import ProfileDialog from '../ProfileDialog/ProfileDialog';
import ToastNotification from '../Notification/toast';
import Button from '../Button/Button';
import { useOutsideHandle } from '../../hooks';
import { generateErrorDetail, getInitial } from '../../utils';
import ClipsUploadModal from '../Clips/ClipsUploadModal';
import NotificationDialog from '../Notification/NotificationDialog';
import logo from '../../assets/logo.svg';
import icSearch from '../../assets/ic-search.svg';
import icProfile from '../../assets/ic-profile.svg';
import icArrowBack from '../../assets/ic-arrow-back.svg';
import icMoviesMenu from '../../assets/ic-movie.svg';
import icSeriesMenu from '../../assets/ic-series.svg';
import icAnimationMenu from '../../assets/ic-animation.svg';
import icLiveTVMenu from '../../assets/ic-live-tv.svg';
// import icCameraAdd from '../../assets/ic-camera-add.svg';
import icCart from '../../assets/ic-cart.svg';
import icBroadcast from '../../assets/ic-broadcast.svg';
// import icShare from '../../assets/ic-share.svg';
// import icUpload from '../../assets/ic-upload.svg';

import styles from './Header.module.css';
// import { broadcasting } from '../../redux/actions/liveGame';
import PopModalDialog from '../PopModalDialog/PopModalDialog';
import LoginDialog from '../LoginDialog/LoginDialog';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';
import { authPopup, loginByEmail, logout } from '../../redux/actions/auth';
import ValidateSessionNotification from '../Notification/ValidateSessionNotification';
// import { callHeartbeat } from '../../redux/actions/heartbeat';
// import ShareModal from '../ShareModal/ShareModal';
import { AnimatedModal } from '..';
import NotificationIcon from '../Notification/NotificationIcon';
import { toggleDialogNotif } from '../../redux/actions/inAppNotification';
import PopModalNotification from '../PopModalDialog/PopModalNotification';
import { showCreateClips } from '../../redux/actions/clips';
import { surgeMobileAutologin } from '../../config/partners';
import DefaultPassword from '../LoginDialog/DefaultPassword';
import UserConsent from '../LoginDialog/UserConsent';

const pageOrderCondition = ['movies', 'series', 'animation'];
const sortPage = (page1, page2) => {
  for (let i = 0; i < pageOrderCondition.length; i++) {
    const item = pageOrderCondition[i];
    if (page1 === item) return -1;
    if (page2 === item) return 1;
  }
};

const subMenuIcon = [icMoviesMenu, icSeriesMenu, icAnimationMenu];

const mainMenu = [
  {
    name: 'Home',
    path: '/',
    type: 'menu'
  },
  {
    name: 'Movies',
    path: '/movies',
    type: 'menu'
  },
  {
    name: 'Series',
    path: '/series',
    type: 'menu'
  },
  {
    name: 'Animation',
    path: '/animation',
    type: 'menu'
  },
  {
    name: 'Live TV',
    path: '/live-tv',
    type: 'menu'
  }
  // {
  //   type: 'submenu'
  // }
  // {
  //   name: 'Live',
  //   path: '/live',
  //   type: 'menu'
  // },
  // {
  //   name: 'Clips',
  //   path: '/clips',
  //   type: 'menu'
  // }
];

const Header = ({ showHeaderMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const partner = searchParams.get('partner');
  const msisdn = searchParams.get('msisdn');
  const authType = searchParams.get('type');
  const email = searchParams.get('email');
  const isNewUser = searchParams.get('isNewUser');
  const password = searchParams.get('password');
  const dispatch = useDispatch();
  const { user, authPopupStatus, token, sessionStatus, showConsent } =
    useSelector((state) => state.auth);
  const pageData = useSelector((state) => state.page.data);
  // const { liveGameInfo, isLiveGameBroadcasting } = useSelector(
  //   (state) => state.liveGame
  // );
  const { showClipsModal } = useSelector((state) => state.clips);
  const authenticated = !!user;
  const [showProfile, toggleDialog] = useState(false);
  const [showSearchField, toggleSearch] = useState(false);
  const [showSearchError, setSearchError] = useState(null);
  // const [showShareModal, setShowShareModal] = useState(false);
  // const [endStreamModal, setEndStreamModal] = useState(false);
  const [showDefaultPassword, setShowDefaultPassword] = useState();
  const [showLiveAndClipsMenu, setShowLiveAndClipsMenu] = useState(false);
  const headerRef = useRef();
  const dialogRef = useRef();
  const formRef = useRef();
  const searchRef = useRef();
  const liveRef = useRef();
  const [defaultPassword, setDefaultPassword] = useState(password);

  const toggleProfile = () => {
    toggleDialog((open) => !open);
  };

  const isNotifPage = location.pathname === '/notifications/';

  const handleNotifClick = () => {
    dispatch(toggleDialogNotif());
    if (window.innerWidth < 500 && token) {
      return navigate('/notifications');
    }
    if (!token) {
      return dispatch(authPopup.show());
    }
  };
  // const isStreamerPage = location.pathname === '/streamer-dashboard';
  const subMenu = useMemo(() => {
    const subMenuList = pageData
      .filter((item) => item.friendlyId !== 'home')
      .sort((firstPage, secondPage) => {
        const { friendlyId: firstId } = firstPage;
        const { friendlyId: secondId } = secondPage;
        const sortValue = sortPage(firstId, secondId);
        return sortValue || 0;
      })
      .map((item, i) => {
        const name = item.titleLocalized;
        const friendlyId = item.friendlyId;
        return {
          name,
          path: `/${friendlyId}`,
          icon: subMenuIcon[i]
        };
      });

    subMenuList &&
      subMenuList.push({
        name: 'Live TV',
        path: '/live-tv',
        icon: icLiveTVMenu
      });

    return subMenuList;
  }, [pageData]);
  const newMainMenuData = useMemo(() => {
    if (subMenu && subMenu.length) {
      return mainMenu.map((item) => {
        if (item.type === 'submenu') {
          item.subMenuItem = subMenu;
        }
        return item;
      });
    }
  }, [subMenu]);
  const activeSubMenu = useMemo(() => {
    if (subMenu && subMenu.length) {
      const active = subMenu.find((item) => item.path === location.pathname);

      return active || subMenu[0];
    }
  }, [subMenu, location.pathname]);
  const [selectedSubMenu, setSelectedSubMenu] = useState(activeSubMenu);

  useEffect(() => {
    setSelectedSubMenu(activeSubMenu || selectedSubMenu);
  }, [activeSubMenu, selectedSubMenu]);

  const handleClickOutsideDialog = useCallback(
    (target) => {
      // ignore user-profile-icon to use its onClick func
      if (target.dataset.iconName === 'user-profile-icon') {
        return;
      }
      if (showProfile) {
        toggleProfile();
      }
    },
    [showProfile]
  );

  const handleClickOutsideSearchField = useCallback(() => {
    if (showSearchField) {
      toggleSearch(!showSearchField);
    }
  }, [showSearchField]);

  const handleClickOutsideLiveAndClipsMenu = useCallback(
    (target) => {
      if (target.dataset.button === 'live-and-clips-button') return;
      if (showLiveAndClipsMenu) {
        setShowLiveAndClipsMenu(false);
      }
    },
    [showLiveAndClipsMenu]
  );

  useOutsideHandle(dialogRef, handleClickOutsideDialog);
  useOutsideHandle(formRef, handleClickOutsideSearchField);
  useOutsideHandle(liveRef, handleClickOutsideLiveAndClipsMenu);

  useLayoutEffect(() => {
    if (showSearchField) {
      searchRef.current.focus();
    }
  }, [showSearchField]);

  // const handleClickSearch = () => {
  //   toggleSearch(!showSearchField)
  // }

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // Remove non-alphanumeric characters
    const sanitizedInput = inputValue.replace(/[^a-z0-9\s]/gi, '');
    searchRef.current.value = sanitizedInput;
    setSearchError(null);
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();

    if (searchRef.current.value.length < 3) {
      setSearchError('Input must be at least 3 characters');
      return;
    }
    if (searchRef.current.value) {
      navigate(`/search?keyword=${searchRef.current.value}`);
    }
  };

  // const handleGoLive = () => {
  //   if (authenticated) {
  //     setShowLiveAndClipsMenu(false);
  //     return navigate('/streamer-dashboard');
  //   }
  //   return dispatch(authPopup.show());
  // };

  const toggleBodyScroll = (action) => {
    const body = document.querySelector('body');
    body.style.overflow = action;
  };

  // const handleUploadClips = () => {
  //   if (authenticated) {
  //     setShowLiveAndClipsMenu(false);
  //     toggleBodyScroll('hidden');
  //     return dispatch(showCreateClips.show());
  //   }
  //   return dispatch(authPopup.show());
  // };

  // const handleStreamButton = () => {
  //   if (isLiveGameBroadcasting) {
  //     setEndStreamModal(true);
  //   } else {
  //     dispatch(broadcasting());
  //   }
  // };

  const clearURLParams = () => {
    searchParams.delete('email');
    searchParams.delete('promo_page');
    searchParams.delete('isNewUser');
    searchParams.delete('password');
    // setSearchParams(searchParams);

    window.history.replaceState({}, '', `/${searchParams.toString()}`);
  };

  useEffect(() => {
    const handleAutoLogin = async (inputEmail, inputPassword) => {
      try {
        await dispatch(loginByEmail(inputEmail, inputPassword));
      } catch (err) {
        generateErrorDetail(err);
      }
    };

    const formatMobileNumber = (number) => {
      // Remove all spaces
      let mobile = number.replace(/ /g, '');

      // If string starts with +, drop first 3 characters
      if (number.slice(0, 1) === '+') {
        mobile = mobile.substring(3);
      }

      // If string starts with 62, drop first 2 characters
      if (number.slice(0, 2) === '62') {
        mobile = mobile.substring(2);
      }

      // If string starts with 0, drop first 1 characters
      if (number.slice(0, 1) === '0') {
        mobile = mobile.substring(1);
      }
      return mobile;
    };

    if (isNewUser && (msisdn || email)) {
      if (isNewUser === 'true' && email && password) {
        setDefaultPassword(password);

        if (!showDefaultPassword) {
          handleAutoLogin(email, decodeURIComponent(password));
          setShowDefaultPassword(true);
        } else {
          clearURLParams();
        }
      } else if (msisdn) {
        if (token) {
          if (
            user.account.mobile &&
            formatMobileNumber(user.account.mobile) ===
              formatMobileNumber(msisdn)
          ) {
            clearURLParams();
          } else {
            dispatch(logout());
          }
        } else if (!token) {
          dispatch(
            authPopup.show({
              showClose: true,
              state: authType,
              type: msisdn ? 'phone' : 'email',
              showFooter: false
            })
          );
        } else {
          clearURLParams();
        }
      } else if (isNewUser === 'false' && email) {
        if (!token) {
          dispatch(
            authPopup.show({
              showClose: false,
              state: authType,
              type: 'email',
              showFooter: false
            })
          );
        } else if (user?.account?.email !== email) {
          dispatch(logout());
        } else if (token) {
          clearURLParams();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isNewUser, msisdn, email, dispatch, authType, token, password]);

  useEffect(() => {
    // Add redirection after buy package
    const redirectPath = localStorage.getItem('gfRedirectPath');
    const fromFWT = localStorage.getItem('fromFWT');

    if (location.pathname === '/' && redirectPath && fromFWT) {
      setTimeout(() => {
        localStorage.removeItem('gfRedirectPath');
        return navigate(redirectPath, { replace: true });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    if (authType && !isNaN(msisdn) && surgeMobileAutologin.includes(partner)) {
      localStorage.setItem('partner_id', partner);

      if (!token) {
        dispatch(
          authPopup.show({
            showClose: false,
            state: authType,
            type: 'phone',
            showFooter: false
          })
        );
      }
    }
  }, [token, partner, msisdn, authType, dispatch]);

  return (
    <div className={styles['headerSpace']}>
      <div
        className={styles['header']}
        ref={headerRef}
      >
        <ToastNotification />
        <Link to='/'>
          <img
            src={logo}
            alt='logo'
          />
        </Link>
        {showHeaderMenu ? (
          <ul className={`${styles['menu']} hide-mobile`}>
            {newMainMenuData.map((item) => {
              const selected = location?.pathname === item.path;

              if (
                item.type === 'submenu' &&
                item.subMenuItem.length &&
                activeSubMenu
              ) {
                const selectedPath = location?.pathname === activeSubMenu.path;

                return (
                  <li
                    key={activeSubMenu.name}
                    className={selectedPath ? styles['selected'] : ''}
                  >
                    <PopModalDialog
                      content={
                        <div className={styles['sub-menu-wrapper']}>
                          {item.subMenuItem.map((subMenuItem) => {
                            return (
                              <Button
                                key={subMenuItem.name}
                                icon={subMenuItem.icon}
                                type='text'
                                style={{
                                  margin: '0 10px',
                                  fontSize: 14
                                }}
                                onClick={() => navigate(subMenuItem.path)}
                              >
                                {subMenuItem.name}
                              </Button>
                            );
                          })}
                        </div>
                      }
                      action='hover'
                      modalStyle={{
                        borderRadius: 50
                      }}
                      customYBase={headerRef.current}
                    >
                      <span>
                        {activeSubMenu.name}
                        <img
                          src={icArrowBack}
                          alt='drop-down'
                          data-dropdown
                        />
                      </span>
                    </PopModalDialog>
                  </li>
                );
              }

              return (
                <li
                  key={item.name}
                  className={selected ? styles['selected'] : ''}
                >
                  <Link to={item.path}>{item.name}</Link>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className={styles['streamer-dashboard-header']}>
            <img
              src={icBroadcast}
              alt='signal'
            />
            Streamer Dashboard
          </div>
        )}

        <ul className={styles['actions-menu']}>
          {showHeaderMenu && (
            <>
              <li className={`${styles['search']} ${styles['search-opened']}`}>
                <form
                  className={styles['search-form']}
                  ref={formRef}
                  onSubmit={handleSubmitSearch}
                >
                  <i
                    className={styles['search-media-icon']}
                    onClick={handleSubmitSearch}
                  />

                  <input
                    ref={searchRef}
                    placeholder='Search movies, live, clips'
                    className={`${styles['search-media-field']} ${
                      showSearchError && styles['error']
                    }`}
                    onChange={handleInputChange}
                  />
                  {showSearchError && (
                    <div className={styles['error-message']}>
                      {showSearchError}
                    </div>
                  )}
                </form>
              </li>
              <li className={styles['search-button-modal']}>
                <PopModalDialog
                  content={
                    <form
                      className={styles['search-form']}
                      ref={formRef}
                      onSubmit={handleSubmitSearch}
                    >
                      <i
                        className={styles['search-media-icon']}
                        onClick={handleSubmitSearch}
                      />
                      <input
                        ref={searchRef}
                        placeholder='Search movies, live, clips'
                        className={`${styles['search-media-field']} ${
                          showSearchError && styles['error']
                        }`}
                        onChange={handleInputChange}
                      />
                      {showSearchError && (
                        <div className={styles['error-message']}>
                          {showSearchError}
                        </div>
                      )}
                    </form>
                  }
                  modalStyle={{
                    borderRadius: 50
                  }}
                  customYBase={headerRef.current}
                >
                  <img
                    src={icSearch}
                    alt='search'
                  />
                </PopModalDialog>
              </li>
              <li className={styles['subscribe-button']}>
                <Button
                  icon={icCart}
                  iconSize={20}
                  style={{
                    margin: 0,
                    fontSize: 16,
                    background:
                      'linear-gradient(104.77deg, #FF3434 -25.37%, #FF7D34 100%)'
                  }}
                  shape='rounded'
                  onClick={() =>
                    token ? navigate('/shop') : dispatch(authPopup.show())
                  }
                >
                  Subscribe
                </Button>
              </li>
              <li className={`${styles['subscribe-button-mobile']}`}>
                <img
                  src={icCart}
                  alt='cart'
                  onClick={() =>
                    token ? navigate('/shop') : dispatch(authPopup.show())
                  }
                />
              </li>
              {/* <li className={styles['live-button']}>
                <PopModalDialog
                  spacing={30}
                  content={
                    <div className={styles['popup-menu']}>
                      <div onClick={handleGoLive}>
                        <img
                          src={icBroadcast}
                          alt='signal'
                          width={23}
                        />
                        <p>Go Live</p>
                      </div>
                      <div onClick={handleUploadClips}>
                        <img
                          src={icUpload}
                          alt='upload'
                          width={18}
                        />
                        <p>Upload Clips</p>
                      </div>
                    </div>
                  }
                  customYBase={headerRef.current}
                >
                  <Button
                    icon={icCameraAdd}
                    iconSize={20}
                    type='text'
                    className={styles['createButton']}
                    data-button='live-and-clips-button'
                  >
                    {window.innerWidth > 500 ? 'Create' : null}
                  </Button>
                </PopModalDialog>
              </li> */}
            </>
          )}
          {showHeaderMenu &&
            (token && window.innerWidth > 500 && !isNotifPage ? (
              <PopModalNotification
                placement='bottom-right'
                spacing={30}
                content={<NotificationDialog />}
                customYBase={headerRef.current}
              >
                <NotificationIcon />
              </PopModalNotification>
            ) : (
              <NotificationIcon onClick={handleNotifClick} />
            ))}
          {authenticated ? (
            <>
              {/* {liveGameInfo && isStreamerPage && (
                <>
                  <li>
                    <Button
                      color={
                        liveGameInfo?.isBroadcasting
                          ? '#F20303'
                          : 'linear-gradient(104.77deg, #ff3434 -25.37%, #ff7d34 100%)'
                      }
                      shape='rounded'
                      style={{
                        margin: 0,
                        padding: '11px 24px',
                        fontSize: 14,
                        fontWeight: 600
                      }}
                      onClick={handleStreamButton}
                    >
                      {isLiveGameBroadcasting
                        ? 'End Stream'
                        : 'Start Streaming'}
                    </Button>
                  </li>
                  <li>
                    <img
                      src={icShare}
                      alt='share'
                      onClick={() => setShowShareModal(!showShareModal)}
                    />
                  </li>
                </>
              )} */}
              <li className={styles['user-profile']}>
                <PopModalDialog
                  placement='bottom-right'
                  spacing={30}
                  content={<ProfileDialog />}
                  customYBase={headerRef.current}
                >
                  {user && user.profile && user.profile.avatar ? (
                    <img
                      src={user.profile.avatar}
                      className={styles['user-profile-icon']}
                      alt='icon'
                      data-icon-name='user-profile-icon'
                      // onClick={toggleProfile}
                    />
                  ) : (
                    <div
                      className={styles['user-profile-icon']}
                      data-initial-name
                    >
                      {getInitial(user?.profile?.name)}
                    </div>
                  )}
                </PopModalDialog>
              </li>
            </>
          ) : (
            <li className={styles['user-profile']}>
              <img
                src={
                  user && user.profile && user.profile.avatar
                    ? user.profile.avatar
                    : icProfile
                }
                className={styles['user-profile-icon']}
                alt='icon'
                data-icon-name='user-profile-icon'
                onClick={() => dispatch(authPopup.show())}
              />
            </li>
          )}
        </ul>

        {/* <ShareModal
          onCloseModal={() => setShowShareModal(!showShareModal)}
          type='live-show'
          id={liveGameInfo?.id}
          open={showShareModal}
        /> */}

        {/* <GeneralNotifModal
          open={endStreamModal}
          contentClass={styles['modalWrapper']}
        >
          <div className={styles['modalWrapper']}>
            <div className={styles['endStreamModal']}>
              <p>Do you want to end the stream?</p>
              <Button
                color='#282828'
                onClick={() => {
                  setEndStreamModal(false);
                }}
              >
                No
              </Button>
              <Button
                onClick={async () => {
                  await dispatch(broadcasting());
                  setEndStreamModal(false);
                  await navigate('/live');
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </GeneralNotifModal> */}

        <AnimatedModal
          duration={10}
          open={authPopupStatus}
          opacity={0.5}
        >
          <GeneralNotifModal open={authPopupStatus}>
            <LoginDialog onClose={() => dispatch(authPopup.close())} />
          </GeneralNotifModal>
        </AnimatedModal>

        <AnimatedModal
          duration={10}
          open={showConsent}
          opacity={0.5}
        >
          <GeneralNotifModal open={showConsent}>
            <UserConsent />
          </GeneralNotifModal>
        </AnimatedModal>

        <AnimatedModal
          duration={10}
          open={showDefaultPassword}
          opacity={0.5}
        >
          <GeneralNotifModal open={showDefaultPassword}>
            <DefaultPassword
              onClose={() => {
                clearURLParams();
                setShowDefaultPassword(false);
              }}
              password={defaultPassword}
            />
          </GeneralNotifModal>
        </AnimatedModal>

        <CSSTransition
          in={showClipsModal}
          timeout={100}
          classNames='fade'
          unmountOnExit
        >
          <ClipsUploadModal
            closeModal={() => {
              dispatch(showCreateClips.hide());
              toggleBodyScroll('auto');
            }}
            show={showClipsModal}
          />
        </CSSTransition>

        <ValidateSessionNotification open={sessionStatus === 'failed'} />
      </div>
    </div>
  );
};

export default Header;
