const initialState = {
  liveGameInfo: null,
  isLiveGameBroadcasting: false,
  rtmp: null,
  loading: false,
  loadingEdit: false,
  loadingRtmp: false,
  loadingBroadcast: false,
  liveGameHeartbeat: null,
  error: null
};

export default function liveGameReducer(state = initialState, action) {
  const { type, payload, err } = action;

  switch (type) {
    case 'CREATE_LIVE_GAME':
      return {
        ...state,
        loading: true,
        err: null
      };
    case 'CREATE_LIVE_GAME_SUCCESS':
      return {
        ...state,
        liveGameInfo: payload.show,
        isLiveGameBroadcasting: payload.show?.isBroadcasting,
        rtmp: payload.rtmp,
        show: payload.show,
        loading: false,
        err: null
      };
    case 'CREATE_LIVE_GAME_FAILED':
      return {
        ...state,
        loading: false,
        err
      };
    case 'UPDATE_LIVE_GAME':
      return {
        ...state,
        loadingEdit: true,
        err: null
      };
    case 'UPDATE_LIVE_GAME_INFO_SUCCESS':
      return {
        ...state,
        liveGameInfo: payload,
        isLiveGameBroadcasting: payload?.isBroadcasting,
        loadingEdit: false,
        err: null
      };
    case 'UPDATE_LIVE_GAME_INFO_FAILED':
      return {
        ...state,
        loadingEdit: false,
        err
      };
    case 'GET_AVAILABLE_LIVE_GAME':
      return {
        ...state,
        loading: true,
        err: null
      };
    case 'GET_AVAILABLE_LIVE_GAME_SUCCESS':
      return {
        ...state,
        liveGameInfo: payload,
        isLiveGameBroadcasting: payload?.isBroadcasting,
        loading: false,
        err: null
      };
    case 'SET_LIVE_GAME_AVAILABLE':
      return {
        ...state,
        loading: false,
        err: null
      };
    case 'GET_AVAILABLE_LIVE_GAME_FAILED':
      return {
        ...state,
        loading: false,
        err
      };
    case 'GET_STREAM_URL_KEY':
      return {
        ...state,
        loadingRtmp: true,
        err: null
      };
    case 'GET_STREAM_URL_KEY_SUCCESS':
      return {
        ...state,
        rtmp: payload,
        loadingRtmp: false,
        err: null
      };
    case 'GET_STREAM_URL_KEY_FAILED':
      return {
        ...state,
        loadingRtmp: false,
        err
      };
    case 'RESET_STREAM_KEY':
      return {
        ...state,
        loadingRtmp: true,
        err: null
      };
    case 'RESET_STREAM_KEY_SUCCESS':
      return {
        ...state,
        rtmp: payload,
        loadingRtmp: false,
        err: null
      };
    case 'RESET_STREAM_KEY_FAILED':
      return {
        ...state,
        loadingRtmp: false,
        err
      };
    case 'BROADCASTING':
      return {
        ...state,
        loadingBroadcast: true,
        err: null
      };
    case 'START_LIVE_GAME_SUCCESS':
      return {
        ...state,
        isLiveGameBroadcasting: true,
        loadingBroadcast: false
      };
    case 'STOP_LIVE_GAME':
      return {
        ...state,
        loadingBroadcast: true,
        err: null
      };
    case 'STOP_LIVE_GAME_SUCCESS':
      return {
        ...initialState,
        liveGameHeartbeat: state.liveGameHeartbeat
      };
    case 'STOP_LIVE_GAME_FAILED':
      return {
        ...state,
        loadingBroadcast: false,
        err
      };
    case 'SET_LIVE_STREAM_HEARTBEAT':
      return {
        ...state,
        liveGameHeartbeat: payload,
        err
      };
    case 'CLEAR_LIVE_STREAM_HEARTBEAT':
      return {
        ...state,
        liveGameHeartbeat: null,
        err
      };
    case 'SET_LIVE_STREAM_HEARTBEAT_FAILED':
      return {
        ...state,
        liveGameHeartbeat: null,
        err
      };
    case 'RESET_LIVEGAME_STATE':
      return {
        ...initialState
      };
    default:
      return state;
  }
}
