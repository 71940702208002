import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './freeWatchTimeOverlay.module.css';
import Button from '../Button/Button';
import warningIcon from '../../assets/ic-warning.svg';
import timer_worker from './timer-worker.js';
import { parseQuery } from '../../utils';

import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { authPopup } from '../../redux/actions/auth.js';

const FreeWatchTimeOverlay = ({
  className = '',
  contentClass = '',
  videoInfo,
  onWatchTimeEnd,
  onDismissPlayer,
  freeWatchTimeInSeconds,
  videoController,
  userSubs,
  isFWT
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const query = parseQuery(location?.search);
  const queryPlayEpisode = query.playEpisode;
  // const autoPlay = query.autoplay;
  const user = useSelector((state) => state.auth && state.auth.user);
  // const autoplayQuery = autoPlay === 'true' ? '?autoplay=true' : '';

  const [counter, setCounter] = React.useState(freeWatchTimeInSeconds);
  const [buyClicked, setBuyClicked] = React.useState(false);
  const [timeEnd, setTimeEnd] = React.useState(false);
  const [runTimerFunction, setRunTimerFunction] = React.useState(true);
  const [redirectionLogic, setRedirectionLogic] = React.useState(false);

  const timerWorker = useMemo(() => {
    return new Worker(timer_worker);
  }, []);

  const freeWatchTimeEvents = {
    endtimeRedirect: analyticEvents.FREEWATCHTIME.FWT_PREMIUM_PLAYER_OFFERSUBS,
    promo: analyticEvents.FREEWATCHTIME.FWT_PREMIUM_PLAYER_PROMO,
    buy: analyticEvents.FREEWATCHTIME.FWT_PREMIUM_PLAYER_BUYNEW,
    timer: analyticEvents.FREEWATCHTIME.FWT_PREMIUM_PLAYER_CLICKCOUNTDOWN,
    endtime: analyticEvents.FREEWATCHTIME.FWT_PREMIUM_PLAYER_TIMEREND,
    free_promo: analyticEvents.FREEWATCHTIME.FWT_FREE_PLAYER_PROMO,
    free_buy: analyticEvents.FREEWATCHTIME.FWT_FREE_PLAYER_BUYNEW
  };

  const padTime = (time) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };

  const format = (time) => {
    // Convert seconds into minutes and take the whole part
    const minutes = Math.floor(time / 60);

    // Get the seconds left after converting minutes
    const seconds = time % 60;

    // Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`;
  };

  const addAnalytics = (clickPosition) => {
    if (videoInfo?.premium) {
      // Add analytics
      if (videoInfo?.type === 'series' || videoInfo?.type === 'episode') {
        const paramsEvent = {
          seriesID: videoInfo.id,
          seriesName: videoInfo.titleLocalized,
          seriesGenres: videoInfo.tags.genres,
          contentType: videoInfo.type
        };

        const clickPositionEvent = freeWatchTimeEvents[clickPosition];

        analytic(analyticTypes.event, clickPositionEvent, {
          params: paramsEvent,
          user
        });
      }

      if (videoInfo?.type === 'movie') {
        const paramsEvent = {
          movieID: videoInfo.id,
          movieName: videoInfo.titleLocalized,
          movieGenres: videoInfo?.tags?.genres,
          contentType: videoInfo.type
        };

        const clickPositionEvent = freeWatchTimeEvents[clickPosition];

        analytic(analyticTypes.event, clickPositionEvent, {
          params: paramsEvent,
          user
        });
      }
    } else {
      // Add analytics
      if (videoInfo?.type === 'series' || videoInfo?.type === 'episode') {
        const paramsEvent = {
          seriesID: videoInfo.id,
          seriesName: videoInfo.titleLocalized,
          seriesGenres: videoInfo.tags.genres,
          contentType: videoInfo.type
        };

        const promoEvent =
          freeWatchTimeEvents[
            clickPosition === 'promo' ? 'free_promo' : 'free_buy'
          ];
        analytic(analyticTypes.event, promoEvent, {
          params: paramsEvent,
          user
        });
      }

      if (videoInfo?.type === 'movie') {
        const paramsEvent = {
          movieID: videoInfo.id,
          movieName: videoInfo.titleLocalized,
          movieGenres: videoInfo?.tags?.genres,
          contentType: videoInfo.type
        };

        const promoEvent =
          freeWatchTimeEvents[
            clickPosition === 'promo' ? 'free_promo' : 'free_buy'
          ];
        analytic(analyticTypes.event, promoEvent, {
          params: paramsEvent,
          user
        });
      }
    }
  };

  const handleRedirect = (clickPosition) => {
    // setRunTimerFunction(false);
    if (clickPosition === 'promo') {
      navigate(
        '/promo',
        queryPlayEpisode && {
          replace: true
        }
      );
    }
    if (clickPosition === 'buy') {
      if (user) {
        setRunTimerFunction(false);
        localStorage.setItem(
          'gfRedirectPath',
          videoInfo?.type === 'series' || videoInfo?.type === 'episode'
            ? `/series/${videoInfo.seriesId}`
            : `/movie/${videoInfo.id}`
        );
        localStorage.setItem('fromFWT', true);

        navigate(
          '/shop',
          queryPlayEpisode && {
            replace: true
          }
        );
      } else {
        localStorage.setItem(
          'gfRedirectPath',
          videoInfo?.type === 'series' || videoInfo?.type === 'episode'
            ? `/series/${videoInfo.seriesId}`
            : `/movie/${videoInfo.id}`
        );
        localStorage.setItem('fromFWT', true);

        setBuyClicked(true);
        dispatch(
          authPopup.show({
            onCloseCallback: () => setRedirectionLogic(true)
          })
        );
      }
    }
  };

  const showPremiumPopup = () => {
    // this error code to show subscription page
    const err = {
      code: 'gfc4030205',
      message: 'Video NotSubscribed',
      isFWT: true,
      details: [
        {
          name: 'Genflix Premium'
        }
      ]
    };
    onWatchTimeEnd(err);
  };

  useEffect(() => {
    if (isFWT && videoInfo?.premium) {
      timerWorker.postMessage({ turn: 'on', duration: freeWatchTimeInSeconds });
      timerWorker.onmessage = ({ data: { time } }) => {
        setCounter(time);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (counter <= 0 && runTimerFunction) {
      timerWorker.postMessage({ turn: 'off' });
      timerWorker.terminate();

      if (videoController?.current) {
        const { player } = videoController.current || {};
        player?.unload();
      }

      if (videoInfo?.premium) {
        // Handle when time is runs out
        addAnalytics('endtime');
        if (user) {
          addAnalytics('endtimeRedirect');
          if (userSubs) {
            navigate(
              videoInfo?.type === 'series' || videoInfo?.type === 'episode'
                ? `/series/${videoInfo.seriesId}`
                : `/movie/${videoInfo.id}`
            );
          } else {
            showPremiumPopup();
          }
        } else {
          setTimeEnd(true);
          // setOpenLogin(true);
          return dispatch(
            authPopup.show({
              onCloseCallback: () => setRedirectionLogic(true)
            })
          );
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, runTimerFunction]);

  useEffect(() => {
    if (redirectionLogic) {
      if (user) {
        if (buyClicked) {
          if (userSubs) {
            navigate(
              videoInfo?.type === 'series' || videoInfo?.type === 'episode'
                ? `/series/${videoInfo.seriesId}`
                : `/movie/${videoInfo.id}`
            );
          } else {
            navigate(
              '/shop',
              queryPlayEpisode && {
                replace: true
              }
            );
          }
        }
        if (timeEnd) {
          setTimeEnd(false);

          if (userSubs) {
            // navigate(
            //   videoInfo?.type === 'series' || videoInfo?.type === 'episode'
            //     ? `/series/${videoInfo.seriesId}${autoplayQuery}`
            //     : `/movie/${videoInfo.id}${autoplayQuery}`
            // );
          } else {
            showPremiumPopup();
          }
        }
      } else {
        if (buyClicked) {
          localStorage.removeItem('gfRedirectPath');
          localStorage.removeItem('fromFWT');

          setBuyClicked(false);
          if (videoInfo?.type === 'series' || videoInfo?.type === 'episode') {
            navigate(-1, { replace: true });
          } else {
            onDismissPlayer();
          }
        }
        if (timeEnd) {
          onDismissPlayer();
        }
      }
      setRedirectionLogic(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, redirectionLogic]);

  return (
    !userSubs &&
    isFWT && (
      <div className={`${styles['container']} ${className}`}>
        <section className={styles['upperSection']}>
          <div className={`${styles['modalContent']} ${contentClass}`}>
            {videoInfo?.premium && (
              <div
                className={styles['timerBar']}
                onClick={() => {
                  addAnalytics('timer');
                }}
              >
                <img
                  src={warningIcon}
                  alt='share'
                  width='19'
                  height='19'
                />
                {counter <= 0 ? (
                  <div>Time over</div>
                ) : (
                  <div>
                    Aktifkan paket premium{' '}
                    <span className={styles['contentTextBold']}>
                      {format(counter)}
                    </span>
                  </div>
                )}
              </div>
            )}
            {/* {children} */}
          </div>
        </section>
        <section
          className={`${styles['bottomSection']} ${
            videoInfo?.premium
              ? styles['bottomPosition']
              : styles['topPosition']
          }`}
        >
          <div className={`${styles['ctaBar']} ${contentClass}`}>
            <div className={styles['contentText']}>
              Nikmati konten premium mulai dari{' '}
              <span className={styles['contentTextBold']}>Rp5000/hari</span>
            </div>
            <Button
              className={`${styles['button']} ${styles['promoButton']}`}
              onClick={() => {
                addAnalytics('promo');
                handleRedirect('promo');
              }}
            >
              Promo
            </Button>
            <Button
              className={`${styles['button']}`}
              onClick={() => {
                addAnalytics('buy');
                handleRedirect('buy');
              }}
            >
              Beli Baru
            </Button>
            {/* {children} */}
          </div>
        </section>
      </div>
    )
  );
};

export default FreeWatchTimeOverlay;
