import fbPixel from './facebookPixel';
import trackGa, { trackPage } from './firebaseAnalytic';

export const analyticTypes = {
  event: 'event',
  trackPage: 'trackPage'
};

export const analyticEvents = {
  CATALOGUE: {
    SELECT_CAROUSEL: 'Catalogue_selectCarousel',
    SELECT_SEASON: 'Catalogue_selectSeason',
    SELECT_LIVESHOW: 'Catalogue_selectLiveShow',
    SELECT_LIVESHOW_VOD: 'selectLiveShowVOD',
    SELECT_CHANNEL: 'Catalogue_selectChannel',
    VIEW_MOVIE_DETAIL: 'Catalogue_viewMovieDetails',
    VIEW_SERIES_DETAIL: 'Catalogue_viewSeriesDetails',
    VIEW_PLAYLIST_CONTENT: 'Catalogue_viewPlaylistContent'
  },

  AUTH: {
    START_LOGIN_BY_EMAIL: 'Auth_startLogInByEmail',
    START_LOGIN_BY_GOOGLE: 'Auth_startLogInByGoogle',
    START_LOGIN_BY_FACEBOOK: 'Auth_startLogInByFacebook',
    START_LOGIN_BY_PHONE: 'Auth_startLogInByPhone',
    START_REGISTER_BY_EMAIL: 'Auth_startRegisterByEmail',
    START_REGISTER_BY_GOOGLE: 'Auth_startRegisterByGoogle',
    START_REGISTER_BY_FACEBOOK: 'Auth_startRegisterByFacebook',
    START_REGISTER_BY_PHONE: 'Auth_startRegisterByPhone',
    COMPLETE_LOGIN_BY_EMAIL: 'Auth_completeLogInByEmail',
    COMPLETE_LOGIN_BY_GOOGLE: 'Auth_completeLogInByGoogle',
    COMPLETE_LOGIN_BY_FACEBOOK: 'Auth_completeLogInByFacebook',
    COMPLETE_LOGIN_BY_PHONE: 'Auth_completeLogInByPhone',
    COMPLETE_REGISTER_BY_EMAIL: 'Auth_completeRegisterByEmail',
    COMPLETE_REGISTER_BY_GOOGLE: 'Auth_completeRegisterByGoogle',
    COMPLETE_REGISTER_BY_FACEBOOK: 'Auth_completeRegisterByFacebook',
    COMPLETE_REGISTER_BY_PHONE: 'Auth_completeRegisterByPhone',
    LOGOUT: 'Auth_logOut'
  },

  SEARCH: {
    TRIGGER_SEARCH: 'Search_triggerSearch'
  },

  GENCASH: {
    REQUEST_CASHOUT: 'Gencash_requestCashout'
  },

  DIAMONDS: {
    TOPUP_DIAMOND: 'Diamonds_topUpDiamonds'
  },

  VOUCHER: {
    COMPLETE_REDEEM: 'Voucher_completeRedeem'
  },

  PROFILE: {
    EDIT_PROFILE: 'Profile_editProfile'
  },

  PUBLIC_PROFILE: {
    VIEW_USER_PROFILE: 'PublicProfile_viewUserProfile',
    FOLLOW_USER: 'PublicProfile_followUser',
    UNFOLLOW_USER: 'PublicProfile_unfollowUser'
  },

  LIVESHOW: {
    SHARE_LIVESHOW: 'LiveShow_shareLiveShow',
    SEND_COMMENT: 'LiveShow_sendComment',
    SEND_GIFT: 'LiveShow_sendGift'
  },

  LIVEGAME: {
    START_STREAM: 'LiveGame_startStream',
    END_STREAM: 'LiveGame_endStream',
    CREATE_STREAM: 'LiveGame_createStream',
    EDIT_STREAM: 'LiveGame_editStream'
  },

  SUBSCRIPTION: {
    SELECT_PACKAGE: 'Subscription_selectPackage',
    COMPLETE_SUBSCRIPTION: 'Subscription_completeSubscription'
  },

  MEDIA_PLAYBACK: {
    MEDIA_LAUNCH: 'MediaPlayback_mediaLaunch',
    MEDIA_PAUSE: 'MediaPlayback_mediaPause',
    MEDIA_RESUME: 'MediaPlayback_mediaResume',
    MEDIA_SEEK: 'MediaPlayback_mediaSeek',
    MEDIA_END: 'MediaPlayback_mediaEnded',
    MEDIA_EXIT: 'MediaPlayback_mediaExit',
    MEDIA_REACH_25: 'MediaPlayback_mediaReach25Percent',
    MEDIA_REACH_50: 'MediaPlayback_mediaReach50Percent',
    MEDIA_REACH_75: 'MediaPlayback_mediaReach75Percent',
    CLIPS_LAUNCH: 'MediaPlayback_clipsLaunch'
  },

  CAMPAIGN: {
    CAMPAIGN_BUTTON: 'clickCampaignButton',
    CAMPAIGN_UGC_DETAIL_ACTION: 'CampaignUGCDetail_clickActionButton'
  },

  NOTIFICATION: {
    IN_APP_NOTIFICATION_RECEIVE: 'in-app receive',
    IN_APP_NOTIFICATION_OPEN: 'in-app open',
    IN_APP_NOTIFICATION_HIGHLIGHTS_OPEN: 'highlights_open',
    IN_APP_NOTIFICATION_SET_READ: 'in-app_set_read',
    IN_APP_NOTIFICATION_PAGE_OPEN: 'notification_page_open',
    IN_APP_NOTIFICATION_BELL_CLICK: 'notification_bell_icon_click'
  },

  FREEWATCHTIME: {
    FWT_MOVIE_TRYNOW: 'MovieDetail_clickTryNow',
    FWT_SERIES_TRYNOW: 'SeriesDetail_clickTryNow',
    FWT_SERIES_EPISODE_TRYNOW: 'SeriesDetail_episodeItemClickTryNow',
    FWT_FREE_PLAYER_PROMO: 'PlayerFreeWatchTimeFreeContent_clickPromo',
    FWT_FREE_PLAYER_BUYNEW: 'PlayerFreeWatchTimeFreeContent_clickBuyNew',
    FWT_FREE_PLAYER_SUBSCRIBED: 'PlayerFreeWatchTimeFreeContent_subscribed',
    FWT_FREE_PLAYER_CLICKBACK: 'PlayerFreeWatchTimeFreeContent_clickBack',
    FWT_PREMIUM_PLAYER_PROMO: 'PlayerFreeWatchTimePremiumContent_clickPromo',
    FWT_PREMIUM_PLAYER_BUYNEW: 'PlayerFreeWatchTimePremiumContent_clickBuyNew',
    FWT_PREMIUM_PLAYER_SUBSCRIBED:
      'PlayerFreeWatchTimePremiumContent_subscribed',
    FWT_PREMIUM_PLAYER_CLICKCOUNTDOWN:
      'PlayerFreeWatchTimePremiumContent_clickCountDownTimer',
    FWT_PREMIUM_PLAYER_OFFERSUBS:
      'PlayerFreeWatchTimePremiumContent_offerSubscription',
    FWT_PREMIUM_PLAYER_CLICKBACK: 'PlayerFreeWatchTimePremiumContent_clickBack',
    FWT_PREMIUM_PLAYER_TIMEREND:
      'PlayerFreeWatchTimePremiumContent_timerRunsOut'
  },
  SHOP: {
    PURCHASE_ONE_TIME_SUBS: 'ShopPage_clickPurchaseOneTimeSubs',
    PURCHASE_RECURRING_SUBS: 'ShopPage_clickPurchaseRecurringSubs'
  },
  BUYNEW: {
    PURCHASE_RECURRING_SUBS: 'BuyNew_clickPurchaseRecurringSubs',
    PURCHASE_ONE_TIME_SUBS: 'BuyNew_clickPurchaseOneTimeSubs'
  },
  ONGOING: {
    CLICK_RENEW_ONE_TIME_SUBS: 'Ongoing_clickRenewOneTimeSubs'
  },
  ERROR: {
    VIDEO_UNABLE_TO_PLAY: 'Error_unableToPlayVideo'
  }
};

const trackTiktok = (eventName, options) => {
  if (window.ttq) {
    if (options.user) {
      // Identify user
      window.ttq.identify({
        email: options.user.account.email,
        phone_number: options.user.account.mobile,
        external_id: options.user.profile.id
      });
    }

    window.ttq.track(eventName, options.params);
  }
};

const analytic = (type, eventName, options = { params: {}, user: null }) => {
  if (type === analyticTypes.event) {
    trackGa(eventName, options);
    fbPixel(eventName, options);
    trackTiktok(eventName, options);
  } else {
    trackPage(eventName);
  }
};

export default analytic;
