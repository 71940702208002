import React, { useRef } from 'react';
import Slider from 'react-slick';
import SliderCard from './SliderCard';
import './styles.css';

const settings = {
  infinite: true,
  slidesToShow: 1,
  dots: true,
  arrows: false,
  adaptiveHeight: true,
  centerPadding: '1rem',
  speed: 500,
  focusOnSelect: true,
  autoplay: false,
  cssEase: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
};

// Slider for the popup notification

const DialogSlider = ({ dataHighlight }) => {
  const slider = useRef();

  return (
    <div className='notif-slider-wrapper'>
      <Slider
        {...settings}
        ref={slider}
      >
        {dataHighlight?.map((item, index) => {
          return (
            <SliderCard
              data={item?.common}
              key={index}
              type='dialog'
            />
          );
        })}
      </Slider>
    </div>
  );
};

export default DialogSlider;
