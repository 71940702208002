import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { getAppSettings } from '../../redux/actions/settings';
import UploadProgressIndicator from './UploadProgressIndicator';
import { craeteVideoMetadata, createTags } from '../../redux/actions/clips';

import styles from './ClipsUploadModal.module.css';

import icClose from '../../assets/ic-close.svg';
import icUpload from '../../assets/ic-upload.svg';
import icArrowBack from '../../assets/ic-arrow-back.svg';
import icThumbnail from '../../assets/ic-thumbnail.svg';
import UploadStatusDialog from './UploadStatusDialog';
import useSettings from '../../hooks/useSettings';
import { generateErrorDetail } from '../../utils';
import Button from '../Button/Button';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';

const findGCD = (x, y) => {
  if (typeof x !== 'number' || typeof y !== 'number') {
    return false;
  }
  x = Math.abs(x);
  y = Math.abs(y);
  while (y) {
    const t = y;
    y = x % y;
    x = t;
  }
  return x;
};

const findAspectRatio = (width, height) => {
  const gcd = findGCD(width, height);
  return `${width / gcd}:${height / gcd}`;
};

const findSizeInMB = (size) => (size / 1048576).toFixed(0);

const checkVideoDuration = (duration, { minDuration, maxDuration }) => {
  const minSecs = minDuration / 1000;
  const maxSecs = maxDuration / 1000;

  return duration > minSecs && duration <= maxSecs;
};

const ClipsUploadModal = ({ closeModal, show }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const ugcPath = location.pathname.includes('campaign-ugc');
  const campaignId = ugcPath && location.pathname.replace('/campaign-ugc/', '');
  const settings = useSettings();
  const {
    settingsLoading,
    settingsError,
    clipsVideoMaxSize,
    clipsVideoFormat,
    clipsVideoResolution,
    clipsVideoAspectRatio,
    clipsVideoMinDuration,
    clipsVideoMaxDuration,
    thumbnailMaxSize,
    thumbnailAspectRatio,
    dataClipsCategories
  } = settings;

  const minDurationInMinutes =
    clipsVideoMinDuration && (clipsVideoMinDuration / 60000).toFixed(0);
  const maxDurationInMinutes =
    clipsVideoMaxDuration && (clipsVideoMaxDuration / 60000).toFixed(0);
  // const resolutions =
  //   clipsVideoResolution &&
  //   clipsVideoResolution
  //     .split(/[\s,]+|[\s×]+|[\sx]+/)
  //     .filter((item) => item !== '')
  //     .map((item) => parseInt(item))
  //     .sort((a, b) => a - b);
  // const minResolution = resolutions && resolutions[0] + 'p';

  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } =
    useDropzone({
      accept: `video/${clipsVideoFormat}`,
      multiple: false,
      noClick: true,
      maxFiles: 1,
      maxSize: clipsVideoMaxSize
    });
  const videoRef = useRef();
  const [videoFile, setVideoFile] = useState();
  const [videoPreview, setVideoPreview] = useState();
  const [videoError, setVideoError] = useState(false);

  const [clipsTitle, setClipsTitle] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [clipsThumbnail, setClipsThumbnail] = useState();
  const [thumbnailError, setThumbnailError] = useState();
  const [videoDuration, setVideoDuration] = useState();
  const clipsMetadata =
    clipsTitle &&
    clipsThumbnail &&
    selectedCategories &&
    selectedCategories.length;

  const [uploadLoading, setuploadLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState();

  const { loadingTags, loadingMetadata } = useSelector((state) => state.clips);

  useEffect(() => {
    acceptedFiles.map(async (newFile) => {
      setVideoError();

      const processingVideo = new Promise((resolve, reject) => {
        const dataURL = URL.createObjectURL(newFile);
        const video = document.createElement('video');
        video.onloadedmetadata = () => {
          // const videoWidth = video.videoWidth;
          // const videoHeight = video.videoHeight;
          // const videoAspectRatio = findAspectRatio(videoWidth, videoHeight);
          const loadedVideoDuration = video.duration;

          // const checkWidth = clipsVideoResolution.includes(videoWidth)
          // const checkHeight = clipsVideoResolution.includes(videoHeight)
          // const checkAspectRatio =
          //   clipsVideoAspectRatio.includes(videoAspectRatio)
          const checkDuration = checkVideoDuration(loadedVideoDuration, {
            minDuration: clipsVideoMinDuration,
            maxDuration: clipsVideoMaxDuration
          });

          // if (!checkWidth && !checkHeight) reject('invalid-resolution')
          // if (!checkAspectRatio) reject('invalid-aspect-ratio')
          if (!checkDuration) reject(new Error('invalid-duration'));

          resolve({
            dataURL,
            loadedVideoDuration
          });

          video.remove();
        };
        video.src = dataURL;
        video.load();
      });

      try {
        const { dataURL, loadedVideoDuration } = await processingVideo;

        setVideoFile(newFile);
        setVideoPreview(dataURL);
        setVideoDuration(loadedVideoDuration);

        videoRef.current.src = dataURL;
        videoRef.current.load();
      } catch (err) {
        const errorData = generateErrorDetail(err);
        setVideoError(errorData);
      }
    });
  }, [
    acceptedFiles,
    clipsVideoAspectRatio,
    clipsVideoMaxDuration,
    clipsVideoMinDuration,
    clipsVideoResolution
  ]);

  useEffect(() => {
    fileRejections.map(({ errors }) => {
      return setVideoError(errors[0].code);
    });
  }, [fileRejections]);

  const handleBack = () => {
    setVideoFile();
    setVideoPreview();
    setClipsTitle();
    setSelectedCategories([]);
    setClipsThumbnail();
  };

  const handleSelectCategories = (category) => {
    const findCategory = selectedCategories.find((item) => item === category);

    if (findCategory) {
      setSelectedCategories((state) =>
        state.filter((item) => item !== category)
      );
    } else {
      setSelectedCategories((state) => [...state, category]);
    }
  };

  const handleSelectThumbnail = (e) => {
    setThumbnailError();

    const imageFile = e.target.files[0];

    if (imageFile) {
      const image = new Image();

      image.onload = () => {
        const imageAspectRatio = findAspectRatio(
          image.naturalWidth,
          image.naturalHeight
        );
        const checkImgAspectRatio =
          thumbnailAspectRatio.includes(imageAspectRatio);
        const checkImgSize = imageFile.size < thumbnailMaxSize;

        if (!checkImgAspectRatio)
          return setThumbnailError(
            `Error: thumbnail aspect ratio should be in ${thumbnailAspectRatio}`
          );
        if (!checkImgSize)
          return setThumbnailError(
            `Error: maximum file size is ${findSizeInMB(thumbnailMaxSize)}MB`
          );

        setClipsThumbnail(imageFile);
      };

      image.src = URL.createObjectURL(imageFile);
    }
  };

  const handleUploadProgress = (e) => {
    const uploadPercentage = ((e.loaded / videoFile.size) * 100).toFixed(0);
    setUploadProgress(uploadPercentage);
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = 'You cannot leave this page during upload process';
  };

  const handleUploadClips = async () => {
    setuploadLoading(true);
    window.addEventListener('beforeunload', handleBeforeUnload);

    const uploadClipsMeta = {
      titles: {
        1: clipsTitle
      },
      duration: Math.round(videoDuration),
      thumbnail: clipsThumbnail
    };

    try {
      const dataTags = await dispatch(createTags(selectedCategories));
      const metadata = await dispatch(
        craeteVideoMetadata({
          ...uploadClipsMeta,
          tags: dataTags,
          campaignId
        })
      );

      await axios.put(metadata.s3url, videoFile, {
        headers: {
          'Content-Type': videoFile.type
        },
        onUploadProgress: handleUploadProgress
      });

      setUploadStatus('success');
    } catch (err) {
      setUploadStatus('failed');
    } finally {
      setuploadLoading(false);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  };

  const handleUploadResult = () => {
    if (uploadStatus === 'success') {
      window.location.href = window.location.origin;
    } else {
      setUploadStatus();
    }
  };

  return (
    <GeneralNotifModal
      open={show}
      contentClass={styles['modalWrapper']}
    >
      <div className={styles['clipsModal']}>
        <div className={styles['clipsModalContent']}>
          {videoFile && videoPreview && !videoError ? (
            <>
              <div className={styles['detailHeader']}>
                <img
                  src={icArrowBack}
                  alt='arrow-back'
                  onClick={handleBack}
                />
                <h3>Add Details</h3>
                <Button
                  className={styles['postClipsButton']}
                  width='40%'
                  shape='rounded'
                  data-button-status={clipsMetadata ? 'active' : 'inactive'}
                  // disabled={!clipsMetadata}
                  onClick={clipsMetadata && handleUploadClips}
                >
                  Post Clips
                </Button>
              </div>
              <div className={styles['detailContent']}>
                <div className={styles['metadataSection']}>
                  <section>
                    <h4>Title</h4>
                    <input
                      className={styles['editInput']}
                      placeholder='Enter a title'
                      maxLength={100}
                      onChange={(e) => setClipsTitle(e.target.value)}
                    />
                    <span className={styles['titleCount']}>
                      {clipsTitle?.length || 0}/100
                    </span>
                  </section>
                  <section>
                    <h4>Select Categories</h4>
                    <div className={styles['categoryList']}>
                      {dataClipsCategories &&
                        dataClipsCategories.map((item, i) => {
                          const selected = selectedCategories.find(
                            (category) => category === item
                          );

                          return (
                            <div
                              key={i}
                              className={styles['categoryItem']}
                              data-selected={selected ? 'active' : 'inactive'}
                              onClick={() => handleSelectCategories(item)}
                            >
                              <span>{item}</span>
                            </div>
                          );
                        })}
                    </div>
                  </section>
                  <section>
                    <h4>Upload Thumbnail</h4>
                    <div className={styles['thumbnailSection']}>
                      <label htmlFor='select-thumbnail'>
                        {clipsThumbnail ? (
                          <img
                            className={styles['previewThumbnail']}
                            src={
                              clipsThumbnail instanceof File
                                ? URL.createObjectURL(clipsThumbnail)
                                : clipsThumbnail
                            }
                            alt='thumbnail'
                          />
                        ) : (
                          <div className={styles['selectThumbnail']}>
                            <img
                              src={icThumbnail}
                              alt='thumbnail'
                            />
                            Upload thumbnail
                          </div>
                        )}
                      </label>
                      <p>
                        Thumbnail should be in {thumbnailAspectRatio} aspect
                        ratio and{' '}
                        {thumbnailMaxSize && findSizeInMB(thumbnailMaxSize)}MB
                        max
                      </p>
                    </div>
                    <input
                      id='select-thumbnail'
                      className={styles['thumbnailInput']}
                      name='thumbnail'
                      type='file'
                      accept='image/*'
                      onChange={handleSelectThumbnail}
                    />
                    {thumbnailError && (
                      <p className={styles['thumbnailError']}>
                        {thumbnailError}
                      </p>
                    )}
                  </section>
                </div>
                <div className={styles['videoPreview']}>
                  <video
                    ref={videoRef}
                    controls
                  />
                  <p>{videoFile.name}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <img
                className={styles['closeButton']}
                src={icClose}
                alt='close'
                onClick={closeModal}
              />
              {settingsLoading ? (
                <div className={styles['loadingSection']}>
                  <Loading height='min-content' />
                  <p>Loading settings</p>
                </div>
              ) : (
                <div
                  className={styles['uploadSection']}
                  {...getRootProps()}
                >
                  <h1>Upload Videos</h1>
                  <input {...getInputProps()} />
                  <div className={styles['uploadDropzone']}>
                    <img
                      src={icUpload}
                      alt='upload'
                    />
                  </div>
                  <p>drop video file here</p>
                  <p>or</p>
                  <Button
                    className={styles['selectVideoButton']}
                    width='100%'
                    shape='rounded'
                    onClick={open}
                  >
                    Select Video File
                  </Button>
                  <div className={styles['restrictionDetail']}>
                    <p>Make sure your video is the correct size and format:</p>
                    <p>
                      {/* Minimum {minResolution} resolution in{' '} */}
                      {/* {clipsVideoAspectRatio} aspect ratio, */}
                      {clipsVideoFormat && clipsVideoFormat.toUpperCase()}, Max
                      file size{' '}
                      {clipsVideoMaxSize && findSizeInMB(clipsVideoMaxSize)} MB
                      with minimum {minDurationInMinutes} minutes and maximum{' '}
                      {maxDurationInMinutes} minutes duration.
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        {settingsError && (
          <div className={styles['modalWrapper']}>
            <div className={styles['settingsError']}>
              <p>Failed to get clips settings</p>
              <div onClick={() => dispatch(getAppSettings())}>Try again</div>
            </div>
          </div>
        )}

        {videoError && (
          <div className={styles['modalErrorWrapper']}>
            <div className={styles['modalError']}>
              <h3 className={styles['errorHeader']}>
                Video format not supported
              </h3>
              <p>
                The format of the selected file is not supported. Make sure your
                video is the correct size and format:
              </p>
              <ul>
                {/* <li>
                  Minimum {minResolution} resolution and 
                  {clipsVideoAspectRatio} aspect ratio.
                </li> */}
                <li>{clipsVideoFormat && clipsVideoFormat.toUpperCase()}.</li>
                <li>
                  Max file size{' '}
                  {clipsVideoMaxSize && findSizeInMB(clipsVideoMaxSize)} MB.
                </li>
                <li>
                  Minimum {minDurationInMinutes} minutes and maximum{' '}
                  {maxDurationInMinutes} minutes duration.
                </li>
              </ul>
              <div
                className={styles['errorConfirmButton']}
                onClick={() => setVideoError()}
              >
                Got it
              </div>
            </div>
          </div>
        )}

        {uploadLoading && (
          <div className={styles['modalWrapper']}>
            <div className={styles['uploadProgressModal']}>
              <UploadProgressIndicator
                value={uploadProgress}
                isLoading={loadingTags || loadingMetadata}
              />
              {loadingTags && <p>Creating tags</p>}
              {loadingMetadata && <p>Creating clips metadata</p>}
              {!!uploadProgress && <p>Uploading...</p>}
            </div>
          </div>
        )}

        {uploadStatus && (
          <div className={styles['modalWrapper']}>
            <UploadStatusDialog
              status={uploadStatus}
              onConfirmDialog={handleUploadResult}
            />
          </div>
        )}
      </div>
    </GeneralNotifModal>
  );
};

export default ClipsUploadModal;
