import React from 'react';
import { useSelector } from 'react-redux';
import NotificationBadge from '../../assets/NotificationBadge.svg';
import styles from './NotificationIcon.module.css';

const NotificationIcon = ({ onClick }) => {
  const { unread } = useSelector((state) => state.inAppNotification);

  return (
    <li className={styles['notification']}>
      <img
        src={NotificationBadge}
        className={styles['user-profile-icon']}
        alt='icon'
        data-icon-name='user-profile-icon'
        onClick={onClick}
      />
      {unread > 0 && <span className={styles['badge']}>{unread}</span>}
    </li>
  );
};

export default NotificationIcon;
