import React, { useState } from 'react';
import styles from './TextTruncated.module.css';

const toggleData = {
  id: {
    more: 'Lebih Banyak',
    less: 'Lebih Sedikit'
  },
  en: {
    more: 'More',
    less: 'Less'
  }
};

const TextTruncated = ({
  content,
  maxLength,
  readMore = true,
  lang = 'en',
  color
}) => {
  const [showMore, setShowMore] = useState(
    content && content.length > maxLength
  );
  const hideReadMore = () => {
    setShowMore(false);
  };

  const hideLess = () => {
    setShowMore(true);
  };

  if (!content) return '';
  return showMore ? (
    <div>
      {`${content.substring(0, maxLength)}... `}
      {readMore && (
        <span
          className={styles['read-more']}
          style={{ color }}
          onClick={hideReadMore}
        >
          {toggleData[lang].more}
        </span>
      )}
    </div>
  ) : (
    <div>
      {content}
      {readMore && content && content.length > maxLength && (
        <span
          className={styles['read-less']}
          style={{ color }}
          onClick={hideLess}
        >
          {toggleData[lang].less}
        </span>
      )}
    </div>
  );
};

export default TextTruncated;
