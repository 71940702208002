import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { beginTransaction } from '../../redux/actions/transaction'
import styles from './SubscriptionPackage.module.css';

import danaLogo from '../../assets/dana-logo.svg';
import logo from '../../assets/logo.svg';
import { PAYMENT_GATEWAY_URL } from '../../config/URL';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import dablena from '../../service/dableIo';

const SubscriptionPackage = ({
  id,
  title,
  days,
  duration,
  durationType,
  price,
  subscriptions,
  gateway,
  package_code,
  paymentGateway,
  ...otherProps
}) => {
  // const dispatch = useDispatch()
  // const [retry, setRetry] = useState(false)
  const user = useSelector((state) => state.auth && state.auth.user);
  const { settings } = useSelector((state) => state.appSettings);
  const telcoPackageKey = 'telco.package.url';
  const telcoPackageURL =
    settings &&
    settings[telcoPackageKey] &&
    JSON.parse(settings[telcoPackageKey]);

  const planPrice = useMemo(() => {
    const formatPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `Rp ${formatPrice}`;
  }, [price]);

  const planDuration = useMemo(() => {
    if (paymentGateway) {
      if (+days > 0) {
        return `Valid for ${days} day${+days > 1 ? 's' : ''}`;
      }
    } else if (+duration > 0) {
      return `Valid for ${duration} ${durationType}${+duration > 1 ? 's' : ''}`;
    }
  }, [days, duration, durationType, paymentGateway]);

  const renderPayments = (gatewayPayments) => {
    if (gatewayPayments !== 'telco') return '';

    const telcos = {
      'telco-isat': 'Indosat',
      'telco-hutch': '3',
      'telco-smart': 'Smartfren',
      'telco-tsel': 'Telkomsel',
      'telco-axis': 'Axis',
      'telco-xl': 'XL'
    };

    return Object.entries(otherProps.payments)
      .reduce((gws, [key, value]) => {
        const name = telcos[key];

        if (name && value.description && telcoPackageURL) {
          const telco =
            telcoPackageURL[key] &&
            telcoPackageURL[key].find((data) => {
              const telcoItem = value.description.split(/\s/);
              const findPackage =
                telcoItem.find((item) => item === data.code) ||
                data.code === value.description;

              return findPackage;
            });
          const url = telco && telco.url;

          gws.push({ name, description: value.description, url });
        }

        return gws;
      }, [])
      .map((payment) => {
        return (
          <li
            key={payment.name}
            className={styles['package-item']}
          >
            <strong>{payment.name}</strong>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={payment.url && payment.url}
              data-url={payment.url ? 'active' : 'inactive'}
            >
              <span className={styles['plan-description']}>
                {payment.description}
              </span>
            </a>
          </li>
        );
      });
  };

  const onPurchase = async () => {
    const partner = localStorage.getItem('partner_id');

    const paramsEvent = {
      packageID: id,
      packageCode: package_code,
      packageTitle: title,
      packagePrice: price,
      packageDuration: `${days} day${+days > 1 ? 's' : ''}`
    };
    analytic(analyticTypes.event, analyticEvents.SUBSCRIPTION.SELECT_PACKAGE, {
      params: paramsEvent,
      user
    });

    // dable.io tracker
    dablena('InitiateCheckout');

    window.location.href = `${PAYMENT_GATEWAY_URL}/?member=${
      user.account.id
    }&plan=${id}${partner ? `&utm_source=${partner}` : ''}`;
  };

  return (
    <div className={styles['plan-wrapper']}>
      <p
        className={styles['plan-name']}
        title={title}
      >
        {title}
      </p>
      <div className={styles['plan-info']}>
        {paymentGateway && (
          <img
            className={styles['payment-logo']}
            alt='payment-logo'
            src={logo}
          />
        )}
        {gateway === 'dana' ? (
          <img
            className={styles['payment-logo']}
            alt='dana-logo'
            src={danaLogo}
          />
        ) : (
          ''
        )}
        {gateway !== 'telco' ? (
          <p className={styles['plan-price']}>{planPrice}</p>
        ) : (
          ''
        )}
        <p className={styles['plan-duration']}>{planDuration}</p>
        <ul>{renderPayments(gateway)}</ul>
        {gateway !== 'telco' ? (
          <button
            type='button'
            onClick={onPurchase}
          >
            Purchase
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default SubscriptionPackage;
