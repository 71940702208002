import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import packageJson from '../../../package.json';
import appStoreBtn from '../../assets/btn-app-store.svg';
import ggPlayBtn from '../../assets/btn-gg-play.svg';
import icFb from '../../assets/ic-facebook.svg';
import icInsta from '../../assets/ic-insta.svg';
import icTwitter from '../../assets/ic-twitter.svg';
import { appURL, social } from '../../constants';
import logo from '../../assets/logo.svg';
import { detectAgent } from '../../utils';
import form from '../../assets/form.svg';

const Footer = () => {
  const [isMobile, setIsMobile] = useState(detectAgent('mobile'));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const token = useSelector((state) => state.auth.token);

  const menuSection = useMemo(() => {
    return (
      <>
        <div className={styles['grow1']}>
          <Link to='/about-us'>About Us</Link>
        </div>
        <div className={styles['grow1']}>
          <Link to='/terms'>Terms and Conditions</Link>
        </div>

        {/* <div className={styles['grow1']}>
          <Link to='/terms-ugc'>Terms UGC</Link>
        </div> */}
        <div className={styles['grow1']}>
          <Link to='/privacy-policy'>Privacy Policy</Link>
        </div>
        <div className={styles['grow1']}>
          <Link to='/faqs'>FAQs</Link>
        </div>
        <div className={styles['grow1']}>
          <Link to='/end-user-license-agreement'>EULA</Link>
        </div>
        {token && (
          <div className={styles['grow1']}>
            <Link to='/profile/subscription/buy-new'>Subscription</Link>
          </div>
        )}
        <div className={styles['grow1']}>
          <Link to='/promo'>Promo</Link>
        </div>
      </>
    );
  }, [token]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    // Attach event listener on component mount
    window.addEventListener('resize', handleResize);

    // Detach event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // The empty dependency array ensures that the effect runs only on mount and unmount

  useEffect(() => {
    setIsMobile(windowWidth < 960);
  }, [windowWidth, windowHeight]);

  return (
    <div className={styles['footer-wrapper']}>
      <div className={styles['footerContent']}>
        <div className={styles['footer']}>
          <section className={styles['layout']}>
            <div className={!isMobile ? styles['grow1'] : ''}>
              <Link to='/'>
                <img
                  src={logo}
                  alt='logo'
                  className={styles['logo']}
                />
              </Link>
            </div>
            {!isMobile && (
              <div className={styles['grow1']}>
                <section className={styles['layout']}>{menuSection}</section>
              </div>
            )}
            <div className={!isMobile && styles['grow1']}>
              <div className={styles['iconsWrapper']}>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href={social.GENFLIX_INSTAGRAM}
                >
                  <img
                    src={icInsta}
                    alt='icon-instagram'
                  />
                </a>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href={social.GENFLIX_FACEBOOK}
                >
                  <img
                    src={icFb}
                    alt='icon-facebook'
                  />
                </a>
                <a
                  rel='noopener noreferrer'
                  target='_blank'
                  href={social.GENFLIX_TWITTER}
                >
                  <img
                    src={icTwitter}
                    alt='icon-twitter'
                  />
                </a>
                <Link
                  to='/form'
                  target='_blank'
                >
                  <img
                    src={form}
                    alt='icon-form'
                  />
                </Link>
              </div>
            </div>
          </section>
          {isMobile && (
            <section className={styles['layout']}>
              <div className={styles['grow1']}>
                <div className={styles['menu']}>{menuSection}</div>
              </div>
            </section>
          )}
          <section
            className={styles['layout']}
            style={{ marginTop: '30px' }}
          >
            <div className={styles['grow1']}>
              <div className={styles['head']}>Download Genflix App</div>
              <Link
                to={appURL.APP_STORE}
                target='_blank'
              >
                <img
                  alt='app-store-button'
                  src={appStoreBtn}
                />
              </Link>
              <Link
                to={appURL.GOOGLE_PLAY}
                target='_blank'
              >
                <img
                  alt='google-play-button'
                  src={ggPlayBtn}
                />
              </Link>
            </div>
          </section>
          <section className={styles['copyrightEmail']}>
            Copyright © {new Date().getFullYear()} Genflix All Rights Reserved{' '}
            {isMobile ? <br /> : ' | '}
            <a
              href={`mailto:${social.GENFLIX_EMAIL}`}
              target='_top'
            >
              {social.GENFLIX_EMAIL}
            </a>
          </section>
          <section className={styles['appVersion']}>
            {`Application Version ${packageJson.version}`}
          </section>
        </div>
      </div>
    </div>
  );
};

export default Footer;
