import React from 'react';
import styles from './Loading.module.css';

const Loading = ({ className, height }) => {
  const style = {
    height: height || '100%'
  };

  return (
    <div
      className={`${className} ${styles['loading-wrapper']}`}
      style={style}
    >
      <div className={styles['lds-ring']}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loading;
