const initialState = {
  data: [],
  meta: null,
  loading: false,
  error: null
};

export default function subscriptionsReducer(state = initialState, action) {
  const { type, payload, err } = action;

  switch (type) {
    case 'GET_ACCOUNT_SUBSCRIPTIONS':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'GET_ACCOUNT_SUBSCRIPTIONS_SUCCESS':
      return {
        ...state,
        data: payload.data,
        meta: payload.meta,
        loading: false,
        error: null
      };
    case 'GET_ACCOUNT_SUBSCRIPTIONS_FAILED':
      return {
        ...state,
        loading: false,
        error: err
      };
    default:
      return state;
  }
}
