const initialState = {
  packages: [],
  paymentGatewayPackages: [],
  recurringPackages: [],
  loading: false,
  err: false
};

export default function PackageReducer(state = initialState, action) {
  const { type, payload, err } = action;
  switch (type) {
    case 'GET_PACKAGES':
      return {
        ...state,
        loading: true,
        err: false
      };
    case 'GET_PACKAGES_SUCCESS':
      return {
        ...state,
        packages: payload,
        loading: false,
        err: false
      };
    case 'GET_PACKAGES_FAILED':
      return {
        ...state,
        loading: false,
        err
      };
    case 'GET_PAYMENT_GATEWAY_PACKAGES':
      return {
        ...state,
        loading: true,
        err: false
      };
    case 'GET_PAYMENT_GATEWAY_PACKAGES_SUCCESS':
      return {
        ...state,
        paymentGatewayPackages: payload,
        loading: false
      };
    case 'GET_PAYMENT_GATEWAY_PACKAGES_SUCCESS_FROM_STATE':
      return {
        ...state,
        recurringPackages: payload,
        loading: false
      };
    case 'GET_PAYMENT_GATEWAY_PACKAGES_ERROR':
      return {
        ...state,
        loading: false,
        err
      };
    case 'GET_RECURRING_PACKAGES':
      return {
        ...state,
        loading: true,
        err: false
      };
    case 'GET_RECURRING_PACKAGES_SUCCESS':
      return {
        ...state,
        recurringPackages: payload,
        loading: false
      };
    case 'GET_RECURRING_PACKAGES_ERROR':
      return {
        ...state,
        loading: false,
        err
      };
    default:
      return state;
  }
}
