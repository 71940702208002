const initialState = {
  data: [],
  views: 0,
  comments: {
    comments: [],
    meta: null
  },
  meta: null,
  loading: false,
  err: null
};

export default function interactiveReducer(state = initialState, action) {
  const { type, payload, page, err } = action;

  switch (type) {
    case 'GET_INTERACTIVE':
      return {
        ...state,
        loading: true,
        err: null
      };

    case 'GET_INTERACTIVE_SUCCESS':
      return {
        ...state,
        loading: false,
        data: page === 1 ? payload.data : [...state.data, ...payload.data],
        meta: payload.meta,
        page
      };

    case 'GET_INTERACTIVE_SUCCESS_FROM_STATE':
      return {
        ...state,
        loading: false,
        data: payload.data,
        meta: payload.meta,
        page
      };

    case 'ADD_COMMENT':
      return {
        ...state,
        comments: {
          comments: [...state.comments.comments, payload.data],
          meta: state.comments.meta
        }
      };

    case 'SET_COMMENTS':
      return {
        ...state,
        comments: {
          comments: payload?.comments?.reverse() || [],
          meta: payload?.meta
        }
      };

    case 'SET_VIEWS':
      return {
        ...state,
        views: payload.data
      };

    case 'RESET_INTERACTIVE':
      return {
        ...state,
        views: 0,
        comments: {
          comments: [],
          meta: null
        }
      };

    case 'GET_INTERACTIVE_FAILURE':
      return {
        ...state,
        loading: false,
        err
      };

    default:
      return state;
  }
}
