import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAppSettings } from '../redux/actions/settings';

const _keys = {
  videoAspectRatio: 'clips.allowed.video.aspect.ratio',
  videoFormat: 'clips.allowed.video.format',
  videoResolution: 'clips.allowed.video.resolution',
  clipsCategories: 'clips.categories',
  videoMinDuration: 'clips.min.video.duration.millisecond',
  videoMaxDuration: 'clips.max.video.duration.millisecond',
  videoMaxSize: 'clips.max.video.size.mb',
  thumbnailSize: 'clips.max.thumbnail.size.bytes',
  thumbnailResolution: 'clips.allowed.thumbnail.resolution',
  thumbnailAspectRatio: 'clips.allowed.thumbnail.aspect.ratio',
  reportReason: 'userReport.reason.content',
  commentReason: 'userReport.reason.comment',
  blockerAccessContentTitle: 'blocker.access_content.error_dialog.title',
  blockerAccessContentMessage: 'blocker.access_content.error_dialog.message',
  blockerFollowProfileTitle: 'blocker.follow_profile.error_dialog.title',
  blockerFollowProfileMessage: 'blocker.follow_profile.error_dialog.message',
  blockeeAccessContentTitle: 'blockee.access_content.error_dialog.title',
  blockeeAccessContentMessage: 'blockee.access_content.error_dialog.message',
  blockeeFollowProfileTitle: 'blockee.follow_profile.error_dialog.title',
  blockeeFollowProfileMessage: 'blockee.follow_profile.error_dialog.message',
  blockerUnblockDialogMessage: 'blocker.unblock_dialog.message',
  blockerUnblockDialogTitle: 'blocker.unblock_dialog.title',
  telcoPackage: 'telco.package.url',
  adTagUrl: 'ad.tag.url',
  adTagUrlWeb: 'ad.tag.url.web',
  recurringPack: 'recurring.packagegroup.ids',
  gracePeriodPack: 'graceperiod.packagegroup.ids'
};

export const settingKeys = Object.keys(_keys).reduce(
  (acc, curr) => ({ ...acc, [`${curr}Key`]: _keys[curr] }),
  {}
);

const keys = Object.keys(_keys).map((key) => _keys[key]);

const useSettings = () => {
  const dispatch = useDispatch();

  const { settings, loading, err } = useSelector((state) => state.appSettings);

  const mapSettings = useMemo(() => {
    return {
      clipsVideoMaxSize:
        settings?.[_keys.videoMaxSize] && Number(settings[_keys.videoMaxSize]),
      clipsVideoFormat: settings?.[_keys.videoFormat],
      clipsVideoResolution: settings?.[_keys.videoResolution],
      clipsVideoAspectRatio: settings?.[_keys.videoAspectRatio],
      clipsVideoMinDuration: settings?.[_keys.videoMinDuration],
      clipsVideoMaxDuration: settings?.[_keys.videoMaxDuration],
      thumbnailMaxSize:
        settings?.[_keys.thumbnailSize] &&
        Number(settings[_keys.thumbnailSize]),
      thumbnailResolution: settings?.[_keys.thumbnailResolution],
      thumbnailAspectRatio: settings?.[_keys.thumbnailAspectRatio],
      dataClipsCategories: settings?.[_keys.clipsCategories]?.split(','),

      // Settigs for Block and Report
      reportReason: settings?.[_keys.reportReason]?.split(','),
      commentReason: settings?.[_keys.commentReason]?.split(','),
      blockerAccessContentTitle: settings?.[_keys.blockerAccessContentTitle],
      blockerAccessContentMessage:
        settings?.[_keys.blockerAccessContentMessage],
      blockerFollowProfileTitle: settings?.[_keys.blockerFollowProfileTitle],
      blockerFollowProfileMessage:
        settings?.[_keys.blockerFollowProfileMessage],
      blockeeAccessContentTitle: settings?.[_keys.blockeeAccessContentTitle],
      blockeeAccessContentMessage:
        settings?.[_keys.blockeeAccessContentMessage],
      blockeeFollowProfileTitle: settings?.[_keys.blockeeFollowProfileTitle],
      blockeeFollowProfileMessage:
        settings?.[_keys.blockeeFollowProfileMessage],

      blockerUnblockDialogMessage:
        settings?.[_keys.blockerUnblockDialogMessage],
      blockerUnblockDialogTitle: settings?.[_keys.blockerUnblockDialogTitle]
    };
  }, [settings]);

  useEffect(() => {
    const currentConfigs = Object.entries(settings);
    if (currentConfigs?.length < keys?.length) dispatch(getAppSettings());
  }, [dispatch, settings]);

  return {
    settingsLoading: loading,
    settingsError: err,
    ...mapSettings
  };
};

export default useSettings;
