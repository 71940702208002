import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import throttle from 'lodash/throttle';
import { v4 as uuidv4 } from 'uuid';
import { getChannelUrl, getChannelDetail } from '../../redux/actions/channel';
import { withRouter } from '../../hooks';
import ChannelPlayer from '../VideoPlayer/ChannelPlayer';
import PlaylistViewContent from './PlaylistViewContent';
// import { BlockUnblockModal } from '..';

import styles from './PlaylistPageView.module.css';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import {
  authPopup // isBlockBlockedProfile
} from '../../redux/actions/auth';
// import { useUserDetail } from '../../hooks';
import { generateSelectChannelURL } from '../../utils';
import Metadata from '../Metadata/Metadata';

const itemLengthOptions = [
  {
    width: 1500,
    length: 8,
    itemWidth: '15%'
  },
  {
    width: 1335,
    length: 6,
    itemWidth: '17%'
  },
  {
    width: 926,
    length: 5,
    itemWidth: '24%'
  },
  {
    width: 742,
    length: 4,
    itemWidth: '45%'
  },
  {
    width: 328,
    length: 3,
    itemWidth: '45%'
  },
  {
    width: 0,
    length: 2,
    itemWidth: '45%'
  }
];

const findItemsByWidth = () => {
  if (window) {
    const width = window.innerWidth;
    const findLength = itemLengthOptions.findIndex(
      (item) => width > item.width
    );
    const selectedOption = itemLengthOptions[findLength];

    return selectedOption;
  }
};

const PlaylistPageView = ({
  type,
  title,
  titleClassName = '',
  containerClassName = '',
  itemClassName,
  data,
  fetchNextData,
  completed,
  searchView,
  handleViewAll,
  allowSeeAll = true,
  // onBlockUnblockDone,
  autoEvenList,
  loadOnScroll,
  showHistoryBar,
  showPlayIcon = false
}) => {
  const navigate = useNavigate();
  const [maxItemsPerRow, setMax] = useState(itemLengthOptions[2]);
  const [openPlayer, setOpenPlayer] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth && state.auth.user);
  // const [isTriggerBlockModal, setTriggerBlockModal] = useState(false);
  // const [blockType, setBlockType] = useState('block');
  // const [blockProfile, setBlockProfile] = useState(null);
  const location = useLocation();
  const params = useParams();
  const { channelID } = params;
  const handleResize = throttle(() => {
    setMax(findItemsByWidth(data, autoEvenList));
  }, 500);
  const [sessionId, setSessionId] = useState();
  const { partner } = useParams();
  // const { findUserSubsByName } = useUserDetail();
  // const hasPartnerSubs = findUserSubsByName(partner);
  const additionalUrl =
    /^\/mobile/g.test(location.pathname) && partner ? `/mobile/${partner}` : '';

  useLayoutEffect(() => {
    if (window) {
      window.addEventListener('resize', handleResize);
      setMax(findItemsByWidth(data, autoEvenList));
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, data, autoEvenList]);

  const handleChannel = useCallback(
    (item) => async () => {
      setSessionId(uuidv4());

      const paramsEvent = {
        channelID: item.id
      };
      analytic(analyticTypes.event, analyticEvents.CATALOGUE.SELECT_CHANNEL, {
        params: paramsEvent,
        user
      });

      item.url = await dispatch(getChannelUrl(item));
      item.type === 'webview' ? setOpenIframe(item) : setOpenPlayer(item);
    },
    [dispatch, user]
  );

  const autoplay = useCallback(async () => {
    const channel = await dispatch(getChannelDetail(channelID));
    if (!channel) {
      return navigate('/live-tv');
    }
    if (channel.contentRights === 'premium' && !userToken) {
      navigate('/live-tv');
      dispatch(authPopup.show());
    }
    await handleChannel(channel)();
  }, [channelID, dispatch, navigate, handleChannel, userToken]);

  useEffect(() => {
    channelID && autoplay();
  }, [channelID, autoplay]);

  const handleSelectChannel = useCallback(
    (item) => {
      // if (item && item.interactive)
      //   return navigate(
      //     `${isSurgeUser ? '/mobile/surge' : ''}/stream/${item.id}`
      //   );
      // if (item && !item.interactive)
      //   return navigate(
      //     `${isSurgeUser ? '/mobile/surge' : ''}/live-tv/${item.id}`
      //   );
      const channelURL = generateSelectChannelURL(item, type, {
        ...(additionalUrl && { additionalFrontURL: additionalUrl })
      });

      navigate(channelURL);
    },
    [type, navigate, additionalUrl]
  );

  const dismissPlayer = () => {
    setOpenPlayer(false);
    setOpenIframe(false);
    return navigate(-1);
  };

  // const checkIsBlocked = useCallback(
  //   async (liveShowData) => {
  //     const redirectURL = `${additionalUrl}/${liveShowData.type}/${liveShowData.id}`;

  //     try {
  //       const res = await dispatch(
  //         isBlockBlockedProfile(
  //           liveShowData.profile.accountId,
  //           liveShowData.profile.id
  //         )
  //       );
  //       setBlockProfile(liveShowData?.profile);
  //       if (res?.data?.status === 'BLOCKING') {
  //         setBlockType('unblock');
  //         setTriggerBlockModal(true);
  //       }

  //       if (res?.data?.status === 'BLOCKED') {
  //         // setBlockProfile(data?.profile)
  //         setBlockType('access');
  //         setTriggerBlockModal(true);
  //       }
  //       if (!res) {
  //         setBlockProfile(liveShowData?.profile);
  //         setBlockType('block');
  //         navigate(redirectURL);
  //       }
  //     } catch (error) {
  //       setBlockProfile(null);
  //       setBlockType('block');

  //       navigate(redirectURL);
  //     }
  //   },
  //   [dispatch, navigate, additionalUrl]
  // );

  // const onLiveshowCLicked = useCallback(
  //   (item) => {
  //     checkIsBlocked(item);
  //   },
  //   []
  // );
  // const onCloseBlockModal = (inputBlockType) => {
  //   setTriggerBlockModal(false);
  //   if (inputBlockType === 'blocked' || inputBlockType === 'unblocked') {
  //     if (onBlockUnblockDone) {
  //       onBlockUnblockDone();
  //     }
  //   }
  //   setTriggerBlockModal(false);
  // };

  return (
    <>
      <Metadata
        name='Genflix'
        title={`Genflix - ${title}`}
        description={`Saksikan daftar tayangan ${title} hanya di Genflix`}
        url={window.location.href}
      />

      <div className={`${styles['wrapper']} ${containerClassName}`}>
        {!channelID && (
          <PlaylistViewContent
            itemClassName={itemClassName}
            data={data}
            title={title}
            titleClassName={titleClassName}
            searchView={searchView}
            allowSeeAll={allowSeeAll}
            handleViewAll={handleViewAll}
            completed={completed}
            fetchNextData={fetchNextData}
            handleSelectChannel={handleSelectChannel}
            maxItemsPerRow={maxItemsPerRow}
            // onLiveshowClicked={onLiveshowCLicked}
            autoEvenList={autoEvenList}
            loadOnScroll={loadOnScroll}
            showHistoryBar={showHistoryBar}
            showPlayIcon={showPlayIcon}
          />
        )}
        <ChannelPlayer
          openPlayer={openPlayer}
          openIframe={openIframe}
          dismissPlayer={dismissPlayer}
          sessionId={sessionId}
        />
        {/* 
        {blockProfile && (
          <BlockUnblockModal
            profile={blockProfile}
            blockType={blockType}
            isTriggered={isTriggerBlockModal}
            onClose={onCloseBlockModal}
          />
        )} */}
      </div>
    </>
  );
};

export default withRouter(PlaylistPageView);
