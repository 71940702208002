/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { getValue } from 'firebase/remote-config';
import {
  authPopup,
  getRefreshToken,
  logout,
  pruneSession
} from './redux/actions/auth';
import { errorCode } from './constants';
import { configKeys, remoteConfig } from './service/firebase';
import { generateDeviceInfo, parseQuery } from './utils';
import gcpPaths from './config/gcp-paths';
import { JWT_APP_ID } from './config/URL';

let isRefreshing = false;
let requestQueue = [];

const nodeEnv = process.env.REACT_APP_NODE_ENV;

const processQueue = (error, token = null) => {
  requestQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });

  requestQueue = [];
};

let getInfoRequestCount = 0;
let refreshCount = 0;
let pruningCount = 0;
let alreadyOpen = false;
let retryPage = {};
export default {
  setupInterceptors: (store) => {
    // add a request interceptor
    axios.interceptors.request.use((config) => {
      const { url, method } = config;

      const isGetInfoRequest =
        url.includes('/user/v1.1/accounts') && method === 'get';

      if (isGetInfoRequest) {
        getInfoRequestCount++;
      }

      if (isGetInfoRequest && getInfoRequestCount > 3) {
        return {
          ...config,
          cancelToken: new axios.CancelToken((cancel) =>
            cancel('Cancel repeated request')
          )
        };
      }

      if (url.includes('/user/v1.2/sessions/refresh') && refreshCount > 1) {
        return {
          ...config,
          cancelToken: new axios.CancelToken((cancel) =>
            cancel('Cancel repeated request')
          )
        };
      }

      if (url.includes('/user/v1.2/sessions/pruning') && pruningCount > 3) {
        return {
          ...config,
          cancelToken: new axios.CancelToken((cancel) =>
            cancel('Cancel repeated request')
          )
        };
      }

      if (nodeEnv === 'development') {
        const getConfigUrl = getValue(remoteConfig, configKeys.baseURL['user']);
        const BASE_CONFIG_URL = getConfigUrl.asString();
        const oldURL = new URL(config.url);
        const newHost = new URL(BASE_CONFIG_URL);
        oldURL.hostname = newHost.hostname;

        config.url = oldURL.href;
      } else {
        const reqPath = gcpPaths.find((item) => url.includes(`/${item}/`));
        if (reqPath) {
          const getConfigUrl = getValue(
            remoteConfig,
            configKeys.baseURL[reqPath]
          );
          const BASE_CONFIG_URL = getConfigUrl.asString();
          const requestURL = new URL(url);
          const configURL = new URL(BASE_CONFIG_URL);
          if (configURL.pathname.slice(-1) === '/') {
            configURL.pathname = configURL.pathname.slice(0, -1);
          }

          requestURL.hostname = configURL.hostname;
          configURL.pathname = configURL.pathname.replace(/\/$/, ''); // Remove trailing slash
          let remainingRequestPath = requestURL.pathname.replace(
            `/${reqPath}`,
            ''
          );
          remainingRequestPath = remainingRequestPath.replace(/^\//, ''); // Remove leading slash

          requestURL.pathname =
            configURL.pathname === '/'
              ? requestURL.pathname
              : `${configURL.pathname}/${remainingRequestPath}`;
          config.url = requestURL.href;
        }
      }

      if (url.includes('/notification')) {
        const getConfigUrl = getValue(remoteConfig, configKeys.baseURL['user']);
        const BASE_CONFIG_URL = getConfigUrl.asString();
        config.url = config.url.replace(
          /.+?(?=\/notification)/,
          BASE_CONFIG_URL
        );
      }

      if (url.includes('/stat')) {
        const getConfigUrl = getValue(remoteConfig, configKeys.baseURL['user']);
        const BASE_CONFIG_URL = getConfigUrl.asString();
        config.url = config.url.replace(/.+?(?=\/stat)/, BASE_CONFIG_URL);
      }

      config.headers['Accept-Language'] = 'en';
      config.headers['Timing-Allow-Origin'] = '*';

      return config;
    });

    // Add a response interceptor
    axios.interceptors.response.use(
      async (response) => {
        const dispatch = store.dispatch;
        const { config, status, data } = response;
        const { url, method } = config;
        const isGetInfoRequest =
          url.includes('/user/v1.1/accounts') && method === 'get';
        const isValidate =
          url.includes('/user/v1.2/sessions/validateSession') &&
          method === 'post';
        // reset get info request when there has response
        if (isGetInfoRequest) {
          getInfoRequestCount = 0;
        }

        if (url.includes('/user/v1.2/sessions/refresh') && status === 200) {
          refreshCount = 0;
        }

        if (url.includes('/user/v1.2/sessions/pruning') && status === 204) {
          pruningCount = 0;
        }

        if (
          retryPage?.retry &&
          retryPage?.originalReq &&
          url?.includes('sessions') &&
          data?.data?.accessToken
        ) {
          const originalRequest = retryPage.originalReq;
          originalRequest.headers['Authorization'] = data.data.accessToken;
          originalRequest.headers['Cloudfront-JWT-AppId'] = JWT_APP_ID;
          originalRequest.headers['cloudfront-jwt-accountid'] =
            data.data.account.id;
          originalRequest.headers['cloudfront-jwt-profileid'] =
            data.data.profile.id;

          const res = await axios(originalRequest);

          const pageData = res.data.data;
          const formatPageData = pageData.map((pageItem) => ({
            ...pageItem,
            friendlyId: data.friendlyId.toLowerCase()
          }));

          dispatch({
            type: 'GET_PAGES_SUCCESS',
            payload: formatPageData
          });
          retryPage = {};
        }

        if (isValidate && status === 204) {
          alreadyOpen = false;
          // eslint-disable-next-line no-console
          console.log('refreshed open');
        }

        return response;
      },
      (err) => {
        if (!err.response) {
          throw err;
        }
        const error = err?.response?.data?.error;
        // console.log('error response on network, ', error);

        if (
          error?.code === errorCode.INVALID_SESSION_AUTH &&
          error?.message === 'Session InvalidAuthentication'
        ) {
          const url = new URL(err?.request?.responseURL);
          const path = url.pathname;
          const match = parseQuery(url.search);
          // const originalRequest = err.config;

          if (path === '/catalog/v1.1/pages' && match?.showEligibleContent) {
            retryPage.retry = true;
            retryPage.originalReq = err?.config;
          }
        }

        if (
          (error?.code === errorCode.MODEL_UNIQUE_ERROR ||
            error?.message === 'Model UniqueConstraintError') &&
          error?.details?.fields?.uuid
        ) {
          localStorage.removeItem('device_uuid');

          const originalRequest = err.config;
          const originalPayload = JSON.parse(originalRequest.data);
          const newDeviceInfo = generateDeviceInfo();
          originalPayload.device = newDeviceInfo;
          originalRequest.data = JSON.stringify(originalPayload);

          return axios(originalRequest);
        }

        const isLogout =
          error?.config?.method === 'delete' &&
          err?.config?.url?.includes('user/v1.0/sessions');

        if (
          (error?.code === errorCode.INVALID_SESSION_TOKEN ||
            error?.message === 'Session InvalidSessionToken') &&
          !isLogout
        ) {
          // eslint-disable-next-line no-console
          console.log('token expired. refreshing....');
          const state = store.getState();
          const authState = state.auth;
          const { token, refreshToken } = authState;

          if (!token || !refreshToken) throw err;

          const originalRequest = err.config;
          const dispatch = store.dispatch;

          if (!originalRequest._retry) {
            // when request refresh token is process, push parallel request to queue
            if (isRefreshing) {
              return new Promise((resolve, reject) => {
                requestQueue.push({ resolve, reject });
              })
                .then((newToken) => {
                  // axios.defaults.headers.common['Authorization'] = token;
                  originalRequest.headers['Authorization'] = newToken;
                  return axios(originalRequest);
                })
                .catch((newReqError) => {
                  // eslint-disable-next-line no-console
                  console.log('error in token expire', newReqError);
                  throw err;
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise((resolve) => {
              dispatch(getRefreshToken())
                .then((data) => {
                  const newToken = data.data.accessToken;
                  // axios.defaults.headers.common['Authorization'] = newToken; //this appends auth header on every subsequent refreshes
                  originalRequest.headers['Authorization'] = newToken;
                  processQueue(null, newToken);
                  dispatch(pruneSession());
                  resolve(axios(originalRequest));
                  // process parallel request queue after with new token
                })
                .catch(async () => {
                  if (!alreadyOpen) {
                    await dispatch(logout());

                    const searchParam = window.location.search;
                    const params = parseQuery(searchParam);
                    const isNewUser = params?.isNewUser;
                    // prevent popup login to shows when current logged in user is from payment gateway
                    if (isNewUser !== 'true') dispatch(authPopup.show());

                    alreadyOpen = true;
                  }

                  throw err;
                })
                .finally(() => {
                  isRefreshing = false;
                });
            });
          }
        } else {
          // console.log(err);
          throw err;
        }
      }
    );
  }
};
