const initialState = {
  data: null,
  error: null,
  meta: null
};

export default function searchReducer(state = initialState, action) {
  const { type, payload, error } = action;

  switch (type) {
    case 'SEARCH_MEDIA_SUCCESS':
      return {
        data: {
          ...state.data,
          [payload.type]:
            state.data && state.data[payload.type]
              ? [...state.data[payload.type], ...payload.data]
              : payload.data
        },
        error: state.error,
        meta: {
          ...state.meta,
          [payload.type]: payload.meta
        }
      };

    case 'SEARCH_MEDIA_ERROR':
      return {
        ...state,
        error: {
          ...state.error,
          [error.type]: error.error
        }
      };

    case 'CLEAR_SEARCH_DATA':
      return initialState;

    default:
      return state;
  }
}
