import React from 'react';
import styles from './Button.module.css';

const generateButtonColor = (color) => {
  switch (color) {
    case 'primary':
      return '#F49832';
    case 'light':
      return '#FFF';
    case 'dark':
      return '#1C1C1C';
    case 'shortbite':
      return '#F3D247';
    default:
      return color || '#fff';
  }
};

const generateTextColor = (color) => {
  switch (color) {
    case 'primary':
      return '#FFF';
    case 'dark':
      return '#000';
    case 'light':
      return '#FFF';
    default:
      return color || '#fff';
  }
};

const Button = ({
  onClick,
  children,
  className,
  icon,
  iconSize,
  iconStyle,
  width,
  color = 'primary',
  textColor,
  fontSize,
  type = 'primary', // primary, outlined, text
  size, // default, small, large
  shape = 'default',
  textWrap,
  style,
  borderColor,
  onHover = 'darken',
  disabled = false,
  buttonType,
  ...otherButtonProps
}) => {
  const customStyle = {
    '--button-props-background': generateButtonColor(color),
    '--button-props-color': generateTextColor(textColor),
    width: width || 'initial',
    fontSize,
    borderColor,
    ...style
  };
  const textWrapStyle = {
    display: '-webkit-box',
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: textWrap?.maxLine,
    WebkitBoxOrient: 'vertical'
  };
  const textNoWrapStyle = {
    whiteSpace: 'nowrap'
  };

  const handleClick = (e) => {
    if (disabled) return;

    onClick && onClick(e);
  };

  const generateClassName = () => {
    let result = `${styles['button']} ${className || ''} `;

    if (icon && !children) {
      result += `${styles['button-icon']} `;
    }

    if (size === 'small') {
      result += `${styles['small']} `;
    }
    if (size === 'large') {
      result += `${styles['large']} `;
    }

    if (type === 'primary') {
      result += `${styles['primary']} `;
    }
    if (type === 'outlined') {
      result += `${styles['outlined']} `;
    }
    if (type === 'text') {
      result += `${styles['text']} `;
    }

    if (shape === 'default') {
      result += `${styles['default-shape']} `;
    }
    if (shape === 'rounded') {
      result += `${styles['round-shape']} `;
    }

    if (onHover === 'darken' && !disabled) {
      result += `${styles['darken-hover']} `;
    }
    if (onHover === 'scale' && !disabled) {
      result += `${styles['scale-hover']} `;
    }

    return result;
  };

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={generateClassName()}
      onClick={handleClick}
      style={customStyle}
      data-disabled={disabled}
      {...otherButtonProps}
    >
      {!!icon &&
        (iconStyle ? (
          <div
            style={{
              display: 'flex',
              ...iconStyle,
              ...(icon && !children && { margin: 0 })
            }}
          >
            <img
              src={icon}
              alt='icon'
              width={iconSize}
            />
          </div>
        ) : (
          <img
            src={icon}
            alt='icon'
            width={iconSize}
            style={{
              marginRight: icon && !children ? '0' : '0.7rem'
            }}
          />
        ))}
      <span
        style={{
          ...(textWrap ? textWrapStyle : textNoWrapStyle),
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center'
        }}
      >
        {children}
      </span>
      {disabled && <div className={styles['disabled']} />}
    </button>
  );
};

export default Button;
