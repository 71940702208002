import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getContinueWatching } from '../../redux/actions/history';
import Section from '../Section/Section';
import { generateSelectChannelURL } from '../../utils';
import MediaPlayer from '../MediaPlayer/MediaPlayer';

const ContinueWatchingSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const continueWatchingData = useSelector(
    (state) => state.history.continueWatching
  );
  const [searchParams] = useSearchParams();
  const isNewUser = searchParams.get('isNewUser');

  const authState = useSelector((state) => state.auth);
  const token = authState.token;
  const [playVideo, setPlayVideo] = useState(false);

  useEffect(() => {
    if (token && isNewUser !== 'true') {
      dispatch(getContinueWatching());
    }
  }, [dispatch, token, isNewUser]);

  const handleClickWatch = async (item) => {
    if (item.type === 'webview' || item.type === 'stream') {
      const channelURL = generateSelectChannelURL(item);
      return navigate(channelURL);
    }
    setPlayVideo(item);
  };

  const onCloseVideo = () => {
    setPlayVideo();
    if (token) {
      dispatch(getContinueWatching());
    }
  };

  if (!continueWatchingData || !continueWatchingData.length) return null;
  return (
    <>
      <Section
        name='Continue Watching'
        sectionData={continueWatchingData}
        allowSeeAll={false}
        topSection
        playOnClick={handleClickWatch}
      />

      {playVideo?.id && (
        <MediaPlayer
          mediaType={playVideo.type}
          mediaId={playVideo.id}
          autoPlay
          onClose={onCloseVideo}
        />
      )}
    </>
  );
};

export default ContinueWatchingSection;
