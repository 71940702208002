import React, { useCallback, useEffect } from 'react';
import PlayerSubscription from './PlayerSubscription';
import VideoInterval from './VideoInterval';

import styles from './IframePlayer.module.css';

const IframePlayer = ({
  viewers,
  isRelative,
  src,
  title,
  onDismissPlayer,
  handleUpdateHistory,
  ...otherProps
}) => {
  const updateHistoryCallback = useCallback(() => {
    handleUpdateHistory();
  }, [handleUpdateHistory]);

  useEffect(() => {
    handleUpdateHistory(0);
  }, [handleUpdateHistory]);

  if (src.error) {
    return (
      <PlayerSubscription
        onDismissPlayer={onDismissPlayer}
        otherProps={otherProps}
        error={src.error}
      />
    );
  }

  return (
    <div className={`live-player ${isRelative && 'relative'}`}>
      <VideoInterval updateHistoryCallback={updateHistoryCallback} />
      {onDismissPlayer && (
        <div
          className={`vjs-dismiss-btn-wrapper ${isRelative && 'relative'}`}
          onClick={onDismissPlayer}
        >
          <i className='vjs-dismiss-player-icon' />
        </div>
      )}
      {isRelative && (
        <div className='vjs-viewer-btn-wrapper'>
          <i className='vjs-viewer-player-icon' />
          <span>{viewers} </span>
        </div>
      )}
      <iframe
        src={src}
        className={`${styles['iframe-player']} ${
          isRelative ? styles['relative'] : ''
        }`}
        title={title}
        width='100%'
      />
    </div>
  );
};

export default IframePlayer;
