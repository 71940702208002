import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  authPopup,
  setWatchHistoryBeforeLogin
} from '../../redux/actions/auth';
import Button from '../Button/Button';

import styles from './styles.module.css';

const QuizOffer = ({ onQuizStateChange, onQuizClose, onQuizStatusChange }) => {
  const quizChance = useSelector((state) => state?.quiz?.quizChance);
  const quizShow = useSelector((state) => state?.quiz?.quizDetail?.timeToShow);
  const quizName = useSelector(
    (state) => state?.quiz?.quizDetail?.quizCampaign?.name
  );
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const handleAcceptQuiz = () => {
    if (user && quizChance) {
      onQuizStateChange('quizQuestions');
    } else if (user && !quizChance) {
      onQuizStatusChange('notEligible');
      onQuizStateChange('quizResult');
    } else {
      dispatch(setWatchHistoryBeforeLogin(quizShow));
      dispatch(authPopup.show());
    }
  };
  return (
    <div className={styles['wrapper']}>
      <div className={styles['content']}>
        <div className={styles['header']}>
          Mau ikutan undian berhadiah "{quizName}"?
        </div>
      </div>
      <div className={styles['action']}>
        <Button
          id='yes'
          width='343px'
          shape='rounded'
          onClick={handleAcceptQuiz}
        >
          Ya
        </Button>
        <Button
          id='no'
          color='dark'
          textColor='primary'
          width='343px'
          shape='rounded'
          onClick={onQuizClose}
        >
          Tidak
        </Button>
      </div>
    </div>
  );
};

export default QuizOffer;
