import React, { memo } from 'react';
import { InView } from 'react-intersection-observer';
import styles from './PlaylistPageView.module.css';
import { Spinner } from '..';
import playStreamIc from '../../assets/ic-play-streaming.svg';
import ItemComponent from '../Slider/Item';
import ClipsCollectionItem from '../CollectionItem/ClipsCollectionItem';

const PlaylistViewContent = ({
  data,
  title,
  titleClassName,
  itemClassName,
  searchView,
  allowSeeAll,
  handleViewAll,
  completed,
  fetchNextData,
  handleSelectChannel,
  onLiveshowClicked,
  maxItemsPerRow,
  autoEvenList,
  loadOnScroll,
  error,
  showHistoryBar,
  showPlayIcon
}) => {
  const filteredData = data.filter((item) => item.type !== '-');

  const generateDataItems = () => {
    let results = filteredData.map((item, key) => {
      if (title === 'Clips') {
        return (
          <ClipsCollectionItem
            key={item.id}
            data={item}
            onChannelSelected={handleSelectChannel}
            playlistType='playlist'
          />
        );
      }

      return (
        <ItemComponent
          itemClassName={itemClassName}
          onChannelClick={handleSelectChannel}
          onLiveshowClicked={onLiveshowClicked}
          index={item.id}
          key={key}
          item={item}
          showHistoryBar={showHistoryBar}
          showPlayIcon={showPlayIcon}
        />
      );
    });

    if (autoEvenList) {
      const itemInRow = (data?.length || 0) % maxItemsPerRow.length;

      if (
        window.innerWidth > maxItemsPerRow.width &&
        itemInRow !== 0 &&
        itemInRow !== data?.length
      ) {
        results = results.slice(
          0,
          results.length - ((data?.length || 0) % maxItemsPerRow.length)
        );
      }
    }

    for (
      let i = 1;
      i <= maxItemsPerRow.length - (data.length % maxItemsPerRow.length);
      i++
    ) {
      results.push(
        <ItemComponent
          empty
          key={`empty_${i}`}
        />
      );
    }

    return results;
  };

  const NoResultGenerateDataItems = memo(() => {
    return (
      <div className={styles['noData']}>
        <img
          className={styles['iconLive']}
          src={playStreamIc}
          alt='icon-play-stream'
        />
        <span className={styles['textNoVideos']}>
          {error ? `Error ${error}` : "You haven't watched any content"}
        </span>
      </div>
    );
  });
  NoResultGenerateDataItems.displayName = 'NoResultGenerateDataItems';

  return (
    <>
      {(title || !(!searchView && allowSeeAll)) && (
        <div className={styles['title-wrapper']}>
          <h1 className={`${styles['title']} ${titleClassName}`}>{title}</h1>
          {!!searchView && allowSeeAll && (
            <p onClick={handleViewAll}>See all</p>
          )}
        </div>
      )}

      <div className={styles['collection']}>
        <div className={styles['list-container']}>{generateDataItems()}</div>
        {!searchView && completed && !filteredData.length && (
          <NoResultGenerateDataItems />
        )}
        {!searchView && !completed && (
          <div className={styles['loader-wrapper']}>
            <InView
              onChange={(inView) => {
                if (loadOnScroll) return loadOnScroll(inView);

                inView && filteredData.length && fetchNextData();
              }}
            >
              {!completed ? <Spinner height='100px' /> : null}
            </InView>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(PlaylistViewContent);
