/* eslint-disable no-restricted-globals */

const workercode = () => {
  let timerInterval;
  let time = 0;
  self.onmessage = function ({ data: { turn, duration } }) {
    time = duration;
    if (turn === 'off' || timerInterval) {
      clearInterval(timerInterval);
      time = duration;
    }
    if (turn === 'on') {
      timerInterval = setInterval(() => {
        time -= 1;
        self.postMessage({ time });
      }, 1000);
    }
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });
const timer_worker = URL.createObjectURL(blob);

module.exports = timer_worker;
