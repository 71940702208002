import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';
import { getDiamondTransactions } from '../../redux/actions/pack';
import styles from './transaction.module.css';
import Loading from '../Loading/Loading';
import { currencyFormatter } from '../../utils';

const PackHistory = () => {
  const dispatch = useDispatch();
  const { diamondTransactions, loading, err } = useSelector(
    (state) => state.pack
  );
  const { page } = diamondTransactions;
  const loadCompleted =
    !loading &&
    diamondTransactions?.data &&
    diamondTransactions?.meta &&
    diamondTransactions?.meta?.total <= diamondTransactions?.data?.length;

  const packages = diamondTransactions?.data?.map((diamond) => {
    return {
      name: `Pembelian ${
        diamond?.pack?.amount
      } Diamonds - ${currencyFormatter.format(diamond?.pack?.price)}`,
      date: `${new Date(diamond.updatedAt).toLocaleDateString('en-US')}`,
      amount: diamond?.pack?.amount,
      price: diamond?.pack?.price,
      updatedAt: diamond?.updatedAt
    };
  });

  useEffect(() => {
    dispatch(getDiamondTransactions({ page }));
  }, [dispatch, page]);

  const fetchNextPage = () => {
    dispatch({ type: 'FETCH_NEXT_PAGE_DIAMOND_TRANSACTION' });
  };

  return (
    <div className={styles['listWrapper']}>
      {!packages.length && (
        <div className={styles['packagesWrapper']}>
          <div className={styles['noData']}>
            <div>No History Diamond</div>
            <div>Your history diamond will show up here.</div>
          </div>
        </div>
      )}
      {packages.map((pack, key) => {
        return (
          <div
            className={styles['itemWrapper']}
            key={key}
          >
            <div className={styles['detail']}>
              <h3 className={styles['title']}>{pack.name}</h3>
              <span className={styles['date']}>{pack.date}</span>
            </div>
          </div>
        );
      })}
      {!err && !loadCompleted && (
        <InView
          onChange={(inView) => {
            inView && diamondTransactions?.data?.length && fetchNextPage();
          }}
        >
          <Loading />
        </InView>
      )}
    </div>
  );
};

export default PackHistory;
