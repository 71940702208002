let drmType = 'No DRM';

// Replace the DEMO site ID with yours when you test your own FPS content.
const fairplayCertUri =
  'https://license.pallycon.com/ri/fpsKeyManager.do?siteId=LZ9U';

// Detect the browser and set proper DRM type
export function checkBrowser() {
  let agent = navigator.userAgent.toLowerCase();
  const name = navigator.appName;
  let browser;

  if (
    name === 'Microsoft Internet Explorer' ||
    agent.indexOf('trident') > -1 ||
    agent.indexOf('edge/') > -1
  ) {
    browser = 'ie';
    if (name === 'Microsoft Internet Explorer') {
      // IE old version (IE 10 or Lower)
      agent = /msie ([0-9]{1,}[.0-9]{0,})/.exec(agent);
      // browser += parseInt(agent[1]);
    } else if (agent.indexOf('edge/') > -1) {
      // Edge
      browser = 'Edge';
    }
    drmType = 'PlayReady';
  } else if (agent.indexOf('safari') > -1) {
    // Chrome or Safari
    if (agent.indexOf('opr') > -1) {
      // Opera
      browser = 'Opera';
      drmType = 'Widevine';
    } else if (agent.indexOf('whale') > -1) {
      // Chrome
      browser = 'Whale';
      drmType = 'Widevine';
    } else if (agent.indexOf('edg/') > -1 || agent.indexOf('Edge/') > -1) {
      // Chrome
      browser = 'Edge';
      drmType = 'PlayReady';
    } else if (agent.indexOf('chrome') > -1) {
      // Chrome
      browser = 'Chrome';
      drmType = 'Widevine';
    } else {
      // Safari
      browser = 'Safari';
      drmType = 'FairPlay';
    }
  } else if (agent.indexOf('firefox') > -1) {
    // Firefox
    browser = 'firefox';
    drmType = 'Widevine';
  }

  // The below three lines are for the sample code only. May need to be removed.
  const result = `Running in ${browser}. ${drmType} supported.`;
  document.getElementById('browserCheckResult').innerHTML = result;
  // eslint-disable-next-line no-console
  console.log(result);

  return browser;
}

export function arrayBufferToString(buffer) {
  const arr = new Uint8Array(buffer);
  // eslint-disable-next-line prefer-spread
  const str = String.fromCharCode.apply(String, arr);
  // if(/[\u0080-\uffff]/.test(str)){
  //     throw new Error("this string seems to contain (still encoded) multibytes");
  // }
  return str;
}

export function getFairplayCert(shaka) {
  try {
    let xmlhttp;
    if (window.XMLHttpRequest) {
      xmlhttp = new XMLHttpRequest();
    }
    xmlhttp.open('GET', fairplayCertUri, false);
    xmlhttp.send();
    // eslint-disable-next-line no-console
    console.log('fpsCert : ', xmlhttp.responseText);
    const fpsCert = shaka.util.Uint8ArrayUtils.fromBase64(xmlhttp.responseText);
    // eslint-disable-next-line no-console
    console.log('fpsCert decrypt : ', fpsCert);
    return fpsCert;
  } catch (error) {
    console.log(error);
  }
}

export function parsingResponse(response) {
  let responseText = arrayBufferToString(response.data);
  // Trim whitespace.
  responseText = responseText.trim();

  // console.log('responseText :: ', responseText);

  try {
    const pallyconObj = JSON.parse(responseText);
    if (pallyconObj && pallyconObj.errorCode && pallyconObj.message) {
      if (pallyconObj.errorCode !== '8002') {
        // eslint-disable-next-line no-alert
        alert(
          `PallyCon Error : ${pallyconObj.message}(${pallyconObj.errorCode})`
        );
        // window.alert('No Rights. Server Response ' + responseText);
      } else {
        const errorObj = JSON.parse(pallyconObj.message);
        // eslint-disable-next-line no-alert
        alert(`Error : ${errorObj.MESSAGE}(${errorObj.ERROR})`);
      }
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}
