import React, { useState } from 'react';
import CommentOn from '../../../assets/btn-comment-on.svg';
import CommentOff from '../../../assets/btn-comment-off.svg';

function CommentButton() {
  const [isCommentOn, setComment] = useState(true);
  return (
    <img
      onClick={() => setComment(!isCommentOn)}
      className='vjs-custom-icon'
      src={isCommentOn ? CommentOff : CommentOn}
      alt='Fast Forward'
    />
  );
}

CommentButton.handleClick = (player, option) => {
  option.onCommentButtonClick();
};

export default CommentButton;
