import React, { useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

import { useSelector, useDispatch } from 'react-redux';
import styles from './FullScreenMode.module.css';
import { LiveStreamCommentSection } from '../..';
import ReportModal from '../../ReportModal';
import backArrowIcn from '../../../assets/ic-arrow-back.svg';
import reportIcn from '../../../assets/report-icon.svg';
import moreIcn from '../../../assets/more.svg';

import UserInfo from '../../UserInfo/UserInfo';
import viewsIcn from '../../../assets/views-icon.svg';
import gencashIcn from '../../../assets/ic-gencash.svg';
import { authPopup } from '../../../redux/actions/auth';

function FullScreenMode({
  isHideComment,
  isVerticalVideo,
  isShowStreamerInfo,
  isLiveShow,
  views,
  totalGencash,
  onExitFullScreen,
  streamInfo
}) {
  const dispatch = useDispatch();

  const { webSocket } = useSelector((state) => state.liveShow);
  const { profileInfo } = useSelector((state) => state.publicProfile);
  const { token } = useSelector((state) => state.auth);
  const [moreDialog, setMoreDialog] = useState(false);
  const [showReportModal, setReportModal] = useState(false);

  const handleLogin = () => {
    if (!token) {
      onExitFullScreen();
      dispatch(authPopup.show());
    }
  };

  return (
    <>
      {isShowStreamerInfo && (
        <div className={styles['userInfoContainer']}>
          {isVerticalVideo && isLiveShow && (
            <img
              height={24}
              style={{ marginRight: '1.9rem', cursor: 'pointer' }}
              src={backArrowIcn}
              alt='leftArrow'
              onClick={onExitFullScreen}
            />
          )}
          <UserInfo
            endDivider
            limitName={15}
            onFollowAdditionalAction={onExitFullScreen}
          />

          <div
            className={styles['moreBtn']}
            onClick={() => setMoreDialog(!moreDialog)}
            style={{ position: 'relative' }}
          >
            <img
              src={moreIcn}
              alt='more'
              width='24px'
              height='24px'
              style={{ cursor: 'pointer' }}
            />

            <CSSTransition
              in={moreDialog}
              timeout={100}
              classNames='fade'
              unmountOnExit
            >
              <div className={styles['popup-menu']}>
                <div
                  className={styles['popupMenuWrapper']}
                  onClick={() => {
                    handleLogin();

                    setReportModal(true);
                    setMoreDialog(false);
                  }}
                >
                  <img
                    src={reportIcn}
                    alt='more'
                    width='21'
                    height='21'
                  />
                  <span style={{ marginLeft: '1rem' }}>Report</span>
                </div>
              </div>
            </CSSTransition>
          </div>

          <div className={styles['badgeWrapper']}>
            {typeof totalGencash === 'number' && (
              <div
                className={styles['badge']}
                data-gencash
                data-portrait={isVerticalVideo}
              >
                <img
                  src={gencashIcn}
                  alt='gen-icn'
                />
                <span>{totalGencash || 0}</span>
              </div>
            )}
            {typeof views === 'number' && (
              <div
                className={styles['badge']}
                data-views
              >
                <img
                  src={viewsIcn}
                  alt='view-icn'
                />
                <span>{views || 0}</span>
              </div>
            )}
          </div>
        </div>
      )}

      <div
        className={styles['badge']}
        data-live-badge
        data-onhover={isShowStreamerInfo}
      >
        <div data-item='live'>LIVE</div>
      </div>

      <div className={styles['commentContainer']}>
        {webSocket && !isHideComment && (
          <LiveStreamCommentSection
            isVerticalVideo={isVerticalVideo}
            isFullScreenMode
          />
        )}
      </div>

      {profileInfo && streamInfo && (
        <ReportModal
          idEntity={streamInfo?.id}
          type='stream'
          profile={profileInfo.profile}
          isTriggeredReport={showReportModal}
          valueEntity={streamInfo?.name || streamInfo?.titleLocalized}
          onReportClose={() => {
            setReportModal(false);
          }}
        />
      )}
    </>
  );
}

export default React.memo(FullScreenMode);
