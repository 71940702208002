import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Item.module.css';

import ItemDetail from '../MediaDetail/ItemDetail';
import MediaItem from './MediaItem';
import { generateSelectChannelURL } from '../../utils';

const ItemComponent = ({
  item,
  onChannelClick,
  onLiveshowClicked,
  empty,
  showPlayIcon
}) => {
  const navigate = useNavigate();
  const onChannelClickDetail = () => {
    const channelURL = generateSelectChannelURL(item);
    navigate.push(channelURL);
  };

  return (
    <div className={`${empty ? styles['empty'] : ''} ${styles['slider-item']}`}>
      {item && !empty && (
        <ItemDetail
          item={item}
          onChannelSelected={onChannelClick || onChannelClickDetail}
          onLiveshowClicked={onLiveshowClicked}
          showPlayIcon={showPlayIcon}
          component={MediaItem}
        />
      )}
    </div>
  );
};

export default ItemComponent;
