import axios from 'axios';
import { getValue } from 'firebase/remote-config';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import {
  deleteCookie,
  generateDeviceInfo,
  generateErrorDetail,
  getCookie,
  retryOperation
} from '../../utils';
import { errorCode } from '../../constants';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { configKeys, remoteConfig } from '../../service/firebase';
import { initFbPixelUserData } from '../../service/facebookPixel';
import { showToastNotif } from './notification';
import {
  getInAppNotification,
  getInAppNotificationHighlight,
  getInAppNotificationSummary
} from './inAppNotification';

const BASE_CONFIG_URL = getValue(
  remoteConfig,
  configKeys.baseURL['user']
).asString();

const accountUrl = `${BASE_CONFIG_URL}/user/v1.0/accounts`;
const accountUrlVOneOne = `${BASE_CONFIG_URL}/user/v1.1/accounts`;
const loginUrl = `${BASE_CONFIG_URL}/user/v1.0/sessions`;
// const loginUrlVOneOne = `${BASE_CONFIG_URL}/user/v1.1/sessions`

const oauthURL = `${BASE_CONFIG_URL}/user/v1.2/sessions/oauth`;
const sessionURLv1_2 = `${BASE_CONFIG_URL}/user/v1.2/sessions`;
const otpUrl = `${BASE_CONFIG_URL}/user/v1.0/sessions/otp`;
const refreshUrl = `${BASE_CONFIG_URL}/user/v1.2/sessions/refresh`;
const pruningUrl = `${BASE_CONFIG_URL}/user/v1.2/sessions/pruning`;
const forgotPassUrl = `${BASE_CONFIG_URL}/user/v1.0/passwords/forget`;
const resetPassUrl = `${BASE_CONFIG_URL}/user/v1.0/passwords/reset`;
const verifyUrl = `${BASE_CONFIG_URL}/user/v1.0/accounts/verify`;
const profileUrl = `${BASE_CONFIG_URL}/user/v1.1/profile`;
const updatePasswordUrl = `${BASE_CONFIG_URL}/user/v1.0/passwords`;
const authIOH = `${BASE_CONFIG_URL}/user/v1.0/sessions/ioh`;
const whatsappLinkUrl = `${BASE_URL}/screen/v1.0/setting/csWhatsapp`;
const popularStreamersUrl = `${BASE_URL}/catalog/v1.0/liveShows/topStreamers`;

// const blockUrl = `${BASE_CONFIG_URL}/user/v1.0/blocks`;
// const blockedProfilesUrl = `${BASE_CONFIG_URL}/user/v1.0/blocks/profile`;
const reportUrl = `${BASE_CONFIG_URL}/user/v1.0/reports/index`;
const consentUrl = `${BASE_CONFIG_URL}/user/v1.0/consents`;

const emailTester =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const authPopup = {
  show: (configs) => (dispatch) => {
    dispatch({
      type: 'SHOW_AUTH_POPUP',
      configs
    });
  },
  close: () => (dispatch) => {
    dispatch({
      type: 'CLOSE_AUTH_POPUP'
    });
  }
};

export function getAuthInfo() {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    let user = null;
    let refreshToken = null;

    if (token) {
      try {
        user = JSON.parse(getCookie('user'));
        refreshToken = localStorage.getItem('refreshToken');
      } catch (err) {
        // const errorData = generateErrorDetail(err);
      }
    }

    if (token && refreshToken && user) {
      dispatch(setToken(token, refreshToken));
      // dispatch(setUser(user));
      dispatch(getAccountInfo());
    }
  };
}

export function getAccountInfo({ dispatchState = true } = {}) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;

    try {
      dispatchState &&
        dispatch({
          type: 'GET_ACCOUNT_INFO'
        });

      const res = await axios.get(accountUrlVOneOne, {
        headers: {
          Authorization: token
        }
      });

      await initFbPixelUserData(res?.data?.data);
      dispatchState &&
        dispatch({
          type: 'GET_ACCOUNT_INFO_SUCCESS',
          data: res.data.data
        });

      return res?.data?.data;
    } catch (err) {
      if (err.response && err.response.data) {
        const errorData = generateErrorDetail(err);

        dispatchState &&
          dispatch({
            type: 'GET_ACCOUNT_INFO_FAILURE',
            err: errorData.message
          });
      }
    }
  };
}

export function updateProfile(id, payload) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;

    dispatch({ type: 'UPDATE_PROFILE' });
    try {
      const res = await axios.put(`${profileUrl}/${id}`, payload, {
        headers: {
          Authorization: token
        }
      });

      dispatch({
        type: 'UPDATE_PROFILE_SUCCESS'
      });
      return res.data;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      if (errorData) {
        dispatch({
          type: 'UPDATE_PROFILE_FAILURE',
          err: errorData.message
        });
      }
      return errorData;
    }
  };
}

export function updatePassword(currentPassword, password, confirmPassword) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const payload = {
        password,
        confirmPassword
      };
      if (currentPassword) {
        payload.currentPassword = currentPassword;
      }
      const res = await axios.post(`${updatePasswordUrl}`, payload, {
        headers: {
          Authorization: token
        }
      });
      dispatch({
        type: 'UPDATE_PASSWORD_SUCCESS'
      });
      return res.data;
    } catch (err) {
      // dispatch({
      //   type: 'UPDATE_PASSWORD_FAILURE',
      //   err: err.response.data.error.message,
      // });
      throw new Error(err);
    }
  };
}

export function getWhatsappUrl() {
  return async (dispatch, getState) => {
    const state = getState();
    const { token, whatsappLink } = state.auth;

    dispatch({ type: 'GET_WHATSAPP' });
    try {
      if (!whatsappLink) {
        const res = await axios.get(whatsappLinkUrl, {
          headers: {
            Authorization: token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        });
        dispatch({ type: 'GET_WHATSAPP_SUCCESS', payload: res.data });
        return res.data;
      }

      dispatch({
        type: 'GET_WHATSAPP_SUCCESS_FROM_STATE',
        payload: whatsappLink
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_WHATSAPP_FAILURE',
        err: errorData.message
      });
    }
  };
}

// function setUser(user) {
//   return (dispatch) => {
//     dispatch({
//       type: 'SET_USER',
//       user,
//     });
//   };
// }

export function setToken(token, refreshToken) {
  return (dispatch) => {
    dispatch({
      type: 'SET_TOKEN',
      token,
      refreshToken
    });
  };
}

export const updateTokenState = () => (dispatch, getState) => {
  const state = getState();
  const { liveGameInfo, isLiveGameBroadcasting } = state.liveGame;

  if (liveGameInfo && isLiveGameBroadcasting) {
    return dispatch(authPopup.show());
  }

  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  const user = getCookie('user') ? JSON.parse(getCookie('user')) : null;

  dispatch({
    type: 'UPDATE_TOKEN_STATE',
    data: {
      token,
      refreshToken,
      user
    }
  });
};

export function registerByEmail(name, email, password) {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'REGISTER_USER'
      });
      const res = await axios.post(
        accountUrl,
        {
          email: emailTester.test(email) ? email.toLowerCase() : email,
          password,
          name
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      if (res.status === 204) {
        window.location.href = window.location.origin;
      }

      dispatch({
        type: 'REGISTER_USER_SUCCESS'
      });
      await dispatch(loginByEmail(email, password));
      const userID = res.data.data.userId;
      const paramsEvent = {
        userID,
        profileName: name,
        accountName: name,
        accountEmail: email
      };
      analytic(
        analyticTypes.event,
        analyticEvents.AUTH.COMPLETE_REGISTER_BY_EMAIL,
        {
          params: paramsEvent
        }
      );
    } catch (err) {
      const errorData = generateErrorDetail(err);

      let errorMessage = 'General error, please try again later';
      if (errorData) {
        errorMessage = errorData.details[0] || errorData.message;
        if (errorData.code === errorCode.INVALID_PARAMETERS) {
          errorMessage = 'Password should be at least 8 characters';
        }
        if (errorData.code === errorCode.EMAIL_ALREADY_EXIST) {
          errorMessage = 'Email has existed, please use another email';
        }
      }

      dispatch({
        type: 'REGISTER_USER_FAILURE',
        err: errorMessage
      });

      throw err;
    }
  };
}

export function logout() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const user = state.auth.user;
    const userID = user && user.account && user.account.userId;

    const cleanUserStorage = async () => {
      await localStorage.removeItem('token');
      await localStorage.removeItem('refreshToken');
      await deleteCookie('token');
      await deleteCookie('refreshToken');
      await deleteCookie('user');
      await localStorage.removeItem('sessionStatus');
      await localStorage.removeItem('partner_id');
    };

    if (!token) {
      await cleanUserStorage();
      return null;
    }

    const paramsEvent = {
      time: new Date().getTime(),
      userID
    };
    analytic(analyticTypes.event, analyticEvents.AUTH.LOGOUT, {
      params: paramsEvent,
      user
    });

    dispatch({ type: 'LOGGING_OUT' });

    try {
      axios.delete(loginUrl, {
        headers: {
          Authorization: token
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('logout failed', error);
    } finally {
      await cleanUserStorage();

      dispatch({ type: 'LOGOUT' });
      dispatch({ type: 'RESET_PACK_STATE' });
      dispatch({ type: 'RESET_CAMPAIGN_UGC' });
      dispatch({ type: 'RESET_CASHOUT_STATE' });
      dispatch({ type: 'RESET_MY_LIST_STATE' });
      dispatch({ type: 'RESET_HISTORY_STATE' });
      dispatch({ type: 'RESET_LIVEGAME_STATE' });
      dispatch({ type: 'RESET_IN_APP_NOTIFICATION' });
      dispatch({ type: 'CLEAR_PUBLIC_PROFILE_STATE' });
    }

    // if (action === 'refresh') {
    //   return window.location.reload();
    // }
  };
}

export const logoutAllDevice = () => async (dispatch) => {
  const logoutToken = localStorage.getItem('logoutToken');

  await axios.delete(`${sessionURLv1_2}/clearByAccountId`, {
    headers: {
      Authorization: logoutToken
    }
  });

  dispatch({ type: 'LOGOUT' });

  return window.location.reload();
};

export function loginByEmail(email, password) {
  return async (dispatch, getState) => {
    const state = getState();
    const consentSelection = state.auth.consentSelection;
    try {
      dispatch({
        type: 'LOGIN'
      });

      const res = await axios.post(
        sessionURLv1_2,
        {
          type: 'username',
          username: emailTester.test(email) ? email.toLowerCase() : email,
          password,
          device: generateDeviceInfo()
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      const paramsEvent = {
        time: new Date().getTime(),
        userID: res.data.data.account.userId
      };
      const user = { ...res.data.data, telco: null };
      analytic(
        analyticTypes.event,
        analyticEvents.AUTH.COMPLETE_LOGIN_BY_EMAIL,
        {
          params: paramsEvent,
          user
        }
      );
      await initFbPixelUserData(user);

      await dispatch(validateSession(user?.isNewDevice, user?.accessToken));
      dispatch({
        type: 'LOGIN_SUCCESS',
        data: res.data.data
      });
      // dispatch(getAccountInfo());
      dispatch(getInAppNotificationSummary());
      dispatch(getInAppNotification(1, 10));
      dispatch(getInAppNotificationHighlight(1, 5));
      if (consentSelection === null) {
        await dispatch(getConsent()); // dont call this if consentSelection is not null
      } else {
        await dispatch(postConsent());
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      const code = errorData.code;
      let mes = '';

      if (
        code === errorCode.INVALID_CREDENTIAL ||
        code === errorCode.INVALID_PARAMETERS
      ) {
        mes = 'Incorrect Email or Password';
      } else {
        mes = errorData.message || 'General Login Error';
      }

      dispatch({
        type: 'LOGIN_FAILURE',
        err: mes
      });
      throw err;
    }
  };
}

export const validateSession =
  (isNewDevice, accessToken) => async (dispatch) => {
    dispatch({
      type: 'VALIDATE_SESSION'
    });
    try {
      const validate = new Promise((resolve, reject) => {
        let counter = 0;
        const validatingLoginSession = async () => {
          counter += 1;
          try {
            await axios.post(
              `${sessionURLv1_2}/validateSession`,
              {
                isNewDevice
              },
              {
                headers: {
                  'Cloudfront-JWT-AppId': JWT_APP_ID,
                  Authorization: accessToken
                }
              }
            );

            return resolve('success');
          } catch (err) {
            if (counter >= 3) return reject(new Error('failed'));

            setTimeout(validatingLoginSession, 10000);
          }
        };

        validatingLoginSession();
      });
      await validate;

      dispatch({
        type: 'VALIDATE_SESSION_SUCCESS'
      });
      return 'success';
    } catch (err) {
      dispatch({
        type: 'VALIDATE_SESSION_FAILED',
        data: accessToken
      });
      throw err;
    }
  };

export function forgotPassword(email) {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'FORGOT_PASSWORD'
      });
      await axios.post(
        forgotPassUrl,
        {
          email
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'FORGOT_PASSWORD_SUCCESS'
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'FORGOT_PASSWORD_FAILURE',
        err: errorData.message
      });
    }
  };
}

export function resetPassword(password, token) {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'RESET_PASSWORD'
      });
      await axios.post(
        resetPassUrl,
        {
          password,
          token
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'RESET_PASSWORD_SUCCESS'
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'RESET_PASSWORD_FAILURE',
        err: errorData.message
      });
    }
  };
}

export const getRefreshToken = () => async (dispatch, getState) => {
  const state = getState();
  const token = state.auth.token;
  const refreshToken = state.auth.refreshToken;
  try {
    const res = await axios.post(
      refreshUrl,
      {
        accessToken: token,
        device: generateDeviceInfo()
      },
      {
        headers: {
          authorization: refreshToken,
          'Content-Type': 'application/json'
        }
      }
    );

    localStorage.removeItem('isFcmTokenSent');

    await dispatch(
      setToken(res.data.data.accessToken, res.data.data.refreshToken)
    );
    await dispatch(getAccountInfo());

    // eslint-disable-next-line no-console
    console.log('refreshed token');

    return res.data;
  } catch (err) {
    dispatch(logout());
    // eslint-disable-next-line no-console
    console.log('error refreshing', err);
    throw err;
  }
};

export const pruneSession = () => async (dispatch, getState) => {
  const state = getState();
  const { token } = state.auth;
  try {
    token &&
      (await axios.post(pruningUrl, undefined, {
        headers: {
          authorization: token,
          'Content-Type': 'application/json'
        }
      }));
    // eslint-disable-next-line no-console
    console.log('pruned');
  } catch (err) {
    retryOperation(
      async () => {
        try {
          await axios.post(pruningUrl, undefined, {
            headers: {
              authorization: token,
              'Content-Type': 'application/json'
            }
          });
        } catch (error) {
          throw new Error(error);
        }
      },
      500,
      1
    );
  }
};

export const resetAuthState = () => (dispatch) => {
  return dispatch({
    type: 'RESET_AUTH_STATE'
  });
};

export function signinWithGoogle(credentialResponse) {
  return async (dispatch, getState) => {
    const state = getState();
    const consentSelection = state.auth.consentSelection;
    try {
      dispatch({
        type: 'LOGIN'
      });
      // await window.gapi.auth2
      //   .getAuthInstance()
      //   .signIn({ scope: 'profile email', prompt: 'select_account' });

      // const googleUser = window.gapi.auth2.getAuthInstance().currentUser.get();
      // const ggToken = googleUser.getAuthResponse().id_token;

      const token = await axios.post(
        oauthURL,
        {
          code: credentialResponse.code
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      ); // get token from oauth credential response
      const ggToken = token.data.data.res.data.id_token;
      const res = await axios.post(
        sessionURLv1_2,
        {
          type: 'google',
          token: ggToken,
          device: generateDeviceInfo()
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      const paramsEvent = {
        time: new Date().getTime(),
        userID: res.data.data.account.userId
      };
      const user = { ...res.data.data, telco: null };
      analytic(
        analyticTypes.event,
        analyticEvents.AUTH.COMPLETE_LOGIN_BY_GOOGLE,
        {
          params: paramsEvent,
          user
        }
      );
      await initFbPixelUserData(user);

      await dispatch(validateSession(user?.isNewDevice, user?.accessToken));
      dispatch({
        type: 'LOGIN_SUCCESS',
        data: res.data.data
      });
      dispatch(getInAppNotificationSummary());

      if (consentSelection === null) {
        await dispatch(getConsent()); // dont call this if consentSelection is not null
      } else {
        await dispatch(postConsent());
      }
      // dispatch(getAccountInfo());
    } catch (err) {
      const errorData = generateErrorDetail(err);
      const code = errorData.code;
      let mes = '';

      if (
        code === errorCode.INVALID_CREDENTIAL ||
        code === errorCode.INVALID_PARAMETERS
      ) {
        mes = 'Invalid Credentials';
      } else if (err.error === 'popup_closed_by_user') {
        mes = 'You closed the login pop-up.';
      } else {
        mes = errorData.message;
      }

      dispatch({
        type: 'LOGIN_FAILURE',
        err: mes
      });
      throw err;
    }
  };
}

const getFBToken = () => {
  return new Promise((resolve, reject) => {
    window.FB.login(
      (res) => {
        if (res.status !== 'connected') {
          reject(res);
        } else resolve(res.authResponse);
      },
      { scope: 'email,public_profile', auth_type: 'rerequest' }
    );
  });
};

export function signinWithFB() {
  return async (dispatch, getState) => {
    const state = getState();
    const consentSelection = state.auth.consentSelection;
    try {
      dispatch({
        type: 'LOGIN'
      });
      const auth = await getFBToken();

      const res = await axios.post(
        sessionURLv1_2,
        {
          type: 'facebook',
          token: auth.accessToken,
          device: generateDeviceInfo()
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      const paramsEvent = {
        time: new Date().getTime(),
        userID: res.data.data.account.userId
      };
      const user = { ...res.data.data, telco: null };
      analytic(
        analyticTypes.event,
        analyticEvents.AUTH.COMPLETE_LOGIN_BY_FACEBOOK,
        {
          params: paramsEvent,
          user
        }
      );
      await initFbPixelUserData(user);

      await dispatch(validateSession(user?.isNewDevice, user?.accessToken));
      dispatch({
        type: 'LOGIN_SUCCESS',
        data: res.data.data
      });
      // dispatch(getAccountInfo());
      if (consentSelection === null) {
        await dispatch(getConsent()); // dont call this if consentSelection is not null
      } else {
        await dispatch(postConsent());
      }
      dispatch(getInAppNotificationSummary());
    } catch (err) {
      const errorData = generateErrorDetail(err);
      const code = errorData.code;
      let mes = '';

      if (
        code === errorCode.INVALID_CREDENTIAL ||
        code === errorCode.INVALID_PARAMETERS
      ) {
        mes = 'Invalid Credentials';
      } else if (err.authResponse === null) {
        mes = 'You closed the login pop-up.';
      } else {
        mes = errorData.message || 'General Facebook Login Error';
      }

      dispatch({
        type: 'LOGIN_FAILURE',
        err: mes
      });
      throw err;
    }
  };
}

export const requestOtp = (phone) => {
  return axios.post(
    otpUrl,
    { mobile: phone },
    {
      headers: { 'Cloudfront-JWT-AppId': JWT_APP_ID }
    }
  );
};

export const requestOtpChangeNumber = (phone) => {
  return axios.post(
    otpUrl,
    { mobile: phone, purpose: 'modifyPhoneNumber' },
    {
      headers: { 'Cloudfront-JWT-AppId': JWT_APP_ID }
    }
  );
};

export function updatePhoneNumber(payload) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const id = state.auth.user.profile.id;

    // dispatch({ type: 'UPDATE_PROFILE' });
    try {
      await axios.post(`${profileUrl}/${id}/updatePhoneNumber`, payload, {
        headers: {
          Authorization: token
        }
      });

      // dispatch({
      //   type: 'UPDATE_PROFILE_SUCCESS',
      // });
    } catch (err) {
      // if (err.response && err.response.data) {
      //   dispatch({
      //     type: 'UPDATE_PROFILE_FAILURE',
      //     err: err.response.data.error.message,
      //   });
      // }
      throw new Error(err);
    }
  };
}

export function postConsent() {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      await axios.post(
        consentUrl,
        {
          accepted: state.auth.consentSelection
        },
        {
          headers: {
            Authorization: state.auth.token
          }
        }
      );
      dispatch({
        type: 'CLOSE_CONSENT_POPUP'
      });
    } catch (err) {
      throw new Error(err);
    }
  };
}

export function setProfileConsent(payload) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      await axios.post(
        consentUrl,
        {
          accepted: payload
        },
        {
          headers: {
            Authorization: state.auth.token
          }
        }
      );
    } catch (err) {
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      throw err;
    }
  };
}

export function getConsent() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    if (token) {
      try {
        const res = await axios.get(`${consentUrl}/latest`, {
          headers: {
            Authorization: token
          }
        });
        return res;
      } catch (err) {
        if (err?.response?.status === 404) {
          dispatch({
            type: 'OPEN_CONSENT_POPUP'
          });
        } else {
          dispatch(
            showToastNotif(
              'error',
              'Some error occured, please refresh the page!'
            )
          );
        }
      }
    }
  };
}

export function selectConsent(payload) {
  return (dispatch) => {
    dispatch({
      type: 'CONSENT_SELECT',
      payload
    });
  };
}

export const verifyEmail = (token) => {
  return axios.post(
    verifyUrl,
    {
      token
    },
    {
      headers: { 'Cloudfront-JWT-AppId': JWT_APP_ID }
    }
  );
};

export function loginByPhone(phone, otp) {
  return async (dispatch, getState) => {
    const state = getState();
    const consentSelection = state.auth.consentSelection;
    try {
      dispatch({
        type: 'LOGIN'
      });

      const res = await axios.post(
        sessionURLv1_2,
        {
          type: 'mobile',
          username: phone,
          otp,
          device: generateDeviceInfo()
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      const paramsEvent = {
        time: new Date().getTime(),
        userID: res.data.data.account.userId
      };
      const user = { ...res.data.data, telco: null };
      analytic(
        analyticTypes.event,
        analyticEvents.AUTH.COMPLETE_LOGIN_BY_PHONE,
        {
          params: paramsEvent,
          user
        }
      );
      await initFbPixelUserData(user);

      await dispatch(validateSession(user?.isNewDevice, user?.accessToken));
      dispatch({
        type: 'LOGIN_SUCCESS',
        data: res.data.data,
        msisdn: phone
      });
      // dispatch(getAccountInfo());
      if (consentSelection === null) {
        await dispatch(getConsent()); // dont call this if consentSelection is not null
      } else {
        await dispatch(postConsent());
      }
      dispatch(getInAppNotificationSummary());
    } catch (err) {
      const errorData = generateErrorDetail(err);
      const code = errorData.code;
      let mes = '';

      if (
        code === errorCode.INVALID_CREDENTIAL ||
        code === errorCode.INVALID_NUMBER
      ) {
        mes = 'Incorrect mobile number';
      } else if (
        code === errorCode.INVALID_PARAMETERS ||
        code === errorCode.INVALID_OTP
      ) {
        mes = 'Incorrect OTP code';
      } else if (
        code === errorCode.TOO_MANY_ATTEMPTS ||
        code === errorCode.TOO_QUICK_RETRY
      ) {
        mes = 'Too many attempts, please try again later';
      } else {
        mes = errorData.message || 'General Login Error';
      }

      dispatch({
        type: 'LOGIN_FAILURE',
        err: mes
      });
      throw err;
    }
  };
}

// export const unBlockUser = (accountId) => async (dispatch, getState) => {
//   const state = getState();
//   dispatch({ type: 'SET_UNBLOCK_USER' });

//   try {
//     const res = await axios.delete(`${blockUrl}`, {
//       data: { blockTo: accountId },
//       headers: {
//         Authorization: state.auth.token
//       }
//     });
//     dispatch({ type: 'SET_UNBLOCK_USER_SUCCESS' });
//     return res;
//   } catch (err) {
//     const errorData = generateErrorDetail(err);
//     dispatch(
//       showToastNotif('error', 'Some error occured, please refresh the page!')
//     );
//     dispatch({
//       type: 'SET_UNBLOCK_USER_FAILED',
//       err: errorData
//     });
//   }
// };

// export const blockUser = (accountId) => async (dispatch, getState) => {
//   const state = getState();

//   dispatch({ type: 'SET_BLOCK_USER' });
//   try {
//     const res = await axios.post(
//       blockUrl,
//       {
//         blockTo: accountId
//       },
//       {
//         headers: {
//           Authorization: state.auth.token
//         }
//       }
//     );
//     dispatch({ type: 'SET_BLOCK_USER_SUCCESS' });
//     return res;
//   } catch (err) {
//     dispatch({ type: 'SET_BLOCK_USER_FAILED' });
//     const errorData = generateErrorDetail(err);
//     // console.log(errorData);
//     throw errorData;
//   }
// };

// /**
//  *
//  * @param {*} blockBy Account ID of user who block
//  * @param {*} blockTo Account ID of user who get blocked
//  * @param {*} profileId Profile ID of user who get blocked
//  * @returns
//  */
// export const isBlockBlockedProfile =
//   (blockTo) => async (dispatch, getState) => {
//     const state = getState();

//     const { user } = state.auth;
//     const accountId = user?.account?.id;
//     try {
//       const res = await axios.get(
//         `${BASE_CONFIG_URL}/user/v1.1/blocks/isblock?blockBy=${accountId}&blockTo=${blockTo}`,
//         {
//           headers: {
//             Authorization: state.auth.token
//           }
//         }
//       );

//       if (res.status === 204) return;
//       if (res?.data?.data && res?.status === 200) {
//         return res.data;
//       }
//     } catch (err) {
//       const errorData = generateErrorDetail(err);
//       // console.log(errorData);
//       throw errorData;
//     }
//   };

// export const getBlockedProfileList = () => async (dispatch, getState) => {
//   const state = getState();
//   const {
//     blockedProfileList,
//     blockUser: blockUserStat,
//     unBlockUser: unBlockUserStat
//   } = state.publicProfile;

//   dispatch({ type: 'GET_BLOCKED_PROFILE_LIST' });

//   const getResBlockedPofileList = async () => {
//     return axios.get(`${blockedProfilesUrl}`, {
//       headers: {
//         Authorization: state.auth.token
//         // 'Cloudfront-JWT-ProfileId': JWT_APP_ID
//       }
//     });
//   };

//   try {
//     if (!blockedProfileList.length || blockUserStat || unBlockUserStat) {
//       const res = await getResBlockedPofileList();
//       dispatch({
//         type: 'GET_BLOCKED_PROFILE_LIST_SUCCESS',
//         payload: res.data.data
//       });
//     } else {
//       dispatch({
//         type: 'GET_BLOCKED_PROFILE_LIST_SUCCESS_FROM_STATE',
//         payload: blockedProfileList
//       });
//     }
//   } catch (err) {
//     const errorData = generateErrorDetail(err);
//     dispatch(
//       showToastNotif('error', 'Some error occured, please refresh the page!')
//     );
//     dispatch({
//       type: 'GET_BLOCKED_PROFILE_LIST_FAIL',
//       err: errorData
//     });

//     throw errorData;
//   }
// };

export function getPopularStreamersList() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;
    const popularStreamers = state.auth.popularStreamers;
    const userId = state.auth.user ? state.auth.user.profile.id : null;
    const query = userId ? `?viewerId=${userId}` : '';
    dispatch({ type: 'GET_POPULAR_STREAMER_LIST' });

    try {
      if (popularStreamers.length < 1) {
        const res = await axios.get(
          `${popularStreamersUrl}${query}`,
          {},
          {
            headers: {
              Authorization: token
            }
          }
        );

        dispatch({
          type: 'GET_POPULAR_STREAMER_LIST_SUCCESS',
          data: res.data.data
        });

        if (res?.data?.data) {
          return res.data.data;
        }
      } else {
        dispatch({
          type: 'GET_POPULAR_STREAMER_LIST_SUCCESS_FROM_STATE',
          data: popularStreamers
        });
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      if (errorData) {
        dispatch({
          type: 'GET_POPULAR_STREAMER_LIST_FAILURE',
          err: errorData.message
        });
      }
    }
  };
}

export const reportUserOrContent =
  ({ idReportee, type, reason, idEntity, valueEntity, titleCase, idCase }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;

    const { email } = user.account;
    try {
      const res = await axios.post(
        reportUrl,
        {
          profileIdReportee: idReportee || '',
          idEntity: idEntity || '',
          valueEntity: valueEntity || email,
          type: type || 'user',
          reason: reason || '',
          titleCase: titleCase || null,
          idCase: idCase || null
        },
        {
          headers: {
            Authorization: state.auth.token
          }
        }
      );

      return res;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      throw errorData;
    }
  };

export const setWatchHistoryBeforeLogin = (time) => async (dispatch) => {
  dispatch({
    type: 'SET_WATCH_TIME',
    data: time
  });
};

export const resetWatchHistoryBeforeLogin = () => async (dispatch) => {
  dispatch({
    type: 'RESET_WATCH_TIME'
  });
};

export const loginIOH =
  (token, client, msisdn = '') =>
  async (dispatch) => {
    try {
      const res = await axios.post(
        authIOH,
        {
          type: 'username',
          payload: token,
          device: generateDeviceInfo()
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );
      dispatch({
        type: 'LOGIN_SUCCESS',
        data: res.data.data,
        msisdn
      });
      return;
    } catch (err) {
      const errorData = generateErrorDetail(err);

      dispatch({
        type: 'LOGIN_FAILURE',
        err: errorData
      });
      throw err;
    }
  };
