import React from 'react';
import styles from './Error.module.css';

const Error = () => {
  return (
    <h1 className={styles['content']}>
      There's Something wrong. Please try again later.
    </h1>
  );
};

export default Error;
