import React from 'react';

import styles from './UploadProgressIndicator.module.css';

const UploadProgressIndicator = ({ value, isLoading }) => {
  const boxWidth = 100;
  const radius = 45;
  const diameter = 90;
  const circumference = 2 * Math.PI * radius;

  // define initial point of the circle path
  const pointX = boxWidth / 2;
  const pointY = (boxWidth - diameter) / 2;

  const draw = `M ${pointX} ${pointY}
  a ${radius} ${radius} 0 0 1 0 ${diameter}
  a ${radius} ${radius} 0 0 1 0 ${-diameter}`;

  const generateStroke = () => {
    if (isLoading) {
      const strokeDasharray = `40 ${circumference}`;
      return strokeDasharray;
    }

    const percentValue =
      value > 100 ? circumference : (value / 100) * circumference;
    const strokeDasharray = `${percentValue} ${circumference}`;

    return strokeDasharray;
  };

  return (
    <div className={styles['container']}>
      <svg
        viewBox='0 0 100 100'
        data-loading={isLoading}
      >
        <path d={draw} />
        <path
          d={draw}
          strokeDasharray={generateStroke()}
        />
      </svg>
      <div className={styles['percentage']}>
        <p>{value > 100 ? 100 : value}%</p>
      </div>
    </div>
  );
};

export default UploadProgressIndicator;
