import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { resetStreamKey } from '../../redux/actions/liveGame';
import { Button, Loading, Prompt, StreamerPlayer, StreamSetupModal } from '..';
import styles from './GameStreamDetail.module.css';
import { showToastNotif } from '../../redux/actions/notification';
import { updateHistory } from '../../redux/actions/history';
import icEdit from '../../assets/ic-edit-white.svg';
import copyButton from '../../assets/ic-copy-nobg.svg';
import icView from '../../assets/views-icon.svg';
import icViewDisabled from '../../assets/view-disabled-icon.svg';
import StreamTipsModal from './StreamTipsModal';

const GameStreamDetail = ({ setShowEditStreamInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    liveGameInfo,
    loadingBroadcast,
    rtmp,
    loadingRtmp,
    isLiveGameBroadcasting
  } = useSelector((state) => state.liveGame);
  const [showSetup, setShowSetup] = useState(false);
  const [showTipsModal, setShowTipsModal] = useState(false);
  const streamName = liveGameInfo && liveGameInfo.name;
  const streamDescription = liveGameInfo && liveGameInfo.description;
  const streamCategory = liveGameInfo && liveGameInfo.showCategory;
  // const streamGameTitle = liveGameInfo && liveGameInfo.gameTitle
  const streamThumbnail = liveGameInfo && liveGameInfo.thumbnail;
  const streamUrl = (rtmp && rtmp.url) || '';
  const streamKey = (rtmp && rtmp.key) || '';
  const serverUrlRef = useRef();
  const streamKeyRef = useRef();
  const previewRef = useRef();
  const [showStreamKey, setShowStreamKey] = useState(false);
  const [sessionId, setSessionId] = useState();
  // const [showLeaveMessage, setShowLeaveMessage] = useState(false);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue =
      'Your liveshow will be terminated, if you close this page. Are you sure to close the page?';
  };
  useEffect(() => {
    if (liveGameInfo && isLiveGameBroadcasting) {
      setSessionId(uuidv4());
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [liveGameInfo, isLiveGameBroadcasting, navigate, dispatch]);

  const handleCopyToClipboard = (text) => {
    if (text) {
      navigator.clipboard.writeText(text);
      dispatch(showToastNotif('success', 'Successfully copied to clipboard'));
    }
  }; // replace clipboard logic to not use execCommand

  const handleUpdateHistory = useCallback(
    (currentTime) => {
      const liveShow = liveGameInfo;
      try {
        const historyData = {
          mediaType: 'show',
          mediaSubType: 'show',
          mediaId: liveShow.id,
          position: currentTime,
          length: currentTime
        };

        dispatch(updateHistory(historyData, sessionId));
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    [liveGameInfo, dispatch, sessionId]
  );

  return (
    <>
      <div className={`${styles['detailWrapper']} ${styles['streamPreview']}`}>
        <div>
          <div className={styles['headerItem']}>
            <h3>Stream Preview</h3>
            {liveGameInfo && (
              <div
                className={styles['liveGameURL']}
                onClick={() =>
                  handleCopyToClipboard(previewRef.current.textContent)
                }
              >
                <p ref={previewRef}>
                  {liveGameInfo &&
                    `${window.location.origin}/liveshow/${liveGameInfo.id}`}
                </p>
                <img
                  className={styles['copyButton']}
                  src={copyButton}
                  alt='copy'
                />
              </div>
            )}
          </div>
          {isLiveGameBroadcasting && rtmp && rtmp.playbackUrl ? (
            <StreamerPlayer
              channelID={liveGameInfo.id}
              stream={rtmp.playbackUrl}
              rtmp={rtmp}
              isRelative
              handleUpdateHistory={handleUpdateHistory}
            />
          ) : (
            <div className={styles['screenPreview']}>
              <span>OFFLINE</span>
              <div>
                <p>
                  Click <b>'Start Streaming'</b>, then connect to streaming
                  software
                </p>
                <p
                  className={styles['setupButton']}
                  onClick={() => setShowSetup(!showSetup)}
                >
                  How to setup in OBS
                </p>
                <p
                  className={styles['setupButton']}
                  onClick={() => setShowTipsModal(!showTipsModal)}
                >
                  Liveshow Tips
                </p>
              </div>
            </div>
          )}
        </div>
        <div>
          <div className={styles['headerItem']}>
            <h3>Stream Info</h3>
            <Button
              color='#2e2e2e'
              icon={icEdit}
              className={styles['editButton']}
              onClick={setShowEditStreamInfo}
              disabled={isLiveGameBroadcasting}
            >
              Edit
            </Button>
          </div>
          <div className={styles['streamInfo']}>
            <div>
              <div>
                <p>Title</p>
                <p>{streamName || '-'}</p>
              </div>
              <div>
                <p>Description</p>
                <p>{streamDescription || '-'}</p>
              </div>
              <div>
                <p>Category</p>
                <p>{streamCategory ? streamCategory.name : '-'}</p>
              </div>
              {/* <div>
                <p>Game</p>
                <p>{streamGameTitle ? streamGameTitle : '-'}</p>
              </div> */}
            </div>
            <div>
              <p>Thumbnail</p>
              {streamThumbnail ? (
                <img
                  className={styles['thumbnailPreview']}
                  src={streamThumbnail}
                  alt='thumbnail'
                />
              ) : (
                <p>-</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['detailWrapper']}>
        {loadingRtmp ? (
          <Loading />
        ) : (
          <>
            <section>
              <h3>Server URL</h3>
              <div>
                <input
                  ref={serverUrlRef}
                  className={styles['editInput']}
                  defaultValue={streamUrl}
                />
                <Button
                  width='82px'
                  onClick={() =>
                    handleCopyToClipboard(serverUrlRef.current.value)
                  }
                >
                  Copy
                </Button>
              </div>
            </section>
            <section>
              <h3>Stream Key</h3>
              <div>
                <div className={styles['inputSection']}>
                  <input
                    ref={streamKeyRef}
                    className={styles['editInput']}
                    defaultValue={streamKey}
                    type={showStreamKey ? 'text' : 'password'}
                  />
                  <img
                    src={showStreamKey ? icView : icViewDisabled}
                    alt='view'
                    onClick={() => setShowStreamKey(!showStreamKey)}
                  />
                </div>
                <Button
                  width='82px'
                  onClick={() =>
                    handleCopyToClipboard(streamKeyRef.current.value)
                  }
                >
                  Copy
                </Button>
                <Button
                  width='82px'
                  color='#282828'
                  onClick={() => dispatch(resetStreamKey())}
                >
                  Reset
                </Button>
              </div>
            </section>
          </>
        )}
      </div>

      <StreamSetupModal
        rtmpInfo={rtmp}
        onCloseModal={() => setShowSetup(false)}
        open={showSetup}
      />
      <StreamTipsModal
        rtmpInfo={rtmp}
        onCloseModal={() => setShowTipsModal(false)}
        open={showTipsModal}
      />
      <Prompt
        when={liveGameInfo && isLiveGameBroadcasting}
        message='Your liveshow will be terminated, if you close this page. Are you sure to close the page?'
      />
      {/* {showLeaveMessage && (
        <div className={styles['modalWrapper']}>
          <div className={styles['modalContainer']}>
            <p>You cannot leave this page during the streaming</p>
            <div
              className={styles['modalButton']}
              onClick={() => setShowLeaveMessage(false)}
            >
              OK
            </div>
          </div>
        </div>
      )} */}

      {loadingBroadcast && (
        <div className={styles['modalWrapper']}>
          <div className={styles['modalContainer']}>
            <Loading />
            <p>
              {liveGameInfo &&
                (isLiveGameBroadcasting
                  ? 'End live stream'
                  : 'Start live stream')}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default GameStreamDetail;
