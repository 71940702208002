import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from '../../hooks';
import { addComment, sendBulletComment } from '../../redux/actions/liveShow';
import { getAccountInfo, authPopup } from '../../redux/actions/auth';

import styles from './CommentInput.module.css';

const emojis = [
  {
    label: 'Thumbs up',
    code: 0x1f44d
  },
  {
    label: 'Ok hand',
    code: 0x1f44c
  },
  {
    label: 'Love',
    code: 0x2764
  },
  {
    label: 'Smiling face with red eye',
    code: 0x1f60d
  },
  {
    label: 'Raising hand',
    code: 0x1f64c
  },
  {
    label: 'Smiling face with hearts',
    code: 0x1f970
  },
  {
    label: 'Smiling face with tears of joy',
    code: 0x1f602
  },
  {
    label: 'Fire',
    code: 0x1f525
  },
  {
    label: 'Eye',
    code: 0x1f440
  },
  {
    label: 'Face with star eye',
    code: 0x1f929
  }
];

const CommentInput = () => {
  const { bulletOption } = useSelector((state) => state.pack);
  const [comment, setComment] = useState('');
  const [isBullet, setBullet] = useState(false);
  const commentRef = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { webSocket } = useSelector((state) => state.liveShow);
  const { token } = auth;
  const [showIcon, setShowIcon] = useState(false);

  const bulletMessageCost = useMemo(() => {
    const bulletComemnt = bulletOption.filter(
      (bullet) => bullet.category.name === 'bullet'
    );
    if (bulletComemnt?.length > 0) {
      return parseInt(bulletComemnt[0].packs[0].price, 10);
    }
    return null;
  }, [bulletOption]);

  const sendComment = (e) => {
    if (e.keyCode === 13) {
      handleSendComment();
      setShowIcon(false);
      setBullet(false);
      setComment('');
    }
  };

  const handleSendComment = async () => {
    if (!comment.length) return;
    if (isBullet) {
      await dispatch(sendBulletComment(comment));
      await dispatch(getAccountInfo());
    } else {
      dispatch(addComment(webSocket, comment));
    }
  };

  const toggleBullet = () => {
    setBullet((bullet) => !bullet);
    commentRef.current.focus();
  };

  // const sendIcon = useCallback(
  //   (code) => {
  //     dispatch(addComment(webSocket, code))
  //   },
  //   [dispatch, webSocket]
  // )

  return (
    <div className={styles['commentInput']}>
      {isBullet && (
        <div className={styles['bulletMessage']}>
          <span
            className={`${styles['emotToggle']} ${styles['bulletIcon']}`}
            onClick={toggleBullet}
          />
          <span>{bulletMessageCost} Diamond per Bullet Message</span>
        </div>
      )}
      {showIcon && (
        <div className={`${styles['emoteModal']}`}>
          {/* <div className={styles['emotList']}> */}
          {emojis.map((emoji, key) => {
            const code = String.fromCodePoint(emoji.code);
            return (
              <span
                onClick={() => setComment(comment + code)}
                key={key}
              >
                {code}
              </span>
            );
          })}
          {/* </div> */}
        </div>
      )}
      <div className={styles['commentForm']}>
        {token ? (
          <>
            <div
              className={styles['inputBox']}
              style={{
                display: 'flex'
              }}
            >
              <input
                className={styles['commentInput']}
                type='text'
                placeholder='Say hi..'
                onChange={(e) => setComment(e.target.value)}
                onKeyUp={sendComment}
                value={comment}
                ref={commentRef}
              />
              {!isBullet && (
                <span
                  className={`${styles['emotToggle']} ${styles['emotSmile']}`}
                  onClick={() => setShowIcon(!showIcon)}
                />
              )}
            </div>
            {/* {isBullet ? (
              <span
                className={`${styles['emotToggle']} ${styles['bulletActive']} ${styles['bulletIcon']}`}
                onClick={toggleBullet}
              />
            ) : (
              <span
                className={`${styles['emotToggle']} ${styles['bulletInactive']} ${styles['bulletIcon']}`}
                onClick={toggleBullet}
              />
            )} */}
          </>
        ) : (
          <div
            className={styles['loginButton']}
            onClick={() => {
              if (!token) {
                dispatch(authPopup.show());
              }
            }}
          >
            Login to join chat
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(CommentInput);
