import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';

export const getAppSettings = (keys) => async (dispatch, getState) => {
  const state = getState();
  const token = state.auth.token;

  const params = keys && keys.map((key, i) => `keys[${i}]=${key}`).join('&');

  dispatch({
    type: 'GET_APP_SETTINGS'
  });
  try {
    const res = await axios.get(
      `${BASE_URL}/screen/v1.0/settings${params ? `?${params}` : ''}`,
      {
        headers: {
          Authentication: token,
          'cloudfront-jwt-appid': JWT_APP_ID
        }
      }
    );

    dispatch({
      type: 'GET_APP_SETTINGS_SUCCESS',
      payload: res.data.data
    });

    const settings = res.data.data;
    const dataClipsCategories =
      settings &&
      settings['clips.categories'] &&
      settings['clips.categories'].split(',');
    dataClipsCategories && dataClipsCategories.unshift('All');

    dispatch({
      type: 'SET_CLIPS_PLAYLIST_CATEGORIES',
      payload: dataClipsCategories
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'SET_CLIPS_PLAYLIST_CATEGORIES',
      payload: ['All']
    });
    dispatch({
      type: 'GET_APP_SETTINGS_FAILED',
      err: errorData
    });
  }
};

export const setCaptionSettings = (payload) => (dispatch) => {
  dispatch({
    type: 'SET_CAPTION_SETTINGS',
    payload
  });
};

export const resetCaptionSettings = () => (dispatch) => {
  dispatch({
    type: 'RESET_CAPTION_SETTINGS'
  });
};
