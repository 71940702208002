import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import diamondIcn from '../../assets/diamonds.svg';
import gencashIcn from '../../assets/gencash.svg';
import userIcn from '../../assets/ic-user.svg';
import logoutIcn from '../../assets/ic-logout.svg';
import icCrown from '../../assets/ic-crown.svg';
import TopupGuideline from './TopupGuideline';
// import CashoutGuideline from './CashoutGuideline'

import styles from './ProfileDialog.module.css';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import { withStandByLoaderTabs } from '../../pages/profile';

const formatAmount = (amount) => {
  const amountString = amount.toString();
  const amountLength = amountString.length;

  if (amountLength > 9) {
    return `${(amountString / 1000000000).toFixed(1)} B`;
  }
  if (amountLength > 6) {
    return `${(amountString / 1000000).toFixed(1)} M`;
  }
  if (amountLength > 3) {
    return `${(amountString / 1000).toFixed(1)} K`;
  }
  return amountString;
};

const ProfileDialog = () => {
  const dispatch = useDispatch();
  const { user, loggingOut } = useSelector((state) => state.auth);
  const { profile, account } = user;
  const [topupOpen, setTopupOpen] = useState(false);
  const [cashoutMaintenance, setCashoutMaintenance] = useState(false);
  // const [cashoutOpen, setCashoutOpen] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const isPremium =
    account.subscriptions &&
    account.subscriptions.find((item) => item.packageGroup.includePremium);

  useEffect(() => {
    analytic(analyticTypes.trackPage, 'Profile', { user });
  }, [user]);

  const initial = useMemo(() => {
    return (
      profile &&
      profile.name
        .split(' ')
        .slice(0, 2)
        .map((n) => n.charAt(0).toUpperCase())
        .reduce((n, space) => n + space)
    );
  }, [profile]);

  const handleLogout = () => {
    if (!loggingOut) {
      dispatch(logout('refresh'));

      if (
        withStandByLoaderTabs.includes(
          location.pathname.replace('/profile', '')
        )
      ) {
        navigate('/');
      }
    }
  };

  const onTopUpOpen = () => {
    setTopupOpen(true);
    analytic(analyticTypes.event, analyticEvents.DIAMONDS.TOPUP_DIAMOND, {
      user
    });
  };

  return (
    <>
      {/* <CashoutGuideline open={cashoutOpen} dismiss={() => setCashoutOpen(false)}/> */}
      <TopupGuideline
        open={topupOpen}
        header={
          cashoutMaintenance
            ? "Can't cash out at the moment"
            : "Can't top up diamonds at the moment"
        }
        maintenance
        dismiss={() => {
          setTopupOpen(false);
          setCashoutMaintenance(false);
        }}
      />
      <div className={styles['profile-wrapper']}>
        <div className={styles['avatarBox']}>
          {profile.avatar && (
            <img
              className={styles['avatar']}
              alt='profile_pic'
              src={profile.avatar}
              width='100'
              height='100'
            />
          )}
          {!profile.avatar && (
            <span className={styles['avatar']}>{initial}</span>
          )}
          {isPremium && (
            <img
              className={styles['premiumIcon']}
              src={icCrown}
              width='30'
              alt='premium-icon'
            />
          )}
        </div>
        <div>
          <h3>{profile.name}</h3>
        </div>
        <div className={styles['packWrapper']}>
          <div>
            <span>
              <img
                src={diamondIcn}
                alt='diamonds'
              />
              {formatAmount(account.diamonds) || 0}
            </span>
            <span>Diamonds</span>
            <section
              className={styles['topup']}
              onClick={() => onTopUpOpen()}
            >
              <span>Top up</span>
            </section>
          </div>
          <div>
            <span>
              <img
                src={gencashIcn}
                alt='diamonds'
              />
              {formatAmount(account.gencash) || 0}
            </span>
            <span>Gencash</span>
            <section
              className={styles['cashout']}
              onClick={() => {
                // navigate('/profile/cashout')
                setCashoutMaintenance(true);
                setTopupOpen(true);
              }}
            >
              <span>Cash out</span>
            </section>
          </div>
        </div>
        <div className={styles['buttonWrapper']}>
          <div onClick={() => navigate('/profile')}>
            <img
              width={20}
              height={20}
              src={userIcn}
              alt='user icon'
            />{' '}
            Account
          </div>
          <div onClick={handleLogout}>
            <img
              width={20}
              height={20}
              src={logoutIcn}
              alt='Logout icon'
            />{' '}
            Log out
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDialog;
