import React from 'react';
import styles from './spinner.module.css';
import spinner from '../../assets/ic-spinner-loader.svg';

const Spinner = ({ style, className, height }) => {
  return (
    <div
      className={`${styles['spinner-wrapper']} ${className || ''}`}
      style={{ ...style, height }}
    >
      <div className={`${styles['spinner-ring']}`}>
        <img
          className={styles['spinner']}
          src={spinner}
          alt='loading...'
        />
      </div>
    </div>
  );
};

export default Spinner;
