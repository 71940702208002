import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './UserConsent.module.css';

import { selectConsent } from '../../redux/actions/auth';

const ConsentCheckbox = ({ language = 'en' }) => {
  const dispatch = useDispatch();
  const consentSelection = useSelector((state) => state.auth.consentSelection);

  const consentText = {
    id: {
      paragraph:
        'Genflix memberikan layanan tambahan kepada Pengguna/Calon Pengguna sehingga dapat turut menikmati berbagai informasi terkini dari layanan/ produk, promosi, pemberitaan yang dipersonalisasi dari Genflix ataupun afiliasi yang telah bekerjasama. \n\nAtas hal tersebut, Genflix ataupun afiliasinya menjalankan aktifitas marketing/penawaran dan layanan tambahan lainnya dengan memanfaatkan informasi yang telah diberikan Pengguna/Calon Pengguna pada layanan/produk yang digunakan. \n\nPengguna/Calon Pengguna dapat mengajukan penarikan persetujuan penggunaan tertentu sebagaimana tersebut diatas melalui cabang yang ditentukan.',
      consent_yes:
        'Ya, saya ingin mengikuti layanan tambahan dan mengijinkan untuk menggunakan informasi yang telah saya berikan.',
      consent_no: 'Tidak, terimakasih. Saya melewatkan penawaran ini.'
    },
    en: {
      paragraph:
        'Genflix provides additional services to Consumers/Prospective customers can also enjoy a variety of information the latest services/products, promotions, news personalized from Genflix or existing affiliate cooperate. Due to this, Genflix or its affiliates carry out marketing activities/offers and additional services others by utilizing the information that has been provided Customers/Prospective Consumers on the services/products used. Consumers/Prospective Consumer can apply for a withdrawal certain use approval as mention above through specified branch.',
      consent_yes:
        'Yes, I would like to participate in additional services and allow you to use the information I have provided.',
      consent_no: 'No, thank you. I missed this offer'
    }
  };

  const handleCheckboxChange = (value) => {
    dispatch(selectConsent(value));
  };

  return (
    <div className={styles['checkbox-container']}>
      <p>{consentText[language].paragraph}</p>
      <div className={styles['round']}>
        <div className={styles['checkboxWrapper']}>
          <input
            type='checkbox'
            id='yes'
            checked={consentSelection === true}
            onChange={() => handleCheckboxChange(true)}
          />
          <label htmlFor='yes' />
        </div>
        <span>{consentText[language].consent_yes}</span>
      </div>
      <div className={styles['round']}>
        <div className={styles['checkboxWrapper']}>
          <input
            type='checkbox'
            checked={consentSelection === false}
            onChange={() => handleCheckboxChange(false)}
            id='no'
          />
          <label htmlFor='no' />
        </div>
        <span>{consentText[language].consent_no}</span>
      </div>
    </div>
  );
};

export default ConsentCheckbox;
