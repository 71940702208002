import { lazy } from 'react';

const Home = lazy(() => import(/* webpackChunkName: "home" */ './home'));

const History = lazy(
  () => import(/* webpackChunkName: "history" */ './history')
);

const PromoPage = lazy(
  () => import(/* webpackChunkName: "promoPage" */ './promo')
);

const PromoDetailPage = lazy(
  () => import(/* webpackChunkName: "promoPage" */ './promo/PromoDetail')
);

const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "forgot_password" */ './forgot-password')
);

const ResetPassword = lazy(
  () => import(/* webpackChunkName: "reset_password" */ './reset-password')
);

const Playlist = lazy(
  () => import(/* webpackChunkName: "playlist" */ './playlist')
);
const MediaPageWrapper = lazy(
  () =>
    import(/* webpackChunkName: "media_page_wrapper" */ './mediaPageWrapper')
);
// const Live = lazy(
//   () => import(/* webpackChunkName: "live_tv" */ './live/live')
// );
const LiveTv = lazy(
  () => import(/* webpackChunkName: "live_show" */ './live/liveTv')
);
const Interactive = lazy(
  () => import(/* webpackChunkName: "interactive" */ './live/interactive')
);
const Detail = lazy(() => import(/* webpackChunkName: "detail" */ './detail'));

const Subscription = lazy(
  () => import(/* webpackChunkName: "subscription" */ './subscription')
);
const Voucher = lazy(
  () => import(/* webpackChunkName: "voucher" */ './voucher')
);
const Search = lazy(() => import(/* webpackChunkName: "search" */ './search'));
const Payment = lazy(
  () => import(/* webpackChunkName: "payment" */ './payment')
);
const PaymentComplete = lazy(
  () => import(/* webpackChunkName: "payment-complete" */ './payment-complete')
);
const AboutUs = lazy(
  () => import(/* webpackChunkName: "about_us" */ './about-us')
);
const Terms = lazy(() => import(/* webpackChunkName: "terms" */ './terms'));
const TermsCoins = lazy(
  () => import(/* webpackChunkName: "terms" */ './terms/termsCoins')
);
const PrivacyPolicy = lazy(
  () => import(/* webpackChunkName: "terms" */ './privacyPolicy')
);
const Faqs = lazy(() => import(/* webpackChunkName: "faqs" */ './faqs'));
const ContactUs = lazy(
  () => import(/* webpackChunkName: "contact_us" */ './contact-us')
);
const UserLicenseAgreement = lazy(
  () =>
    import(
      /* webpackChunkName: "user_license_agreement" */ './userLicenseAgreement'
    )
);
const EmailVerification = lazy(
  () =>
    import(/* webpackChunkName: "email_verification" */ './email-verification')
);
const ProfileRoutes = lazy(
  () => import(/* webpackChunkName: "user_profile" */ './profile')
);
const Stream = lazy(() => import(/* webpackChunkName: "stream" */ './stream'));

// const LiveShowStream = lazy(
//   () => import(/* webpackChunkName: "live show stream" */ './liveShowStream')
// );
const Cashout = lazy(
  () => import(/* webpackChunkName: "cashout" */ './cashout')
);
// const PublicProfile = lazy(
//   () => import(/* webpackChunkName: "public_profile" */ './public-profile')
// );

// const LiveShowPlaylist = lazy(
//   () =>
//     import(/* webpackChunkName: "live show playlist" */ './liveShowPlaylist')
// );
const PreviousLiveShowPage = lazy(
  () =>
    import(
      /* webpackChunkName: "previous live show playlist" */ './liveShowPlaylist/previousLiveShowPlaylist'
    )
);
// const LiveShow = lazy(
//   () => import(/* webpackChunkName: "live show list" */ './live/liveShow')
// );
// const LiveShowVodStream = lazy(
//   () => import(/* webpackChunkName: "live show list" */ './liveShowVodStream')
// );
const StreamerDashboard = lazy(
  () =>
    import(/* webpackChunkName: "streamer dashboard" */ './streamer-dashboard')
);
const PartnerPlayer = lazy(
  () => import(/* webpackChunkName: "streamer dashboard" */ './partner-player')
);

// const ClipsPlaylist = lazy(
//   () => import(/* webpackChunkName: "clips playlist" */ './clipsPlaylist')
// );
// const ClipsStream = lazy(
//   () => import(/* webpackChunkName: "clips stream" */ './clipsStream')
// );

const NotFound = lazy(
  () => import(/* webpackChunkName: "not_found" */ './not-found')
);

const Shop = lazy(() => import(/* webpackChunkName: "shop" */ './shop'));

const CampaignDetail = lazy(
  () => import(/* webpackChunkName: "campaign_detail" */ './campaign')
);

const QuizDetail = lazy(
  () => import(/* webpackChunkName: "campaign_detail" */ './quiz')
);

const MobileAppWrapper = lazy(
  () =>
    import(
      /* webpackChunkName: "MobileAppWrapper" */ './appWrapper/mobileAppWrapper'
    )
);
const TermsCashout = lazy(
  () => import(/* webpackChunkName: "terms-cashout" */ './terms/termsCashout')
);

const Notifications = lazy(
  () => import(/* webpackChunkName: "terms-cashout" */ './notifications')
);
const TermsUgc = lazy(
  () => import(/* webpackChunkName: "terms-cashout" */ './terms/termsUgc')
);
const MaintenancePage = lazy(
  () => import(/* webpackChunkName: "terms-cashout" */ './maintenance')
);
const NotificationDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "NotificationDetail" */ './notifications/NotificationDetail'
    )
);
const CampaignUgcDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "NotificationDetail" */ './campaign/campaignUgcDetail'
    )
);
const PlayPage = lazy(
  () => import(/* webpackChunkName: "PlayPage" */ './partner-player/playPage')
);
const FormPage = lazy(
  () => import(/* webpackChunkName: "FormPage" */ './form')
);

export { default as AppWrapper } from './appWrapper';
export {
  Home,
  History,
  MediaPageWrapper,
  Playlist,
  LiveTv,
  Detail,
  Subscription,
  Voucher,
  Search,
  Payment,
  PaymentComplete,
  ForgotPassword,
  ResetPassword,
  NotFound,
  AboutUs,
  Terms,
  TermsCoins,
  PrivacyPolicy,
  Faqs,
  ContactUs,
  EmailVerification,
  ProfileRoutes,
  Stream,
  // LiveShowStream,
  Cashout,
  // LiveShowPlaylist,
  // PublicProfile,
  // LiveShow,
  // Live,
  Interactive,
  StreamerDashboard,
  PartnerPlayer,
  // LiveShowVodStream,
  // ClipsPlaylist,
  // ClipsStream,
  UserLicenseAgreement,
  Shop,
  CampaignDetail,
  MobileAppWrapper,
  TermsCashout,
  TermsUgc,
  MaintenancePage,
  PreviousLiveShowPage,
  QuizDetail,
  Notifications,
  NotificationDetail,
  CampaignUgcDetail,
  PromoPage,
  PromoDetailPage,
  PlayPage,
  FormPage
};
