import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import analytic, { analyticTypes } from '../../service/analytic';
import { formatDateV2 } from '../../utils';
import DialogSlider from './DialogSlider';
import styles from './NotificationDialog.module.css';
import NotifcationListItem from './NotificationListItem';
import NotificationBadge from '../../assets/NotificationBadge.svg';
import {
  getInAppNotification,
  getInAppNotificationHighlight,
  toggleDialogNotif
} from '../../redux/actions/inAppNotification';
import Button from '../Button/Button';

const NotificationDialog = () => {
  const inAppNotification = useSelector((state) => state.inAppNotification);
  const dispatch = useDispatch();
  const { data, error, errorHighlight, dataHighlight } = inAppNotification;
  const todayDate = formatDateV2(new Date()).split(' ').slice(2).join(' ');
  const yesterdayDate = formatDateV2(
    new Date().setDate(new Date().getDate() - 1)
  )
    .split(' ')
    .slice(2)
    .join(' ');
  const [sortedNotificationData, setSortedNotificationData] = useState({});

  useEffect(() => {
    async function fetchDatas() {
      analytic(analyticTypes.trackPage, 'NotificationPage');
      await dispatch(getInAppNotification(1, 20));
      await dispatch(getInAppNotificationHighlight());
    }
    if (!error && !errorHighlight) {
      fetchDatas();
    }
  }, [dispatch, error, errorHighlight]); // check if error has occured so hopefully it won't fetch infinitely

  useEffect(() => {
    if (data.length) {
      setSortedNotificationData(groupAndSortByTimePeriod(data, 'startDate'));
    }
  }, [data]);

  let groupAndSortByTimePeriod = function (obj, timestamp) {
    let objPeriod = {};
    for (let i = 0; i < obj.length; i++) {
      const epochDate = obj[i]['common'][timestamp];
      let newKey = '';
      if (
        obj[i]['common'].title !== '' ||
        obj[i]['common'].startDate !== '' ||
        obj[i]['common'].endDate !== ''
      ) {
        if (epochDate.toString().length === 13) {
          newKey = formatDateV2(epochDate, 'dd mmm yyyy').trim();
          objPeriod[newKey] = objPeriod[newKey] || [];
          objPeriod[newKey].push(obj[i]);
        }
      }
    }

    objPeriod = Object.keys(objPeriod)
      .sort()
      .reverse()
      .reduce((objItem, key) => {
        objItem[key] = objPeriod[key];
        return objItem;
      }, {});

    objPeriod = Object.keys(objPeriod)
      .sort(function (a, b) {
        const keyA = new Date(a);
        const keyB = new Date(b);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      })
      .reduce((objItem, key) => {
        objItem[key] = objPeriod[key];
        return objItem;
      }, {});
    return objPeriod;
  };

  const navigate = useNavigate();

  return (
    <div className={styles['notification-wrapper']}>
      <div className={styles['title']}>Notification</div>
      {error || errorHighlight ? (
        <>
          <div className={styles['no-notification']}>
            Trouble Getting notifications. please try again later.
          </div>
          <Button
            onClick={async () => {
              await dispatch(getInAppNotification(1, 20));
              await dispatch(getInAppNotificationHighlight());
            }}
            disabled={
              inAppNotification.loading || inAppNotification.loadingHighlight
            }
            type='text'
            className={styles['seeAllButton']}
          >
            Retry
          </Button>
        </>
      ) : (
        <div className={styles['scrollable']}>
          {dataHighlight && dataHighlight.length > 0 && (
            <>
              <div className={styles['titleWrapper']}>Highlights</div>
              <div className={styles['notifSlider']}>
                <DialogSlider dataHighlight={dataHighlight} />
              </div>
            </>
          )}
          {sortedNotificationData &&
          Object.keys(sortedNotificationData)?.length > 0
            ? Object.keys(sortedNotificationData).map((item, index) => {
                const today = item === todayDate && 'Today';
                const yesterday = item === yesterdayDate && 'Yesterday';

                return (
                  <div key={index}>
                    <div className={styles['titleWrapper']}>
                      {today || yesterday || item}
                    </div>
                    {sortedNotificationData[item].map(
                      (subItem, subItemIndex) => {
                        return (
                          <div key={subItemIndex}>
                            <NotifcationListItem
                              notificationDetails={subItem.common}
                              type='dialog'
                              read={subItem.isRead}
                              key={subItemIndex}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              })
            : !error && (
                <div className={styles['no-notification']}>
                  <img
                    src={NotificationBadge}
                    alt='icon'
                    data-icon-name='user-profile-icon'
                    style={{ width: '80px', marginTop: '150px' }}
                  />
                  <div className={styles['no-notification']}>
                    No Notification
                  </div>
                  <div className={styles['no-notification-desc']}>
                    There are no notifications at the moment
                  </div>
                </div>
              )}
        </div>
      )}

      <div
        onClick={() => {
          dispatch(toggleDialogNotif());
          navigate('/notifications/');
        }}
        className={styles['seeAllButton']}
      >
        See All Notifications
      </div>
    </div>
  );
};

export default NotificationDialog;
