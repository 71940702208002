import axios from 'axios';
import { getValue } from 'firebase/remote-config';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';

import { configKeys, remoteConfig } from '../../service/firebase';
import { generateErrorDetail, getDeviceId } from '../../utils';
import { getPublicProfile } from './publicProfile';

const BASE_CONFIG_URL = getValue(
  remoteConfig,
  configKeys.baseURL['user']
).asString();

const tagsURL = '/catalog/v1.1/videos/createTag';
const metadataURL = '/catalog/v1.1/videos/createMetadata';
const playlistURL = '/catalog/v1.1/playlist';
const profilesURL = '/user/v1.1/profiles';
const videoURL = '/catalog/v1.1/video';
const recommendationsURL = '/recommendation/v1.0/videos';
const videoURLV0 = '/catalog/v1.0/video';

export const showCreateClips = {
  show: () => (dispatch) => dispatch({ type: 'SHOW_CLIPS_MODAL' }),
  hide: () => (dispatch) => dispatch({ type: 'HIDE_CLIPS_MODAL' })
};

export const createTags = (categories) => async (dispatch, getState) => {
  const state = getState();
  const { token, user } = state.auth;
  const formatCategories = categories.map((category) => {
    return {
      names: {
        1: category
      },
      type: 'genre'
    };
  });
  const data = {
    data: [
      ...formatCategories,
      {
        names: {
          1: 'clips'
        },
        type: 'type'
      },
      {
        names: {
          1: user && user.profile && user.profile.id
        },
        type: 'profile_id'
      }
    ]
  };

  dispatch({
    type: 'CREATE_TAGS'
  });

  try {
    const res = await axios.post(`${BASE_URL}${tagsURL}`, data, {
      headers: {
        Authorization: token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });

    const payload = res.data.data.results;
    dispatch({
      type: 'CREATE_TAGS_SUCCESS',
      payload
    });
    return payload;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'CREATE_TAGS_FAILED',
      err: errorData
    });
    throw err;
  }
};

export const craeteVideoMetadata =
  (clipsMetadata = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const token = state.auth.token;

    const { titles, tags, thumbnail, duration, campaignId } = clipsMetadata;
    const newTags = tags.map((tag) => Number(tag));
    const formData = new FormData();
    formData.append('titles', JSON.stringify(titles));
    formData.append('duration', duration);
    formData.append('thumbnail_landscape', thumbnail);
    formData.append('tags', JSON.stringify(newTags));
    campaignId && formData.append('ugcCampaignId', campaignId);

    dispatch({
      type: 'CREATE_CLIPS_METADATA'
    });
    try {
      const res = await axios.post(`${BASE_URL}${metadataURL}`, formData, {
        headers: {
          Authorization: token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      });

      const payload = res.data.data;
      dispatch({
        type: 'CREATE_CLIPS_METADATA_SUCCESS',
        payload
      });

      return payload;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'CREATE_CLIPS_METADATA_FAILED',
        err: errorData
      });
      throw err;
    }
  };

const getClipsPlaylistRequest =
  ({ id, page = 1, perPage = 10, category }) =>
  async (dispatch, getState) => {
    const formatCategory = encodeURIComponent(category);
    const categoryParam =
      category && category !== 'All' ? `&genres[0]=${formatCategory}` : '';
    const state = getState();
    const { user } = state.auth;

    const res = await axios.get(
      `${BASE_URL}${playlistURL}/${id}/items?locale=en&page=${page}&perPage=${perPage}${categoryParam}&accountId=${user?.account?.id}`,
      {
        headers: {
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      }
    );

    const clipsPlaylistData = res.data.data;
    const clipsPlaylistMeta = res.data.meta;

    const newClipsData =
      clipsPlaylistData && clipsPlaylistData.length
        ? await dispatch(
            generateClipsWithProfile(clipsPlaylistData, page, perPage)
          )
        : [];

    const newClipsPlaylist = {
      data: newClipsData,
      meta: clipsPlaylistMeta
    };

    return newClipsPlaylist;
  };

export const getClipsPlaylist =
  ({ id, page = 1, perPage = 10, category }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { clipsCategory } = state.clips;

    dispatch({
      type: 'GET_CLIPS_PLAYLIST',
      page
    });

    try {
      // if state clipsCategory empty array []
      if (clipsCategory[`${category}`]?.length < 1) {
        const newClipsPlaylist = await dispatch(
          getClipsPlaylistRequest({ id, page, perPage, category })
        );

        dispatch({
          type: 'GET_CLIPS_PLAYLIST_SUCCESS',
          payload: newClipsPlaylist,
          page,
          category
        });
      } else if (
        page === 1 &&
        clipsCategory[`${category}`]?.data.length !==
          clipsCategory[`${category}`]?.meta.total
      ) {
        // if state clipsCategory page === 1 have data but not completed
        dispatch({
          type: 'GET_CLIPS_PLAYLIST_SUCCESS_FROM_STATE',
          payload: state.clips,
          page,
          category
        });
      } else if (
        page > 1 &&
        clipsCategory[`${category}`]?.data.length !==
          clipsCategory[`${category}`]?.meta.total
      ) {
        // if state clipsCategory page > 1 have data but not completed
        const newClipsPlaylist = await dispatch(
          getClipsPlaylistRequest({ id, page, perPage, category })
        );

        dispatch({
          type: 'GET_CLIPS_PLAYLIST_SUCCESS',
          payload: newClipsPlaylist,
          page,
          category
        });
      } else if (
        clipsCategory[`${category}`]?.data.length ===
        clipsCategory[`${category}`]?.meta.total
      ) {
        // if state clipsCategory have data and completed
        dispatch({
          type: 'GET_CLIPS_PLAYLIST_SUCCESS_FROM_STATE',
          payload: state.clips,
          page,
          category
        });
      }
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_CLIPS_PLAYLIST_FAILED',
        err: errorData
      });
    }
  };

export const generateClipsWithProfile =
  (clipsPlaylist, page = 1, perPage = 10) =>
  async () => {
    try {
      const profileIds = clipsPlaylist
        .map((clips) => clips.tags.profileIds && clips.tags.profileIds[0])
        .filter((item) => item);

      const getProfiles = await axios.post(
        `${BASE_CONFIG_URL}${profilesURL}/getByProfileIds`,
        {
          profileIds
        },
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          },
          params: {
            page,
            perPage
          }
        }
      );

      const profilesData = getProfiles.data.data;
      const clipsPlaylistWithProfile = await clipsPlaylist.map((clips) => {
        const profile = profilesData.find(
          (item) =>
            clips.tags.profileIds && clips.tags.profileIds[0] === item.id
        );

        clips.type = 'clips';

        return {
          ...clips,
          profile
        };
      });

      return clipsPlaylistWithProfile;
    } catch (err) {
      throw new Error(err);
    }
  };

export const getRecommendedClips = (mediaId) => async (dispatch, getState) => {
  const state = getState();
  const { token, user } = state.auth;
  const accountId = user?.profile?.accountId;

  dispatch({
    type: 'GET_RECOMMENDED_CLIPS'
  });

  try {
    const res = await axios.get(`${BASE_URL}${recommendationsURL}`, {
      headers: {
        Authorization: token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      },
      params: {
        videoId: mediaId,
        deviceId: getDeviceId(),
        ...(token && user && { accountId })
      }
    });

    const clipsRecommended = res.data?.data || res.data?.data?.videos;
    const clipsWithProfile = clipsRecommended?.length
      ? await dispatch(
          generateClipsWithProfile(clipsRecommended, 1, clipsRecommended.length)
        )
      : [];
    const filteredRecommendation = clipsWithProfile
      .filter((item, index, self) => {
        if (index === 0) return true;
        return item.id !== self[0].id;
      })
      .filter((item, index, self) => {
        return (
          self.findIndex((findIndex) => findIndex.id === item.id) === index
        );
      })
      .slice(0, 10);

    dispatch({
      type: 'GET_RECOMMENDED_CLIPS_SUCCESS',
      payload: filteredRecommendation
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_RECOMMENDED_CLIPS_FAILED',
      err: errorData
    });
  }
};

export const getProfilesById =
  ({ profileIds, page = 1, perPage = 10 }) =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      const getProfiles = await axios.post(
        `${BASE_CONFIG_URL}${profilesURL}/getByProfileIds?page=${page}&perPage=${perPage}`,
        {
          profileIds
        },
        {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      return getProfiles.data.data;
    } catch (err) {
      throw new Error(err);
    }
  };

export const getClipsDetail = (clipsId) => async (dispatch, getState) => {
  const state = getState();
  const { user } = state.auth;

  dispatch({
    type: 'GET_CLIPS_DETAIL'
  });

  try {
    const res = await axios.get(`${BASE_URL}${videoURL}/${clipsId}`, {
      headers: {
        'Cloudfront-JWT-AppId': JWT_APP_ID,
        ...(user?.account?.id && { 'x-accountId': user?.account?.id || '' })
      }
    });

    const resClipsDetail = res.data?.data?.[0];

    if (resClipsDetail) {
      resClipsDetail.isLiked = resClipsDetail?.isLiked === true;
    }
    const profileId = resClipsDetail?.tags?.profileIds;
    const profileData = await dispatch(getPublicProfile(profileId));

    const clipsDetail = {
      ...resClipsDetail,
      profileData
    };

    dispatch({
      type: 'GET_CLIPS_DETAIL_SUCCESS',
      payload: clipsDetail
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_CLIPS_DETAIL_FAILED',
      err: errorData
    });
  }
};

export const changeClipsProfileFollow = (status) => (dispatch) => {
  dispatch({
    type: 'CHANGE_CLIPS_PROFILE_FOLLOW',
    payload: status
  });
};

export const resetClipsDetail = () => async (dispatch) => {
  dispatch({
    type: 'RESET_CLIPS_DETAIL'
  });
};

export const addVideoClipsView =
  (mediaId, data) => async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;

    dispatch({
      type: 'ADD_CLIPS_VIEW'
    });
    try {
      await axios.post(
        `${BASE_URL}${videoURL}/${mediaId}/${token ? 'view' : 'guestView'}`,
        data,
        {
          headers: {
            Authorization: token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );
      dispatch({
        type: 'ADD_CLIPS_VIEW_SUCCESS'
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'ADD_CLIPS_VIEW_FAILED',
        err: errorData
      });
    }
  };

export const sendClipsComment =
  ({ contentId, comment }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { token } = state.auth;

    return axios.post(
      `${BASE_URL}${videoURL}/${contentId}/addComment`,
      {
        comment
      },
      {
        headers: {
          Authorization: token,
          'Cloudfront-JWT-AppId': JWT_APP_ID
        }
      }
    );
  };

export const getClipsComments =
  ({ contentId, perPage = 10, latestComment }) =>
  async (dispatch) => {
    const latestCommentParam = latestComment
      ? `&latestCommentId=${latestComment}`
      : '';

    dispatch({
      type: 'GET_CLIPS_COMMENTS'
    });

    try {
      const res = await axios.get(
        `${BASE_URL}${videoURL}/${contentId}/getComment?perPage=${perPage}${latestCommentParam}`,
        {
          headers: {
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );

      dispatch({
        type: 'GET_CLIPS_COMMENTS_SUCCESS',
        payload: res.data,
        latestComment
      });
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch({
        type: 'GET_CLIPS_COMMENTS_FAILED',
        err: errorData
      });
    }
  };

export const getLikesDetails = (videoId) => async (dispatch, getState) => {
  const state = getState();
  const { token } = state.auth;

  dispatch({
    type: 'GET_CLIPS_LIKES_DETAIL'
  });
  try {
    const res = await axios.get(`${BASE_URL}${videoURLV0}/${videoId}/isliked`, {
      headers: {
        Authorization: token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });

    dispatch({
      type: 'GET_CLIPS_LIKES_DETAIL_SUCCESS',
      payload: res.data.data
    });
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_CLIPS_LIKES_DETAIL_FAILED',
      err: errorData
    });
  }
};

export const sendClipsLikesUnlikes =
  (videoId, type) => async (dispatch, getState) => {
    const state = getState();
    const { token, user } = state.auth;

    const likesType = type ? 'like' : 'unlike';

    const res = await axios.post(
      `${BASE_URL}${videoURLV0}/${videoId}/${likesType}`,
      {},
      {
        headers: {
          Authorization: token,
          'Cloudfront-JWT-AppId': JWT_APP_ID,
          'cloudfront-jwt-accountid': user ? user?.account?.id : ''
        }
      }
    );

    if (res) {
      // dispatch(getClipsDetail(videoId))

      // dispatch(getLikesDetails(videoId))
      return res;
    }
  };
