import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile, getAccountInfo } from '../../redux/actions/auth';
import { showToastNotif } from '../../redux/actions/notification';
import analytic, {
  analyticEvents,
  analyticTypes
} from '../../service/analytic';
import Button from '../Button/Button';
import Loading from '../Loading/Loading';

import styles from './profileForm.module.css';

const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

const ProfileForm = () => {
  const pic = useRef();
  const name = useRef();
  // const bio = useRef();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = auth.user;
  const [hasPicture, setHasPicture] = useState(false);
  const profile = auth.user && auth.user.profile;
  const loading = auth.loading;

  useEffect(() => {
    analytic(analyticTypes.trackPage, 'EditProfile', { user });
  }, [user]);

  const initial = useMemo(() => {
    return (
      profile &&
      profile.name
        .split(' ')
        .slice(0, 2)
        .map((n) => n.charAt(0).toUpperCase())
        .reduce((n, space) => n + space)
    );
  }, [profile]);

  useEffect(() => {
    name.current.value = profile.name;
    // bio.current.value = profile.bio;
  }, [profile]);

  const onClickProfile = () => {
    pic.current.click();
  };

  const onPicSelected = useCallback(() => {
    if (pic.current.files.length) {
      const image = pic.current.files[0];
      if (!allowedTypes.includes(image.type)) return;
      const picture = URL.createObjectURL(image);
      setHasPicture(picture);
    }
  }, [pic]);

  const mapErrors = (err) => {
    switch (err.code) {
      case 'gfx4000006':
        return 'The image format must be JPEG, PNG, or GIF and cannot exceed 10MB';
      default:
        return 'Something went wrong, please try again later';
    }
  };

  const onSubmit = useCallback(() => {
    const doUpdateProfile = async () => {
      const payload = new FormData();
      payload.set('name', name.current.value);
      // payload.set('bio', bio.current.value);
      pic.current.files[0] && payload.append('avatar', pic.current.files[0]);
      const res = await dispatch(updateProfile(profile.id, payload));
      if (!res.error) {
        dispatch(getAccountInfo());
        analytic(analyticTypes.event, analyticEvents.PROFILE.EDIT_PROFILE, {
          user
        });
        return dispatch(
          showToastNotif('success', 'Your profile has been updated')
        );
      }
      return dispatch(showToastNotif('error', mapErrors(res.error)));
    };
    doUpdateProfile();
  }, [pic, name, profile, dispatch, user]);

  const showAvatar = useMemo(() => {
    return !hasPicture && profile.avatar;
  }, [hasPicture, profile]);

  const showInitial = useMemo(() => {
    return !hasPicture && !profile.avatar;
  }, [hasPicture, profile]);

  return (
    <>
      <div className={styles['formWrapper']}>
        <div className={styles['formInput']}>
          <div className={styles['inputLabel']}>Profile Picture</div>
          <div>
            <div>
              {hasPicture && (
                <img
                  className={styles['avatar']}
                  alt='profile_pic'
                  src={hasPicture}
                  width='100'
                  height='100'
                />
              )}
              {showAvatar && (
                <img
                  className={styles['avatar']}
                  alt='profile_pic'
                  src={profile.avatar}
                  width='100'
                  height='100'
                />
              )}
              {showInitial && (
                <span className={styles['avatar']}>{initial}</span>
              )}
            </div>
            <section className={styles['uploader']}>
              <input
                onChange={onPicSelected}
                type='file'
                accept='image/x-png,image/gif,image/jpeg,image/png'
                capture='camera'
                hidden
                ref={pic}
              />
              <Button
                className={styles['formButton']}
                onClick={onClickProfile}
              >
                Change Profile Picture
              </Button>
              <span>Must be JPEG, PNG, or GIF and cannot exceed 10MB</span>
            </section>
          </div>
        </div>
        <div className={styles['formInput']}>
          <div className={styles['inputLabel']}>Name</div>
          <div>
            <input
              ref={name}
              type='text'
              maxLength='300'
            />
          </div>
        </div>
        <div className={styles['formInput']}>
          {/* <div className={styles['inputLabel']}>Bio</div> */}
          <div>
            <section className={styles['inputBio']}>
              {/* <textarea
                ref={bio}
                maxLength='300'
              /> */}
              <section className={styles['profileAction']}>
                {/* <span>Who you are in fewer than 300 characters</span> */}
                <Button
                  className={styles['formButton']}
                  disabled={loading}
                  onClick={onSubmit}
                >
                  {loading ? 'Updating...' : 'Save Changes'}
                </Button>
              </section>
            </section>
          </div>
        </div>
      </div>

      {loading && (
        <div className={styles['loadingContainer']}>
          <Loading />
        </div>
      )}
    </>
  );
};

export default ProfileForm;
