import React from 'react';
import { formatDate, getInitial } from '../../utils';
import user from '../../assets/ic-user.svg';
import viewsIcn from '../../assets/views-icon.svg';
import clockIcn from '../../assets/ic-clock.svg';
import styles from './LiveShowCollection.module.css';

const LiveShowOverlay = ({ item }) => {
  return (
    item && (
      <>
        <div className={styles['liveshowGradient']} />
        <div className={styles['item-header']}>
          {item?.type === 'liveshow-vod' ? (
            item?.finishedAt && (
              <div className={styles['date']}>
                <img
                  src={clockIcn}
                  alt='clock'
                />
                <span>{formatDate(item?.finishedAt)}</span>
              </div>
            )
          ) : (
            <>
              <div className={styles['live-indicator']}>
                <div className={styles['red-dot']} />
                <div>LIVE</div>
              </div>
              <div className={styles['info-badge']}>
                <img
                  src={viewsIcn}
                  alt='views-icon'
                  width='20'
                />
                <span className={styles['info-list']}>{item?.viewers}</span>
              </div>
            </>
          )}
        </div>
        <div className={styles['info-item']}>
          <div className={styles['title-item']}>
            <div className={styles['genre']}>{item?.showCategory?.name}</div>
          </div>
          <div
            className={`${styles['title']} ${
              item?.name?.indexOf(' ') >= 0 && styles['title-max-2-line']
            }`}
          >
            {item.name}
          </div>
          <div className={styles['profile']}>
            <div
              className={styles['avatar']}
              data-role='host'
            >
              {item?.profile?.avatar ? (
                <img
                  src={item.profile.avatar || user}
                  alt='Avatar'
                />
              ) : (
                <div
                  className={styles['user-profile-icon']}
                  data-initial-name
                >
                  {getInitial(item?.profile?.name || '')}
                </div>
              )}
            </div>
            {item?.guests?.map((guest, index) => {
              return (
                <div
                  key={guest?.index}
                  className={styles['avatar']}
                  data-role='co-host'
                  style={{ '--co-host-num': 3 - index }}
                >
                  {guest?.avatar ? (
                    <img
                      src={guest.avatar || user}
                      alt='Avatar'
                    />
                  ) : (
                    <div
                      className={styles['user-profile-icon']}
                      data-initial-name
                    >
                      {getInitial(guest?.name || '')}
                    </div>
                  )}
                </div>
              );
            })}
            <span className={styles['name']}>
              {item?.profile?.name?.substring(0, 15)}
              {item?.profile?.name?.length > 15 ? '...' : null}
            </span>
          </div>
        </div>
      </>
    )
  );
};

export default LiveShowOverlay;
