import axios from 'axios';
import { BASE_URL, JWT_APP_ID } from '../../config/URL';
import { generateErrorDetail } from '../../utils';
import { showToastNotif } from './notification';

const URL_GIFTS = `${BASE_URL}/loyalty/v1.0/gifts`;

export const getBulletOptions = () => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: 'GET_PACK'
  });
  try {
    const res = await axios.get(`${BASE_URL}/loyalty/v1.0/bulletComments`, {
      headers: {
        Authorization: state.auth.token
      }
    });
    dispatch({
      type: 'GET_BULLET_OPTION_SUCCESS',
      payload: res.data
    });
    return res.data;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_PACK_ERROR',
      payload: errorData
    });
  }
};

export const getGifts = () => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: 'GET_GIFT_OPTION'
  });
  try {
    const res = await axios.get(`${URL_GIFTS}`, {
      headers: {
        Authorization: state.auth.token,
        'Cloudfront-JWT-AppId': JWT_APP_ID
      }
    });

    const sortGiftPacks =
      res.data.data &&
      res.data.data.map((item) => {
        item.packs = item.packs && item.packs.sort((a, b) => a.price - b.price);

        return item;
      });

    dispatch({
      type: 'GET_GIFT_OPTION_SUCCESS',
      payload: sortGiftPacks
    });
    return sortGiftPacks;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_PACK_ERROR',
      err: errorData
    });
    return err.response && err.response.data;
  }
};

export const getGencash = () => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: 'GET_PACK'
  });
  try {
    const res = await axios.get(`${BASE_URL}/loyalty/v1.0/packs?type=gencash`, {
      headers: {
        Authorization: state.auth.token
      }
    });
    dispatch({
      type: 'GET_GENCASH_SUCCESS',
      payload: res.data
    });
    return res.data;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_PACK_ERROR',
      payload: errorData
    });
  }
};

export const getDiamondTransactions =
  ({ page, perPage = 20, purpose = 'transfer' }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { diamondTransactions } = state.pack;
    const { data, meta, noData } = diamondTransactions;

    dispatch({ type: 'GET_DIAMOND_TRANSACTION' });

    try {
      if (noData || (data.length && (page === 1 || page === meta.page))) {
        dispatch({
          type: 'GET_DIAMOND_TRANSACTION_SUCCESS_FROM_STATE',
          payload: { data, meta }
        });
        return;
      }
      const res = await axios.get(`${BASE_URL}/loyalty/v1.0/transactions`, {
        headers: {
          Authorization: state.auth.token
        },
        params: {
          page,
          perPage,
          purpose
        }
      });

      dispatch({
        type: 'GET_DIAMOND_TRANSACTION_SUCCESS',
        payload: res.data,
        page
      });
      return res.data;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_DIAMOND_TRANSACTION_ERROR',
        payload: errorData
      });
    }
  };

export const getGencashTransactions =
  ({ page, perPage = 20, purpose = 'withdraw' }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { gencashTransactions } = state.pack;
    const { data, meta, noData } = gencashTransactions;

    dispatch({ type: 'GET_GENCASH_TRANSACTION' });

    try {
      if (noData || (data.length && (page === 1 || page === meta.page))) {
        dispatch({
          type: 'GET_GENCASH_TRANSACTION_SUCCESS_FROM_STATE',
          payload: { data, meta }
        });
        return;
      }
      const res = await axios.get(`${BASE_URL}/loyalty/v1.0/transactions`, {
        headers: {
          Authorization: state.auth.token
        },
        params: {
          page,
          perPage,
          purpose
        }
      });

      dispatch({
        type: 'GET_GENCASH_TRANSACTION_SUCCESS',
        payload: res.data,
        page
      });
      return res.data;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_GENCASH_TRANSACTION_ERROR',
        payload: errorData
      });
      throw err;
    }
  };

export const getGencashTransaction = (id) => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: 'GET_GENCASH_TRANSACTION_DETAIL'
  });
  try {
    const res = await axios.get(`${BASE_URL}/loyalty/v1.0/transaction/${id}`, {
      headers: {
        Authorization: state.auth.token
      }
    });
    dispatch({
      type: 'GET_GENCASH_TRANSACTION_DETAIL_SUCCESS',
      payload: res.data
    });
    return res.data;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_GENCASH_TRANSACTION_DETAIL_ERROR',
      payload: errorData
    });
    throw err;
  }
};

export const getOfflineGifting =
  ({ page, perPage = 10, only }) =>
  async (dispatch, getState) => {
    const state = getState();
    const { user } = state.auth;
    const { offlineGifting } = state.pack;
    const { data, meta, noData } = offlineGifting;

    dispatch({ type: 'GET_OFFLINE_GIFTING' });

    try {
      if (noData || (data.length && (page === 1 || page === meta.page))) {
        dispatch({
          type: 'GET_OFFLINE_GIFTING_SUCCESS_FROM_STATE',
          payload: { data, meta }
        });
        return;
      }
      const res = await axios.get(`${BASE_URL}/loyalty/v1.0/transfers`, {
        headers: {
          Authorization: state.auth.token
        },
        params: {
          page,
          perPage,
          accountId: user.account.id,
          only
        }
      });

      dispatch({
        type: 'GET_OFFLINE_GIFTING_SUCCESS',
        payload: res.data,
        page
      });
      return res.data;
    } catch (err) {
      const errorData = generateErrorDetail(err);
      dispatch(
        showToastNotif('error', 'Some error occured, please refresh the page!')
      );
      dispatch({
        type: 'GET_OFFLINE_GIFTING_ERROR',
        payload: errorData
      });
      throw err;
    }
  };

export const getPackSettings = () => async (dispatch, getState) => {
  const state = getState();
  const { settings } = state.pack;

  dispatch({
    type: 'GET_PACK_SETTINGS'
  });
  try {
    if (!Object.keys(settings).length) {
      const res = await axios.get(
        `${BASE_URL}/screen/v1.0/settings?keys[]=minimum_cashout_price&keys[]=gencash_conversion_rate`,
        {
          headers: {
            Authorization: state.auth.token,
            'Cloudfront-JWT-AppId': JWT_APP_ID
          }
        }
      );
      dispatch({
        type: 'GET_PACK_SETTINGS_SUCCESS',
        payload: res.data
      });
      return res.data;
    }
    dispatch({
      type: 'GET_PACK_SETTINGS_SUCCESS_FROM_STATE',
      payload: settings
    });
    return settings;
  } catch (err) {
    const errorData = generateErrorDetail(err);
    dispatch({
      type: 'GET_PACK_ERROR',
      payload: errorData
    });
  }
};
