const initialState = {
  detailData: {},
  mediaAccess: {},
  movies: [],
  series: [],
  season: [],
  episode: [],
  loading: false,
  loadingMediaAccess: false,
  err: null
};

export default function mediaReducer(state = initialState, action) {
  const { type, payload, err, detailData } = action;

  switch (type) {
    case 'GET_MEDIA_DETAIL':
      return {
        ...state,
        loading: true
      };

    case 'GET_MEDIA_DETAIL_SUCCESS':
      return {
        ...state,
        detailData,
        movies: payload[0],
        series: payload[1],
        season: [payload[2], ...state.season],
        loading: false
      };

    case 'SET_EPISODES':
      return {
        ...state,
        episode: [payload, ...state.episode],
        loading: false
      };

    case 'GET_MEDIA_DETAIL_SUCCESS_FROM_STATE':
      return {
        ...state,
        detailData,
        movies: payload[0],
        series: payload[1],
        season:
          payload[2]?.length > 0 ? [payload[2], ...state.season] : state.season,
        loading: false
      };

    case 'GET_MEDIA_DETAIL_ERROR':
      return {
        ...state,
        loading: false,
        err
      };

    case 'GET_MEDIA_ACCESS':
      return {
        ...state,
        loadingMediaAccess: true,
        err: null
      };
    case 'GET_MEDIA_ACCESS_SUCCESS':
      return {
        ...state,
        mediaAccess: payload,
        loadingMediaAccess: false,
        err: null
      };
    case 'GET_MEDIA_ACCESS_FAILED':
      return {
        ...state,
        mediaAccess: err,
        loadingMediaAccess: false
      };

    case 'CLEAR_MEDIA_DETAIL':
      return {
        ...state,
        detailData: {},
        mediaAccess: {},
        loading: false,
        err: null
      };

    case 'CLEAR_MEDIA_ACCESS':
      return {
        ...state,
        mediaAccess: {}
      };

    case 'ADD_LIST_SUCCESS':
      return {
        ...state,
        detailData: {
          ...state.detailData,
          addedToMyList: true
        }
      };
    case 'SET_LIST_STATUS':
      return {
        ...state,
        detailData: {
          ...state.detailData,
          addedToMyList: payload
        }
      };

    case 'ADD_LIST_ERROR':
      return {
        err
      };
    case 'REMOVE_LIST_SUCCESS':
      return {
        ...state,
        detailData: {
          ...state.detailData,
          addedToMyList: false
        }
      };

    case 'REMOVE_LIST_ERROR':
      return {
        err
      };
    default:
      return state;
  }
}
