import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '..';
import styles from './StreamSetupModal.module.css';

import streamerImg from '../../assets/streamer.svg';
import icView from '../../assets/views-icon.svg';
import icViewDisabled from '../../assets/view-disabled-icon.svg';
import { showToastNotif } from '../../redux/actions/notification';
import GeneralNotifModal from '../GeneralModal/generalNotifModal';

const StreamSetupModal = ({ rtmpInfo, onCloseModal, open }) => {
  const dispatch = useDispatch();
  const [showStreamKey, setShowStreamKey] = useState(false);
  const serverUrlRef = useRef();
  const streamKeyRef = useRef();

  const handleCopyToClipboard = (element) => {
    if (element) {
      const elType = element.type;

      element.type = 'text';
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand('copy');

      element.type = elType === 'password' && elType;
      dispatch(showToastNotif('success', 'Successfully copied to clipboard'));
    }
  };

  return (
    <GeneralNotifModal
      open={open}
      contentClass={styles['modalWrapper']}
    >
      <div className={styles['modalBox']}>
        <div className={styles['modalHeader']}>
          <h3>Stream Setup</h3>
        </div>
        <div className={styles['modalBanner']}>
          <img
            src={streamerImg}
            alt='streamer'
          />
        </div>
        <div className={styles['modalContent']}>
          <ol>
            <li>
              <p>Download and set up your streaming software</p>
            </li>
            <li>
              <p>Paste the stream key into your software</p>
            </li>
            <div className={styles['streamKeyBox']}>
              <section>
                <div className={styles['labelSection']}>
                  <p>Server URL: </p>
                  <span
                    onClick={() => handleCopyToClipboard(serverUrlRef.current)}
                  >
                    Copy to clipboard
                  </span>
                </div>
                <div>
                  <input
                    ref={serverUrlRef}
                    className={styles['editInput']}
                    value={rtmpInfo && rtmpInfo.url}
                  />
                </div>
              </section>
              <section>
                <div className={styles['labelSection']}>
                  <p>Stream Key: </p>
                  <span
                    onClick={() => handleCopyToClipboard(streamKeyRef.current)}
                  >
                    Copy to clipboard
                  </span>
                </div>
                <div>
                  <div className={styles['inputSection']}>
                    <input
                      ref={streamKeyRef}
                      className={styles['editInput']}
                      value={rtmpInfo && rtmpInfo.key}
                      type={showStreamKey ? 'text' : 'password'}
                    />
                    <img
                      src={showStreamKey ? icView : icViewDisabled}
                      alt='view'
                      onClick={() => setShowStreamKey(!showStreamKey)}
                    />
                  </div>
                </div>
              </section>
            </div>
            <li>
              <p>
                Click ‘
                <span className={styles['boldText']}>Start Streaming</span>’ in
                Streamer Dashboard (Web) or Genflix app, and Broadcaster
                software
              </p>
            </li>
          </ol>
          <div className={styles['center']}>
            <Button
              width='120px'
              onClick={onCloseModal}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </GeneralNotifModal>
  );
};

export default StreamSetupModal;
