const initialState = {
  data: null,
  meta: null,
  page: 1,
  loading: false,
  err: null
};

export default function channelReducer(state = initialState, action) {
  const { type, payload, page, err } = action;

  switch (type) {
    case 'GET_CHANNEL':
      return {
        ...state,
        loading: true,
        err: null
      };

    case 'GET_CHANNEL_SUCCESS':
      return {
        ...state,
        loading: false,
        data: page === 1 ? payload.data : [...state.data, ...payload.data],
        meta: payload.meta,
        page
      };

    case 'GET_CHANNEL_SUCCESS_FROM_STATE':
      return {
        ...state,
        loading: false,
        data: payload.data,
        meta: payload.meta,
        page
      };

    case 'FETCH_NEXT_PAGE_LIVE_TV':
      return {
        ...state,
        page: state.page + 1
      };

    case 'GET_CHANNEL_FAILURE':
      return {
        ...state,
        loading: false,
        err
      };

    default:
      return state;
  }
}
